import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadAdminExpenseListAPI, freelancersDDLAPI, expenseApproveRejectAPI, ExpenseAPI, getExpenseTypesDDLAPI, dispatchLoadingStatus, getstatecityAPI, getProjectTitleAPI, getRolesAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import MainDashbord from "../Sidebar/MainDashbord";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import blankImg from '../../assets/images/blank.jpg'

function Expenses() {
  let wrapper1 = document.getElementById("wrapper1");
  let wrapper2 = document.getElementById("wrapper2");

  const [show, setShow] = useState(false);
  const [show22, setShow22] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const dispatch = useDispatch({});
  let history = useNavigate();

  let getExpenseTypesDDLRes = useSelector((state) => state.globalReducer.getExpenseTypesDDLRes);
  const [order, setOrder] = useState("ASC");

  let token = localStorage.getItem("token");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  const [startDate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setpage] = useState(1);
  const [expenseE, setExpenseE] = useState("");
  const [freelancerCall, setFreelancerCall] = useState("");
  const [statusAR, setStatusAR] = useState("");
  const [allData22, setAllData22] = useState();
  const [freelancerList, setFreelancerList] = useState();

  const status = [
    {
      label: "Approved",
      value: "5",
    },
    {
      label: "Rejected",
      value: "4",
    },
  ];

  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [rej, setRej] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [approveAmount, setApproveAmount] = useState("");
  const [approveAmountError, setApproveAmountError] = useState(false);
  const [approveAmountError22, setApproveAmountError22] = useState(false);
  const [approveAmountError33, setApproveAmountError33] = useState(false);
  const [approveID, setAprroveID] = useState("");

  function handleApproveAmount(e) {
    let val = e.target.value;
    const CenterPhonere = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;

    if (val) {
      if (CenterPhonere.test(val)) {
        setApproveAmountError(false);
        setApproveAmountError22(false);
        setApproveAmount(val);
      } else {
        setApproveAmountError(false);
        setApproveAmountError22(true);
        setApproveAmount("");
      }
    } else {
      setApproveAmountError(true);
      setApproveAmountError22(false);
      setApproveAmount("");
    }
  }

  const handleReason = (e) => {
    setReason(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }
  };
  const Accept = (i) => {
    const CenterPhonere = /^[0-9\b]+$/;
    if (approveAmount) {
      if (CenterPhonere.test(approveAmount)) {
        setApproveAmountError(false);
        setApproveAmountError22(false);
        setApproveAmountError33(false);

        if (JSON.parse(approveAmount) <= JSON.parse(claimAmount)) {
          setApproveAmountError(false);
          setApproveAmountError22(false);
          setApproveAmountError33(false);
        } else {
          setApproveAmountError(false);
          setApproveAmountError22(false);
          setApproveAmountError33(true);
        }
      } else {
        setApproveAmountError(false);
        setApproveAmountError22(true);
        setApproveAmountError33(false);
      }
    } else {
      setApproveAmountError(true);
      setApproveAmountError22(false);
      setApproveAmountError33(false);
    }

    if (token && approveID && approveAmount && JSON.parse(approveAmount) <= JSON.parse(claimAmount)) {
      let formData = new FormData();

      formData.append("token", token);
      formData.append("expense_id", approveID);
      formData.append("isApproved", 1);
      formData.append("approved_expense_amount", approveAmount);
      dispatch(dispatchLoadingStatus(true));
      dispatch(expenseApproveRejectAPI(formData));
      setShow(false);
      Apicall((page - 1) * 20, page);
    }
  };

  const Reject = () => {
    if (reason == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }

    if (token && rej && reason) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("expense_id", rej);
      formData.append("isApproved", 0);
      formData.append("reject_reason", reason);
      dispatch(dispatchLoadingStatus(true));
      dispatch(expenseApproveRejectAPI(formData));
      setShow22(false);
      Apicall((page - 1) * 20, page);
    }
  };

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("ExpenseAPI", false);
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    dispatch(getProjectTitleAPI(formData));
    dispatch(getRolesAPI(formData));
    dispatch(getExpenseTypesDDLAPI());
    dispatch(freelancersDDLAPI(formData));
  }, []);

  function Apicall(start, pageno) {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("start_date", startDate ? moment(startDate).format("DD/MM/yyyy") : "");
    formData.append("end_date", endDate ? moment(endDate).format("DD/MM/yyyy") : "");
    formData.append("expense_type", expenseE);
    formData.append("freelancer_id", freelancerCall);
    formData.append("status", statusAR);

    if (startDate || endDate || expenseE || freelancerCall || freelancerCall == "" || statusAR || statusAR == "") {
      if (localStorage.getItem("ExpenseAPI") != "true") {
        localStorage.setItem("ExpenseAPI", true);
        setpage(pageno ? pageno : 1);
        dispatch(ExpenseAPI(formData));
      }
    } else {
      if (localStorage.getItem("ExpenseAPI") != "true") {
        localStorage.setItem("ExpenseAPI", true);
        setpage(pageno ? pageno : 1);
        dispatch(dispatchLoadingStatus(true));
        dispatch(ExpenseAPI(formData));
      }
    }
  }

  let ExpenseRes = useSelector((state) => state.globalReducer.ExpenseRes);
  let total = ExpenseRes ? ExpenseRes.count : "";
  let freelancersDDLRes = useSelector((state) => state.globalReducer.freelancersDDLRes);

  useEffect(() => {
    if (freelancersDDLRes) {
      if (freelancersDDLRes.errorcode == 1) {
        setFreelancerList([]);
      } else {
        setFreelancerList(freelancersDDLRes ? freelancersDDLRes.data : freelancersDDLRes.data);
      }
    }
  }, [freelancersDDLRes]);

  useEffect(() => {
    if (ExpenseRes) {
      if (ExpenseRes.errorcode == 1) {
        setAllData22([]);
      } else {
        setAllData22(ExpenseRes ? ExpenseRes.data : ExpenseRes.data);
      }
    }
  }, [ExpenseRes]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [expenseE]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [freelancerCall]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [statusAR]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [startDate]);
  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [endDate]);

  function downloadcsv() {
    if (allData22.length > 0) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("start_date", startDate ? moment(startDate).format("DD/MM/yyyy") : "");
      formData.append("end_date", endDate ? moment(endDate).format("DD/MM/yyyy") : "");
      formData.append("expense_type", expenseE ? expenseE : "");
      formData.append("freelancer_id", freelancerCall);
      dispatch(dispatchLoadingStatus(true));
      dispatch(downloadAdminExpenseListAPI(formData));
    } else {
      swal("", "No data found", "error");
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");

    if (tokenRolee.find((t) => t === "Expenses")) {
      if (token) {
        if (!window.location.href.includes("Expenses")) {
          window.location.href = window.location.origin + "/Expenses";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  return (
    <>
      <MainDashbord />
      <Loader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Expenses</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                        Dashboard
                      </a>
                      <a href="/Expenses" className="breadcrumb-item active">
                        Expenses
                      </a>
                    </ol>
                    <div className="">
                      <button className=" mt-4 btn btn-primary float-lg-end waves-effect waves-light" onClick={() => downloadcsv()}>
                        <i className="bx bx-download font-size-17"></i> Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-rep-plugin">
                      <div className="row mb-3 no-gutters">
                        <div className="col-md-4">
                          <form className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                          </form>
                        </div>

                        <div className="col-md-8">
                          <div className="row align-items-center">
                            <div className="col-md-4 col-6 mb-4">
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setStartdate(value);
                                }}
                                selected={startDate ? startDate : null}
                                placeholderText={"Start date"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                maxDate={endDate ? endDate : moment().toDate()}
                              />
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setEndDate(value);
                                }}
                                selected={endDate ? endDate : null}
                                placeholderText={"End date"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                minDate={startDate}
                                maxDate={moment().toDate()}
                              />
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                              <Select options={getExpenseTypesDDLRes ? [{ label: "Expense type", value: "" }].concat(getExpenseTypesDDLRes.data.map((e) => ({ label: e.expense_type, value: e._id }))) : []} isClearable={false} placeholder={"Expense type"} onChange={(e) => setExpenseE(e.value)} />
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                              <Select options={freelancerList ? [{ label: "Freelancer", value: "" }].concat(freelancerList.map((e) => ({ label: e.freelancer_full_name, value: e._id }))) : []} isClearable={false} placeholder={"Freelancer"} onChange={(e) => setFreelancerCall(e.value)} />
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                              <Select options={status ? [{ label: "Status", value: "" }].concat(status.map((e) => ({ label: e.label, value: e.value }))) : []} isClearable={false} placeholder={"Status"} onChange={(e) => setStatusAR(e.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                      Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {ExpenseRes ? ExpenseRes.count : ""} entries
                      <div
                        id="wrapper1"
                        onScroll={() => {
                          if (wrapper2) {
                            wrapper2.scrollLeft = wrapper1.scrollLeft;
                          }
                        }}
                      >
                        <div id="div1"></div>
                      </div>
                      <div
                        className="table-responsive"
                        id="wrapper2"
                        onScroll={() => {
                          if (wrapper1) {
                            wrapper1.scrollLeft = wrapper2.scrollLeft;
                          }
                        }}
                      >
                        <div id="div2" className="scrollbar">
                          <table id="example" className="table table-striped no-wrap dt-responsive force-overflow">
                            <thead>
                              <tr>
                                <th> Sr No. </th>
                                <th>
                                  Freelancer
                                  <i onClick={() => Sortt("Name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="3">
                                  Expense type
                                  <i onClick={() => Sortt("expense_type")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="1">
                                  Claimed Amount
                                  <i onClick={() => Sortt("expense_amount")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="1">
                                  Approved Amount
                                  <i onClick={() => Sortt("expense_amount")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="3">
                                  Remarks
                                  <i onClick={() => Sortt("expense_remarks")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="1">Photo</th>

                                <th data-priority="1">
                                  Created at
                                  <i onClick={() => Sortt("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="1">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allData22 &&
                                allData22.map((i, index) => (
                                  <tr>
                                    <th> {page * 20 + index + 1 - 20}.</th>
                                    <td>
                                      <div className="d-flex ">
                                        <div className="flex-shrink-0 me-3">
                                          <Zoom>
                                            {" "}
                                            <img className="d-flex-object rounded-circle avatar-xs" alt="" src={i.profiepic ? i.profiepic : blankImg} />
                                          </Zoom>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-weight-bold mb-1">{i.Name}</h5>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{i.expense_type}</td>
                                    <td>{i.expense_amount}</td>
                                    <td>{i.approved_expense_amount ? i.approved_expense_amount : "-"}</td>
                                    <td>{i.expense_remarks ? i.expense_remarks : <center>-</center>}</td>

                                    <td>
                                      <Zoom> {i.expense_img ? <img style={{ width: "60px" }} src={i.expense_img}></img> : <center>-</center>}</Zoom>{" "}
                                    </td>
                                    <td>{i.created_at}</td>

                                    {i.status == "4" ? (
                                      <td>
                                        {" "}
                                        <center>
                                          <div style={{ color: "red" }}>Rejected</div>
                                        </center>{" "}
                                      </td>
                                    ) : i.status == "5" ? (
                                      <td>
                                        <center>
                                          <div style={{ color: "green" }}>Approved</div>
                                        </center>{" "}
                                      </td>
                                    ) : i.status == "1" ? (
                                      <td>
                                        <div className="d-flex">
                                          <button
                                            type="button"
                                            className="btn btn-success me-2  waves-effect waves-light"
                                            id="sa-success-grt"
                                            onClick={() => {
                                              setAprroveID(i._id);
                                              setApproveAmountError33(false);
                                              setClaimAmount(i.expense_amount);
                                              setApproveAmountError(false);
                                              setApproveAmountError22(false);
                                              setApproveAmount("");
                                              handleShow();
                                            }}
                                          >
                                            <i className="mdi mdi-check"></i> Accept
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-primary  waves-effect waves-light"
                                            onClick={() => {
                                              handleShow22();
                                              setRej(i._id);
                                              setReasonError("");
                                              setReason("");
                                            }}
                                          >
                                            <i className="mdi mdi-close"></i>Reject
                                          </button>
                                        </div>
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {allData22 ? (
                            allData22.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}
                        </div>
                      </div>
                      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                          <Modal.Title>Approve expense amount </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="modal-body">
                            <label htmlFor="validationCustom05" className="form-label">
                              Claimed expense amount *
                            </label>

                            <input type="text" className="form-control" placeholder="Claim amount" value={claimAmount} disabled maxLength="6" />
                          </div>

                          <div className="modal-body">
                            <label htmlFor="validationCustom05" className="form-label">
                              Approve expense amount *
                            </label>

                            <input type="text" className="form-control" placeholder="Approve amount" value={approveAmount} onChange={(e) => handleApproveAmount(e)} />
                            {approveAmountError ? approveAmountError && <span style={{ color: "#BE312E" }}>Please enter approve amount</span> : approveAmountError22 ? approveAmountError22 && <span style={{ color: "#BE312E" }}>Please enter valid approve amount</span> : approveAmountError33 ? approveAmountError33 && <span style={{ color: "#BE312E" }}>Please enter less amount claim</span> : ""}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button type="button" className="btn btn-light" onClick={handleClose}>
                            Close
                          </button>

                          <button type="button" className="btn btn-primary" onClick={() => Accept()}>
                            Submit
                          </button>
                        </Modal.Footer>
                      </Modal>
                      <Modal show={show22} onHide={handleClose22} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                          <Modal.Title>Reason for Rejection </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form>
                            <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason}></textarea>
                          </form>
                          {reasonError}
                        </Modal.Body>
                        <Modal.Footer>
                          <button type="button" className="btn btn-light" onClick={handleClose22}>
                            Close
                          </button>
                          {reason == "" ? (
                            <button type="button" className="btn btn-primary" onClick={Reject}>
                              Submit
                            </button>
                          ) : (
                            <button type="button" id="close" className="btn btn-primary" data-bs-dismiss="modal" onClick={Reject}>
                              Submit
                            </button>
                          )}
                        </Modal.Footer>
                      </Modal>
                      <div className=" btns-table align-items-center justify-content-end">
                        {ExpenseRes ? (
                          ExpenseRes.count > 0 ? (
                            <div className="row align-items-center wrapper-pg-main">
                              <div className="col-6">
                                Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {ExpenseRes ? ExpenseRes.count : ""} entries
                              </div>
                              <div className="col-6">
                                <div className="wrapper-pgn">
                                  <div className="pgn pgn-default">
                                    <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                      <i className="bx bx-chevrons-left"></i>
                                    </div>
                                    <div className="pages" id="content">
                                      {(() => {
                                        const pages = [];

                                        for (let i = 1; i < ExpenseRes.count / 20 + 1; i++) {
                                          pages.push(
                                            <div
                                              className={page == i ? "page page-btn active" : "page page-btn"}
                                              onClick={() => {
                                                Apicall((i - 1) * 20);
                                                setpage(i);
                                              }}
                                            >
                                              {i}
                                            </div>
                                          );
                                        }

                                        return pages;
                                      })()}
                                    </div>
                                    <div onClick={() => document.getElementById("content").scrollBy(50, 0)} className=" next ">
                                      <i className="bx bx-chevrons-right"></i>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script src="%PUBLIC_URL%/libs/magnific-popup/jquery.magnific-popup.min.js"></script>
      <script src="%PUBLIC_URL%/js/pages/lightbox.init.js"></script>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Powered by{" "}
                <a href="https://www.nichetechsolutions.com/" target="_blank">
                  Nichetech.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Expenses;
