
import {
  LoginReg, sendOtpReg, AdminLoginReg, getStatecity, getfreelancersReg, getstatecityReg, getRolesReg, getProfile,
  getQulificationsReg, getProjectsRes, getProjectddlRes, getProjectTypeReg, getProjectTitleRes, AssignProjectRes, centersbyfreelanceprojectIdRes, getCenterListRes, freelancersAssignedCentersRes,
  getadminattendancelistRes, getCenterNameRes, AttendanceCSVRes, attendanceapproverejectRes, getAdminCompletedAuditListRes, getCentersByProjectIdRes, TypeSchoolRes, CategoryRes, changeCenterIsActiveRes, importCenterRes, addEditCenterDataRes, getStatesCitiesByProjectIdRes, changeProjectIsActiveRes,
  getExpenseTypesDDLRes, ExpenseRes, expenseApproveRejectRes, dashboardDataRes, getDashboardProjectListRes,
  sendOtpInEmailRes, OtpVerifyForEmailRes, resetPasswordRes, sendMessageRes, getAdminMessagesListRes,
  addEditAdminUserRes, changeAdminUserIsActiveRes, getAdminUsersListRes, getFreelancerRoleListRes,
  addEditFreelancerRoleRes, deleteFreelancerRoleDataRes, changeFreelancerRoleIsActiveRes, addReviewRes,
  associateCentersWithProjectRes, getAttendanceHistoryListRes, associatedCentersByProjectIdRes, listCentersByProjectIdRes,
  freelancersDDLRes, downloadAdminExpenseListRes, assignTeamToProjectCenterRes, pendingCentersByFreelancerProjectIdRes, assignCentersToFreelancerRolesRes,
  getFreelancerDataPdfRes, getExamsRes, CenterAddReviewRes, getCenterAuditedImagesRes, getCenterProjectsRes, getAdminProfileByIdRes, updateAdminProfileByIdRes,
  ActiveInactiveFreelancerRes, EditProjectReg, downloadCenterDataReg, getActiveFreelancersReg, importFreelancersDataReg, deleteFreelancerReg, paginationReg,
  projectVsFreelancerChartData, projectVsCenterChartData, updateCentersLatLongReg, freelancerDataReg, centerDataReg , attendenceDataReg , projectDataReg
  ,deleteCenter_URL
} from '../../Constant';


let defaultState = {


  LoginReg: null,
  sendOtpReg: null,
  AdminLoginReg: null,
  getfreelancersReg: null,
  getstatecityReg: null,
  getRolesReg: null,
  getProfile: null,
  getQulificationsReg: null,
  getProjectsRes: null,
  getProjectddlRes: null,
  getStatecity: null,
  getProjectTypeReg: null,
  centersbyfreelanceprojectIdRes: null,
  getCenterListRes: null,
  AssignProjectRes: null,
  freelancersAssignedCentersRes: null,
  getadminattendancelistRes: null,
  getCenterNameRes: null,
  AttendanceCSVRes: null,
  attendanceapproverejectRes: null,
  getAdminCompletedAuditListRes: null,
  getCentersByProjectIdRes: null,
  TypeSchoolRes: null,
  CategoryRes: null,
  changeCenterIsActiveRes: null,
  importCenterRes: null,
  addEditCenterDataRes: null,
  getStatesCitiesByProjectIdRes: null,
  changeProjectIsActiveRes: null,
  getExpenseTypesDDLRes: null,
  ExpenseRes: null,
  expenseApproveRejectRes: null,
  dashboardDataRes: null,
  getDashboardProjectListRes: null,
  sendOtpInEmailRes: null,
  OtpVerifyForEmailRes: null,
  resetPasswordRes: null,
  sendMessageRes: null,
  getAdminMessagesListRes: null,
  addEditAdminUserRes: null,
  changeAdminUserIsActiveRes: null,
  getAdminUsersListRes: null,
  getFreelancerRoleListRes: null,
  addEditFreelancerRoleRes: null,
  deleteFreelancerRoleDataRes: null,
  changeFreelancerRoleIsActiveRes: null,
  addReviewRes: null,
  associateCentersWithProjectRes: null,
  getAttendanceHistoryListRes: null,
  associatedCentersByProjectIdRes: null,
  listCentersByProjectIdRes: null,
  freelancersDDLRes: null,
  downloadAdminExpenseListRes: null,
  assignTeamToProjectCenterRes: null,
  pendingCentersByFreelancerProjectIdRes: null,
  assignCentersToFreelancerRolesRes: null,
  getFreelancerDataPdfRes: null,
  getExamsRes: null,
  CenterAddReviewRes: null,
  getCenterAuditedImagesRes: null,
  getCenterProjectsRes: null,
  getAdminProfileByIdRes: null,
  updateAdminProfileByIdRes: null,
  ActiveInactiveFreelancerRes: null,
  EditProjectReg: null,
  downloadCenterDataReg: null,
  getActiveFreelancersReg: null,
  importFreelancersDataReg: null,
  deleteFreelancerReg: null,
  paginationReg: null,
  updateCentersLatLongReg: null,
  freelancerDataReg: null,
  centerDataReg: null ,
  projectDataReg : null ,
  attendenceDataReg : null

}

const globalReducer = (state = defaultState, action) => {

  switch (action.type) {


    case LoginReg:
      return Object.assign({}, state, {
        LoginReg: action.payload
      });

    case sendOtpReg:
      return Object.assign({}, state, {
        sendOtpReg: action.payload
      });

    case AdminLoginReg:
      return Object.assign({}, state, {
        AdminLoginReg: action.payload
      });

    case getfreelancersReg:
      return Object.assign({}, state, {
        getfreelancersReg: action.payload
      });

    case getstatecityReg:
      return Object.assign({}, state, {
        getstatecityReg: action.payload
      });

    case getRolesReg:
      return Object.assign({}, state, {
        getRolesReg: action.payload
      });

    case getProfile:
      return Object.assign({}, state, {
        getProfile: action.payload
      });

    case getQulificationsReg:
      return Object.assign({}, state, {
        getQulificationsReg: action.payload
      });
    case getProjectsRes:
      return Object.assign({}, state, {
        getProjectsRes: action.payload
      });
    case getProjectddlRes:
      return Object.assign({}, state, {
        getProjectddlRes: action.payload
      });
    case getStatecity:
      return Object.assign({}, state, {
        getStatecity: action.payload
      });
    case getProjectTypeReg:
      return Object.assign({}, state, {
        getProjectTypeReg: action.payload
      });
    case getProjectTitleRes:
      return Object.assign({}, state, {
        getProjectTitleRes: action.payload
      });
    case centersbyfreelanceprojectIdRes:
      return Object.assign({}, state, {
        centersbyfreelanceprojectIdRes: action.payload
      });
    case getCenterListRes:
      return Object.assign({}, state, {
        getCenterListRes: action.payload
      });
    case AssignProjectRes:
      return Object.assign({}, state, {
        AssignProjectRes: action.payload
      });
    case freelancersAssignedCentersRes:
      return Object.assign({}, state, {
        freelancersAssignedCentersRes: action.payload
      });
    case getadminattendancelistRes:
      return Object.assign({}, state, {
        getadminattendancelistRes: action.payload
      });

    case getCenterNameRes:
      return Object.assign({}, state, {
        getCenterNameRes: action.payload
      });

    case AttendanceCSVRes:
      return Object.assign({}, state, {
        AttendanceCSVRes: action.payload
      });

    case attendanceapproverejectRes:
      return Object.assign({}, state, {
        attendanceapproverejectRes: action.payload
      });

    case getAdminCompletedAuditListRes:
      return Object.assign({}, state, {
        getAdminCompletedAuditListRes: action.payload
      });

    case getCentersByProjectIdRes:
      return Object.assign({}, state, {
        getCentersByProjectIdRes: action.payload
      });

    case TypeSchoolRes:
      return Object.assign({}, state, {
        TypeSchoolRes: action.payload
      });

    case CategoryRes:
      return Object.assign({}, state, {
        CategoryRes: action.payload
      });

    case changeCenterIsActiveRes:
      return Object.assign({}, state, {
        changeCenterIsActiveRes: action.payload
      });

    case importCenterRes:
      return Object.assign({}, state, {
        importCenterRes: action.payload
      });

    case addEditCenterDataRes:
      return Object.assign({}, state, {
        addEditCenterDataRes: action.payload
      });

    case getStatesCitiesByProjectIdRes:
      return Object.assign({}, state, {
        getStatesCitiesByProjectIdRes: action.payload
      });

    case changeProjectIsActiveRes:
      return Object.assign({}, state, {
        changeProjectIsActiveRes: action.payload
      });

    case getExpenseTypesDDLRes:
      return Object.assign({}, state, {
        getExpenseTypesDDLRes: action.payload
      });


    case ExpenseRes:
      return Object.assign({}, state, {
        ExpenseRes: action.payload
      });

    case expenseApproveRejectRes:
      return Object.assign({}, state, {
        expenseApproveRejectRes: action.payload
      });


    case dashboardDataRes:
      return Object.assign({}, state, {
        dashboardDataRes: action.payload
      });

    case getDashboardProjectListRes:
      return Object.assign({}, state, {
        getDashboardProjectListRes: action.payload
      });


    case sendOtpInEmailRes:
      return Object.assign({}, state, {
        sendOtpInEmailRes: action.payload
      });


    case OtpVerifyForEmailRes:
      return Object.assign({}, state, {
        OtpVerifyForEmailRes: action.payload
      });

    case resetPasswordRes:
      return Object.assign({}, state, {
        resetPasswordRes: action.payload
      });

    case sendMessageRes:
      return Object.assign({}, state, {
        sendMessageRes: action.payload
      });

    case getAdminMessagesListRes:
      return Object.assign({}, state, {
        getAdminMessagesListRes: action.payload
      });

    case addEditAdminUserRes:
      return Object.assign({}, state, {
        addEditAdminUserRes: action.payload
      });

    case changeAdminUserIsActiveRes:
      return Object.assign({}, state, {
        changeAdminUserIsActiveRes: action.payload
      });

    case getAdminUsersListRes:
      return Object.assign({}, state, {
        getAdminUsersListRes: action.payload
      });

    case getFreelancerRoleListRes:
      return Object.assign({}, state, {
        getFreelancerRoleListRes: action.payload
      });

    case addEditFreelancerRoleRes:
      return Object.assign({}, state, {
        addEditFreelancerRoleRes: action.payload
      });

    case deleteFreelancerRoleDataRes:
      return Object.assign({}, state, {
        deleteFreelancerRoleDataRes: action.payload
      });

    case changeFreelancerRoleIsActiveRes:
      return Object.assign({}, state, {
        changeFreelancerRoleIsActiveRes: action.payload
      });

    case addReviewRes:
      return Object.assign({}, state, {
        addReviewRes: action.payload
      });

    case associateCentersWithProjectRes:
      return Object.assign({}, state, {
        associateCentersWithProjectRes: action.payload
      });

    case getAttendanceHistoryListRes:
      return Object.assign({}, state, {
        getAttendanceHistoryListRes: action.payload
      });

    case associatedCentersByProjectIdRes:
      return Object.assign({}, state, {
        associatedCentersByProjectIdRes: action.payload
      });

    case listCentersByProjectIdRes:
      return Object.assign({}, state, {
        listCentersByProjectIdRes: action.payload
      });

    case freelancersDDLRes:
      return Object.assign({}, state, {
        freelancersDDLRes: action.payload
      });

    case downloadAdminExpenseListRes:
      return Object.assign({}, state, {
        downloadAdminExpenseListRes: action.payload
      });

    case assignTeamToProjectCenterRes:
      return Object.assign({}, state, {
        assignTeamToProjectCenterRes: action.payload
      });

    case pendingCentersByFreelancerProjectIdRes:
      return Object.assign({}, state, {
        pendingCentersByFreelancerProjectIdRes: action.payload
      });

    case assignCentersToFreelancerRolesRes:
      return Object.assign({}, state, {
        assignCentersToFreelancerRolesRes: action.payload
      });

    case getFreelancerDataPdfRes:
      return Object.assign({}, state, {
        getFreelancerDataPdfRes: action.payload
      });

    case getExamsRes:
      return Object.assign({}, state, {
        getExamsRes: action.payload
      });

    case CenterAddReviewRes:
      return Object.assign({}, state, {
        CenterAddReviewRes: action.payload
      });

    case getCenterAuditedImagesRes:
      return Object.assign({}, state, {
        getCenterAuditedImagesRes: action.payload
      });

    case getCenterProjectsRes:
      return Object.assign({}, state, {
        getCenterProjectsRes: action.payload
      });

    case getAdminProfileByIdRes:
      return Object.assign({}, state, {
        getAdminProfileByIdRes: action.payload
      });

    case updateAdminProfileByIdRes:
      return Object.assign({}, state, {
        updateAdminProfileByIdRes: action.payload
      });

    case ActiveInactiveFreelancerRes:
      return Object.assign({}, state, {
        ActiveInactiveFreelancerRes: action.payload
      });
    case EditProjectReg:
      return Object.assign({}, state, {
        EditProjectReg: action.payload
      });
    case downloadCenterDataReg:
      return Object.assign({}, state, {
        downloadCenterDataReg: action.payload
      });
    case getActiveFreelancersReg:
      return Object.assign({}, state, {
        getActiveFreelancersReg: action.payload
      });
    case importFreelancersDataReg:
      return Object.assign({}, state, {
        importFreelancersDataReg: action.payload
      });
    case deleteFreelancerReg:
      return Object.assign({}, state, {
        deleteFreelancerReg: action.payload
      });
    case paginationReg:
      return Object.assign({}, state, {
        paginationReg: action.payload
      });
    //added by kajal 11-07-2023
    case projectVsFreelancerChartData: {
      // debugger
      return Object.assign({}, state, {
        projectVsFreelancerChartData: action.payload
      })
    }
    case projectVsCenterChartData: {
      return Object.assign({}, state, {
        projectVsCenterChartData: action.payload
      })
    }

    case updateCentersLatLongReg:
      return Object.assign({}, state, {
        updateCentersLatLongReg: action.payload
      });

    case freelancerDataReg:
      return Object.assign({}, state, {
        freelancerDataReg: action.payload
      });
    case centerDataReg:
      return Object.assign({}, state, {
        centerDataReg: action.payload
      });

      case projectDataReg:
        return Object.assign({}, state, {
          projectDataReg: action.payload
        });

      case attendenceDataReg:
        return Object.assign({}, state, {
          attendenceDataReg: action.payload
        });
             
       case deleteCenter_URL :
        return Object.assign({},state,{
          deleteCenter_URL:action.payload
        });
    default:
      return state;
  }



};
export default globalReducer;

