import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainDashbord from "../Sidebar/MainDashbord";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

export default function MessageDetails() {
  let history = useNavigate();

  const { state } = useLocation();

  const [centerName, setCenterName] = useState();

  useEffect(() => {
    setCenterName(state ? state.center_names.split(",") : []);
  }, []);

  return (
    <>
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Messages Details</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => history("/Message")} className="breadcrumb-item active">
                          Messages list
                        </a>
                        <a href="/MessageDetails" className="breadcrumb-item active">
                          Message Details{" "}
                        </a>
                      </ol>
                      <div className="">
                        <a onClick={() => history("/Message")} className=" float-lg-end  mt-4 btn btn-primary waves-effect waves-light">
                          Back to List
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-responsive mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Project Title :</th>
                                <td>{state ? state.projectTitle : "-"}</td>
                              </tr>

                              <tr>
                                <th scope="row">Role :</th>
                                <td>{state ? state.role : "-"}</td>
                              </tr>

                              <tr>
                                <th style={{ whiteSpace: "inherit" }} scope="row">
                                  {" "}
                                  Message:
                                </th>
                                <td>{state ? state.message : "-"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-responsive mb-0">
                            <tbody>
                              <tr>
                                <th className="pt-0" scope="row">
                                  Center Name :
                                </th>
                                {centerName &&
                                  centerName.map((i) => (
                                    <tr>
                                      {" "}
                                      <td> {i}</td>{" "}
                                    </tr>
                                  ))}
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
