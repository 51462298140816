import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import MainDashbord from '../Sidebar/MainDashbord'; 
import Loader from '../Loader/Loader';
import { useNavigate } from "react-router-dom";
import moment from "moment";


export default function ProjectDetail() {
    let history = useNavigate();

    const { state } = useLocation();

  useEffect(()=>{
    localStorage.removeItem("projectTitle22")
    localStorage.removeItem("projectTitle")

    localStorage.removeItem("projectTitle33")
    localStorage.removeItem("projectTitle44")

  },[])

  function handleClick () {

    localStorage.setItem("projectTitle22" , state ? state.projectTitle : "");
    localStorage.setItem("projectTitle" , state ? state._id : "");

    localStorage.setItem("projectTitle44" , state ? state.projectTitle : "");
    localStorage.setItem("projectTitle33" , state ? state._id : "");

    window.location.href = window.location.origin + '/Freelancers';

  }

  useEffect(() => {


    const token = localStorage.getItem('token');
    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");



    if (tokenRolee.find(t => t === "Dashboard")) {

        if (token) {


            if (!window.location.href.includes('Projectdetails')) {
                window.location.href = window.location.origin + '/Projectdetails'
            }
        }
        else {
            if (!window.location.href.includes('/Login')) {
                window.location.href = window.location.origin + '/Login'
            }

        }
    }
  
    else if (tokenRolee.find(t => t === "Projects")){
        if (!window.location.href.includes('Projects')) {
            window.location.href = window.location.origin + '/Projects'
        }
        
    }

    else if (tokenRolee.find(t => t === "Centers")){
        if (!window.location.href.includes('Centers')) {
            window.location.href = window.location.origin + '/Centers'
        }
        
    }



    else if (tokenRolee.find(t => t === "Assign")){
        if (!window.location.href.includes('Addpeople')) {
            window.location.href = window.location.origin + '/Addpeople'
        }
        
    }

    else if (tokenRolee.find(t => t === "Attendance")){
        if (!window.location.href.includes('Attendance')) {
            window.location.href = window.location.origin + '/Attendance'
        }
        
    }

    else if (tokenRolee.find(t => t === "Message")){
        if (!window.location.href.includes('Message')) {
            window.location.href = window.location.origin + '/Message'
        }
        
    }
    else if (tokenRolee.find(t => t === "Audits")){
        if (!window.location.href.includes('Audits')) {
            window.location.href = window.location.origin + '/Audits'
        }
        
    }
    else if (tokenRolee.find(t => t === "Expenses")){
        if (!window.location.href.includes('Expenses')) {
            window.location.href = window.location.origin + '/Expenses'
        }
        
    }
    else if (tokenRolee.find(t => t === "Role Management")){
        if (!window.location.href.includes('AdminUserManagement')) {
            window.location.href = window.location.origin + '/AdminUserManagement'
        }
        
    }
    else if (tokenRolee.find(t => t === "Report")){
        if (!window.location.href.includes('Report')) {
            window.location.href = window.location.origin + '/Reports'
        }
        
    }



}, [])
    return (
        <>
            <Loader />
            <div id="layout-wrapper">
                <MainDashbord />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">Project Details</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">

                                                <a onClick={()=> history('/Projects')} className="breadcrumb-item active">Projects</a><a href='/Projectdetails' className="breadcrumb-item active">Projectdetail </a>

                                            </ol>
                                            <div className="">

                                                <a onClick={()=> history('/Projects')} className=" float-lg-end  mt-4 btn btn-primary waves-effect waves-light" >Back to List</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="table-responsive">
                                                    <table className="table table-responsive mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Project Title :</th>
                                                                <td>{state ? state.projectTitle : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Project ID :</th>
                                                                <td>{state ? state.project_code : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Project Type :</th>
                                                                <td>{state ? state.projectType : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Startdate :</th>
                                                                <td>  {moment(state ? state.startdate : "-").format("DD/MM/YYYY")}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Enddate :</th>
                                                                <td>{moment(state ? state.enddate : "-").format("DD/MM/YYYY")}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="table-responsive">
                                                    <table className="table table-responsive mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Exam Type :</th>
                                                                <td>{state ? state.examType : "-"}</td>
                                                            </tr> 
                                                            <tr>
                                                                <th scope="row">Exam Code :</th>
                                                                <td>{state ? state.examCode : "-"}</td>
                                                            </tr>
                                                             <tr>
                                                                <th scope="row">Project States :</th>
                                                                <td>{state ? state.projectStates : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">About :</th>
                                                                <td>{state ? state.notes : "--"}</td>
                                                            </tr>
                                                            <tr>
                                                            <td colSpan={2} style={{border:'none'}}>
                                                                <div className="">
                                                                <button onClick={() =>  {handleClick()}} className="float-lg-end  mt-4 btn btn-primary waves-effect waves-light">View Freelancers</button>
                                                                </div>
                                                                </td>
                                                            </tr>

                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                © Edu-Test All Rights Reserved {(new Date().getFullYear())}.
                                </div>
                                <div className="col-sm-6">
                                    <div className="text-sm-end d-none d-sm-block">
                                        Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}
