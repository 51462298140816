import 'react-confirm-alert/src/react-confirm-alert.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { freelancerstatusAPI, getRolesAPI, getQulificationAPI, dispatchLoadingStatus,} from '../../API/Action/globalAction';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainDashbord from '../Sidebar/MainDashbord';
import moment from "moment";
import blankImg from '../../assets/images/blank.jpg'
import aadharimg from '../../assets/images/aadhar.png'


toast.configure();

function ProfileFreelancer(props) {
    const token = localStorage.getItem('token');

    const getQulificationsReg = useSelector(state => state.globalReducer.getQulificationsReg);
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getQulificationAPI())
        let formData = new FormData();
        formData.append('token', localStorage.getItem('token'));
        dispatch(dispatchLoadingStatus(true))
        dispatch(getRolesAPI(formData))
    }, [])


    const { state } = useLocation();

    const { status, roles, altrphone, bankname, dob, qualification, jobtitle, organization, stardate, enddate, responsibilities, rejectReason, _id, profile, fname, lname, phone, email, address, aadhar, pan, bankAC, ifsc,
        aadharImage, workexperience, panImage, bankAcImage, pincode, city, stateid, statename, exam_experiences, vendor_email } = state;

    let qu = qualification ? JSON.parse(qualification) : [];
    let workexperience22 = workexperience ? JSON.parse(workexperience) : [];
    let exam_experiences22 = exam_experiences ? JSON.parse(exam_experiences) : [];

    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState(false);
    const [ReqAccepts, setReqAccepts] = useState("");
    const [ReqRejects, setReqRejects] = useState("");


    const handleReason = (e) => {
        setReason(e.target.value.trim() == "" ? "" : e.target.value);
        if (e.target.value == "") {
            setReasonError(<p className='error'>Reason is required</p>);
        }
        else {
            setReasonError("");
        }
    }

    const getRolesReg = useSelector(state => state.globalReducer.getRolesReg);


    const dispatch = useDispatch({
    });



    const Accept = (event) => {

        let id = event.target.id;

        if (token && _id) {
            let formData = new FormData();
            formData.append("token", token);
            formData.append("freelancerId", _id);
            formData.append("status", 1);
            formData.append("reason", "");
            dispatch(dispatchLoadingStatus(true))
            dispatch(freelancerstatusAPI(formData ,1));
            setReqAccepts("1")
        }


        else {
            swal("", "Something Wrong!", "error");
        }
    }


    const Reject = () => {
        if (reason == "") {
            setReasonError(<p className='error'>Reason is required</p>);
        }
        else {
            setReasonError("");
        }

        if (reason) {
            let formData = new FormData();
            formData.append("token", token);
            formData.append("freelancerId", _id);
            formData.append("status", 2);
            formData.append("reason", reason);
            dispatch(dispatchLoadingStatus(true))
            dispatch(freelancerstatusAPI(formData,2));
            setReqRejects("2")
        }
    }

    useEffect(() => {
        setReason("");
        const token = localStorage.getItem('token');
        if (token) {
            if (!window.location.href.includes('ProfileFreelancer')) {
                window.location.href = window.location.origin + '/ProfileFreelancer'
            }
        }
        else {
            if (!window.location.href.includes('/Login')) {
                window.location.href = window.location.origin + '/Login'
            }
        }
    }, [])

    return (
        <div id="layout-wrapper">
            <MainDashbord />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Freelancer </h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <a href='/Freelancers' className="breadcrumb-item active">Freelancer </a> <a href='/ProfileFreelancer' className="breadcrumb-item active">Freelancerdetail </a>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card overflow-hidden">
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="avatar-md profile-user-wid freelancer-profile">
                                                    <img src={profile ? profile : blankImg} alt="" className="img-thumbnail rounded-circle" />
                                                </div>
                                            </div> <div className="table-responsive">
                                                <table className="table table-responsive mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Name :</th>
                                                            <td>{fname} {lname}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Mobile :</th>
                                                            <td>+91{phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">E-mail :</th>
                                                            <td>{email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Alternative Mobile:</th>
                                                            <td>{altrphone ? altrphone : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Date Of Birth :</th>
                                                            <td>{dob ? dob : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Location :</th>
                                                            <td>{address ? address : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">State :</th>
                                                            <td>{statename ? statename : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">City :</th>
                                                            <td>{city ? city : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Pincode:</th>
                                                            <td>{pincode ? pincode : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Created By:</th>
                                                            <td>{vendor_email ? vendor_email : ""}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-12">


                                                <div className="table-responsive">
                                                    {status == 2 || ReqRejects == 2 ?
                                                        <div >
                                                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                                <h4 className="mb-sm-0 font-size-18" style={{ "color": "red" }}>Freelancer Request Rejected : {reason ?  reason : rejectReason}</h4>

                                                            </div>
                                                        </div> :

                                                        status == 1 || ReqAccepts == 1 ?
                                                            <div >
                                                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                                    <h4 className="mb-sm-0 font-size-18" style={{ "color": "green" }}>Freelancer Request Accepted</h4>
                                                                </div>
                                                            </div>
                                                            :
                                                            status == 3 ?
                                                                <table className="table table-responsive align-middle  mb-0">

                                                                    <tbody>
                                                                        <h4 className="mb-sm-0 font-size-18" style={{ "color": "grey" }}>Freelancer Request </h4>
                                                                        <tr>
                                                                            <td >
                                                                                <div className='d-flex'>
                                                                                    <button type="button" className="btn btn-success me-2  waves-effect waves-light" id="sa-success-grt" onClick={Accept} >
                                                                                        <i className="mdi mdi-check"></i> Accept
                                                                                    </button>
                                                                                    <button type="button" className="btn btn-primary  waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                                                                                        <i className="mdi mdi-close"></i>Reject
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <table className="table table-responsive align-middle  mb-0">

                                                                    <tbody>
                                                                        <h4 className="mb-sm-0 font-size-18" style={{ "color": "grey" }}>Freelancer Request </h4>
                                                                        <tr>
                                                                            <td >
                                                                                <div className='d-flex'>
                                                                                    <button type="button" className="btn btn-success me-2  waves-effect waves-light" id="sa-success-grt" onClick={Accept} >
                                                                                        <i className="mdi mdi-check"></i> Accept
                                                                                    </button>
                                                                                    <button type="button" className="btn btn-primary  waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                                                                                        <i className="mdi mdi-close"></i>Reject
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                    }
                                                </div>


                                                <div className="product-detai-imgs">
                                                    <div className="row">
                                                        <div className="col-md-2 col-sm-3 col-4">
                                                            <div className="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                                <a className="nav-link active" id="product-1-tab" data-bs-toggle="pill" href="#product-1" role="tab" aria-controls="product-1" aria-selected="true">
                                                                    <figure>
                                                                        <img src={aadharImage ? aadharImage : aadharimg} alt="" className="img-fluid mx-auto d-block rounded" />
                                                                        <figcaption className='imageColor' style={{ "color": "black" }}> Aadhar Card</figcaption>
                                                                    </figure>

                                                                </a>

                                                                <a className="nav-link" id="product-2-tab" data-bs-toggle="pill" href="#product-2" role="tab" aria-controls="product-2" aria-selected="false">
                                                                    <figure>
                                                                        <img src={panImage ? panImage : aadharimg} alt="" className="img-fluid mx-auto d-block rounded" />
                                                                        <figcaption className='imageColor' style={{ "color": "black" }}> Pan Card</figcaption>
                                                                    </figure>
                                                                </a>
                                                                <a className="nav-link" id="product-3-tab" data-bs-toggle="pill" href="#product-3" role="tab" aria-controls="product-3" aria-selected="false">
                                                                    <figure>
                                                                        <img src={bankAcImage ? bankAcImage : aadharimg} alt="" className="img-fluid mx-auto d-block rounded" />
                                                                        <figcaption className='imageColor' style={{ "color": "black" }}> Cancel Cheque </figcaption>
                                                                    </figure>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7 offset-md-1 col-sm-9 col-8">
                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                <div className="tab-pane fade show active" id="product-1" role="tabpanel" aria-labelledby="product-1-tab">
                                                                    <div className='show-img'>
                                                                        <img src={aadharImage ? aadharImage : aadharimg} alt="" className="img-fluid mx-auto d-block" />
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade" id="product-2" role="tabpanel" aria-labelledby="product-2-tab">
                                                                    <div className='show-img'>
                                                                        <img src={panImage ? panImage : aadharimg} alt="" className="img-fluid mx-auto d-block" />
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade" id="product-3" role="tabpanel" aria-labelledby="product-3-tab">
                                                                    <div className='show-img'>
                                                                        <img src={bankAcImage ? bankAcImage : aadharimg } alt="" className="img-fluid mx-auto d-block" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Academic Details</h4>
                                        <div className="table-responsive">
                                            <table className="table table-nowrap align-middle table-hover mb-0">
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th>Exam</th>
                                                        <th>Institute</th>
                                                        <th>Percentage</th>
                                                        <th>Year Of Passing</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {qu && qu.map((i) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {getQulificationsReg && getQulificationsReg.map((get) => {
                                                                        return (
                                                                            i.qualification == get.code ? get.education : null
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    {i.institute ? i.institute :  '-'}
                                                                </td>
                                                                <td>
                                                                    {i.percentage ? i.percentage : '-'}
                                                                </td>
                                                                <td>
                                                                    {i.year ? i.year : '-'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {qu ? qu.length > 0 ? null : <div style={{ textAlign: 'center' }} ><p>No data found</p> </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Professional Details</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-hover mb-0">
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th>Job Title</th>
                                                        <th>Organization</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Responsibilities</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {workexperience22.map((i) =>
                                                        <tr>
                                                            <td>{i.job ? i.job : '-'}</td>
                                                            <td>{i.organization ? i.organization : '-'}</td>
                                                            <td>{i.startDate ? i.startDate : '-'}</td>
                                                            <td>{i.endDate ? i.endDate : '-'}</td>
                                                            <td>{i.responsibilities ? i.responsibilities : '-'}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                            {workexperience22 ? workexperience22.length > 0 ? null : <div style={{ textAlign: 'center' }} ><p>No data found</p> </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Exam Experiance Details</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-hover mb-0">
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th>Contractor</th>
                                                        <th>Month & Year</th>
                                                        <th>Exam Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {exam_experiences22.map((i) =>
                                                        <tr>
                                                            <td>{i.contractor ? i.contractor : ""}</td>
                                                            <td>{i.exam_exp_date ? i.exam_exp_date.includes("-") ? moment(i.exam_exp_date.split('-').join('/')).format("MM/yyyy") : i.exam_exp_date : ""}</td>
                                                            <td>{i.exam_name ? i.exam_name.title : ""} </td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                            {exam_experiences22 ? exam_experiences22.length > 0 ? null : <div style={{ textAlign: 'center' }} ><p>No data found</p> </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Bank Details</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-hover mb-0">
                                                <tbody>
                                                    <tr><th scope="row">Aadhar Card :</th><td> {aadhar ? aadhar : '-'}</td></tr>
                                                    <tr><th scope="row">Pan  Card :</th><td>{pan ? pan : '-'}</td></tr>
                                                    <tr><th scope="row">Bank A/C No :</th><td>{bankAC ? bankAC : '-'}</td></tr>
                                                    <tr><th scope="row">IFSC Code :</th><td>{ifsc ? ifsc : '-'}</td></tr>
                                                    <tr><th scope="row">Bank Name :</th><td>{bankname ? bankname : '-'}</td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Preferred Roles</h4>
                                        <div className='role-div'>
                                            <ul className=''>

                                                {getRolesReg && getRolesReg.map((i) =>
                                                    roles.includes(i.roleid) ? <li><i className='fa fa-circle font-size-8'></i> {i.role}</li> : null 
                                               )}
                                            </ul>
                                            { roles ? null : <div style={{ textAlign: 'center' }} ><p>No data found</p> </div> }
                                      </div>                                              
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                © Edu-Test All Rights Reserved {(new Date().getFullYear())}.
                            </div>
                            <div className="col-sm-6">
                                <div className="text-sm-end d-none d-sm-block">
                                    Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            <div className="right-bar">
                <div data-simplebar className="h-100">
                    <div className="rightbar-title d-flex align-items-center px-3 py-4">
                        <h5 className="m-0 me-2">Settings</h5>
                        <a className="right-bar-toggle ms-auto">
                            <i className="mdi mdi-close noti-icon"></i>
                        </a>
                    </div>

                    <hr className="mt-0" />
                    <h6 className="text-center mb-0">Choose Layouts</h6>

                    <div className="p-4">
                        <div className="mb-2">
                            <img src="images/layouts/layout-1.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" />
                            <label className="form-check-label" htmlFor="light-mode-switch">Light Mode</label>
                        </div>
                        <div className="mb-2">
                            <img src="images/layouts/layout-2.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" />
                            <label className="form-check-label" htmlFor="dark-mode-switch">Dark Mode</label>
                        </div>
                        <div className="mb-2">
                            <img src="images/layouts/layout-3.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch" />
                            <label className="form-check-label" htmlFor="rtl-mode-switch">RTL Mode</label>
                        </div>
                        <div className="mb-2">
                            <img src="images/layouts/layout-4.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch" />
                            <label className="form-check-label" htmlFor="dark-rtl-mode-switch">Dark RTL Mode</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Reason for Rejection</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason} ></textarea>
                            </form>
                            {reasonError}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" id="close">Close</button>
                            {reason == "" ?
                                <button type="button" className="btn btn-primary" onClick={Reject}  >Submit</button>
                                : <button type="button" id="close" className="btn btn-primary" data-bs-dismiss="modal" onClick={Reject}  >Submit</button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="rightbar-overlay"></div>
        </div>
    )
}

export default ProfileFreelancer;