import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainDashbord from "../Sidebar/MainDashbord";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { dispatchLoadingStatus, getCenterAuditedImagesAPI, getCenterProjectsAPI } from "../../API/Action/globalAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { FaStar } from "react-icons/fa";

export default function Centerdetails() {
  const { state } = useLocation();
  const dispatch = useDispatch({});

  let center_id = state ? state._id : "-";

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  let history = useNavigate();

  const [allData22, setAllData22] = useState();
  const [allData33, setAllData33] = useState();

  const [order, setOrder] = useState("ASC");

  let getCenterAuditedImagesRes = useSelector((state) => state.globalReducer.getCenterAuditedImagesRes);

  const stars = Array(5).fill(0);

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    let formData22 = new FormData();
    let formData = new FormData();
    formData22.append("center_id", center_id);
    dispatch(dispatchLoadingStatus(true));
    formData.append("center_id", center_id);
    dispatch(dispatchLoadingStatus(true));
    dispatch(getCenterAuditedImagesAPI(formData));
    dispatch(getCenterProjectsAPI(formData22));
    const token = localStorage.getItem("token");

    if (token) {
      if (!window.location.href.includes("Centerdetails")) {
        window.location.href = window.location.origin + "/Centerdetails";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  let getCenterProjectsRes = useSelector((state) => state.globalReducer.getCenterProjectsRes);

  useEffect(() => {
    if (getCenterProjectsRes) {
      if (getCenterProjectsRes.errorcode == 1) {
        setAllData22([]);
      } else {
        setAllData22(getCenterProjectsRes ? getCenterProjectsRes.data : getCenterProjectsRes.data);
      }
    }
  }, [getCenterProjectsRes]);

  useEffect(() => {
    if (getCenterAuditedImagesRes) {
      if (getCenterAuditedImagesRes.errorcode == 1) {
        setAllData33([]);
      } else {
        setAllData33(getCenterAuditedImagesRes ? getCenterAuditedImagesRes.data : getCenterAuditedImagesRes.data);
      }
    }
  }, [getCenterAuditedImagesRes]);

  return (
    <>
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex  justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Center Details</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => history("/Centers")} className="breadcrumb-item active">
                          Center
                        </a>
                        <a href="/Centerdetails" className="breadcrumb-item active">
                          Centerdetails
                        </a>
                      </ol>
                      <div className="">
                        <a onClick={() => history("/Centers")} className=" float-lg-end  mt-4 btn btn-primary waves-effect waves-light">
                          Back to List
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-responsive mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Center Name </th>
                                <td>{state ? state.center_name : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Address</th>
                                <td>{state ? state.center_address : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">State</th>
                                <td>{state ? state.center_state : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">City</th>
                                <td>{state ? state.center_city : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Pincode</th>
                                <td>{state ? state.center_pincode : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Exam Type </th>
                                <td>{state ? state.examType : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Bank Name </th>
                                <td>{state ? state.center_bank_name : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Bank Account Number </th>
                                <td>{state ? state.center_bank_acc_number : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">IFSC Code </th>
                                <td>{state ? state.center_bank_ifsc : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Principal name</th>
                                <td>{state ? state.center_principal_name : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Contact</th>
                                <td>{state ? state.center_contact : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Type of school</th>
                                <td>{state ? state.center_type_of_school : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Category</th>
                                <td>{state ? state.center_category : "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Seating Capacity</th>
                                <td>{state ? state.center_no_of_candidate : "-"}</td>
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card custom-gallery-card ">
                    <div className="card-body row">
                      <div className="col-md-12">
                        <h4 className="mb-4">Photo Gallery</h4>
                      </div>
                      <div className="images-sec col-md-12">
                        <div className="row">
                          {allData33 && allData33.length > 0 ? (
                            allData33 &&
                            allData33.map((i, index) => (
                              <div className="col-md-4">
                                <Zoom zoomMargin={100}>
                                  {" "}
                                  <img style={{ height: "60px" }} src={i ? i : ""} />{" "}
                                </Zoom>
                              </div>
                            ))
                          ) : (
                            <p style={{ textAlign: "center" }}>No data found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card custom-gallery-card">
                    <div className="card-body row">
                      <div className="col-md-12">
                        <h4 className="mb-4"> Projects</h4>
                      </div>

                      <div className="col-lg-12">
                        <div className=" table-rep-plugin ">
                          <table id="example" className="table table-striped dt-responsive">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th data-priority="1">
                                  Project Title
                                  <i onClick={() => Sortt("projectTitle")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="2">
                                  Type
                                  <i onClick={() => Sortt("examType")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                </th>
                                <th data-priority="2">
                                  States
                                  <i onClick={() => Sortt("projectStates")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                </th>
                                <th data-priority="2">
                                  Start Date
                                  <i onClick={() => Sortt("startdate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                </th>
                                <th data-priority="2">
                                  End Date
                                  <i onClick={() => Sortt("enddate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                </th>

                                <th data-priority="2">
                                  Rating
                                  <i onClick={() => Sortt("star")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                </th>
                                <th data-priority="5">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allData22 ? (
                                allData22 &&
                                allData22.map((i, index) => (
                                  <tr key={"proj-" + index}>
                                    <td>{index + 1}.</td>
                                    <td className="custom-link" onClick={() => history("/Projectdetails", { state: i })}>
                                      {" "}
                                      {i.projectTitle}
                                    </td>

                                    <td>{i.examType}</td>
                                    <td>{i.projectStates}</td>
                                    <td>{moment(i.startdate).format("DD/MM/YYYY")}</td>
                                    <td>{moment(i.enddate).format("DD/MM/YYYY")}</td>
                                    <td>
                                      <div className="stars d-flex">
                                        {stars.map((_, index) => {
                                          return (
                                            <FaStar
                                              key={index}
                                              size={24}
                                              color={(i ? i.avgRating : "0") > index ? colors.orange : colors.grey}
                                              style={{
                                                marginRight: 3,
                                              }}
                                            />
                                          );
                                        })}
                                      </div>
                                    </td>

                                    <td>
                                      <button className="me-2 btn btn-primary waves-effect waves-light" onClick={() => history("/Projectdetails", { state: i })} key={i._id}>
                                        Track
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={11} style={{ textAlign: "center" }}>
                                    No data found
                                  </td>{" "}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {getCenterProjectsRes && getCenterProjectsRes.data ? (
                          getCenterProjectsRes.data.length > 0 ? null : (
                            <div style={{ textAlign: "center" }}>
                              <p>No data found</p>{" "}
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
