import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import AdminLogin from './Component/Authentication/AdminLogin';
import Otp from './Component/Authentication/Otp';

import ForgetPassword from './Component/Password/ForgetPassword';
import ResetPassword from './Component/Password/ResetPassword';

import Dashboard from './Component/Dashboard/Dashboard';


import FRlist from './Component/Freelancers/FRlist';
import ProfileFreelancer from './Component/Freelancers/ProfileFreelancer';

import FreelancerRoleManagement from './Component/FreelancerRole/FreelancerRoleManagement';
import FreelancerAssignRole from './Component/FreelancerRole/FreelancerAssignRole';
import FreelanceEditRole from './Component/FreelancerRole/FreelanceEditRole'; 


import Project from './Component/Projects/AddProject';
import ProjectDetail from './Component/Projects/ProjectDetail';
import Projects from './Component/Projects/Projects';


import AddCenter from './Component/Centers/AddCenter';
import EditCenter from './Component/Centers/EditCenter';
import Centers from './Component/Centers/Centers';
import CenterDetails from './Component/Centers/Centerdetails';
import PBTFilter from './Component/Centers/PBTFilter';
import CBTFilter from './Component/Centers/CBTFilter';


import AddTeam from './Component/Assign/Addteam';
import AddPeople from './Component/Assign/Addpeople';
import AssignAction from './Component/Assign/AssignAction';


import Attendance from './Component/Attendance/Attendance';


import Expenses from './Component/Expenses/Expenses';


import Sendmessage from './Component/Message/Sendmessage';
import Message from './Component/Message/Message';
import MessageDetails from './Component/Message/MessageDetails';


import Audits from './Component/Audits/CompletedAudits';


import RoleManagement from './Component/AdminUserManagement/RoleManagement';
import AssignRole from './Component/AdminUserManagement/AssignRole';
import EditRole from './Component/AdminUserManagement/EditRole';

import Reports from './Component/Reports/Reports';

import MyProfile from './Component/Profile/MyProfile';


function App() {

  useEffect(() => {
    if (window.location.href == window.location.origin + '/') {
      if (localStorage.getItem('token')) {
        window.location.href = window.location.origin + '/Dashboard'
      } else {
        window.location.href = window.location.origin + '/Login'
      }
    }
  }, [])
  return (
    <div className="App">

      <Router>
        <Routes>
          <Route exact path="/Login" element={<AdminLogin />} />
          <Route exact path="/Otp" element={<Otp />} />


          <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
          <Route exact path="/ResetPassword" element={<ResetPassword />} />


         <Route exact path="/Dashboard" element={<Dashboard />} />

          <Route exact path="/Freelancers" element={<FRlist />} />
          <Route exact path="/ProfileFreelancer" element={<ProfileFreelancer />} />

          <Route exact path="/FreelancerRoleManagement" element={<FreelancerRoleManagement />} />
          <Route exact path="/FreelancerAssignRole" element={<FreelancerAssignRole />} />
          <Route exact path="/FreelanceEditRole" element={<FreelanceEditRole />} />

          <Route exact path="/Addproject" element={<Project />} />
          <Route exact path="/Projects" element={<Projects />} />
          <Route exact path="/Projectdetails" element={<ProjectDetail />} />


          <Route exact path="/Centers" element={<Centers />} />
          <Route exact path="/Addcenter" element={<AddCenter />} />
          <Route exact path="/Editcenter" element={<EditCenter />} />
          <Route exact path="/Centerdetails" element={<CenterDetails />} />


          <Route exact path="/AuditedPBT" element={<PBTFilter />} />
          <Route exact path="/AuditedCBT" element={<CBTFilter />} />

          <Route exact path="/Addteam" element={<AddTeam />} />
          <Route exact path="/Addpeople" element={<AddPeople />} />
          <Route exact path="/Assignaction" element={<AssignAction />} />

          <Route exact path="/Attendance" element={<Attendance />} />

          <Route exact path="/Expenses" element={<Expenses />} />

          <Route exact path="/Message" element={<Message />} />
          <Route exact path="/Sendmessage" element={<Sendmessage />} />
          <Route exact path="/MessageDetails" element={<MessageDetails />} />

          <Route exact path="/Audits" element={<Audits />} />

          <Route exact path="/AdminUserManagement" element={<RoleManagement />} />
          <Route exact path="/AssignUser" element={<AssignRole />} />
          <Route exact path="/EditUser" element={<EditRole />} />


          <Route exact path="/Reports" element={<Reports />} />

          <Route exact path="/MyProfile" element={<MyProfile />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
