
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { dispatchLoadingStatus, sendOtpInEmailAPI } from '../../API/Action/globalAction';
import Loader from '../Loader/Loader';
import logo from '../../assets/images/logo.png'


function ForgetPassword() {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false)
    const dispatch = useDispatch({
    });
    let history = useNavigate();

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value.trim() == "" ? "" : e.target.value);
        setEmailError("");
    }

    const Otp = (e) => {
        if (email == "") {
            setEmailError(<p className='error'>Email is required</p>);
        }
        else if (!isValidEmail(email)) {
            setEmailError(<p className='error'>Email is invalid</p>);
        } else {
            setEmailError("");
            let formData = new FormData();
            formData.append('email', email);
            localStorage.setItem("sendEmail", email)
            dispatch(dispatchLoadingStatus(true))
            dispatch(sendOtpInEmailAPI(formData))
        }
    }

    const sendOtpInEmailRes = useSelector(state => state.globalReducer.sendOtpInEmailRes);
    useEffect(() => {
        localStorage.removeItem("resendValue")
        localStorage.removeItem("sendEmail")
        localStorage.removeItem("otp_id")
        localStorage.removeItem("admin_id")
    }, [])

    useEffect(() => {
        if (sendOtpInEmailRes) {
            localStorage.setItem("otp_id", sendOtpInEmailRes ? sendOtpInEmailRes.data.otp_id : "")
            localStorage.setItem("admin_id", sendOtpInEmailRes ? sendOtpInEmailRes.data.admin_id : "")
            history('/Otp')
        }
    }, [sendOtpInEmailRes])

    return (
        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="card-body ">
                                <div className="auth-logo">
                                    <a href="" className="auth-logo-dark">
                                        <div className=" profile-user-wid mb-4">
                                            <span className="logo ">
                                                <img src={logo} alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <h4 className="mb-4">Forgot Password</h4>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">Email</label>
                                        <input type="text" className="form-control" id="email" placeholder="Enter Email" value={email} onChange={handleEmail} />
                                    {emailError}
                                    </div>
                                    <div className="mt-3 d-grid">
                                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={Otp}> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;