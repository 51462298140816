import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addEditCenterDataAPI, dispatchLoadingStatus, getStatecityAPI, getProjectTitleAPI, TypeSchoolAPI, CategoryAPI } from "../../API/Action/globalAction";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import MainDashbord from "../Sidebar/MainDashbord";

function AddCenter() {
  const dispatch = useDispatch({});
  let history = useNavigate();
  const flage = localStorage.getItem("flage");
  const { state } = useLocation();

  let getstatecityReg = useSelector((state) => state.globalReducer.getStatecity);

  function handleMessage(e) {
    if (window.location.href.includes("Addcenter") || window.location.href.includes("Editcenter")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          history(e);
        } else {
        }
      });
    }
  }

  const [centerAddress, setCenterAddress] = useState("");
  const [centerAddressError, setCenterAddressError] = useState("");
  const [state22, setState] = useState("");
  const [stateSendId, setStateSendId] = useState("");

  const [stateError, setStateError] = useState(false);
  const [citylist, setcitylist] = useState("");
  const [citylist22, setcitylist22] = useState("");
  const [citylistError, setcitylistError] = useState(false);
  const [centerName, setCenterName] = useState("");
  const [centerNameError, setCenterNameError] = useState(false);
  const [centerNameError22, setCenterNameError22] = useState(false);
  const [centerNameError33, setCenterNameError33] = useState(false);

  const [centerPhone, setCenterPhone] = useState("");
  const [centerPhoneError, setCenterPhoneError] = useState(false);
  const [centerPhoneError22, setCenterPhoneError22] = useState(false);
  const [altPhone, setAltPhone] = useState("");
  const [altPhoneError, setAltPhoneError] = useState(false);
  const [altPhoneError22, setAltPhoneError22] = useState(false);

  const [bankName, setBankName] = useState("");
  const [bankNameError, setBankNameError] = useState(false);
  const [bankNameError22, setBankNameError22] = useState(false);

  const [bankAccount, setBankAccount] = useState("");
  const [bankAccountError, setBankAccountError] = useState(false);
  const [bankAccountError22, setBankAccountError22] = useState(false);

  const [IFSC, setIFSC] = useState("");
  const [IFSCError, setIFSCError] = useState(false);
  const [IFSCError22, setIFSCError22] = useState(false);

  const [threeError11, setThreeError11] = useState(false);
  const [threeError22, setThreeError22] = useState(false);
  const [threeError33, setThreeError33] = useState(false);

  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactPersonError, setContactPersonError] = useState("");
  const [contactPersonError22, setContactPersonError22] = useState("");
  const [contactPersonError33, setContactPersonError33] = useState(false);

  function handleBankName(e) {
    const BankNameRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z ]*$/;

    let val = e.target.value;
    if (val) {
      if (val.length >= 9 || val.length <= 18) {
        setBankNameError(false);
        setBankNameError22(false);

        if (BankNameRe.test(val)) {
          setBankName(val);
          setBankNameError(false);
          setBankNameError22(false);
        } else {
          setBankName("");
          setBankNameError(true);
          setBankNameError22(false);
        }
      } else {
        setBankNameError(false);
        setBankNameError22(true);
      }
    } else {
      setBankName("");
    }
  }

  function handleBankAccount(e) {
    const BankAccountRe = /^[0-9\b]+$/;
    let val = e.target.value;
    if (val) {
      if (val.length >= 9 || val.length <= 18) {
        setBankAccountError(false);
        setBankAccountError22(false);

        if (BankAccountRe.test(val)) {
          setBankAccount(val);
          setBankAccountError(false);
          setBankAccountError22(false);
        } else {
          setBankAccount("");
          setBankAccountError(true);
          setBankAccountError22(false);
        }
      } else {
        setBankAccountError(false);
        setBankAccountError22(true);
      }
    } else {
      setBankAccount("");
    }
  }

  function handleIFSC(e) {
    let val = e.target.value;
    if (val) {
      setIFSC(val);
    } else {
      setIFSC("");
    }
  }

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  let statelist = useSelector((state) => state.globalReducer.getStatecity);

  const [TypeSchool, setTypeSchool] = useState("");
  const [TypeSchoolError, setTypeSchoolError] = useState(false);
  const [NoCandidate, setNoCandidate] = useState("");
  const [NoCandidateError, setNoCandidateError] = useState("");
  const [Category, setCategory] = useState("");
  const [CategoryError, setCategoryError] = useState(false);
  let getProjectTitleRes = useSelector((state) => state.globalReducer.getProjectTitleRes);

  useEffect(() => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getStatecityAPI());
    dispatch(getProjectTitleAPI(formData));
    dispatch(TypeSchoolAPI(formData));
    dispatch(CategoryAPI(formData));

    if (flage == "true") {
      setCenterName(state ? state.center_name : "");
      setCenterAddress(state ? state.center_address : "");
      setPincode(state ? state.center_pincode : "");
      setContactPerson(state ? state.center_principal_name : "");
      setCenterPhone(state ? state.center_contact : "");
      setTypeSchool(state ? state.center_type_of_school : "");
      setCategory(state ? state.center_category : "");
      setState(state ? state.center_state : "");
      setcitylist22(state ? state.center_city : "");
      setBankAccount(state ? state.center_bank_acc_number : "");
      setIFSC(state ? state.center_bank_ifsc : "");
      setBankName(state ? state.center_bank_name : "");
      setNoCandidate(state ? state.center_no_of_candidate : "");
      setAltPhone(state ? state.center_altr_contact : "");
      setDescription(state ? state.center_notes : "");
    }
  }, []);
  let TypeSchoolRes = useSelector((state) => state.globalReducer.TypeSchoolRes)
  let CategoryRes = useSelector((state) => state.globalReducer.CategoryRes);

  const handleCenterPhone = (e) => {
    const re = /[0-9]{10}/;
    if (e.target.value) {
      if (e.target.value.length == 10 && re.test(e.target.value)) {
        setCenterPhone(e.target.value);
        setCenterPhoneError(false);
        setCenterPhoneError22(false);
      } else {
        setCenterPhone(e.target.value);
        setCenterPhoneError(false);
        setCenterPhoneError22(true);
      }
    } else {
      setCenterPhone("");
      setCenterPhoneError(true);
      setCenterPhoneError22(false);
    }
  };

  const handleAltPhone = (e) => {
    const re = /[0-9]{10}/;
    if (e.target.value) {
      if (e.target.value.length == 10 && re.test(e.target.value)) {
        setAltPhone(e.target.value);
        setAltPhoneError(false);
        if (e.target.value != centerPhone) {
          setAltPhoneError22(false);
        }
      } else {
        setAltPhone(e.target.value);
        setAltPhoneError(true);
      }
    } else {
      setAltPhone("");
      setAltPhoneError(false);
    }
  };

  const handleDescription = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;
    // all number and charcetr allowed //space not  // special symbol not // middle space are allowed

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setDescription(e.target.value);
        setDescriptionError(false);
      } else {
        setDescription("");
        setDescriptionError(true);
      }
    } else {
      setDescription("");
      setDescriptionError(false);
    }
  };

  const handleContactPerson = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z ]*$/;
    if (e.target.value) {
      if (re.test(e.target.value)) {
        setContactPerson(e.target.value);
        setContactPersonError(false);
        setContactPersonError22(false);
      } else {
        setContactPerson("");
        setContactPersonError(false);
        setContactPersonError22(true);
      }
    } else {
      setContactPerson("");
      setContactPersonError(true);
      setContactPersonError22(false);
    }
  };

  const handleCenterPincode = (e) => {
    const re = /[0-9]{6}/;
    if (e.target.value) {
      if (e.target.value.length == 6 && re.test(e.target.value)) {
        setPincode(e.target.value);
        setPincodeError("");
      } else {
        setPincode(e.target.value);
        setPincodeError("Please enter valid pincode");
      }
    } else {
      setPincode("");
      setPincodeError("Please enter pincode");
    }
  };

  const typeSchool = (e) => {
    if (e) {
      setTypeSchool(e.value);
      setTypeSchoolError(false);
    } else {
      setTypeSchool("");
      setTypeSchoolError(true);
    }
  };

  const noCandidate = (e) => {
    const re = /[0-9]/;
    if (e.target.value) {
      if (re.test(e.target.value)) {
        setNoCandidate(e.target.value);
        setNoCandidateError("");
      } else {
        setNoCandidate(e.target.value);
        setNoCandidateError("Please enter valid no of candidate");
      }
    } else {
      setNoCandidate("");
      setNoCandidateError("Please enter no of candidate");
    }
  };

  const categoryFun = (e) => {
    if (e) {
      setCategory(e.value);
      setCategoryError(false);
    } else {
      setCategory("");
      setCategoryError(true);
    }
  };

  const stateFun = (e) => {
    var filter = statelist.filter((i) => i._id == e.value);
    setStateSendId(e.value);
    if (filter && e.label) {
      if (filter[0]) {
        setcitylist(filter[0].districts);
        setState(e.label);
        setcitylist22("");
        setStateError(false);
      }
    } else {
      setcitylist("");
      setState("");
      setStateError(true);
    }
  };

  const handleCity = (e) => {
    if (e.value) {
      setcitylist22(e.value);
      setcitylistError(false);
    } else {
      setcitylist22("");
      setcitylistError(true);
    }
  };

  const allDeatils = () => {
    const CenterNameRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,()& ]*$/;
    const DescriptionRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;
    const BankNameRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z ]*$/;
    const BankAccountRe = /^[0-9\b]+$/;
    const IFSCRe = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (!TypeSchool) {
      setTypeSchoolError(true);
    }

    if (!Category) {
      setCategoryError(true);
    }

    const re22 = /[0-9]/;
    if (NoCandidate) {
      if (re22.test(NoCandidate)) {
        setNoCandidateError("");
      } else {
        setNoCandidateError("Please enter valid no of candidate");
      }
    } else {
      setNoCandidateError("Please enter no of candidate");
    }

    const CenterPhonere = /^[0-9\b]+$/;
    if (centerPhone) {
      if (CenterPhonere.test(centerPhone)) {
        setCenterPhoneError(false);
        setCenterPhoneError22(false);
      } else {
        setCenterPhoneError(false);
        setCenterPhoneError22(true);
      }
    } else {
      setCenterPhoneError(true);
      setCenterPhoneError22(false);
    }

    const ContactPersonRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;
    if (contactPerson) {
      if (contactPerson.length >= 3) {
        setContactPersonError(false);
        setContactPersonError22(false);
        setContactPersonError33(false);
        if (ContactPersonRe.test(contactPerson)) {
          setContactPersonError(false);
          setContactPersonError22(false);
          setContactPersonError33(false);
        } else {
          setContactPersonError(false);
          setContactPersonError22(true);
          setContactPersonError33(false);
        }
      } else {
        setContactPersonError(false);
        setContactPersonError22(false);
        setContactPersonError33(true);
      }
    } else {
      setContactPersonError(true);
      setContactPersonError22(false);
      setContactPersonError33(false);
    }

    if (centerAddress) {
      if (CenterNameRe.test(centerAddress)) {
        setCenterAddressError("");
      } else {
        setCenterAddressError("Please enter valid center address");
      }
    } else {
      setCenterAddressError("Please enter center address");
    }

    if (!state22) {
      setStateError(true);
    }
    if (!citylist22) {
      setcitylistError(true);
    }

    if (centerName) {
      if (centerName.length >= 2) {
        setCenterNameError(false);
        setCenterNameError22(false);
        setCenterNameError33(false);

        if (CenterNameRe.test(centerName)) {
          setCenterNameError(false);
          setCenterNameError22(false);
          setCenterNameError33(false);
        } else {
          setCenterNameError(false);
          setCenterNameError22(true);
          setCenterNameError33(false);
        }
      } else {
        setCenterNameError(false);
        setCenterNameError22(false);
        setCenterNameError33(true);
      }
    } else {
      setCenterNameError(true);
      setCenterNameError22(false);
      setCenterNameError33(false);
    }

    const re = /[0-9]{6}/;
    if (pincode) {
      if (re.test(pincode)) {
        setPincodeError("");
      } else {
        setPincodeError("Please enter valid pincode");
      }
    } else {
      setPincodeError("Please enter pincode");
    }

    const re33 = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;

    if (contactPerson) {
      if (re33.test(contactPerson)) {
        setContactPersonError(false);
        setContactPersonError22(false);
      } else {
        setContactPersonError(false);
        setContactPersonError22(true);
      }
    } else {
      setContactPersonError(true);
      setContactPersonError22(false);
    }

    if (bankName) {
      if (BankNameRe.test(bankName)) {
        setBankNameError(false);
        setBankNameError22(false);
        if (bankName.length >= 3) {
          setBankNameError(false);
          setBankNameError22(false);
        } else {
          setBankNameError(false);
          setBankNameError22(true);
        }
      } else {
        setBankNameError(true);
        setBankNameError22(false);
      }
    }

    if (bankAccount) {
      if (BankAccountRe.test(bankAccount)) {
        setBankAccountError(false);
        setBankAccountError22(false);
        if (bankAccount.length >= 9 && bankAccount.length <= 18) {
          setBankAccountError(false);
          setBankAccountError22(false);
        } else {
          setBankAccountError(false);
          setBankAccountError22(true);
        }
      } else {
        setBankAccountError(true);
        setBankAccountError22(false);
      }
    }

    if (IFSC) {
      if (IFSCRe.test(IFSC)) {
        setIFSCError(false);
        setIFSCError22(false);

        if (IFSC.length == 11) {
          setIFSCError(false);
          setIFSCError22(false);
        } else {
          setIFSCError(false);
          setIFSCError22(true);
        }
      } else {
        setIFSCError(true);
        setIFSCError22(false);
      }
    }

    if (description) {
      if (DescriptionRe.test(description)) {
        setDescriptionError(false);
      } else {
        setDescriptionError(true);
      }
    } else {
      setDescriptionError(false);
    }

    const AltPhoneRe = /^[0-9\b]+$/;
    if (altPhone) {
      if (AltPhoneRe.test(altPhone) && altPhone.length == 10) {
        setAltPhoneError(false);
      } else {
        setAltPhoneError(true);
      }
    }

    if (centerPhone && altPhone) {
      if (centerPhone == altPhone) {
        setAltPhoneError22(true);
      } else {
        setAltPhoneError22(false);
      }
    }

    if (TypeSchool && NoCandidate && Category && contactPerson.length >= 3 && centerName.length >= 2 && centerAddress && state22 && citylist22 && pincode && centerPhone) {
      if (bankName || bankAccount || IFSC || description || altPhone) {
        if (bankName || bankAccount || IFSC) {
          if (!bankName) {
            setThreeError11(true);
          } else {
            setThreeError11(false);
          }
          if (!bankAccount) {
            setThreeError22(true);
          } else {
            setThreeError22(false);
          }
          if (!IFSC) {
            setThreeError33(true);
          } else {
            setThreeError33(false);
          }
          if (IFSC.length == 11 && IFSCRe.test(IFSC) && bankAccount.length >= 9 && bankAccount.length <= 18 && BankAccountRe.test(bankAccount) && bankName.length >= 3 && bankName.length <= 16 && BankNameRe.test(bankName)) {
            if (altPhone) {
              if (AltPhoneRe.test(altPhone) && altPhone.length == 10) {
                if (centerPhone != altPhone) {
                  setAltPhoneError22(false);

                  let formData = new FormData();
                  let center_id = localStorage.getItem("center_id");
                  formData.append("token", localStorage.getItem("token"));
                  formData.append("project_id", "");
                  formData.append("center_id", center_id ? center_id : "");
                  formData.append("center_name", centerName);
                  formData.append("center_address", centerAddress);
                  formData.append("center_state", state22);
                  formData.append("center_state_id", state ? state.center_state_id : stateSendId);
                  formData.append("center_city", citylist22);
                  formData.append("center_pincode", pincode);
                  formData.append("center_type_of_school", TypeSchool);
                  formData.append("center_no_of_candidate", NoCandidate);
                  formData.append("center_category", Category);
                  formData.append("center_principal_name", contactPerson);
                  formData.append("center_contact", centerPhone);
                  formData.append("center_altr_contact", altPhone);
                  formData.append("center_notes", description);
                  formData.append("center_bank_name", bankName ? bankName : "");
                  formData.append("center_bank_acc_number", bankAccount ? bankAccount : "");
                  formData.append("center_bank_ifsc", IFSC ? IFSC : "");
                  dispatch(dispatchLoadingStatus(true));
                  dispatch(addEditCenterDataAPI(formData));
                  setThreeError11(false);
                  setThreeError22(false);
                  setThreeError33(false);
                  setBankNameError22(false);
                }
              }
            } else {
              let formData = new FormData();
              let center_id = localStorage.getItem("center_id");
              formData.append("token", localStorage.getItem("token"));
              formData.append("project_id", "");
              formData.append("center_id", center_id ? center_id : "");
              formData.append("center_name", centerName);
              formData.append("center_address", centerAddress);
              formData.append("center_state", state22);
              formData.append("center_state_id", state ? state.center_state_id : stateSendId);
              formData.append("center_city", citylist22);
              formData.append("center_pincode", pincode);
              formData.append("center_type_of_school", TypeSchool);
              formData.append("center_no_of_candidate", NoCandidate);
              formData.append("center_category", Category);
              formData.append("center_principal_name", contactPerson);
              formData.append("center_contact", centerPhone);
              formData.append("center_altr_contact", altPhone);
              formData.append("center_notes", description);
              formData.append("center_bank_name", bankName ? bankName : "");
              formData.append("center_bank_acc_number", bankAccount ? bankAccount : "");
              formData.append("center_bank_ifsc", IFSC ? IFSC : "");
              dispatch(dispatchLoadingStatus(true));
              dispatch(addEditCenterDataAPI(formData));
              setThreeError11(false);
              setThreeError22(false);
              setThreeError33(false);
              setBankNameError22(false);
            }
          }
        }
        else if (altPhone) {
          if (AltPhoneRe.test(altPhone) && altPhone.length == 10) {
            if (centerPhone != altPhone) {
              setAltPhoneError22(false);
              let formData = new FormData();
              let center_id = localStorage.getItem("center_id");
              formData.append("token", localStorage.getItem("token"));
              formData.append("project_id", "");
              formData.append("center_id", center_id ? center_id : "");
              formData.append("center_name", centerName);
              formData.append("center_address", centerAddress);
              formData.append("center_state", state22);
              formData.append("center_state_id", state ? state.center_state_id : stateSendId);
              formData.append("center_city", citylist22);
              formData.append("center_pincode", pincode);
              formData.append("center_type_of_school", TypeSchool);
              formData.append("center_no_of_candidate", NoCandidate);
              formData.append("center_category", Category);
              formData.append("center_principal_name", contactPerson);
              formData.append("center_contact", centerPhone);
              formData.append("center_altr_contact", altPhone);
              formData.append("center_notes", description);
              formData.append("center_bank_name", bankName ? bankName : "");
              formData.append("center_bank_acc_number", bankAccount ? bankAccount : "");
              formData.append("center_bank_ifsc", IFSC ? IFSC : "");
              dispatch(dispatchLoadingStatus(true));
              dispatch(addEditCenterDataAPI(formData));
              setThreeError11(false);
              setThreeError22(false);
              setThreeError33(false);
              setBankNameError22(false);
            }
          }
        } else if (description) {
          if (DescriptionRe.test(description)) {
            let formData = new FormData();
            let center_id = localStorage.getItem("center_id");
            formData.append("token", localStorage.getItem("token"));
            formData.append("project_id", "");
            formData.append("center_id", center_id ? center_id : "");
            formData.append("center_name", centerName);
            formData.append("center_address", centerAddress);
            formData.append("center_state", state22);
            formData.append("center_state_id", state ? state.center_state_id : stateSendId);
            formData.append("center_city", citylist22);
            formData.append("center_pincode", pincode);
            formData.append("center_type_of_school", TypeSchool);
            formData.append("center_no_of_candidate", NoCandidate);
            formData.append("center_category", Category);
            formData.append("center_principal_name", contactPerson);
            formData.append("center_contact", centerPhone);
            formData.append("center_altr_contact", altPhone);
            formData.append("center_notes", description);
            formData.append("center_bank_name", bankName ? bankName : "");
            formData.append("center_bank_acc_number", bankAccount ? bankAccount : "");
            formData.append("center_bank_ifsc", IFSC ? IFSC : "");
            dispatch(dispatchLoadingStatus(true));
            dispatch(addEditCenterDataAPI(formData));
            setThreeError11(false);
            setThreeError22(false);
            setThreeError33(false);
            setBankNameError22(false);
          }
        }
      } else {
        let formData = new FormData();
        let center_id = localStorage.getItem("center_id");
        formData.append("token", localStorage.getItem("token"));
        formData.append("project_id", "");
        formData.append("center_id", center_id ? center_id : "");
        formData.append("center_name", centerName);
        formData.append("center_address", centerAddress);
        formData.append("center_state", state22);
        formData.append("center_state_id", state ? state.center_state_id : stateSendId);
        formData.append("center_city", citylist22);
        formData.append("center_pincode", pincode);
        formData.append("center_type_of_school", TypeSchool);
        formData.append("center_no_of_candidate", NoCandidate);
        formData.append("center_category", Category);
        formData.append("center_principal_name", contactPerson);
        formData.append("center_contact", centerPhone);
        formData.append("center_altr_contact", altPhone);
        formData.append("center_notes", description);
        formData.append("center_bank_name", bankName ? bankName : "");
        formData.append("center_bank_acc_number", bankAccount ? bankAccount : "");
        formData.append("center_bank_ifsc", IFSC ? IFSC : "");
        setThreeError11(false);
        setThreeError22(false);
        setThreeError33(false);
        setBankNameError22(false);
        dispatch(dispatchLoadingStatus(true));
        dispatch(addEditCenterDataAPI(formData));
      }
    }
  };

  const handleCenterAddress = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;
    // all number and charcetr allowed //space not  // special symbol not // middle space are allowed

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setCenterAddress(e.target.value);
        setCenterAddressError("");
      } else {
        setCenterAddress("");
        setCenterAddressError("Enter valid center address");
      }
    } else {
      setCenterAddress("");
      setCenterAddressError("Enter center address");
    }
  };

  const handleCenterName = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,()& ]*$/;

    // space not
    // only characetr and number
    // middle space allowed
    //special character not  // starting number not //

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setCenterName(e.target.value);
        setCenterNameError(false);
        setCenterNameError22(false);
      } else {
        setCenterName("");
        setCenterNameError(false);
        setCenterNameError22(true);
      }
    } else {
      setCenterName("");
      setCenterNameError(true);
      setCenterNameError22(false);
    }
  };

  return (
    <>
      <h1>Center Page</h1>
      <div id="layout-wrapper">
        <Loader />
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    {flage == "true" ? <h4 className="mb-sm-0 font-size-18">Edit Center</h4> : <h4 className="mb-sm-0 font-size-18">Add Center</h4>}
                    {flage == "true" ? (
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <a onClick={() => handleMessage("/Centers")} className="breadcrumb-item active">
                            Center{" "}
                          </a>{" "}
                          <a href="/Editcenter" className="breadcrumb-item active">
                            Edit Center{" "}
                          </a>
                        </ol>
                      </div>
                    ) : (
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <a onClick={() => handleMessage("/Centers")} className="breadcrumb-item active">
                            Center{" "}
                          </a>{" "}
                          <a href="/AddCenter" className="breadcrumb-item active">
                            Add Center{" "}
                          </a>
                        </ol>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-4"> </div>
                          <div className="col-md-8"></div>
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Center Name *</label>
                              <input type="text" className="form-control" name="centerName" placeholder="Center Name" value={centerName} onChange={handleCenterName} maxLength={100} />
                              {centerNameError && <span style={{ color: "#BE312E" }}>Please enter center name</span>}
                              {centerNameError22 == true ? centerNameError22 && <span style={{ color: "#BE312E" }}>Please enter valid center name</span> : centerNameError33 && <span style={{ color: "#BE312E" }}>Please enter minimum 2 character center name</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Center Address *
                              </label>
                              <input type="text" className="form-control" placeholder="Center Address" value={centerAddress} onChange={handleCenterAddress} maxLength={100} />
                              {<span style={{ color: "#BE312E" }}>{centerAddressError}</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="state" className="form-label">
                                State *
                              </label>
                              <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={!flage == "true" ? "State" : ""} onChange={(e) => stateFun(e)} id="stateSelect" value={flage == "true" ? (state22 ? [{ label: state22, value: state22 }] : [{ label: state ? state.center_state : "State", value: state ? state.center_state : "" }]) : !state22 ? [{ label: "State", value: "" }] : [{ label: state22, value: state22 }]} />
                              {stateError && <span style={{ color: "#BE312E" }}>Select project state</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="city" className="form-label">
                                City *
                              </label>
                              <Select
                                options={citylist ? [{ label: "City", value: "" }].concat(citylist.map((e) => ({ label: e, value: e }))) : []}
                                value={flage == "true" ? (citylist22 ? [{ label: citylist22, value: citylist22 }] : [{ label: state ? state.center_city : "City", value: state ? state.center_city : "" }]) : !citylist22 ? [{ label: "City", value: "" }] : [{ label: citylist22, value: citylist22 }]}
                                onChange={(e) => handleCity(e)}
                              />
                              {citylistError && <span style={{ color: "#BE312E" }}>Select project city</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Center Pincode *
                              </label>
                              <input type="text" className="form-control" placeholder="Center Pincode" name="pincode" onChange={handleCenterPincode} value={pincode} maxLength="6" />
                              <span style={{ color: "#BE312E" }}>{pincodeError}</span>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="state" className="form-label">
                                Type of school *
                              </label>
                              <Select options={TypeSchoolRes ? [{ label: "Select", value: "" }].concat(TypeSchoolRes.data.map((e) => ({ label: e.center_type, value: e.center_type }))) : []} value={flage == "true" ? (TypeSchool ? [{ label: TypeSchool, value: TypeSchool }] : [{ label: "Select", value: "" }]) : TypeSchool ? [{ label: TypeSchool, value: TypeSchool }] : [{ label: "Select", value: "" }]} onChange={(e) => typeSchool(e)} />
                              {TypeSchoolError && <span style={{ color: "#BE312E" }}>Please select type of school</span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Seating Capacity *
                              </label>
                              <input type="text" className="form-control" placeholder="Seating Capacity" name="candidate" onChange={(e) => noCandidate(e)} value={NoCandidate} maxLength="4" />
                              <span style={{ color: "#BE312E" }}>{NoCandidateError}</span>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="state" className="form-label">
                                Category *
                              </label>
                              <Select
                                options={CategoryRes ? [{ label: "Select", value: "" }].concat(CategoryRes.data.map((e) => ({ label: e.center_category, value: e.center_category }))) : []}
                                value={flage == "true" ? (Category ? [{ label: Category, value: Category }] : [{ label: "Select", value: "" }]) : Category ? [{ label: Category, value: Category }] : [{ label: "Select", value: "" }]}
                                onChange={(e) => categoryFun(e)}
                              />
                              {CategoryError && <span style={{ color: "#BE312E" }}>Please select category</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Center Contact Person *
                              </label>
                              <input type="text" className="form-control" placeholder="Center Contact Person" value={contactPerson} onChange={handleContactPerson} maxLength={30} />
                              {contactPersonError && <span style={{ color: "#BE312E" }}>Please enter contact person</span>}

                              {contactPersonError22 == true ? contactPersonError22 && <span style={{ color: "#BE312E" }}>Please enter valid contact person</span> : contactPersonError33 && <span style={{ color: "#BE312E" }}>Please enter minimum 3 character contact person name</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Center Phone *
                              </label>
                              <input type="text" className="form-control" placeholder="Enter Center Phone" name="centerPhone" value={centerPhone} onChange={handleCenterPhone} maxLength="10" />
                              {centerPhoneError && <span style={{ color: "#BE312E" }}>Please enter center phone</span>}
                              {centerPhoneError22 && <span style={{ color: "#BE312E" }}>Please enter valid center phone</span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Bank Name </label>
                              <input type="text" className="form-control" name="bankName" placeholder="Bank Name" value={bankName} onChange={handleBankName} maxLength={600} />
                              {bankNameError == true ? bankNameError && <span style={{ color: "#BE312E" }}>Please enter valid bank name</span> : bankNameError22 == true ? bankNameError22 && <span style={{ color: "#BE312E" }}>Please enter a bank name that contains a minimum of 3 characters</span> : threeError11 && <span style={{ color: "#BE312E" }}>Please fill Bank Name </span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Bank Account Number{" "}
                              </label>
                              <input type="text" className="form-control" placeholder="Enter Account Number" name="bankAccount" value={bankAccount} onChange={handleBankAccount} maxLength={18} />
                              {bankAccountError == true ? bankAccountError && <span style={{ color: "#BE312E" }}>Please enter valid bank account number</span> : bankAccountError22 == true ? bankAccountError22 && <span style={{ color: "#BE312E" }}>Please enter a bank account number that contains a minimum of 9 characters and a maximum of 18 characters</span> : threeError22 && <span style={{ color: "#BE312E" }}>Please fill bank account number</span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                IFSC Code{" "}
                              </label>
                              <input type="text" className="form-control" placeholder="Enter IFSC code" name="IFSC" value={IFSC} onChange={handleIFSC} maxLength={11} />
                              {IFSCError == true ? IFSCError && <span style={{ color: "#BE312E" }}>Please enter valid IFSC code</span> : IFSCError22 == true ? IFSCError22 && <span style={{ color: "#BE312E" }}>Please enter valid IFSC code</span> : threeError33 && <span style={{ color: "#BE312E" }}>Please fill IFSC code</span>} <br />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Alt Phone
                              </label>
                              <input type="text" className="form-control" placeholder="Alt Phone" value={altPhone} name="altPhone" onChange={(e) => handleAltPhone(e)} maxLength="10" />
                              {altPhoneError == true ? <span style={{ color: "#BE312E" }}>Please enter valid alt phone</span> : altPhoneError22 == true ? <span style={{ color: "#BE312E" }}>Center phone and Alternative phone should not be the same.</span> : ""}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Description
                              </label>
                              <input type="text" className="form-control" placeholder="Enter Description" value={description} onChange={handleDescription} maxLength={500} />
                              {descriptionError && <span style={{ color: "#BE312E" }}>Please enter valid description</span>}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="text-center d-block m-auto">
                              <button type="button" className="btn btn-primary btn-md    waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".transaction-detailModal" onClick={allDeatils}>
                                {" "}
                                Submit{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // add center ends */}
    </>
  );
}

export default AddCenter;
