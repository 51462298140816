import React, { useState, useRef, useEffect } from "react";
import Loader from "../Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { dispatchLoadingStatus, OtpVerifyForEmailAPI, sendOtpInEmailAPI } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png'

function Otp() {
  const [counter, setTimer] = useState("00:00");
  const [One, setOne] = useState("");
  const [Two, setTwo] = useState("");
  const [Three, setThree] = useState("");
  const [Four, setFour] = useState("");
  const [Click, setClick] = useState(1);

  function Move(e, p, c, n) {
    var length = document.getElementById(c).value.length;
    var maxLength = document.getElementById(c).getAttribute("maxLength");
    if (length == maxLength) {
      if (n !== "") {
        document.getElementById(n).focus();
      }
    }
    if (e.key == "Backspace") {
      document.getElementById(p).focus();
    }
  }

  let history = useNavigate();
  const resendValue = localStorage.getItem("resendValue");
  var number = localStorage.getItem("sendEmail");

  var a = number ? number.split("@") : "";
  var b = a[0];
  var newstr = "";
  for (var i in b) {
    if (i > 0 && i < b.length - 1) newstr += "*";
    else newstr += b[i];
  }

  const Ref = useRef(null);
  const dispatch = useDispatch({});

  let errorsObj = { Otp: "" };
  const [error, setError] = useState(errorsObj);

  const PhoneNumber = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!One) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (!Two) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (!Three) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (!Four) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (One && Two && Three && Four) {
      let combinee2 = One + Two + Three + Four;
      let otp_id = localStorage.getItem("otp_id");
      let admin_id = localStorage.getItem("admin_id");
      let formData = new FormData();
      formData.append("otp", combinee2);
      formData.append("otp_id", otp_id);
      formData.append("admin_id", admin_id);
      if (combinee2) {
        dispatch(dispatchLoadingStatus(true));
        dispatch(OtpVerifyForEmailAPI(formData));
      }
    }

    setError(errorObj);
  };

  const OtpVerifyForEmailRes = useSelector((state) => state.globalReducer.OtpVerifyForEmailRes);

  useEffect(() => {
    if (OtpVerifyForEmailRes) {
      history("/ResetPassword");
    }
  }, [OtpVerifyForEmailRes]);

  const NumValid = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setOne(e.target.value);
    }
  };

  const NumValid2 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setTwo(e.target.value);
    }
  };
  const NumValid3 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setThree(e.target.value);
    }
  };
  const NumValid4 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setFour(e.target.value);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
    }
  };

  const clearTimer = (e) => {
    setTimer("05:00");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };

  const onClickReset = (e) => {
    e.preventDefault();
    clearTimer(getDeadTime());
    setClick(Click + 1);
    localStorage.setItem("resendValue", Click);
    let formData = new FormData();
    formData.append("email", number);
    dispatch(dispatchLoadingStatus(true));
    dispatch(sendOtpInEmailAPI(formData));
  };
  const sendOtpInEmailRes = useSelector((state) => state.globalReducer.sendOtpInEmailRes);

  useEffect(() => {
    if (sendOtpInEmailRes) {
      localStorage.setItem("otp_id", sendOtpInEmailRes ? sendOtpInEmailRes.data.otp_id : "");
      localStorage.setItem("admin_id", sendOtpInEmailRes ? sendOtpInEmailRes.data.admin_id : "");
      localStorage.setItem("adminemail", sendOtpInEmailRes ? sendOtpInEmailRes.data.adminemail : "");
    }
  }, [sendOtpInEmailRes]);

  useEffect(() => {
    const token = localStorage.getItem("sendEmail");
    if (token) {
      if (!window.location.href.includes("Otp")) {
        window.location.href = window.location.origin + "/Otp";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    clearTimer(getDeadTime());
  }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Loader />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body ">
                <div className="auth-logo">
                  <a href="" className="auth-logo-dark">
                    <div className=" profile-user-wid mb-4">
                      <span className="logo ">
                        <img src={logo} alt="" className="" height="" />
                      </span>
                    </div>
                  </a>
                </div>
                <div className="p-2">
                  <form className="form-horizontal" action="">
                    <div className="text-center">
                      {" "}
                      <h5>Enter Verification code that we sent to {newstr + "@" + a[1]}</h5>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="username" className="form-label">
                          OTP
                        </label>
                      </div>

                      <div className="col text-center">
                        <input type="text" onKeyUp={(event) => Move(event, "", "tx1", "tx2")} className="form-control text-center" id="tx1" value={One} onChange={NumValid} maxLength="1" placeholder="-" />
                      </div>
                      <div className="col text-center">
                        <input type="text" onKeyUp={(event) => Move(event, "tx1", "tx2", "tx3")} className="form-control text-center" id="tx2" value={Two} onChange={NumValid2} maxLength="1" placeholder="-" />
                      </div>
                      <div className="col text-center">
                        <input type="text" onKeyUp={(event) => Move(event, "tx2", "tx3", "tx4")} className="form-control text-center" id="tx3" value={Three} onChange={NumValid3} maxLength="1" placeholder="-" />
                      </div>
                      <div className="col text-center">
                        <input type="text" onKeyUp={(event) => Move(event, "tx3", "tx4", "")} className="form-control text-center" id="tx4" value={Four} onChange={NumValid4} maxLength="1" placeholder="-" />
                      </div>
                      {error.Otp && <div className="error">{error.Otp} </div>}
                    </div>
                    <div className="mt-4 mt-3 d-grid">
                      <a className="btn btn-primary waves-effect waves-light" type="submit" onClick={PhoneNumber}>
                        Submit
                      </a>
                    </div>

                    {resendValue == 1 ? (
                      ""
                    ) : counter === "00:00" ? (
                      <div className="mt-4 text-center">
                        {resendValue >= 1 ? null : (
                          <div className="mt-4 text-center">
                            <a href="" className="text-muted text-underlined" onClick={onClickReset} cursor="not-allowed">
                              {" "}
                              Resend
                            </a>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mt-4 text-center">
                        <p id="couter">Resend OTP in {counter} </p>
                      </div>
                    )}
                    <ToastContainer />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
