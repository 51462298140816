import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function MainDashbord() {
  let history = useNavigate();

  const [openmenu, setopenmenu] = useState(window.navigator.userAgent.toLowerCase().includes("mobi") ? false : true);
  const [subMenu, setSubMenu] = useState(window.location.href.includes("/FreelancerRoleManagement") || window.location.href.includes("/FreelancerAssignRole") || window.location.href.includes("/Freelancers") ? true : false);
  const [assignmenu, setassignmenu] = useState(window.location.href.includes("/Addpeople") || window.location.href.includes("/Assignaction") || window.location.href.includes("/Addteam") ? true : false);
  const [assignmenu22, setassignmenu22] = useState(window.location.href.includes("/AuditedCBT") || window.location.href.includes("/AuditedPBT") || window.location.href.includes("/Centers") ? true : false);

  function LogOut() {
    confirmAlert({
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Ok",
          onClick: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("projapicall");
            localStorage.removeItem("emailAdmin");
            localStorage.setItem("projectTitle", "");
            localStorage.setItem("projectTitle22", "Project");
            history("/Login");
          },
        },
      ],
    });
  }

  let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
      document.body.classList.add("sidebar-main");
    } else {
      document.body.classList.remove("sidebar-main");
    }
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];
    let tokenRolee22 = localStorage.getItem("newRoleVissible");

    if (token && tokenRolee22) {
      if (!window.location.href.includes("/")) {
        window.location.href = window.location.origin + "//";
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  function setopenmenucall() {
    if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
      if (openmenu) {
        document.body.classList.remove("sidebar-main");
      } else {
        document.body.classList.add("sidebar-main");
      }
    } else {
      if (openmenu) {
        document.body.classList.add("sidebar-main");
      } else {
        document.body.classList.remove("sidebar-main");
      }
    }
    setopenmenu(!openmenu);
  }

  let AddTeamToken = localStorage.getItem("AddTeamToken");

  function AllFlage(e) {

    if (window.location.href.includes("Addteam") || window.location.href.includes("Sendmessage") || window.location.href.includes("Addcenter") || window.location.href.includes("FreelancerAssignRole") || window.location.href.includes("Addproject") || window.location.href.includes("Editcenter") || window.location.href.includes("Assignaction") || window.location.href.includes("AssignUser")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          history("/" + e);
        } 
      });
    } else {
      localStorage.setItem("FRlist", false);
      localStorage.setItem("projapicall", false);
      localStorage.setItem("centerapicall", false);
      localStorage.setItem("Addpeople", false);
      localStorage.setItem("getadminattendancelistAPI", false);
      localStorage.setItem("getAdminCompletedAuditList", false);
      localStorage.setItem("ExpenseAPI", false);
      localStorage.setItem("getAdminMessagesListAPI", false);
      localStorage.setItem("getFreelancerRoleListAPI", false);
      window.location.href = window.location.origin + "/" + e;

    }
  }

  function handleCenter() {
    if (AddTeamToken) {
      swal({
        text: "Are you sure you want to leave page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (AddTeamToken == "7") {
            history("/Centers");
          }
        } else {
        }
      });
    } else {
      history("/Centers");
    }
  }

  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <a href="/Dashboard" className="logo ">
                <span className="logo-lg">
                  <img className="logo" src="images/logo.png" alt="" height="" />
                </span>
              </a>
            </div>

            <button onClick={() => setopenmenucall()} type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>

          <div className="d-flex">

            <div className="dropdown d-inline-block">
              <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  {localStorage.getItem("nameAdmin")}
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item text-danger" href="/MyProfile">
                  <i className="bx bx-user-circle font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">My Profile</span>
                </a>

                <a className="dropdown-item text-danger" onClick={LogOut}>
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      {openmenu && (
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            <div id="sidebar-menu" className="mm-active">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title" key="t-menu">
                  Menu
                </li>

                {tokenRolee.find((n) => n === "Dashboard") ? (
                  <li className={window.location.href.includes("/Dashboard") ? "mm-active" : ""}>
                    <a
                      className="waves-effect"
                      onClick={() => {
                        AllFlage("Dashboard");
                      }}
                      style={tokenRolee.find((n) => n === "Dashboard") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-home-circle"></i>
                      <span key="t-dashboards">Dashboard</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Freelancers") ? (
                  <li className={window.location.href.includes("/Freelancers") || window.location.href.includes("/ProfileFreelancer") || window.location.href.includes("/FreelancerRoleManagement") || window.location.href.includes("/FreelancerAssignRole") || window.location.href.includes("/FreelanceEditRole") || subMenu ? "mm-active" : ""}>
                    <a
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                      onClick={() => {
                        setSubMenu(!subMenu);
                      }}
                      style={tokenRolee.find((n) => n === "Freelancers") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-user"></i>
                      <span key="t-invoices">Freelancers</span>
                    </a>

                    {subMenu && (
                      <ul>
                        <li className={window.location.href.includes("/FreelancerRoleManagement") || window.location.href.includes("/FreelancerAssignRole") || window.location.href.includes("/FreelanceEditRole") ? "mm-active" : ""}>
                          <a
                            key="t-invoice-list "
                            onClick={() => {
                              AllFlage("FreelancerRoleManagement");
                              setSubMenu(true);
                            }}
                          >
                            <i className="bx bx-user-check"></i>
                            Freelancer Role Management
                          </a>
                        </li>

                        <li
                          className={window.location.href.includes("/Freelancers") || window.location.href.includes("/ProfileFreelancer") ? "mm-active" : ""}
                          onClick={() => {
                            AllFlage("Freelancers");
                          }}
                        >
                          <a key="t-invoice-list ">
                            <i className="bx bx-user-circle"></i>
                            Freelancers
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Centers") ? (
                  <li className={window.location.href.includes("/AuditedCBT") || window.location.href.includes("/AuditedPBT") || window.location.href.includes("/Centers") || window.location.href.includes("/Centerdetails") || window.location.href.includes("/Addcenter") || window.location.href.includes("/Editcenter") || assignmenu22 ? "mm-active" : ""}>
                    <a
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                      onClick={() => {
                        setassignmenu22(!assignmenu22);
                      }}
                      style={tokenRolee.find((n) => n === "Centers") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-map"></i>
                      <span key="t-layouts">Centers</span>
                    </a>

                    {assignmenu22 && (
                      <ul>
                        <li className={window.location.href.includes("/Centers") ? "mm-active" : ""}>
                          <a
                            key="t-invoice-list"
                            onClick={() => {
                              AllFlage("Centers");
                            }}
                          >
                            <i className="bx bx-group"></i>
                            Centers
                          </a>
                        </li>
                        <li
                          onClick={() => {
                            AllFlage("AuditedCBT");
                          }}
                          className={window.location.href.includes("/AuditedCBT") ? "mm-active" : ""}
                        >
                          <a key="t-invoice-list">
                            <i className="bx bx-group"></i>
                            Audited CBT
                          </a>
                        </li>

                        <li
                          className={window.location.href.includes("/AuditedPBT") ? "mm-active" : ""}
                          onClick={() => {
                            AllFlage("AuditedPBT");
                          }}
                        >
                          <a key="t-invoice-list">
                            <i className="bx bx-group"></i>
                            Audited PBT
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Projects") ? (
                  <li className={window.location.href.includes("/Addproject") || window.location.href.includes("/Projects") || window.location.href.includes("/Projectdetails") ? "mm-active" : ""}>
                    <a
                      aria-expanded="true"
                      onClick={() => {
                        AllFlage("Projects");
                      }}
                      style={tokenRolee.find((n) => n === "Projects") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-layout"></i>
                      <span key="t-layouts">Projects</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Assign") ? (
                  <li className={window.location.href.includes("/Addteam") || window.location.href.includes("/Addpeople") || window.location.href.includes("/Assignaction") || assignmenu ? "mm-active" : ""}>
                    <a
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                      onClick={() => {
                        setassignmenu(!assignmenu);
                      }}
                      style={tokenRolee.find((n) => n === "Assign") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-user"></i>
                      <span key="t-layouts">Assign</span>
                    </a>
                    {assignmenu && (
                      <ul>
                        <li
                          onClick={() => {
                            AllFlage("Addpeople");
                          }}
                          className={window.location.href.includes("/Addpeople") || window.location.href.includes("/Assignaction") ? "mm-active" : ""}
                        >
                          <a key="t-invoice-list">
                            <i className="bx bx-user-plus"></i>
                            Add People
                          </a>
                        </li>

                        <li
                          className={window.location.href.includes("/Addteam") ? "mm-active" : ""}
                          onClick={() => {
                            AllFlage("Addteam");
                          }}
                        >
                          <a key="t-invoice-list">
                            <i className="bx bx-group"></i>
                            Add Team
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Attendance") ? (
                  <li className={window.location.href.includes("/Attendance") ? "mm-active" : ""}>
                    <a
                      aria-expanded="true"
                      onClick={() => {
                        AllFlage("Attendance");
                      }}
                      style={tokenRolee.find((n) => n === "Attendance") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-id-card"></i>
                      <span key="t-layouts">Attendance</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Message") ? (
                  <li className={window.location.href.includes("/Message") || window.location.href.includes("/Sendmessage") || window.location.href.includes("/MessageDetails") ? "mm-active" : ""}>
                    <a
                      className=" waves-effect"
                      onClick={() => {
                        AllFlage("Message");
                      }}
                      style={tokenRolee.find((n) => n === "Message") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-chat"></i>
                      <span key="t-layouts">Messages</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Audits") ? (
                  <li className={window.location.href.includes("/Audits") ? "mm-active" : ""}>
                    <a
                      className=" waves-effect"
                      onClick={() => {
                        AllFlage("Audits");
                      }}
                      style={tokenRolee.find((n) => n === "Audits") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-check-circle"></i>
                      <span key="t-layouts">Audits</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Expenses") ? (
                  <li className={window.location.href.includes("/Expenses") ? "mm-active" : ""}>
                    <a
                      aria-expanded="true"
                      onClick={() => {
                        AllFlage("Expenses");
                      }}
                      style={tokenRolee.find((n) => n === "Expenses") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-rupee"></i>
                      <span key="t-layouts">Expenses</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Role Management") ? (
                  <li className={window.location.href.includes("/AdminUserManagement") || window.location.href.includes("/AssignUser") || window.location.href.includes("/EditUser") ? "mm-active" : ""}>
                    <a
                      aria-expanded="false"
                      onClick={() => {
                        AllFlage("AdminUserManagement");
                      }}
                      style={tokenRolee.find((n) => n === "Role Management") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-user"></i>
                      <span key="t-invoices">Admin User Management</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {tokenRolee.find((n) => n === "Report") ? (
                  <li className={window.location.href.includes("/Reports") ? "mm-active" : ""}>
                    <a
                      aria-expanded="false"
                      onClick={() => {
                        AllFlage("Reports");
                      }}
                      style={tokenRolee.find((n) => n === "Report") ? {} : { "pointer-events": "none", opacity: ".5" }}
                    >
                      <i className="bx bx-file"></i>
                      <span key="t-invoices">Reports</span>
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainDashbord;
