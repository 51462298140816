import React, { useState, useEffect } from "react";
import MainDashbord from "../Sidebar/MainDashbord";
import { useDispatch, useSelector } from "react-redux";
import { centersbyfreelanceprojectIdAPI, pendingCentersByFreelancerProjectIdAPI, assignCentersToFreelancerRolesAPI, getStatesCitiesByProjectIdAPI, freelancersDDLAPI, assignTeamToProjectCenterAPI, dispatchLoadingStatus, getstatecityAPI, getProjectTitleAPI, getRolesAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddTeam() {
  const dispatch = useDispatch({});

  useEffect(() => {
    localStorage.setItem("AddTeamToken", "7");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    dispatch(getProjectTitleAPI(formData));
    dispatch(getRolesAPI(formData));
  }, []);

  function projectSecAPI() {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(getProjectTitleAPI(formData));
  }

  const getProjectTitleRes = useSelector((state) => state.globalReducer.getProjectTitleRes);
  const centersbyfreelanceprojectIdRes = useSelector((state) => state.globalReducer.centersbyfreelanceprojectIdRes);
  const freelancersDDLRes = useSelector((state) => state.globalReducer.freelancersDDLRes);
  let getRolesReg = useSelector((state) => state.globalReducer.getRolesReg);
  let getStatesCitiesByProjectIdRes = useSelector((state) => state.globalReducer.getStatesCitiesByProjectIdRes);
  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);
  let assignCentersToFreelancerRolesRes = useSelector((state) => state.globalReducer.assignCentersToFreelancerRolesRes);
  let assignTeamToProjectCenterRes = useSelector((state) => state.globalReducer.assignTeamToProjectCenterRes);

  const [stateAndCity, setStateAndCity] = useState();
  const [stateAndCity22, setStateAndCity22] = useState();

  useEffect(() => {
    if (assignTeamToProjectCenterRes) {
      if (assignTeamToProjectCenterRes.errorcode == 0) {
        setstate22("");
        setcitii("");
        setFreelancer22([{ label: "Select Freelancer", value: "" }]);
        setPro([{ label: "Select Project", value: "" }]);
        setEndDate22("");
      }
    }
  }, [assignTeamToProjectCenterRes]);

  useEffect(() => {
    if (getstatecityReg) {
      if (getstatecityReg.errorcode == 1) {
        setStateAndCity22([]);
      } else {
        setStateAndCity22(getstatecityReg ? getstatecityReg.data : []);
      }
    }
  }, [getstatecityReg]);

  useEffect(() => {
    if (getStatesCitiesByProjectIdRes) {
      if (getStatesCitiesByProjectIdRes.errorcode == 1) {
        setStateAndCity([]);
      } else {
        setStateAndCity(getStatesCitiesByProjectIdRes ? getStatesCitiesByProjectIdRes.data : []);
      }
    }
  }, [getStatesCitiesByProjectIdRes]);

  useEffect(() => {
    if (assignCentersToFreelancerRolesRes) {
      if (assignCentersToFreelancerRolesRes.errorcode == 0) {
        setstate22("");
        setcitii("");
        setFreelancer22([{ label: "Select Freelancer", value: "" }]);
        setPro([{ label: "Select Project", value: "" }]);
        setEndDate22("");
      }
    }
  }, [assignCentersToFreelancerRolesRes]);

  const [CenterList, setCenterList] = useState();
  const [startDate, setStartdate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartdateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const [startDate22, setStartdate22] = useState(new Date());
  const [endDate22, setEndDate22] = useState("");
  const [startDate22Error, setStartdate22Error] = useState(false);
  const [endDateError22, setEndDate22Error] = useState(false);

  const [state22, setstate22] = useState("");
  const [state33, setstate33] = useState("");
  const [cityNew, setciti] = useState("");
  const [citylist22, setcitylist22] = useState([]);
  const [state22Error, setstate22Error] = useState(false);
  const [cityNewError, setcitiError] = useState(false);

  const [state44, setstate44] = useState("");
  const [state55, setstate55] = useState("");
  const [cityNeww, setcitii] = useState("");
  const [citylist33, setcitylist33] = useState([]);
  const [state44Error, setstate44Error] = useState(false);
  const [cityNewwError, setcitiiError] = useState(false);

  const [assignButton, setAssignButton] = useState(false);
  const [assignButton22, setAssignButton22] = useState(false);
  const [free22, setFree22] = useState(false);

  function handleCity2(e) {
    if (e.value == "") {
      setcitii("");
      setcitiiError(true);
      setFreelancer22([{ label: "Select Freelancer", value: "" }]);
      setPro([{ label: "Select Project", value: "" }]);
      setFreelancer("");
      setProject22("");
      setFreelancerDDL([]);
    } else {
      setcitii(e.value);
      if (e.value && state44) {
        let formData22 = new FormData();
        formData22.append("token", localStorage.getItem("token"));
        formData22.append("state_id", state44 ? state44 : "");
        formData22.append("city", e.value);
        dispatch(freelancersDDLAPI(formData22));
        setFreelancer22([{ label: "Select Freelancer", value: "" }]);
        setPro([{ label: "Select Project", value: "" }]);
        setFreelancer("");
        setProject22("");
      }
      setcitiiError(false);
      setFreelancer22([{ label: "Select Freelancer", value: "" }]);
      setPro([{ label: "Select Project", value: "" }]);
      setFreelancer("");
      setProject22("");
    }
  }

  function setStateCenter22(e) {
    if (e.value == "") {
      setstate44Error(true);
      setstate44("");
      setcitii("");
      setFreelancer22([{ label: "Select Freelancer", value: "" }]);
      setPro([{ label: "Select Project", value: "" }]);
      setFreelancer("");
      setProject22("");
      setcitii("");
      setcitylist33([]);
      setFreelancerDDL([]);
    } else {
      if (getstatecityReg) {
        var filter = getstatecityReg.data.filter((i) => i._id == e.value);
        setstate44(e.value);
        setstate44Error(false);
        setstate55(filter.length > 0 ? filter[0].state : []);

        if (filter && e.value) {
          setcitylist33(filter[0].districts);
          let formData22 = new FormData();
          formData22.append("token", localStorage.getItem("token"));
          formData22.append("state_id", e.value);
          formData22.append("city", cityNeww ? cityNeww : "");
          dispatch(freelancersDDLAPI(formData22));
          setcitii("");
          setFreelancer22([{ label: "Select Freelancer", value: "" }]);
          setPro([{ label: "Select Project", value: "" }]);
          setFreelancer("");
          setProject22("");
        } else {
          setcitylist33([]);
          setcitii("");
          setFreelancer22([{ label: "Select Freelancer", value: "" }]);
          setPro([{ label: "Select Project", value: "" }]);
          setFreelancer("");
          setProject22("");
        }
      }
    }
  }

  function setStateCenter(e) {
    if (e.value == "") {
      setstate22Error(true);
      setstate22("");
      setciti("");
      setCenter22([{ label: "Select Center", value: "" }]);
      setCenter("");
      setCenterList([]);
      setcitylist22([]);
    } else {
      if (getstatecityReg) {
        var filter = getstatecityReg.data.filter((i) => i._id == e.value);
        setstate22(e.value);
        setstate22Error(false);
        setstate33(filter.length > 0 ? filter[0].state : []);

        if (filter && e.value) {
          setcitylist22(filter[0].districts);
          setcitii("");
          setCenter22([{ label: "Select Center", value: "" }]);
          setCenter("");
          setCenterList([]);
        } else {
          setcitylist22([]);
          setcitii("");
          setCenter22([{ label: "Select Center", value: "" }]);
          setCenter("");
          setCenterList([]);
        }
      }
    }
  }

  function handleCity(e) {
    if (e.value == "") {
      setciti("");
      setcitiError(true);
      setCenterList([]);
      setCenter22("");
    } else {
      setciti(e.value);
      if (e.value && Project && state22) {
        let formData22 = new FormData();
        formData22.append("token", localStorage.getItem("token"));
        formData22.append("project_id", Project);
        formData22.append("state_id", state22);
        formData22.append("city", e.value);
        dispatch(centersbyfreelanceprojectIdAPI(formData22));
        setCenter22("");
      }
      setcitiError(false);
    }
  }

  const [FreelacerDDL, setFreelancerDDL] = useState();
  useEffect(() => {
    if (freelancersDDLRes) {
      if (freelancersDDLRes.errorcode == 1) {
        setFreelancerDDL([]);
      } else {
        setFreelancerDDL(freelancersDDLRes ? freelancersDDLRes.data : freelancersDDLRes.data);
      }
    }
  }, [freelancersDDLRes]);

  useEffect(() => {
    if (centersbyfreelanceprojectIdRes) {
      if (centersbyfreelanceprojectIdRes.errorcode == 1) {
        setCenterList([]);
      } else {
        setCenterList(centersbyfreelanceprojectIdRes ? centersbyfreelanceprojectIdRes.data : centersbyfreelanceprojectIdRes.data);
      }
    }
  }, [centersbyfreelanceprojectIdRes]);

  const [Project, setProject] = useState();
  const [ProjectError, setProjectError] = useState(false);

  const [Project22, setProject22] = useState();
  const [Project22Error, setProject22Error] = useState(false);

  const [pro, setPro] = useState({ label: "Select Project", value: "" });

  const [Center, setCenter] = useState();
  const [Center22, setCenter22] = useState({ label: "Select Center", value: "" });
  const [CenterError, setCenterError] = useState(false);

  const [Freelancer, setFreelancer] = useState();
  const [Freelancer22, setFreelancer22] = useState({ label: "Select Freelancer", value: "" });
  const [FreelancerError, setFreelancerError] = useState(false);

  const [Role, setRole] = useState();
  const [Role22, setRole22] = useState();

  const [val, setVal] = useState([{ freelancer_id: "", freelancer_role_id: "" }]);
  const [val22, setVal22] = useState([{ center_id: "", freelancer_role_id: "" }]);

  const handleClick = () => {
    setVal([...val, { freelancer_id: "", freelancer_role_id: "" }]);
  };
  const handleClick22 = () => {
    setVal22([...val22, { center_id: "", freelancer_role_id: "" }]);
  };

  const handleRemove = (index) => {
    const list = [...val];
    if (index > -1) {
      list.splice(index, 1);
    }
    setVal(list);
  };

  const handleRemove22 = (index) => {
    const list = [...val22];
    if (index > -1) {
      list.splice(index, 1);
    }
    setVal22(list);
  };

  function handleProjectTitle22(e) {
    if (e.value && e) {
      setProject22(e.value);
      setPro([{ label: e.label, value: e.value }]);

      setProject22Error(false);
      var filter22 = getRolesReg.filter((i) => i.examType == e.examType);

      if (filter22.length > 0) {
        setRole22(filter22);
      }
    } else {
      setProject("");
      setPro([{ label: "Select Project", value: "" }]);
      setProject22("");
      setProject22Error(true);
    }
  }

  function handleProjectTitle(e) {
    if (e.value) {
      setProject(e.value);
      setProjectError(false);

      var filter22 = getRolesReg.filter((i) => i.examType == e.examType);

      if (filter22.length > 0) {
        setRole(filter22);
      }

      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", e.value);

      dispatch(getStatesCitiesByProjectIdAPI(formData));
      setstate22("");
      setciti("");
      setCenter22([{ label: "Select Center", value: "" }]);
      setCenter("");
      setcitylist22([]);
      setCenterList([]);
    } else {
      setProject("");
      setProjectError(true);
      setstate22("");
      setciti("");
      setCenter22([{ label: "Select Center", value: "" }]);
      setCenter("");
      setcitylist22([]);
      setCenterList([]);
      setStateAndCity([]);
    }
  }

  function handleCenterList22(e) {
    if (e.value) {
      setFreelancer(e.value);
      setFreelancer22([{ label: e.label, value: e.value }]);
      setFreelancerError(false);

      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("freelancerId", e.value);
      dispatch(getProjectTitleAPI(formData));
      setPro([{ label: "Select Project", value: "" }]);
      setProject22("");
    } else {
      setFreelancer("");
      setFreelancer22([{ label: "Select Freelancer", value: "" }]);
      setPro([{ label: "Select Project", value: "" }]);
      setProject22("");

      setFreelancerError(true);
    }
  }

  function handleCenterList(e) {
    if (e.value) {
      setCenter(e.value);
      setCenter22([{ label: e.label, value: e.value }]);
      setCenterError(false);
    } else {
      setCenter("");
      setCenter22([{ label: "Select Center", value: "" }]);

      setCenterError(true);
    }
  }

  function secondDetails() {
    if (!state44) {
      setstate44Error(true);
    } else {
      setstate44Error(false);
    }

    if (!cityNeww) {
      setcitiiError(true);
    } else {
      setcitiiError(false);
    }

    if (!startDate22) {
      setStartdate22Error(true);
    }

    if (!endDate22) {
      setEndDate22Error(true);
    }

    if (!Freelancer || !Freelancer22) {
      setFreelancerError(true);
    } else {
      setFreelancerError(false);
    }

    if (!Project22) {
      setProject22Error(true);
    } else {
      setProject22Error(false);
    }

    if (Freelancer && Project22 && startDate22 && endDate22 && state44 && cityNeww) {
      let formData22 = new FormData();
      formData22.append("token", localStorage.getItem("token"));
      formData22.append("project_id", Project22);
      formData22.append("state_id", state44);
      formData22.append("city", cityNeww);
      dispatch(centersbyfreelanceprojectIdAPI(formData22));
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", Project22);
      formData.append("freelancerId", Freelancer);
      dispatch(pendingCentersByFreelancerProjectIdAPI(formData));
      setAssignButton22(true);
      setFree22(true);
      swal("", "Information saved , Now Please select center and their role NOTE: Use 'Add More' button to add Multiple center ", "success");
    } else {
      setAssignButton22(false);
      setFree22(false);
    }
  }

  function allDetails() {
    if (!state22) {
      setstate22Error(true);
    } else {
      setstate22Error(false);
    }

    if (!cityNew) {
      setcitiError(true);
    } else {
      setcitiError(false);
    }

    if (!startDate) {
      setStartdateError(true);
    }

    if (!endDate) {
      setEndDateError(true);
    }

    if (!Center || !Center22) {
      setCenterError(true);
    } else {
      setCenterError(false);
    }

    if (Project) {
      setProjectError(false);
    } else {
      setProjectError(true);
    }

    if (Center && Project && startDate && endDate && state22 && cityNew) {
      let formData = new FormData();

      formData.append("token", localStorage.getItem("token"));
      dispatch(freelancersDDLAPI(formData));
      setAssignButton(true);
      setstate44("");
      swal("", "Information saved , Now Please select freelancer and their role NOTE: Use 'Add More' button to add Multiple freelancer ", "success");
    } else {
      setAssignButton(false);
    }
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...val];
    list[index][name] = value;

    if (name === "freelancer_id") {
      list[index]["freelancerError"] = "";
    }
    if (name === "freelancer_role_id") {
      list[index]["roleError"] = "";
    }

    setVal(list);
  };

  const handleInputChange22 = (e, index) => {
    const { name, value } = e.target;

    const list = [...val22];
    list[index][name] = value;

    if (name === "center_id") {
      list[index]["centerError"] = "";
    }
    if (name === "freelancer_role_id") {
      list[index]["roleError"] = "";
    }

    setVal22(list);
  };

  function secondDetails22() {
    const color = {
      color: "#BE312E",
    };

    val22.forEach((object) => {
      object.centerError = object.center_id == "Select Center" || object.center_id == "" ? <p style={color}> Please select center</p> : "";
      object.roleError = object.freelancer_role_id == "Select Role" || object.freelancer_role_id == "" ? <p style={color}> Please select role</p> : "";
    });

    setVal22([...val22]);
    var final = val22.filter((i) => i.centerError || i.roleError);

    let formData = new FormData();

    if (final.length === 0) {
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", Project22);
      formData.append("centers", JSON.stringify(val22));
      formData.append("freelancer_id", Freelancer);
      formData.append("assign_date", moment(startDate22).format("DD/MM/yyyy"));
      formData.append("due_date", moment(endDate22).format("DD/MM/yyyy"));

      dispatch(dispatchLoadingStatus(true));
      dispatch(assignCentersToFreelancerRolesAPI(formData));
    }
  }

  function allDetails22() {
    const color = {
      color: "#BE312E",
    };

    val.forEach((object) => {
      object.freelancerError = object.freelancer_id == "Select Freelancer" || object.freelancer_id == "" ? <p style={color}> Please select freelancer</p> : "";
      object.roleError = object.freelancer_role_id == "Select Role" || object.freelancer_role_id == "" ? <p style={color}> Please select role</p> : "";
    });

    setVal([...val]);
    var final = val.filter((i) => i.freelancerError || i.roleError);

    let formData = new FormData();

    if (final.length === 0) {
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", Project);
      formData.append("state_id", state22);
      formData.append("city", cityNew);
      formData.append("center_id", Center);
      formData.append("freelancers", JSON.stringify(val));
      formData.append("assign_date", moment(startDate).format("DD/MM/yyyy"));
      formData.append("due_date", moment(endDate).format("DD/MM/yyyy"));
      dispatch(dispatchLoadingStatus(true));
      dispatch(assignTeamToProjectCenterAPI(formData));
    }
  }

  return (
    <>
      <h1>Center List Page</h1>
      <MainDashbord />
      <Loader />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Team</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <a href="/Addpeople" className="breadcrumb-item active">
                        Assign
                      </a>
                      <a href="/Addteam" className="breadcrumb-item active">
                        Add Team
                      </a>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="">
                    <div className="table-rep-plugin">
                      <div className="row mb-3 no-gutters">

                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-body">
                              <ul className="nav nav-pills nav-justified add-team-ul" role="tablist">
                                <li className="nav-item waves-effect waves-light">
                                  <a
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    onClick={() => {
                                      projectSecAPI();
                                      setCenterList([]);
                                      setFreelancerDDL([]);
                                    }}
                                    href="#home-1"
                                    role="tab"
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Center</span>
                                  </a>
                                </li>
                                <li className="nav-item waves-effect waves-light">
                                  <a
                                    className="nav-link"
                                    id="hrefLink"
                                    data-bs-toggle="tab"
                                    href="#profile-1"
                                    role="tab"
                                    onClick={() => {
                                      setciti("");
                                      setstate22("");
                                      setcitylist22([]);
                                      setCenterList([]);
                                      setCenter22("");
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="far fa-user"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Freelancer</span>
                                  </a>
                                </li>
                                <li className="nav-item waves-effect waves-light">
                                  <a className="nav-link" href="/Addpeople">
                                    <span className="d-block d-sm-none">
                                      <i className="far fa-envelope"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Unassigned</span>
                                  </a>
                                </li>
                              </ul>

                              <div className="tab-content p-3 text-muted">
                                <div className="tab-pane active" id="home-1" role="tabpanel">
                                  <div className="row  mt-5">
                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select Project *</label>

                                        <Select options={getProjectTitleRes ? [{ label: "Project", value: "" }].concat(getProjectTitleRes.map((e) => ({ label: e.projectTitle, value: e._id, examType: e.examType }))) : []} isClearable={false} placeholder={"Select"} onChange={(e) => handleProjectTitle(e)} isDisabled={assignButton == true ? true : false} />
                                      </div>
                                      {ProjectError && <span style={{ color: "#BE312E" }}>Please select project</span>}
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select State *</label>

                                        <Select options={stateAndCity ? (stateAndCity.length > 0 ? [{ label: "State", value: "" }].concat(stateAndCity.map((e) => ({ label: e.state, value: e._id }))) : [{ label: "State", value: "" }]) : [{ label: "State", value: "" }]} placeholder={"State"} value={state22 == "" ? [{ label: "State", value: "" }] : state22 ? [{ label: state33, value: state22 }] : [{ label: "State", value: "" }]} onChange={(e) => setStateCenter(e)} isDisabled={assignButton == true ? true : false} />
                                        {state22Error && <span style={{ color: "#BE312E" }}>Please select state</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select City *</label>

                                        <Select options={citylist22 ? [{ label: "City", value: "" }].concat(citylist22.map((e) => ({ label: e, value: e }))) : []} placeholder={"City"} value={cityNew == "" ? [{ label: "City", value: "" }] : cityNew ? [{ label: cityNew, value: cityNew }] : [{ label: "City", value: "" }]} onChange={(e) => handleCity(e)} isDisabled={assignButton == true ? true : false} />
                                      </div>
                                      {cityNewError && <span style={{ color: "#BE312E" }}>Please select city</span>}
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select Center *</label>

                                        <Select options={CenterList ? (CenterList.length > 0 ? [{ label: "Select Center", value: "" }].concat(CenterList.map((e) => ({ label: e.center_name, value: e._id }))) : [{ label: "Select Center", value: "" }]) : [{ label: "Select Center", value: "" }]} isClearable={false} placeholder={"Select"} value={Center22 ? Center22 : [{ label: "Select Center", value: "" }]} onChange={(e) => handleCenterList(e)} isDisabled={assignButton == true ? true : false} />
                                      </div>
                                      {CenterError && <span style={{ color: "#BE312E" }}>Please select center</span>}
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label>Assign Date *</label>
                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd/MM/yyyy"
                                          onChange={(value) => {
                                            setStartdate(value);
                                            setStartdateError(false);
                                          }}
                                          selected={startDate ? startDate : null}
                                          value={startDate}
                                          placeholderText={"dd/mm/yyyy"}
                                          showMonthDropdown
                                          showYearDropdown
                                          scrollableYearDropdown
                                          maxDate={endDate}
                                          minDate={moment().toDate()}
                                          disabled={assignButton ? true : false}
                                        />
                                        {startDateError && <span style={{ color: "#BE312E" }}>Please select assign date</span>}
                                      </div>
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label>Due Date *</label>

                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd/MM/yyyy"
                                          onChange={(value) => {
                                            setEndDate(value);
                                            setEndDateError(false);
                                          }}
                                          selected={endDate ? endDate : null}
                                          placeholderText={"dd/mm/yyyy"}
                                          showMonthDropdown
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={moment().year() - 1900}
                                          minDate={startDate}
                                          disabled={assignButton ? true : false}
                                        />

                                        {endDateError && <span style={{ color: "#BE312E" }}>Please select due date</span>}
                                      </div>
                                    </div>

                                    {assignButton == false ? (
                                      <div className="col-lg-2">
                                        <button
                                          className="btn btn-primary mt-4 waves-effect waves-light"
                                          onClick={() => {
                                            allDetails();
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="col-lg-2">
                                        <button
                                          className="btn btn-primary mt-4 waves-effect waves-light"
                                          onClick={() => {
                                            setAssignButton(false);
                                          }}
                                        >
                                          Edit{" "}
                                        </button>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-12">
                                    <div className="">
                                      <div className="card-body rounded border mt-5">
                                        <div className="col-12">
                                          <h4 className="mb-4 font-size-18">Select Freelancer</h4>
                                        </div>
                                        {val.map((i, index) => {
                                          return (
                                            <div className="row">
                                              <div className="col-lg-5">
                                                <div>
                                                  <label htmlFor="freelancer" className="form-label">
                                                    Select Freelancer *
                                                  </label>
                                                  <select
                                                    className="form-select"
                                                    name="freelancer_id"
                                                    value={i.freelancer_id}
                                                    onChange={(e) => {
                                                      handleInputChange(e, index);
                                                    }}
                                                  >
                                                    <option>Select Freelancer </option>
                                                    {FreelacerDDL ? (FreelacerDDL.length > 0 ? FreelacerDDL.map((i) => <option value={i._id}> {i.freelancer_full_name}</option>) : "") : ""}
                                                  </select>

                                                  {i.freelancerError && <p>{i.freelancerError}</p>}
                                                </div>
                                              </div>

                                              <div className="col-lg-5">
                                                <div>
                                                  <label htmlFor="freelancer" className="form-label">
                                                    Select Role *
                                                  </label>
                                                  <select
                                                    className="form-select"
                                                    name="freelancer_role_id"
                                                    value={i.freelancer_role_id}
                                                    onChange={(e) => {
                                                      handleInputChange(e, index);
                                                    }}
                                                  >
                                                    <option>Select Role </option>
                                                    {Role ? Role && Role.map((i) => <option value={i.roleid}> {i.role}</option>) : ""}
                                                  </select>
                                                  {i.roleError && <p>{i.roleError}</p>}
                                                </div>
                                              </div>

                                              <div className="col-lg-2">
                                                {val.length > 1 && (
                                                  <button className="btn mt-4 btn-primary" type="submit" onClick={(e) => handleRemove(index)}>
                                                    Remove
                                                  </button>
                                                )}

                                                {val.length - 1 === index && (
                                                  <button className="btn mt-4 btn-primary waves-effect waves-light" type="submit" onClick={() => handleClick()}>
                                                    Add More
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                        <br /> <br />
                                        <button className="btn btn-primary" type="submit" onClick={allDetails22}>
                                          {" "}
                                          Submit{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-pane" id="profile-1" role="tabpanel">
                                  <div className="row  mt-5">
                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select State *</label>

                                        <Select options={stateAndCity22 ? (stateAndCity22.length > 0 ? [{ label: "State", value: "" }].concat(stateAndCity22.map((e) => ({ label: e.state, value: e._id }))) : [{ label: "State", value: "" }]) : [{ label: "State", value: "" }]} placeholder={"State"} value={state44 == "" ? [{ label: "State", value: "" }] : state44 ? [{ label: state55, value: state44 }] : [{ label: "State", value: "" }]} onChange={(e) => setStateCenter22(e)} isDisabled={assignButton22 == true ? true : false} />
                                        {state44Error && <span style={{ color: "#BE312E" }}>Please select state</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select City *</label>

                                        <Select options={citylist33 ? [{ label: "City", value: "" }].concat(citylist33.map((e) => ({ label: e, value: e }))) : []} placeholder={"City"} value={cityNeww == "" ? [{ label: "City", value: "" }] : cityNeww ? [{ label: cityNeww, value: cityNeww }] : [{ label: "City", value: "" }]} onChange={(e) => handleCity2(e)} isDisabled={assignButton22 == true ? true : false} />
                                      </div>
                                      {cityNewwError && <span style={{ color: "#BE312E" }}>Please select city</span>}
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select Freelancer *</label>

                                        <Select options={FreelacerDDL ? (FreelacerDDL.length > 0 ? [{ label: "Select Freelancer", value: "" }].concat(FreelacerDDL.map((e) => ({ label: e.freelancer_full_name, value: e._id }))) : [{ label: "Select Freelancer", value: "" }]) : [{ label: "Select Freelancer", value: "" }]} isClearable={false} placeholder={"Select"} value={Freelancer22 ? Freelancer22 : [{ label: "Select Freelancer", value: "" }]} onChange={(e) => handleCenterList22(e)} isDisabled={assignButton22 == true ? true : false} />
                                      </div>
                                      {FreelancerError && <span style={{ color: "#BE312E" }}>Please select freelancer</span>}
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label className="form-label">Select Project *</label>

                                        <Select options={Freelancer ? (getProjectTitleRes ? [{ label: "Select Project", value: "" }].concat(getProjectTitleRes.map((e) => ({ label: e.projectTitle, value: e._id, examType: e.examType }))) : []) : [{ label: "Select Project", value: "" }]} isClearable={false} placeholder={"Select"} value={pro ? pro : [{ label: "Select Project", value: "" }]} onChange={(e) => handleProjectTitle22(e)} isDisabled={assignButton22 == true ? true : false} />
                                      </div>
                                      {Project22Error && <span style={{ color: "#BE312E" }}>Please select project</span>}
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label>Assign Date *</label>
                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd/MM/yyyy"
                                          onChange={(value) => {
                                            setStartdate22(value);
                                            setStartdate22Error(false);
                                          }}
                                          selected={startDate22 ? startDate22 : null}
                                          value={startDate22}
                                          placeholderText={"dd/mm/yyyy"}
                                          showMonthDropdown
                                          showYearDropdown
                                          scrollableYearDropdown
                                          maxDate={endDate22}
                                          minDate={moment().toDate()}
                                          disabled={assignButton22 ? true : false}
                                        />
                                        {startDate22Error && <span style={{ color: "#BE312E" }}>Please select assign date</span>}
                                      </div>
                                    </div>

                                    <div className="col-lg-5">
                                      <div>
                                        <label>Due Date *</label>

                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd/MM/yyyy"
                                          onChange={(value) => {
                                            setEndDate22(value);
                                            setEndDate22Error(false);
                                          }}
                                          selected={endDate22 ? endDate22 : null}
                                          placeholderText={"dd/mm/yyyy"}
                                          showMonthDropdown
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={moment().year() - 1900}
                                          minDate={startDate22}
                                          disabled={assignButton22 ? true : false}
                                        />

                                        {endDateError22 && <span style={{ color: "#BE312E" }}>Please select due date</span>}
                                      </div>
                                    </div>

                                    {assignButton22 == false ? (
                                      <div className="col-lg-2">
                                        <button
                                          className="btn btn-primary mt-4 waves-effect waves-light"
                                          onClick={() => {
                                            secondDetails();
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="col-lg-2">
                                        <button
                                          className="btn btn-primary mt-4 waves-effect waves-light"
                                          onClick={() => {
                                            setAssignButton22(false);
                                          }}
                                        >
                                          Edit{" "}
                                        </button>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-12">
                                    <div className="">
                                      <div className="card-body rounded border mt-5">
                                        <div className="col-12">
                                          <h4 className="mb-4 font-size-18">Select Centers</h4>
                                        </div>
                                        {val22.map((i, index) => {
                                          return (
                                            <div className="row">
                                              <div className="col-lg-5">
                                                <div>
                                                  <label htmlFor="freelancer" className="form-label">
                                                    Select Center *
                                                  </label>
                                                  <select
                                                    className="form-select"
                                                    name="center_id"
                                                    value={i.associated_center_id}
                                                    onChange={(e) => {
                                                      handleInputChange22(e, index);
                                                    }}
                                                  >
                                                    <option>Select Center </option>
                                                    {free22 == true ? (CenterList ? (CenterList.length > 0 ? CenterList.map((i) => <option value={i.associated_center_id}> {i.center_name}</option>) : "") : "") : ""}
                                                  </select>

                                                  {i.centerError && <p>{i.centerError}</p>}
                                                </div>
                                              </div>

                                              <div className="col-lg-5">
                                                <div>
                                                  <label htmlFor="freelancer" className="form-label">
                                                    Select Role *
                                                  </label>
                                                  <select
                                                    className="form-select"
                                                    name="freelancer_role_id"
                                                    value={i.freelancer_role_id}
                                                    onChange={(e) => {
                                                      handleInputChange22(e, index);
                                                    }}
                                                  >
                                                    <option>Select Role </option>
                                                    {Role22 ? Role22 && Role22.map((i) => <option value={i.roleid}> {i.role}</option>) : ""}
                                                  </select>
                                                  {i.roleError && <p>{i.roleError}</p>}
                                                </div>
                                              </div>

                                              <div className="col-lg-2">
                                                {val22.length > 1 && (
                                                  <button className="btn mt-4 btn-primary" type="submit" onClick={(e) => handleRemove22(index)}>
                                                    Remove
                                                  </button>
                                                )}

                                                {val22.length - 1 === index && (
                                                  <button className="btn mt-4 btn-primary" type="submit" onClick={() => handleClick22()}>
                                                    Add More
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                        <br /> <br />
                                        <button className="btn btn-primary" type="submit" onClick={secondDetails22}>
                                          {" "}
                                          Submit{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className="tab-pane" id="messages-1" role="tabpanel">
                                  <p className="mb-0">Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever gluten-free.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
              <div className="col-sm-6">
                <div className="text-sm-end d-none d-sm-block">
                  Powered by{" "}
                  <a href="https://www.nichetechsolutions.com/" target="_blank">
                    Nichetech.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default AddTeam;
