import swal from "sweetalert";
import axios from "axios";

import {
  IS_LOADING,
  baseURL,
  LoginReg,
  Login_URL,
  sendOtp_URL,
  sendOtpReg,
  verifyOtp_URL,
  AdminLogin_URL,
  AdminLoginReg,
  getfreelancers_URL,
  getfreelancersReg,
  freelancerstatus_URL,
  freelancerstatusReg,
  getstate_URL,
  getstatecityReg,
  getRolesURL,
  getRolesReg,
  getqulification_URL,
  getprojects_URL,
  getQulificationsReg,
  getProjectsRes,
  getprojectddl_URL,
  getProjectddlRes,
  getProjectTypeURL,
  getProjectTypeReg,
  AddProjectURL,
  AddProjectReg,
  getStatecity,
  getStatecityURL,
  getprojecttitle_URL,
  getProjectTitleRes,
  centersbyfreelanceprojectId_URL,
  centersbyfreelanceprojectIdRes,
  getCenterListRes,
  getCenterList_URL,
  AssignProjectURL,
  AssignProjectRes,
  freelancersAssignedCentersRes,
  freelancersAssignedCentersURL,
  getadminattendancelistURL,
  getadminattendancelistRes,
  getCenterNameRes,
  getCenterListDDLURL,
  AttendanceCSVURL,
  attendanceapproverejectURL,
  attendanceapproverejectRes,
  getAdminCompletedAuditListURL,
  getAdminCompletedAuditListRes,
  auditApproveRejectURL,
  auditApproveRejectRes,
  getCentersByProjectIdURL,
  getCentersByProjectIdRes,
  TypeSchoolURL,
  TypeSchoolRes,
  CategoryURL,
  CategoryRes,
  changeCenterIsActiveURL,
  changeCenterIsActiveRes,
  importCenterURL,
  importCenterRes,
  addEditCenterDataURL,
  addEditCenterDataRes,
  getStatesCitiesByProjectIdURL,
  getStatesCitiesByProjectIdRes,
  changeProjectIsActiveURL,
  changeProjectIsActiveRes,
  getExpenseTypesDDLURL,
  getExpenseTypesDDLRes,
  ExpenseURL,
  ExpenseRes,
  expenseApproveRejectURL,
  expenseApproveRejectRes,
  dashboardDataURL,
  dashboardDataRes,
  getDashboardProjectListURL,
  getDashboardProjectListRes,
  sendOtpInEmailURL,
  OtpVerifyForEmailURL,
  resetPasswordURL,
  sendOtpInEmailRes,
  OtpVerifyForEmailRes,
  resetPasswordRes,
  sendMessageURL,
  sendMessageRes,
  getAdminMessagesListURL,
  getAdminMessagesListRes,
  addEditAdminUserURL,
  addEditAdminUserRes,
  changeAdminUserIsActiveURL,
  changeAdminUserIsActiveRes,
  getAdminUsersListURL,
  getAdminUsersListRes,
  getFreelancerRoleListURL,
  getFreelancerRoleListRes,
  addEditFreelancerRoleURL,
  addEditFreelancerRoleRes,
  deleteFreelancerRoleDataURL,
  deleteFreelancerRoleDataRes,
  changeFreelancerRoleIsActiveURL,
  changeFreelancerRoleIsActiveRes,
  addReviewURL,
  addReviewRes,
  associateCentersWithProjectURL,
  associateCentersWithProjectRes,
  getAttendanceHistoryListURL,
  getAttendanceHistoryListRes,
  associatedCentersByProjectIdURL,
  associatedCentersByProjectIdRes,
  listCentersByProjectIdURL,
  listCentersByProjectIdRes,
  freelancersDDLURL,
  freelancersDDLRes,
  downloadAdminExpenseListURL,
  downloadAdminExpenseListRes,
  assignTeamToProjectCenterURL,
  assignTeamToProjectCenterRes,
  pendingCentersByFreelancerProjectId_URL,
  pendingCentersByFreelancerProjectIdRes,
  downloadFreelancerListURL,
  assignCentersToFreelancerRolesURL,
  assignCentersToFreelancerRolesRes,
  getFreelancerDataPdfURL,
  getFreelancerDataPdfRes,
  getExamsURL,
  getExamsRes,
  CenterAddReviewURL,
  CenterAddReviewRes,
  getCenterAuditedImagesURL,
  getCenterAuditedImagesRes,
  getCenterProjectsURL,
  getCenterProjectsRes,
  getAdminProfileByIdURL,
  getAdminProfileByIdRes,
  updateAdminProfileByIdURL,
  updateAdminProfileByIdRes,
  ActiveInactiveFreelancerURL,
  ActiveInactiveFreelancerRes,
  EditProjectURL,
  EditProjectReg,
  downloadCenterDataURL,
  downloadCenterDataReg,
  getActiveFreelancersURL,
  getActiveFreelancersReg,
  importFreelancersDataURL,
  importFreelancersDataReg,
  deleteFreelancerURL,
  deleteFreelancerReg,
  paginationReg,
  projectVsFreelancerChartData,
  projectVsCenterChartData,
  updateCentersLatLongReg,
  updateCentersLatLong_URL,
  freelancerData_URL,
  freelancerDataReg,
  centerData_URL,
  centerDataReg,
  projectData_URL,
  projectDataReg,
  attendenceData_URL,
  attendenceDataReg,
  deleteCenter_URL,
} from "../../Constant";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function getAsios() {
  let token = localStorage.getItem("token");

  if (token) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}

const tokenexpire = "Your token has expired! Please sign in again.";
export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("token", JSON.stringify(tokenDetails));
}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}

export function paginationAPI(data, number) {
  return function (dispatch) {
    return getAsios()
      .post("http://192.168.0.176:5000/admin/order/get-all-two", data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.code == 200) {
          dispatch({ type: paginationReg, payload: response });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function LoginAPI(data, email, name) {
  return function (dispatch) {
    return getAsios()
      .post(AdminLogin_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: AdminLoginReg, payload: response.data });
          toast.success("Login Successfully");
          localStorage.setItem("superr", response.data.data.is_super_admin);
          localStorage.setItem("emailAdmin", email);
          localStorage.setItem("nameAdmin", response.data.data.name);
          localStorage.setItem(AdminLogin_URL, true);
          localStorage.setItem("token", response.data.tokan);
          localStorage.setItem("newRoleVissible", response.data.data.roles);
        } else {
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getfreelancersAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getfreelancers_URL, data)
      .then((response) => {
        localStorage.setItem("Addpeople", false);
        localStorage.setItem("getfreelancersAPIAttandence", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getfreelancersReg, payload: response.data });
        return;
      })
      .catch((err) => {
        localStorage.setItem("Addpeople", false);
        localStorage.setItem("getfreelancersAPIAttandence", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function freelancerstatusAPI(data, number) {
  return function (dispatch) {
    return getAsios()
      .post(freelancerstatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: freelancerstatusReg, payload: response.data });
          swal("", number == 1 ? "Request Accepted!" : "Request Rejected!", "success").then(function () {
            window.location.href = window.location.origin + "/Freelancers";
          });
          localStorage.setItem(freelancerstatus_URL, true);
          localStorage.setItem("acceptDone", response.data.message);
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getstatecityAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(getstate_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getstatecityReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getRolesAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getRolesURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getRolesReg, payload: response.data.data });
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getQulificationAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(getqulification_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getQulificationsReg, payload: response.data.data });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getProjectlistAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getprojects_URL, data)
      .then((response) => {
        localStorage.setItem("projapicall", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getProjectsRes, payload: response.data });
      })
      .catch((err) => {
        localStorage.setItem("projapicall", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getProjectDDLAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getprojectddl_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getProjectddlRes, payload: response.data.data });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getStatecityAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(getStatecityURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getStatecity, payload: response.data.data });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getProjectTypeAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getProjectTypeURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getProjectTypeReg, payload: response.data.data });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function AddProjectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(AddProjectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: AddProjectReg, payload: response.data.data });
          swal("Project!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Projects";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getProjectTitleAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getprojecttitle_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getProjectTitleRes, payload: response.data.data });
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function centersbyfreelanceprojectIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(centersbyfreelanceprojectId_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: centersbyfreelanceprojectIdRes,
            payload: response.data,
          });
        } else {
          swal("", response.data.message, "error");
          dispatch({
            type: centersbyfreelanceprojectIdRes,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getCenterListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getCenterList_URL, data)
      .then((response) => {
        localStorage.setItem("centerapicall", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getCenterListRes, payload: response.data });
      })
      .catch((err) => {
        localStorage.setItem("centerapicall", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}
// Added by NiraliCB : 16 Sept 2022
export function getAssignProjectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(AssignProjectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: AssignProjectRes, payload: response.data });
          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Addpeople";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 23 Sept 2022
export function freelancersAssignedCentersAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(freelancersAssignedCentersURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: freelancersAssignedCentersRes,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 26 Sept 2022
export function getadminattendancelistAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getadminattendancelistURL, data)
      .then((response) => {
        localStorage.setItem("getadminattendancelistAPI", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getadminattendancelistRes, payload: response.data });
      })
      .catch((err) => {
        localStorage.setItem("getadminattendancelistAPI", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 28 Sept 2022
export function getCenterListDDLURLAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getCenterListDDLURL, data)
      .then((response) => {
        localStorage.setItem("", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getCenterNameRes, payload: response.data });
      })
      .catch((err) => {
        localStorage.setItem("", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 04 Oct 2022
export function AttendanceCSVAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(AttendanceCSVURL + data, { responseType: "blob" })
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data) {
          if (response.data.errorcode == 1) {
            swal("", response.data.message, "error");
          } else {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
            // create "a" HTLM element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "Attendance.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 10 Oct 2022
export function attendanceapproverejectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(attendanceapproverejectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: attendanceapproverejectRes,
            payload: response.data,
          });
          swal("Attendance!", response.data.message, "success");
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 19 Oct 2022
export function getAdminCompletedAuditListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAdminCompletedAuditListURL, data)
      .then((response) => {
        localStorage.setItem("getAdminCompletedAuditList", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({
          type: getAdminCompletedAuditListRes,
          payload: response.data,
        });
      })
      .catch((err) => {
        localStorage.setItem("getAdminCompletedAuditList", false);
        dispatch(dispatchLoadingStatus(false));
      });
  };
}

// Added by Parth Roka : 19 Oct 2022
export function auditApproveRejectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(auditApproveRejectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          swal(response.data.message, "", "success");
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 31 Oct 2022
export function getCentersByProjectIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getCentersByProjectIdURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getCentersByProjectIdRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 02 Dec 2022
export function listCentersByProjectIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(listCentersByProjectIdURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: listCentersByProjectIdRes, payload: response.data });
        } else {
          dispatch({ type: listCentersByProjectIdRes, payload: [] });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Nov 2022
export function TypeSchoolAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(TypeSchoolURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: TypeSchoolRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Nov 2022
export function CategoryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(CategoryURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: CategoryRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Nov 2022
export function changeCenterIsActiveAPI(data, id, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(changeCenterIsActiveURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          if (is_active == true) {
            swal("Your center is inactive!", { icon: "success" });
          } else {
            swal("Your center is active!", { icon: "success" });
          }
          dispatch({ type: changeCenterIsActiveRes, payload: { id: id } });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Nov 2022
export function importCenterAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(importCenterURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: importCenterRes, payload: response.data });
          swal("Import center!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Centers";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 03 Nov 2022
export function addEditCenterDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(addEditCenterDataURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: addEditCenterDataRes, payload: response.data });
          swal("Center!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Centers";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 03 Nov 2022
export function getStatesCitiesByProjectIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getStatesCitiesByProjectIdURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: getStatesCitiesByProjectIdRes,
            payload: response.data,
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 03 Nov 2022
export function changeProjectIsActiveAPI(data, id, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(changeProjectIsActiveURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          if (is_active == true) {
            swal("Your project is inactive!", { icon: "success" });
          } else {
            swal("Your project is active!", { icon: "success" });
          }
          dispatch({ type: changeProjectIsActiveRes, payload: { id: id } });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 07 Nov 2022
export function getExpenseTypesDDLAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(getExpenseTypesDDLURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getExpenseTypesDDLRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 07 Nov 2022
export function ExpenseAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(ExpenseURL, data)
      .then((response) => {
        localStorage.setItem("ExpenseAPI", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: ExpenseRes, payload: response.data });
      })
      .catch((err) => {
        localStorage.setItem("ExpenseAPI", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 07 Nov 2022
export function expenseApproveRejectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(expenseApproveRejectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: expenseApproveRejectRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 08 Nov 2022
export function dashboardDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(dashboardDataURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: dashboardDataRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 08 Nov 2022
export function getDashboardProjectListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getDashboardProjectListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getDashboardProjectListRes, payload: response.data });
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 10 Nov 2022
export function sendOtpInEmailAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(sendOtpInEmailURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: sendOtpInEmailRes, payload: response.data });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 10 Nov 2022
export function OtpVerifyForEmailAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(OtpVerifyForEmailURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: OtpVerifyForEmailRes, payload: response.data });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 10 Nov 2022
export function resetPasswordAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(resetPasswordURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: resetPasswordRes, payload: response.data });
          toast.success(response.data.message);
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 11 Nov 2022
export function sendMessageAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(sendMessageURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: sendMessageRes, payload: response.data });
          swal("Message!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Message";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 14 Nov 2022
export function getAdminMessagesListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAdminMessagesListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("getAdminMessagesListAPI", false);
        dispatch({ type: getAdminMessagesListRes, payload: response.data });
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("getAdminMessagesListAPI", false);
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 14 Nov 2022
export function addEditAdminUserAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(addEditAdminUserURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: addEditAdminUserRes, payload: response.data });
          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/AdminUserManagement";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 14 Nov 2022
export function changeAdminUserIsActiveAPI(data, id, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(changeAdminUserIsActiveURL, data)
      .then((response) => {
        if (response.data.errorcode == 0) {
          if (is_active == true) {
            swal(" Your sub admin is inactive!", { icon: "success" });
          } else {
            swal(" Your sub admin is active!", { icon: "success" });
          }
          dispatch({ type: changeAdminUserIsActiveRes, payload: { id: id } });
        }
        dispatch(dispatchLoadingStatus(false));
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 14 Nov 2022
export function getAdminUsersListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAdminUsersListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("getAdminUsersListAPI", false);
        dispatch({ type: getAdminUsersListRes, payload: response.data });
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("getAdminUsersListAPI", false);
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 21 Nov 2022
export function getFreelancerRoleListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getFreelancerRoleListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("getFreelancerRoleListAPI", false);
        dispatch({ type: getFreelancerRoleListRes, payload: response.data });
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("getFreelancerRoleListAPI", false);
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 21 Nov 2022
export function addEditFreelancerRoleAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(addEditFreelancerRoleURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: addEditFreelancerRoleRes, payload: response.data });
          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/FreelancerRoleManagement";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 21 Nov 2022
export function deleteFreelancerRoleDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(deleteFreelancerRoleDataURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: deleteFreelancerRoleDataRes,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 21 Nov 2022
export function changeFreelancerRoleIsActiveAPI(data, id, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(changeFreelancerRoleIsActiveURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          if (is_active == true) {
            swal(" Freelancer role is inactive!", { icon: "success" });
          } else {
            swal(" Freelancer role is active!", { icon: "success" });
          }
          dispatch({
            type: changeFreelancerRoleIsActiveRes,
            payload: { id: id },
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 25 Nov 2022
export function addReviewAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(addReviewURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: addReviewRes, payload: response.data });
          swal("", response.data.message, "success");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 30 Nov 2022
export function associateCentersWithProjectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(associateCentersWithProjectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: associateCentersWithProjectRes,
            payload: response.data,
          });
          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Projects";
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 30 Nov 2022
export function getAttendanceHistoryListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAttendanceHistoryListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: getAttendanceHistoryListRes,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Nov 2022
export function associatedCentersByProjectIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(associatedCentersByProjectIdURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: associatedCentersByProjectIdRes,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 05 Dec 2022
export function freelancersDDLAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(freelancersDDLURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: freelancersDDLRes, payload: response.data });
        } else {
          dispatch({ type: freelancersDDLRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function downloadAdminExpenseListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(downloadAdminExpenseListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data) {
          if (response.data.errorcode == 1) {
            swal("", response.data.message, "error");
          } else {
            // create file link in browser's memory
            var binaryData = [];
            binaryData.push(response.data);
            const href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "Expenses.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 09 Dec 2022
export function assignTeamToProjectCenterAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(assignTeamToProjectCenterURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: assignTeamToProjectCenterRes,
            payload: response.data,
          });
          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Addteam";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function pendingCentersByFreelancerProjectIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(pendingCentersByFreelancerProjectId_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: pendingCentersByFreelancerProjectIdRes,
            payload: response.data,
          });
        } else {
          swal("", response.data.message, "error");
          dispatch({
            type: pendingCentersByFreelancerProjectIdRes,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 14 Dec 2022
export function downloadFreelancerListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(downloadFreelancerListURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data) {
          if (response.data.errorcode == 1) {
            swal("", response.data.message, "error");
          } else {
            // create file link in browser's memory
            var binaryData = [];
            binaryData.push(response.data);
            const href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "Freelancer.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 15 Dec 2022
export function assignCentersToFreelancerRolesAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(assignCentersToFreelancerRolesURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({
            type: assignCentersToFreelancerRolesRes,
            payload: response.data,
          });
          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Addteam";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 19 Dec 2022
export function downloadFreelancerProfileAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getFreelancerDataPdfURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data) {
          if (response.data.errorcode == 0) {
            dispatch({
              type: getFreelancerDataPdfRes,
              payload: response.data.data,
            });
            window.open(response.data.data.html_freelancer_pdf);
          }
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getExamsAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(getExamsURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response) {
          if (response.data.errorcode == 0) {
            dispatch({ type: getExamsRes, payload: response.data });
          }
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 30 Jan 2023
export function CenterAddReviewAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(CenterAddReviewURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: CenterAddReviewRes, payload: response.data });
          swal("", response.data.message, "success");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Feb 2023
export function getCenterAuditedImagesAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getCenterAuditedImagesURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getCenterAuditedImagesRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 01 Feb 2023
export function getCenterProjectsAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getCenterProjectsURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getCenterProjectsRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 06 Feb 2023
export function getAdminProfileByIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAdminProfileByIdURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getAdminProfileByIdRes, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// Added by Parth Roka : 06 Feb 2023
export function updateAdminProfileByIdAPI(data, Pass, ConfirmPass) {
  return function (dispatch) {
    return getAsios()
      .post(updateAdminProfileByIdURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: updateAdminProfileByIdRes, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Dashboard";
          });
        }
      })
      .catch((err) => {
        if (err.toString().includes("401")) {
          swal({ text: tokenexpire, icon: "error" }).then(function () {});
        } else {
          dispatch(dispatchLoadingStatus(false));
          swal("", "Something went wrong, please try again", "error");
        }
      });
  };
}

// Added by Parth Roka : 06 April 2023
export function ActiveInactiveFreelancerAPI(data, id, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(ActiveInactiveFreelancerURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          if (is_active == true) {
            swal("Your freelancer is inactive!", { icon: "success" });
          } else {
            swal("Your freelancer is active!", { icon: "success" });
          }
          dispatch({ type: ActiveInactiveFreelancerRes, payload: { id: id } });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function EditProjectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(EditProjectURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: EditProjectReg, payload: response.data });
          swal("Project!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Projects";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function downloadCenterDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(downloadCenterDataURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data) {
          if (response.data.errorcode == 1) {
            swal("", response.data.message, "error");
          } else {
            // create file link in browser's memory
            var binaryData = [];
            binaryData.push(response.data);
            const href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "Center.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function getActiveFreelancersAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getActiveFreelancersURL, data)
      .then((response) => {
        localStorage.setItem("Addpeople", false);
        localStorage.setItem("getfreelancersAPIAttandence", false);
        dispatch(dispatchLoadingStatus(false));
        dispatch({ type: getActiveFreelancersReg, payload: response.data });
        return;
      })
      .catch((err) => {
        localStorage.setItem("Addpeople", false);
        localStorage.setItem("getfreelancersAPIAttandence", false);
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function importFreelancersAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(importFreelancersDataURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: importFreelancersDataReg, payload: response.data });
          swal("Import freelancer!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/Centers";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function deleteFreelancerAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(deleteFreelancerURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        //added by kajal 07-08-2023
        if (response.data.errorcode == 0) {
          swal(" Freelancer data deleted!", { icon: "success" });
        }
        if (response.data.errorcode == 0) {
          dispatch({
            type: deleteFreelancerReg,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}
//added by kajal 11-07-2023
export function projectFreelancerChartAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(projectVsFreelancerChartData, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode === 0) {
          dispatch({ type: projectVsFreelancerChartData, payload: response.data.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function FreelancerCentersChartAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(projectVsCenterChartData, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode === 0) {
          dispatch({ type: projectVsCenterChartData, payload: response.data.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function updateCentersLatLongAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(updateCentersLatLong_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: updateCentersLatLongReg, payload: response.data });
          swal("", response.data.message, "success");
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function freelancerDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(freelancerData_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: freelancerDataReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function centerDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(centerData_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: centerDataReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function projectDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(projectData_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: projectDataReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function attendenceDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(attendenceData_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: attendenceDataReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function deleteCenterAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(deleteCenter_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        //added by kajal 07-08-2023
        if (response.data.errorcode == 0) {
          swal("Center data deleted !", { icon: "success" });
        }
        if (response.data.errorcode == 0) {
          dispatch({
            type: deleteCenter_URL,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}
