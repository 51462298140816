import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveFreelancersAPI, freelancersAssignedCentersAPI, dispatchLoadingStatus, getRolesAPI, getstatecityAPI, getQulificationAPI, getProjectTitleAPI } from "../../API/Action/globalAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainDashbord from "../Sidebar/MainDashbord";
import Loader from "../Loader/Loader";
import Select from "react-select";
import "react-responsive-modal/styles.css";
import blankImg from '../../assets/images/blank.jpg'

toast.configure();

function AddPeople() {
  const [start, setStart] = useState(0);
  const [freelancerRole, setFreelancerRole] = useState("");

  // Api Call (Parth Roka)
  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    dispatch(dispatchLoadingStatus(true));
    dispatch(getQulificationAPI());
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(getProjectTitleAPI(formData));
    dispatch(getRolesAPI(formData));
  }, []);
  const [state, setstate] = useState("");
  const [city, setciti] = useState("");
  const [pincode, setpincode] = useState("");
  const [frname, setFRname] = useState("");
  const [project, setproject] = useState("");
  const [page, setpage] = useState(1);

  useEffect(() => {
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getRolesAPI(formData));
  }, []);

  const getRolesReg = useSelector((state) => state.globalReducer.getRolesReg);
  const freelancersAssignedCentersRes = useSelector((state) => state.globalReducer.freelancersAssignedCentersRes);

  useEffect(() => {
    if (freelancersAssignedCentersRes) {
      if (freelancersAssignedCentersRes.errorcode == 1) {
        setAllData33([]);
      } else {
        setAllData33(freelancersAssignedCentersRes ? freelancersAssignedCentersRes.data : freelancersAssignedCentersRes.data);
      }
    }
  }, [freelancersAssignedCentersRes]);

  const NumValid = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setpincode(e.target.value);
    }
  };

  const handleModel = (i) => {
    let formData = new FormData();

    formData.append("freelancer_id", i._id);
    dispatch(dispatchLoadingStatus(true));
    dispatch(freelancersAssignedCentersAPI(formData));
  };

  let getfreelancersReg = useSelector((state) => state.globalReducer.getActiveFreelancersReg);
  let total = getfreelancersReg ? getfreelancersReg.count : "";
  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);
  let getProjectTitleRes = useSelector((state) => state.globalReducer.getProjectTitleRes);
  let token = localStorage.getItem("token");

  const dispatch = useDispatch({});
  let history = useNavigate();


  const [allData22, setAllData22] = useState();
  const [allData33, setAllData33] = useState();

  const [order, setOrder] = useState("ASC");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  const [citylist, setcitylist] = useState([]);

  function Apicall(start) {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("firstname", frname);
    formData.append("is_assigned", "0");
    formData.append("project_id", project);
    formData.append("status", 1);
    formData.append("roleid", freelancerRole);

    if (frname || state || state == "" || city || project || pincode || freelancerRole) {
      if (localStorage.getItem("Addpeople") != "true") {
        localStorage.setItem("Addpeople", true);
        dispatch(getActiveFreelancersAPI(formData));
        localStorage.removeItem("acceptDone");
      }
    } else {
      if (localStorage.getItem("Addpeople") != "true") {
        localStorage.setItem("Addpeople", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getActiveFreelancersAPI(formData));
        localStorage.removeItem("acceptDone");
      }
    }
  }

  useEffect(() => {
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
  }, []);

 
  const [state33, setstate33] = useState("");

  function setcity(e) {
    if (getstatecityReg) {
      var filter = getstatecityReg.data.filter((i) => i._id == e.value);
      setstate(e.value);
      setstate33(filter.length > 0 ? filter[0].state : []);
      setciti("");
      if (filter && e.value) {
        setcitylist(filter[0].districts);
        setStart(0);
        setciti([]);
      } else {
        setcitylist([]);
      }
    }
  }

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [freelancerRole]);

  useEffect(() => {
    Apicall(start);
  }, [start]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [state]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [city]);

  useEffect(() => {
    if (pincode.length == 6 || pincode.length == 0) {
      setpage(1);
      Apicall(0);
    }
  }, [pincode]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [project]);

  function ChangesFRname(value) {
    setFRname(value.trim() == "" ? "" : value);
  }

  useEffect(() => {
    if (frname == "" || frname.length > 0) {
      setpage(1);
      Apicall(0);
    }
  }, [frname]);

  useEffect(() => {
    if (getfreelancersReg) {
      if (getfreelancersReg.errorcode == 1) {
        setAllData22([]);
      } else {
        setAllData22(getfreelancersReg ? getfreelancersReg.data : getfreelancersReg.data);
      }
    }
  }, [getfreelancersReg]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");
    if (tokenRolee.find((t) => t === "Assign")) {
      if (token) {
        if (!window.location.href.includes("Addpeople")) {
          window.location.href = window.location.origin + "/Addpeople";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  return (
    <>
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Assigned Freelancers</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => history("/Addpeople")} className="breadcrumb-item active">
                          Assign
                        </a>
                        <a href="/Addpeople" className="breadcrumb-item active">
                          Add People
                        </a>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-4    ">
                            <form className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                              <div className="search-box me-2 mb-4">
                                <div className="position-relative">
                                  <input type="text" className="form-control " onChange={(e) => ChangesFRname(e.target.value)} value={frname} placeholder="Search..." />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="col-md-8">
                            <div className="row align-items-center">
                              <div className="col-md-4 col-6 mb-4">

                                <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} isClearable={false} value={state == "" ? [{ label: "State", value: "" }] : state ? [{ label: state33, value: state }] : [{ label: "State", value: "" }]} onChange={(e) => setcity(e)} />
                              </div>
                              <div className="col-md-4 col-6 mb-4">
                                <Select
                                  options={citylist ? [{ label: "City", value: "" }].concat(citylist.map((e) => ({ label: e, value: e }))) : []}
                                  isClearable={false}
                                  placeholder={"City"}
                                  value={city == "" ? [{ label: "City", value: "" }] : city ? [{ label: city, value: city }] : [{ label: "City", value: "" }]}
                                  onChange={(e) => setciti(e.value)}
                                />
                              </div>
                              <div className="col-md-4 col-6 mb-4">
                                <input value={pincode} className="form-control" maxLength={6} placeholder={"Pincode"} onChange={NumValid} />
                              </div>
                              <div className="col-md-4 col-6 mb-4">
                                <Select options={getProjectTitleRes ? [{ label: "Project title", value: "" }].concat(getProjectTitleRes.map((e) => ({ label: e.projectTitle, value: e._id }))) : []} isClearable={false} placeholder={"Project title"} onChange={(e) => setproject(e.value)} />
                              </div>

                              <div className="col-md-4 col-6 mb-4">
                                <Select options={getRolesReg ? [{ label: "Role", value: "" }].concat(getRolesReg.map((e) => ({ label: e.role, value: e.roleid }))) : []} placeholder={"Role"} isClearable={false} onChange={(e) => setFreelancerRole(e.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getfreelancersReg ? getfreelancersReg.count : ""} entries
                        <div className="table-responsive ">
                          <table id="example" className="table table-striped dt-responsive">
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>
                                  Name
                                  <i onClick={() => Sortt("firstname")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="1" style={{ width: 70 }}>
                                  Location
                                  <i onClick={() => Sortt("address")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="2">
                                  Phone
                                  <i onClick={() => Sortt("phone")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="2">
                                  Role
                                  <i onClick={() => Sortt("role_names")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="5">
                                  Status
                                  <i onClick={() => Sortt("isAnyAssignedCenter")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="5">Actions</th>
                              </tr>
                            </thead>
                            <tbody>

                              {allData22 &&
                                allData22.map((i, index) => (
                                  <tr>
                                    <th> {page * 20 + index + 1 - 20}.</th>
                                    <th>
                                      <div className="d-flex ">
                                        <div className="flex-shrink-0 me-3">
                                          <img className="d-flex-object rounded-circle avatar-xs" alt="" src={i.profiepic ? i.profiepic : blankImg} />
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-weight-bold mb-1">
                                            {i.firstname} {i.lastname}
                                          </h5>
                                          <p className="text-muted mb-1"></p>
                                        </div>
                                      </div>
                                    </th>
                                    <td>
                                      {i.city} {!i.city && !i.state ? "-" : ","} {getstatecityReg ? (getstatecityReg.data.filter((j) => j._id == i.state)[0] ? getstatecityReg.data.filter((j) => j._id == i.state)[0].state : "") : ""}
                                    </td>
                                    <td>{i.phone}</td>
                                    <td>{i.role_names}</td>
                                    <td className="">
                                      <div class="d-flex align-items-center">
                                        <td>{i.isAnyAssignedCenter == "Yes" ? "Assigned" : "UnAssigned"}</td>
                                      </div>
                                    </td>
                                    <td className="">
                                      <div class="d-flex align-items-center">
                                        <i
                                          className="cursor-pointer far fa-eye me-3 font-size-18"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                          onClick={() => {
                                            handleModel(i);
                                          }}
                                        ></i>
                                        <button
                                          className="me-2 btn btn-primary waves-effect waves-light"
                                          onClick={() =>
                                            history("/Assignaction", {
                                              state: {
                                                freelancerId: i._id,
                                                firstname: i.firstname + " " + i.lastname,
                                                statee: getstatecityReg ? (getstatecityReg.data.filter((j) => j._id == i.state)[0] ? getstatecityReg.data.filter((j) => j._id == i.state)[0].state : "") : "",
                                                city: i.city,
                                              },
                                            })
                                          }
                                          key={i._id}
                                        >
                                          Assign
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {allData22 ? (
                          allData22.length > 0 ? null : (
                            <div style={{ textAlign: "center" }}>
                              <p>No data found</p>{" "}
                            </div>
                          )
                        ) : null}
                        <div className=" btns-table align-items-center justify-content-end">
                          {getfreelancersReg ? (
                            getfreelancersReg.count > 0 ? (
                              <div className="row align-items-center wrapper-pg-main">
                                <div className="col-6">
                                  Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getfreelancersReg ? getfreelancersReg.count : ""} entries
                                </div>
                                <div className="col-6">
                                  <div className="wrapper-pgn">
                                    <div className="pgn pgn-default">
                                      <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                        <i className="bx bx-chevrons-left"></i>
                                      </div>
                                      <div className="pages" id="content">
                                        {(() => {
                                          const pages = [];

                                          for (let i = 1; i < getfreelancersReg.count / 20 + 1; i++) {
                                            pages.push(
                                              <div
                                                className={page == i ? "page page-btn active" : "page page-btn"}
                                                onClick={() => {
                                                  Apicall((i - 1) * 20);
                                                  setpage(i);
                                                }}
                                              >
                                                {i}
                                              </div>
                                            );
                                          }

                                          return pages;
                                        })()}
                                      </div>
                                      <div onClick={() => document.getElementById("content").scrollBy(50, 0)} className=" next ">
                                        <i className="bx bx-chevrons-right"></i>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="right-bar">
          <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-4">
              <h5 className="m-0 me-2">Settings</h5>
              <a className="right-bar-toggle ms-auto">
                <i className="mdi mdi-close noti-icon"></i>
              </a>
            </div>
            <hr className="mt-0" />
            <h6 className="text-center mb-0">Choose Layouts</h6>
            <div className="p-4">
              <div className="mb-2">
                <img src="images/layouts/layout-1.jpg" className="img-thumbnail" alt="layout images" />
              </div>
              <div className="form-check form-switch mb-3">
                <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" />
                <label className="form-check-label" htmlFor="light-mode-switch">
                  Light Mode
                </label>
              </div>
              <div className="mb-2">
                <img src="images/layouts/layout-2.jpg" className="img-thumbnail" alt="layout images" />
              </div>
              <div className="form-check form-switch mb-3">
                <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" />
                <label className="form-check-label" htmlFor="dark-mode-switch">
                  Dark Mode
                </label>
              </div>
              <div className="mb-2">
                <img src="images/layouts/layout-3.jpg" className="img-thumbnail" alt="layout images" />
              </div>
              <div className="form-check form-switch mb-3">
                <input className="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch" />
                <label className="form-check-label" htmlFor="rtl-mode-switch">
                  RTL Mode
                </label>
              </div>
              <div className="mb-2">
                <img src="images/layouts/layout-4.jpg" className="img-thumbnail" alt="layout images" />
              </div>
              <div className="form-check form-switch mb-5">
                <input className="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch" />
                <label className="form-check-label" htmlFor="dark-rtl-mode-switch">
                  Dark RTL Mode
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Assigned Centers Details
                </h5>
                <button type="button" onClick={() => setAllData33("")} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body custom-scroll-body">
                <div className="table-responsive">
                  <table className="table mb-0S">
                    <thead className="table-light">
                      <tr>
                        <th>Project </th>
                        <th>Center </th>
                        <th>Role</th>
                        <th>Assigned Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData33 && allData33 ? (
                        allData33.map((i) => {
                          return (
                            <tr>
                              <td>{i.projectTitle ? i.projectTitle : null}</td>
                              <td>{i.center_name ? i.center_name : null}</td>
                              <td>{i.role ? i.role : null}</td>
                              <td>{i.assign_date ? i.assign_date : null}</td>
                              <td>{i.status_text ? i.status_text : null}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightbar-overlay"></div>
      </div>
    </>
  );
}

export default AddPeople;
