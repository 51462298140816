
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { dispatchLoadingStatus, resetPasswordAPI } from '../../API/Action/globalAction';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loader from '../Loader/Loader';
import logo from '../../assets/images/logo.png'



function ResetPassword() {

    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState(false);
    const [ConformPassword, setConformPassword] = useState("");
    const [ConformPasswordError, setConformPasswordError] = useState(false);
    const [BothPasswordError, setBothPasswordError] = useState(false);
    const [PasswordError22, setPasswordError22] = useState(false);
    const [ConformPasswordError22, setConformPasswordError22] = useState(false);
    const [eyes, setEyes] = useState(false);
    const [eyes22, setEyes22] = useState(false);

    const dispatch = useDispatch({
    });

    let history = useNavigate();
    const toggleBtn = () => {
        setEyes(preState => !preState);
    }

    const toggleBtn22 = () => {
        setEyes22(preState => !preState);
    }


    function handlePassword(e) {
        if (e) {
            setPassword(e.target.value);
            setPasswordError(false);
        }
        else {
            setPassword("");
            setPasswordError(true);
        }
    }

    function handleConformPassword(e) {
        if (e) {
            setConformPassword(e.target.value);
            setConformPasswordError(false);
        }
        else {
            setConformPassword("");
            setConformPasswordError(true);
        }
    }


    const allDeatils = () => {
        if (Password) {
            if (Password.length >= 6 && Password.length <= 16) {
                setPasswordError22(false);
                setPasswordError(false)
            }
            else {
                setPasswordError22(true);
                setPasswordError(false)
            }
        }
        else {
            setPasswordError(true)
            setPasswordError22(false);
        }

        if (ConformPassword) {
            if (ConformPassword.length >= 6 && ConformPassword.length <= 16) {
                setConformPasswordError22(false);
                setConformPasswordError(false)
            }
            else {
                setConformPasswordError22(true);
                setConformPasswordError(false)
            }
        }
        else {
            setConformPasswordError(true)
            setConformPasswordError22(false);
        }


        if (Password.length >= 6 && Password.length <= 16 && ConformPassword.length >= 6 && ConformPassword.length <= 16) {
            if (Password == ConformPassword) {
                setBothPasswordError(false)
                let admin_id = localStorage.getItem("admin_id")
                let formData = new FormData();
                formData.append('admin_id', admin_id);
                formData.append('password', Password);
                formData.append('confirm_password', ConformPassword);
                dispatch(dispatchLoadingStatus(true))
                dispatch(resetPasswordAPI(formData))
            }
            else {
                setBothPasswordError(true)
            }
        }
    }

    const resetPasswordRes = useSelector(state => state.globalReducer.resetPasswordRes);

    useEffect(() => {
        localStorage.removeItem("resendValue")
        localStorage.removeItem('sendEmail');
    }, [])

    let adminemail = localStorage.getItem('adminemail');

    useEffect(() => {
        if (resetPasswordRes) {
            history('/Login')
        }

    }, [resetPasswordRes])

    useEffect(() => {
        if (adminemail) {
            if (!window.location.href.includes('ResetPassword')) {
                window.location.href = window.location.origin + '/ResetPassword'
            }
        }

        else {
            if (!window.location.href.includes('/Login')) {
                window.location.href = window.location.origin + '/Login'
            }
        }

    }, [])

    return (
        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="card-body ">
                                <div className="auth-logo">
                                    <a href="" className="auth-logo-dark">
                                        <div className=" profile-user-wid mb-4">
                                            <span className="logo ">
                                                <img src={logo} alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <h4 className="mb-4">Reset Password</h4>
                                    <div className="mb-3">
                                        <label className="form-label">Password</label>
                                        <div class="input-group auth-pass-inputgroup"><input type={eyes ? "text" : "password"} className="form-control" placeholder="Enter Password" value={Password} onChange={(e) => handlePassword(e)} maxLength={16} />
                                            <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn}>
                                                {
                                                    eyes ? <Visibility style={{ width: "18", height: "18" }} /> : <VisibilityOff style={{ width: "18", height: "18" }} />
                                                }
                                            </button>
                                        </div>
                                        {PasswordError && <span style={{ "color": "#BE312E" }}>Please enter password</span>}
                                        {PasswordError22 && <span style={{ "color": "#BE312E" }}>Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters</span>}
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">Confirm password</label>
                                        <div class="input-group auth-pass-inputgroup">
                                            <input type={eyes22 ? "text" : "password"} className="form-control" placeholder="Enter Conform Password" value={ConformPassword} onChange={(e) => handleConformPassword(e)} maxLength={16} />
                                            <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn22}>
                                                {
                                                    eyes22 ? <Visibility style={{ width: "18", height: "18" }} /> : <VisibilityOff style={{ width: "18", height: "18" }} />
                                                }
                                            </button>

                                        </div>
                                        {ConformPasswordError && <span style={{ "color": "#BE312E" }}>Please enter confirm password</span>}
                                        {ConformPasswordError22 == true ? ConformPasswordError22 && <span style={{ "color": "#BE312E" }}>Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters</span> :
                                            BothPasswordError && <span style={{ "color": "#BE312E" }}>Password and Confirm Password does not match</span>}
                                    </div>

                                    <div className="mt-3 d-grid">
                                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={allDeatils}> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;