import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainDashbord from "../Sidebar/MainDashbord";
import { projectDataAPI, getStatesCitiesByProjectIdAPI, associatedCentersByProjectIdAPI, associateCentersWithProjectAPI, getCenterListAPI, changeProjectIsActiveAPI, dispatchLoadingStatus, getstatecityAPI, getProjectlistAPI, getProjectDDLAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import Select from "react-select";
import swal from "sweetalert";
import moment from "moment";

export default function Projects() {
  let wrapper1 = document.getElementById("wrapper1");
  let wrapper2 = document.getElementById("wrapper2");

  const dispatch = useDispatch({});
  let history = useNavigate();

  const [order, setOrder] = useState("ASC");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [searchval, setsearch] = useState("");
  const [datasrc, setdatasrc] = useState();
  const [start, setStart] = useState(0);

  const [state22, setstate22] = useState("");
  const [state33, setstate33] = useState("");
  const [cityNew, setciti] = useState("");
  const [citylist22, setcitylist22] = useState([]);

  const [state22Error, setstate22Error] = useState(false);

  const [allData33, setAllData33] = useState();
  const [assciatedPID, setAssciatedPID] = useState("");
  const [viewCenters, setViewCenters] = useState();

  function OtherPage(i) {
    localStorage.setItem("flageee", "0");
    history("/Addproject", { state: i });
  }

  function handleViewCenter(i) {
    if (i._id && localStorage.getItem("token")) {
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", i._id);
      dispatch(dispatchLoadingStatus(true));
      dispatch(associatedCentersByProjectIdAPI(formData));
    }
  }

  function handleStateByProject(i) {
    let formData = new FormData();
    formData.append("project_id", i._id);
    dispatch(dispatchLoadingStatus(true));
    dispatch(getStatesCitiesByProjectIdAPI(formData));
  }

  let getStatesCitiesByProjectIdRes = useSelector((state) => state.globalReducer.getStatesCitiesByProjectIdRes);

  let associatedCentersByProjectIdRes = useSelector((state) => state.globalReducer.associatedCentersByProjectIdRes);

  useEffect(() => {
    if (associatedCentersByProjectIdRes) {
      if (associatedCentersByProjectIdRes.errorcode == 1) {
        setViewCenters([]);
      } else {
        setViewCenters(associatedCentersByProjectIdRes ? associatedCentersByProjectIdRes.data : associatedCentersByProjectIdRes.data);
      }
    }
  }, [associatedCentersByProjectIdRes]);

  function setStateCenter(e) {
    if (getstatecityReg) {
      var filter = getstatecityReg.data.filter((i) => i._id == e.value);
      setstate22(e.value);
      setstate33(filter.length > 0 ? filter[0].state : []);
      setciti("");
      if (filter && e.value) {
        setcitylist22(filter[0].districts);
        setStart(0);
        setciti([]);
      } else {
        setcitylist22([]);
      }
    }
  }

  const [page, setpage] = useState(1);

  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);
  let getprojectsres = useSelector((state) => state.globalReducer.getProjectsRes);

  let total = getprojectsres ? getprojectsres.count : "";

  let getCenterListRes = useSelector((state) => state.globalReducer.getCenterListRes);

  useEffect(() => {
    if (getCenterListRes) {
      if (getCenterListRes.errorcode == 1) {
        setAllData33([]);
      } else {
        setAllData33(getCenterListRes ? getCenterListRes.data : getCenterListRes.data);
      }
    }
  }, [getCenterListRes]);

  const [CheckBoxError, setCheckBoxError] = useState(false);

  function handleCheckBox22(e) {
    const { name, checked } = e.target;

    if (name === "allSelect") {
      if (allData33) {
        const checkedvalue = allData33.map((user) => {
          return { ...user, isChecked: user.is_associated == true ? false : checked };
        });
        setAllData33(checkedvalue);
      }
    } else {
      const checkedvalue = allData33.map((user) => (user._id === name ? { ...user, isChecked: checked } : user));
      setAllData33(checkedvalue);
    }
  }

  function handleAssociated() {
    const checkInputValue = [];

    for (let i = 0; i < allData33.length; i++) {
      if (allData33[i].isChecked === true) {
        checkInputValue.push(allData33[i]._id);
      }
    }
    if (checkInputValue.length == 0) {
      setCheckBoxError(true);
    } else {
      setCheckBoxError(false);
    }

    if (state22) {
      setstate22Error(false);
    } else {
      setstate22Error(true);
    }

    if (checkInputValue.length > 0 && state22) {
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", assciatedPID);
      formData.append("state_id", state22);
      formData.append("city", cityNew);
      formData.append("center_ids", checkInputValue.toString());
      dispatch(dispatchLoadingStatus(true));
      dispatch(associateCentersWithProjectAPI(formData));
    }
  }

  useEffect(() => {
    localStorage.setItem("flageee", "1");
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    dispatch(dispatchLoadingStatus(true));
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(getstatecityAPI());
    dispatch(getProjectDDLAPI(formData));
    dispatch(projectDataAPI(formData));
  }, []);

  useEffect(() => {
    if (getprojectsres) {
      if (getprojectsres.errorcode == 1) {
        if (state22) {
        } else {
          setdatasrc([]);
        }
      } else {
        if (state22) {
        } else {
          setdatasrc(getprojectsres ? getprojectsres.data : getprojectsres.data);
        }
      }
    }
  }, [getprojectsres]);

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };
  function setstatecitilist(val) {
    if (getstatecityReg) {
      var filter = getstatecityReg.data.filter((i) => i._id == val);
      setstate(val);
      setcity("");
      if (filter) {
        setStart(0);
      }
    }
  }

  function Apicall(start) {
    let formData = new FormData();

    formData.append("token", localStorage.getItem("token"));
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("projectStates", state);
    formData.append("city", city);
    formData.append("projectTitle", searchval);

    let formData22 = new FormData();

    formData22.append("token", localStorage.getItem("token"));
    formData22.append("start", start);
    formData22.append("center_state", state22);
    formData22.append("center_city", cityNew);
    formData22.append("project_id", assciatedPID);

    if (state22) {
      if (localStorage.getItem("centerapicall") != "true") {
        localStorage.setItem("centerapicall", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getCenterListAPI(formData22));
      }
    } else {
      if (searchval || state || state == "" || city) {
        if (localStorage.getItem("projapicall") != "true") {
          localStorage.setItem("projapicall", true);
          dispatch(getProjectlistAPI(formData));
        }
      } else {
        if (localStorage.getItem("projapicall") != "true") {
          localStorage.setItem("projapicall", true);
          dispatch(dispatchLoadingStatus(true));
          dispatch(getProjectlistAPI(formData));
        }
      }
    }
  }

  useEffect(() => {
    Apicall(start);
  }, [start]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [state]);

  useEffect(() => {
    Apicall(0);
  }, [state22]);

  useEffect(() => {
    Apicall(0);
  }, [cityNew]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [city]);

  useEffect(() => {
    if (pincode.length == 6 || pincode.length == 0) {
      setpage(1);
      Apicall(0);
    }
  }, [pincode]);

  function searchchange(value) {
    setsearch(value.trim() == "" ? "" : value);
  }
  useEffect(() => {
    if (searchval == "" || searchval.length > 0) {
      setpage(1);
      Apicall(0);
    }
  }, [searchval]);

  const [projectData, setprojectData] = useState();

  let changeSRes = useSelector((state) => state.globalReducer.changeProjectIsActiveRes);
  let projectDataReg = useSelector((state) => state.globalReducer.projectDataReg);

  useEffect(() => {
    if (projectDataReg) {
      if (projectDataReg.errorcode == 1) {
        setprojectData([]);
      } else {
        setprojectData(projectDataReg ? projectDataReg.data : projectDataReg.data);
        window.scrollTo(0, 0);
      }
    }
  }, [projectDataReg]);

  useEffect(() => {
    if (changeSRes) {
      let formData = new FormData();

      Apicall((page - 1) * 20, page);
      formData.append("token", localStorage.getItem("token"));

      dispatch(projectDataAPI(formData));
    }
  }, [changeSRes]);

  function toggleSwitch(e, i) {
    var vall = i.is_active ? "inactive" : "active";

    swal({
      text: "Are you sure you want to " + vall + " this project?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("project_id", i._id);
        dispatch(dispatchLoadingStatus(true));
        dispatch(changeProjectIsActiveAPI(formData, i._id, i.is_active));
      }
    });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");

    if (tokenRolee.find((t) => t === "Projects")) {
      if (token) {
        if (!window.location.href.includes("Projects")) {
          window.location.href = window.location.origin + "/Projects";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  return (
    <>
      {" "}
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex  justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Project List</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                          Dashboard
                        </a>
                        <a href="/Projects" className="breadcrumb-item active">
                          Projects{" "}
                        </a>
                      </ol>
                      <div className="">
                        <a onClick={() => history("/Addproject")} className=" mt-4 btn btn-primary float-lg-end waves-effect waves-light">
                          Add Project
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Projects Count</p>
                          <h4 className="mb-0">{projectData ? projectData.total_projects_count : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Active Projects Count</p>
                          <h4 className="mb-0">{projectData ? projectData.activeProjectsCount : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Active Freelancers</p>
                          <h4 className="mb-0">{projectData ? projectData.totalActiveFreelancers : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-user font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Completed Projects Count</p>
                          <h4 className="mb-0">{projectData ? projectData.completedProjectsCount : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-4   col-12  ">
                            <div className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                              <div className="search-box me-2">
                                <div className="position-relative">
                                  <input type="text" className="form-control " onChange={(e) => searchchange(e.target.value)} value={searchval} placeholder="Search..." />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 col-12 mtsm-4">
                            <div className="row align-items-center justify-content-end">
                              <div className="col-md-4 col-6 mb-4">
                                <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} onChange={(e) => setstatecitilist(e.value)} />
                              </div>
                            </div>
                          </div>
                          Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getprojectsres ? getprojectsres.count : ""} entries
                          <div
                            id="wrapper1"
                            onScroll={() => {
                              if (wrapper2) {
                                debugger;

                                wrapper2.scrollLeft = wrapper1.scrollLeft;
                              }
                            }}
                          >
                            <div id="div1"></div>
                          </div>
                          <div
                            className="table-responsive "
                            id="wrapper2"
                            onScroll={() => {
                              if (wrapper1) {
                                wrapper1.scrollLeft = wrapper2.scrollLeft;
                              }
                            }}
                          >
                            <div id="div2" className="scrollbar">
                              <table id="example" className="table table-striped no-wrap dt-responsive force-overflow">
                                <thead>
                                  <tr>
                                    <th> Sr No.</th>
                                    <th data-priority="1">
                                      Project Title
                                      <i onClick={() => Sortt("projectTitle")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>
                                    <th data-priority="2">
                                      Start Date
                                      <i onClick={() => Sortt("startdate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>
                                    <th data-priority="2">
                                      End Date
                                      <i onClick={() => Sortt("enddate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>

                                    <th data-priority="2">
                                      Exam Type
                                      <i onClick={() => Sortt("examType")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>
                                    <th data-priority="2">
                                      States
                                      <i onClick={() => Sortt("projectStates")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>

                                    <th data-priority="5">Active/Inactive</th>
                                    <th data-priority="5">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {datasrc &&
                                    datasrc.map((i, index) => (
                                      <tr key={"proj-" + index}>
                                        <th> {page * 20 + index + 1 - 20}.</th>
                                        <td className="custom-link" onClick={() => history("/Projectdetails", { state: i })}>
                                          {" "}
                                          {i.projectTitle}
                                        </td>
                                        <td>{moment(i.startdate).format("DD/MM/YYYY")}</td>
                                        <td>{moment(i.enddate).format("DD/MM/YYYY")}</td>
                                        <td>{i.examType}</td>
                                        <td>{i.projectStatesNames}</td>

                                        <td>
                                          <label class="switch">
                                            <input type="checkbox" key={i._id} onClick={(e) => toggleSwitch(e, i)} id="toggleSwitch" checked={i.is_active} defaultChecked={i.is_active} />
                                            <span class="slider round"></span>
                                          </label>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <i
                                              className="cursor-pointer fa fa-plus font-size-18"
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              onClick={() => {
                                                setstate22("");
                                                setciti("");
                                                setAllData33("");
                                                setAssciatedPID(i._id);
                                                handleStateByProject(i);
                                                setstate22Error(false);
                                                setCheckBoxError(false);
                                              }}
                                            ></i>
                                            <i className="cursor-pointer far fa-eye ms-3 font-size-18" data-bs-toggle="modal" data-bs-target="#staticBackdrop22" onClick={() => handleViewCenter(i)}></i>

                                            <i
                                              className="cursor-pointer bx bx-edit ms-3 font-size-18"
                                              onClick={() => {
                                                OtherPage(i);
                                              }}
                                            ></i>

                                            <div className="modal" id="staticBackdrop22" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                              <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h5 className="modal-title" id="staticBackdropLabel">
                                                      Assigned Centers Details
                                                    </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setViewCenters("")}></button>
                                                  </div>

                                                  <div className="modal-body custom-scroll-body">
                                                    <div className="table-responsive">
                                                      <table className="table mb-0S">
                                                        <thead className="table-light">
                                                          <tr>
                                                            <th>Sr No.</th>

                                                            <th>Center Name</th>
                                                            <th>Role</th>
                                                            <th>Address</th>
                                                            <th>State</th>
                                                            <th>City</th>
                                                            <th>Pincode</th>
                                                            <th>Principal name</th>
                                                            <th>Contact</th>
                                                            <th>Type of school</th>
                                                            <th>Category</th>
                                                            <th>Seating capacity</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {viewCenters && viewCenters ? (
                                                            viewCenters.map((i, index) => {
                                                              return (
                                                                <tr key={index}>
                                                                  <td>{index + 1}.</td>

                                                                  <td>{i.center_name ? i.center_name : null}</td>
                                                                  <td>{i.assign_roles ? i.assign_roles : null}</td>

                                                                  <td>{i.center_address ? i.center_address : null}</td>
                                                                  <td>{i.center_state ? i.center_state : null}</td>
                                                                  <td>{i.center_city ? i.center_city : null}</td>
                                                                  <td>{i.center_pincode ? i.center_pincode : null}</td>
                                                                  <td>{i.center_principal_name ? i.center_principal_name : null}</td>
                                                                  <td>{i.center_contact ? i.center_contact : null}</td>
                                                                  <td>{i.center_type_of_school ? i.center_type_of_school : null}</td>
                                                                  <td>{i.center_category ? i.center_category : null}</td>
                                                                  <td>{i.center_no_of_candidate ? i.center_no_of_candidate : null}</td>
                                                                </tr>
                                                              );
                                                            })
                                                          ) : (
                                                            <tr>
                                                              <td colSpan={11} style={{ textAlign: "center" }}>
                                                                No data found
                                                              </td>
                                                            </tr>
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                              <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h5 className="modal-title" id="staticBackdropLabel">
                                                      Add Centers
                                                    </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                  </div>

                                                  <div className="modal-body">
                                                    <form className="row">
                                                      <div class="col-md-6">
                                                        <Select options={getStatesCitiesByProjectIdRes ? [{ label: "State", value: "" }].concat(getStatesCitiesByProjectIdRes.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} value={state22 == "" ? [{ label: "State", value: "" }] : state22 ? [{ label: state33, value: state22 }] : [{ label: "State", value: "" }]} onChange={(e) => setStateCenter(e)} />
                                                        {state22Error && <span style={{ color: "#BE312E" }}>Please select state</span>}
                                                      </div>
                                                      <div class="col-md-6">
                                                        <Select options={citylist22 ? [{ label: "City", value: "" }].concat(citylist22.map((e) => ({ label: e, value: e }))) : []} placeholder={"City"} value={cityNew == "" ? [{ label: "City", value: "" }] : cityNew ? [{ label: cityNew, value: cityNew }] : [{ label: "City", value: "" }]} onChange={(e) => setciti(e.value)} />
                                                      </div>
                                                    </form>

                                                    <div className="modal-body custom-scroll-body">
                                                      <div className="table-responsive">
                                                        <table className="table mb-0S">
                                                          <thead className="table-light">
                                                            <tr>
                                                              <th>Sr No.</th>

                                                              {allData33 && allData33.length > 0 ? (
                                                                <th>
                                                                  <input
                                                                    type="checkbox"
                                                                    onChange={handleCheckBox22}
                                                                    name="allSelect"
                                                                  />
                                                                </th>
                                                              ) : (
                                                                ""
                                                              )}

                                                              <th>Center Name</th>
                                                              <th>Address</th>
                                                              <th>State</th>
                                                              <th>City</th>
                                                              <th>Pincode</th>
                                                              <th>Principal name</th>
                                                              <th>Contact</th>
                                                              <th>Type of school</th>
                                                              <th>Category</th>
                                                              <th>Seating capacity</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {allData33 && allData33 ? (
                                                              allData33.map((i, index) => {
                                                                return (
                                                                  <tr key={index}>
                                                                    <td>{index + 1}.</td>

                                                                    <td>

                                                                      <input type="checkbox" onChange={handleCheckBox22} value={i._id} name={i._id} checked={i?.isChecked || false} disabled={i.is_associated ? true : false} />
                                                                    </td>

                                                                    <td>{i.center_name ? i.center_name : null}</td>

                                                                    <td>{i.center_address ? i.center_address : null}</td>
                                                                    <td>{i.center_state ? i.center_state : null}</td>
                                                                    <td>{i.center_city ? i.center_city : null}</td>
                                                                    <td>{i.center_pincode ? i.center_pincode : null}</td>
                                                                    <td>{i.center_principal_name ? i.center_principal_name : null}</td>
                                                                    <td>{i.center_contact ? i.center_contact : null}</td>
                                                                    <td>{i.center_type_of_school ? i.center_type_of_school : null}</td>
                                                                    <td>{i.center_category ? i.center_category : null}</td>
                                                                    <td>{i.center_no_of_candidate ? i.center_no_of_candidate : null}</td>
                                                                  </tr>
                                                                );
                                                              })
                                                            ) : (
                                                              <tr>
                                                                <td colSpan={11} style={{ textAlign: "center" }}>
                                                                  No data found
                                                                </td>
                                                              </tr>
                                                            )}
                                                          </tbody>
                                                        </table>
                                                        {allData33 ? (
                                                          allData33.length > 0 ? null : (
                                                            <div style={{ textAlign: "center" }}>
                                                              {" "}
                                                              <p>No data found</p>{" "}
                                                            </div>
                                                          )
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                    {CheckBoxError && <span style={{ color: "#BE312E" }}>Please select center</span>}

                                                    <center>
                                                      {" "}
                                                      <button className="me-2 btn btn-primary waves-effect waves-light" onClick={handleAssociated}>
                                                        Submit
                                                      </button>
                                                    </center>
                                                  </div>

                                                  <div className="modal-footer">
                                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" id="close">
                                                      Close
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {datasrc ? (
                            datasrc.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}
                          <div className=" btns-table align-items-center justify-content-end">
                            {getprojectsres ? (
                              getprojectsres.count > 0 ? (
                                <div className="row align-items-center wrapper-pg-main">
                                  <div className="col-6">
                                    Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getprojectsres ? getprojectsres.count : ""} entries
                                  </div>
                                  <div className="col-6">
                                    <div className="wrapper-pgn">
                                      <div className="pgn pgn-default">
                                        <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                          <i className="bx bx-chevrons-left"></i>
                                        </div>
                                        <div className="pages" id="content">
                                          {(() => {
                                            const pages = [];

                                            for (let i = 1; i < getprojectsres.count / 20 + 1; i++) {
                                              pages.push(
                                                <div
                                                  className={page == i ? "page page-btn active" : "page page-btn"}
                                                  onClick={() => {
                                                    Apicall((i - 1) * 20);
                                                    setpage(i);
                                                  }}
                                                >
                                                  {i}
                                                </div>
                                              );
                                            }

                                            return pages;
                                          })()}
                                        </div>
                                        <div onClick={() => document.getElementById("content").scrollBy(50, 0)} className=" next ">
                                          <i className="bx bx-chevrons-right"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
