import React, { useState,  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminMessagesListAPI, dispatchLoadingStatus, getstatecityAPI, getProjectTitleAPI, getRolesAPI, attendanceapproverejectAPI, getCenterListDDLURLAPI, } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import "react-medium-image-zoom/dist/styles.css";
import MainDashbord from "../Sidebar/MainDashbord";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

function Message() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  let token = localStorage.getItem("token");

  const [page, setpage] = useState(1);
  const [allData22, setAllData22] = useState();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);


  const handleReason = (e) => {
    setReason(e.target.value.trim() == "" ? "" : e.target.value);
    if (e.target.value == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }
  };

  const Reject = (i) => {
    if (reason == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }
    if (token && reason) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("isApproved", 0);
      formData.append("reject_reason", reason);
      dispatch(dispatchLoadingStatus(true));
      dispatch(attendanceapproverejectAPI(formData));
    }
  };

  useEffect(() => {
    let formData22 = new FormData();
    formData22.append("token", localStorage.getItem("token"));
    formData22.append("start", 0);
    formData22.append("limit", 20);
    formData22.append("start_date", "");
    formData22.append("end_date", "");
    formData22.append("freelancer_role_id", "");
    formData22.append("project_id", "");

    dispatch(dispatchLoadingStatus(true));
    dispatch(getAdminMessagesListAPI(formData22));

  },[])

  function Apicall(start) {
    let formData22 = new FormData();
    formData22.append("token", localStorage.getItem("token"));
    formData22.append("start", start);
    formData22.append("limit", 20);
    formData22.append("start_date", "");
    formData22.append("end_date", "");
    formData22.append("freelancer_role_id", "");
    formData22.append("project_id", "");

    if (localStorage.getItem("getAdminMessagesListAPI") != "true") {
      localStorage.setItem("getAdminMessagesListAPI", true);
      dispatch(dispatchLoadingStatus(true));
      dispatch(getAdminMessagesListAPI(formData22));
    }
  }

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    dispatch(getRolesAPI(formData));
    dispatch(getProjectTitleAPI(formData));
    dispatch(getCenterListDDLURLAPI(formData));
  }, []);

  let getAdminMessagesListRes = useSelector((state) => state.globalReducer.getAdminMessagesListRes);

  let total = getAdminMessagesListRes ? getAdminMessagesListRes.count : "";

  useEffect(() => {
    if (getAdminMessagesListRes) {
      if (getAdminMessagesListRes.errorcode === 1) {
        setAllData22([]);
      } else {
        setAllData22(getAdminMessagesListRes ? getAdminMessagesListRes.data : getAdminMessagesListRes.data);
      }
    }
  }, [getAdminMessagesListRes]);


  const [order, setOrder] = useState("ASC");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");

    if (tokenRolee.find((t) => t === "Message")) {
      if (token) {
        if (!window.location.href.includes("Message")) {
          window.location.href = window.location.origin + "/Message";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);
  return (
    <>
      <MainDashbord />
      <Loader />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Messages List</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                        Dashboard
                      </a>
                      <a href="/Message" className="breadcrumb-item active">
                        Messages
                      </a>
                    </ol>
                    <div className="">
                      <button className=" mt-4 btn btn-primary float-lg-end waves-effect waves-light" onClick={() => history("/Sendmessage")}>
                        Add message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminMessagesListRes ? getAdminMessagesListRes.count : ""} entries
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-rep-plugin">
                    <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap w-100">
                        <thead>
                          <tr>
                            <th> Sr No. </th>
                            <th>
                              Project Title <i onClick={() => Sortt("projectTitle")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>
                            <th>
                              Role <i onClick={() => Sortt("role")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>
                            <th>
                              Created At <i onClick={() => Sortt("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allData22 &&
                            allData22.map((i, index) => (
                              <tr>
                                <th> {page * 20 + index + 1 - 20}.</th>
                                <td>{i.projectTitle} </td>
                                <td>{i.role}</td>
                                <td>{i.created_at}</td>
                                <td>
                                  <i className="cursor-pointer far fa-eye ms-3 font-size-18" onClick={() => history("/MessageDetails", { state: i })}></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {allData22 ? (
                        allData22.length > 0 ? null : (
                          <div style={{ textAlign: "center" }}>
                            <p>No data found</p>{" "}
                          </div>
                        )
                      ) : null}
                    </div>

                    <div className="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                              Reason for Rejection
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setReason("")}></button>
                          </div>
                          <div className="modal-body">
                            <form>
                              <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason}></textarea>
                            </form>
                            {reasonError}
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" id="close" onClick={() => setReason("")}>
                              Close
                            </button>
                            {reason == "" ? (
                              <button type="button" className="btn btn-primary" onClick={Reject}>
                                Submit
                              </button>
                            ) : (
                              <button type="button" id="close" className="btn btn-primary" data-bs-dismiss="modal" onClick={Reject}>
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" btns-table align-items-center justify-content-end">
                      {getAdminMessagesListRes ? (
                        getAdminMessagesListRes.count > 0 ? (
                          <div className="row align-items-center wrapper-pg-main">
                            <div className="col-6">
                              Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminMessagesListRes ? getAdminMessagesListRes.count : ""} entries
                            </div>
                            <div className="col-6">
                              <div className="wrapper-pgn">
                                <div className="pgn pgn-default">
                                  <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                    <i className="bx bx-chevrons-left"></i>
                                  </div>
                                  <div className="pages" id="content">
                                    {(() => {
                                      const pages = [];
                                      for (let i = 1; i < getAdminMessagesListRes.count / 20 + 1; i++) {
                                        pages.push(
                                          <div
                                            className={page == i ? "page page-btn active" : "page page-btn"}
                                            onClick={() => {
                                              Apicall((i - 1) * 20);
                                              setpage(i);
                                            }}
                                          >
                                            {i}
                                          </div>
                                        );
                                      }
                                      return pages;
                                    })()}
                                  </div>
                                  <div onClick={() => document.getElementById("content").scrollBy(50, 0)} className=" next ">
                                    <i className="bx bx-chevrons-right"></i>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="%PUBLIC_URL%/libs/magnific-popup/jquery.magnific-popup.min.js"></script>
      <script src="%PUBLIC_URL%/js/pages/lightbox.init.js"></script>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Powered by{" "}
                <a href="https://www.nichetechsolutions.com/" target="_blank">
                  Nichetech.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Message;
