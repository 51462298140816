
import React, { useEffect } from 'react';
import MainDashbord from "../Sidebar/MainDashbord";
import Loader from '../Loader/Loader';
import { useNavigate } from "react-router-dom";

function Reports() {

    let history = useNavigate();

    useEffect(() => {

        const token = localStorage.getItem('token');
        let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];


        if (tokenRolee.find(t => t === "Report")) {
            if (token) {
                if (!window.location.href.includes('Reports')) {
                    window.location.href = window.location.origin + '/Reports'
                }
            }
            else {
                if (!window.location.href.includes('/Login')) {
                    window.location.href = window.location.origin + '/Login'
                }
            }
        }

        else if (tokenRolee.find(t => t === "Dashboard")) {
            if (!window.location.href.includes('Dashboard')) {
                window.location.href = window.location.origin + '/Dashboard'
            }
        }

        else if (tokenRolee.find(t => t === "Freelancers")) {
            if (!window.location.href.includes('Freelancers')) {
                window.location.href = window.location.origin + '/Freelancers'
            }
        }
        else if (tokenRolee.find(t => t === "Projects")) {
            if (!window.location.href.includes('Projects')) {
                window.location.href = window.location.origin + '/Projects'
            }
        }

        else if (tokenRolee.find(t => t === "Centers")) {
            if (!window.location.href.includes('Centers')) {
                window.location.href = window.location.origin + '/Centers'
            }
        }

        else if (tokenRolee.find(t => t === "Assign")) {
            if (!window.location.href.includes('Addpeople')) {
                window.location.href = window.location.origin + '/Addpeople'
            }
        }

        else if (tokenRolee.find(t => t === "Attendance")) {
            if (!window.location.href.includes('Attendance')) {
                window.location.href = window.location.origin + '/Attendance'
            }
        }

        else if (tokenRolee.find(t => t === "Message")) {
            if (!window.location.href.includes('Message')) {
                window.location.href = window.location.origin + '/Message'
            }
        }
        else if (tokenRolee.find(t => t === "Audits")) {
            if (!window.location.href.includes('Audits')) {
                window.location.href = window.location.origin + '/Audits'
            }
        }
        else if (tokenRolee.find(t => t === "Expenses")) {
            if (!window.location.href.includes('Expenses')) {
                window.location.href = window.location.origin + '/Expenses'
            }
        }
        else if (tokenRolee.find(t => t === "Role Management")) {
            if (!window.location.href.includes('AdminUserManagement')) {
                window.location.href = window.location.origin + '/AdminUserManagement'
            }
        }

    }, [])



    return (
        <>
            <h1>Center List Page</h1>
            <MainDashbord />
            <Loader />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">Reports </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <a href='/Dashboard' className="breadcrumb-item active">Dashboard</a><a href='/Reports' className="breadcrumb-item active">Reports</a>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-rep-plugin">
                                            <div className="row mb-3 no-gutters">
                                                <div className="table-responsive reports-table">
                                                    <table id="example" className="table table-striped dt-responsive">
                                                        <thead>
                                                            <tr>
                                                                <th> Sr No. </th>
                                                                <th>Reports</th>
                                                                <th >Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1.</td>
                                                                <td>Freelancers</td>
                                                                <td >
                                                                    <i className='cursor-pointer far fa-eye me-3 font-size-18' onClick={() => history('/Freelancers')}></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2.</td>
                                                                <td>Attendance</td>
                                                                <td >
                                                                    <i className='cursor-pointer far fa-eye me-3 font-size-18' onClick={() => history('/Attendance')}></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>3.</td>
                                                                <td>Expenses</td>
                                                                <td>
                                                                    <i className='cursor-pointer far fa-eye me-3 font-size-18' onClick={() => history('/Expenses')}></i>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            © Edu-Test All Rights Reserved {(new Date().getFullYear())}.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Reports;