import React from 'react';
import FreelancerAssignRole from './FreelancerAssignRole';


function FreelanceEditRole() {

  return (
    <>
      <FreelancerAssignRole/>
    </>
  )
}

export default FreelanceEditRole;