import MainDashbord from "../Sidebar/MainDashbord";
import React, { useState, useEffect } from "react";
import { addEditFreelancerRoleAPI, dispatchLoadingStatus, getstatecityAPI } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProjectTitleRes, centersbyfreelanceprojectIdRes } from "../../Constant";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../Loader/Loader";

function FreelancerAssignRole() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  let flage22 = localStorage.getItem("flage22");
  const { state } = useLocation();
  const [freelancerId, setFreelancerId] = useState("");

  function handleMessage(e) {
    if (window.location.href.includes("FreelancerAssignRole") || window.location.href.includes("FreelanceEditRole")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          history(e);
        } 
      });
    } else {
      history(e);
    }
  }

  const [Audit, setAudit] = useState(false);
  const handleAudit = (e) => {
    setAudit(e.target.checked);
  };

  const [rating, setRating] = useState(false);
  const handleRating = (e) => {
    setRating(e.target.checked);
  };

  const [route, setRoute] = useState(false);
  const handleRoute = (e) => {
    setRoute(e.target.checked);
  };

  useEffect(() => {
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    localStorage.removeItem("AlertFlage");

    if (flage22 == "true") {
      setExamType(state ? state.examType : "");
      setName(state ? state.role : "");
      setFreelancerId(state ? state._id : "");
      setAudit(state ? state.is_auditable : false);
      setRating(state ? state.isRateAllow : false);
      setRoute(state ? state.isRouteAllow : false);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: getProjectTitleRes, payload: null });
    dispatch({ type: centersbyfreelanceprojectIdRes, payload: null });
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
  }, []);

  const [Name, setName] = useState("");
  const [NameError, setNameError] = useState(false);
  const [NameError22, setNameError22] = useState(false);
  const [NameError33, setNameError33] = useState(false);
  const [examType, setExamType] = useState("");
  const [examTypeError, setExamTypeError] = useState(false);

  function handleName(e) {
    if (e) {
      setName(e.target.value.trim() == "" ? "" : e.target.value);
      localStorage.setItem("AlertFlage", true);
    } else {
      if (e == "") {
        setName("");
        localStorage.removeItem("AlertFlage");
      }
    }
  }

  const handleExamType = (e) => {
    if (e.target.value) {
      setExamType(e.target.value);
    } else {
      setExamType("");
    }
  };

  const allDeatils = () => {
    let nameRe = /^[A-Za-z ]+$/;
    if (Name) {
      if (Name.length >= 3) {
        setNameError22(false);
        setNameError(false);
        setNameError33(false);
        if (!nameRe.test(Name)) {
          setNameError22(true);
          setNameError(false);
          setNameError33(false);
        } else {
          setNameError22(false);
          setNameError(false);
          setNameError33(false);
        }
      } else {
        setNameError22(false);
        setNameError(false);
        setNameError33(true);
      }
    } else {
      setNameError(true);
      setNameError22(false);
      setNameError33(false);
    }

    if (!examType) {
      setExamTypeError(true);
    } else {
      setExamTypeError(false);
    }
    if (nameRe.test(Name) && Name.length >= 3 && examType) {
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("role", Name);
      formData.append("examType", examType);
      formData.append("freelancer_role_id", freelancerId);
      formData.append("is_auditable", Audit);
      formData.append("isRateAllow", rating);
      formData.append("isRouteAllow", route);
      dispatch(dispatchLoadingStatus(true));
      dispatch(addEditFreelancerRoleAPI(formData));
    }
  };

  const addEditAdminUserRes = useSelector((state) => state.globalReducer.addEditAdminUserRes);

  useEffect(() => {
    if (addEditAdminUserRes) {
      history("/AdminUserManagement");
    }
  }, [addEditAdminUserRes]);

  return (
    <>
      <MainDashbord />
      <Loader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    {flage22 == "true" ? <h4 className="mb-sm-0 font-size-18">Freelancer Edit Assign Role</h4> : <h4 className="mb-sm-0 font-size-18">Freelancer Assign Role</h4>}
                    {flage22 == "true" ? (
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <a onClick={() => handleMessage("/FreelancerRoleManagement")} className="breadcrumb-item active">
                            Freelancer Role Management
                          </a>
                          <a href="/FreelanceEditRole" className="breadcrumb-item active">
                            Freelancer Edit Assign Role
                          </a>
                        </ol>
                      </div>
                    ) : (
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <a onClick={() => handleMessage("/FreelancerRoleManagement")} className="breadcrumb-item active">
                            Freelancer Role Management
                          </a>
                          <a href="/FreelancerAssignRole" className="breadcrumb-item active">
                            Freelancer Assign Role
                          </a>
                        </ol>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-rep-plugin">
                      <div className="row mt-5">
                        <div className="col-lg-4">
                          <div className="mb-4">
                            <label className="form-label">Role Name *</label>
                            <input type="text" className="form-control" placeholder="Enter Role Name" value={Name} onChange={(e) => handleName(e)} maxLength={30} />
                            {NameError && <span style={{ color: "#BE312E" }}>Please enter role name</span>}

                            {NameError22 == true ? NameError22 && <span style={{ color: "#BE312E" }}>Please enter valid role name</span> : NameError33 && <span style={{ color: "#BE312E" }}>Please enter minimum 3 character name</span>}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mb-3">
                            <label className="d-block mb-3">Exam Type *</label>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" value="PBT" name="examptype" onChange={handleExamType} id="pbt" checked={examType == "PBT" ? true : false} /> <label htmlFor="pbt">PBT</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" value="CBT" name="examptype" onChange={handleExamType} id="cbt" checked={examType == "CBT" ? true : false} /> <label htmlFor="cbt">CBT</label>
                            </div>{" "}
                            <br />
                            {examTypeError && <span style={{ color: "#BE312E" }}>Please select a type of exam</span>}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mb-3">
                            <label className="d-block mb-3">Audit</label>

                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" value={Audit} name="audit" onChange={handleAudit} id="audit" checked={Audit} /> <label htmlFor="audit"> Audit Allowed </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mb-3">
                            <label className="d-block mb-3">Rating</label>

                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" value={rating} name="audit" onChange={handleRating} id="audit" checked={rating} /> <label htmlFor="audit"> Rating Allowed </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mb-3">
                            <label className="d-block mb-3">Route</label>

                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" value={route} name="audit" onChange={handleRoute} id="audit" checked={route} /> <label htmlFor="audit"> Route Allowed </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="text-center d-block m-auto">
                            <button
                              type="button"
                              className="btn btn-primary btn-md    waves-effect waves-light"
                              data-bs-toggle="modal"
                              data-bs-target=".transaction-detailModal"
                              onClick={() => {
                                allDeatils();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FreelancerAssignRole;
