import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCentersByProjectIdAPI, dispatchLoadingStatus, auditApproveRejectAPI, getAdminCompletedAuditListAPI, getstatecityAPI, getProjectTitleAPI, getCenterListDDLURLAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import "react-medium-image-zoom/dist/styles.css";
import MainDashbord from "../Sidebar/MainDashbord";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";   

function PBTFilter() {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer", // set the cursor to pointer for the control (main input)
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer", // set the cursor to pointer for each option
    }),
  };
  //added by kajal 15-09-2023

  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const [showFilters1, setShowFilters1] = useState(false);
  const [searchFilters, setSearchFilters] = useState(false);
  const [show22, setShow22] = useState(false);
  const [strongroomoptionValue, setstrongroomoptionValue] = useState("");
  const [parkingoptionValue, setparkingoptionValue] = useState("");
  const [washdrinkoptionValue, setwashdrinkoptionValue] = useState("");
  const [genestoptionValue, setgenestoptionValue] = useState("");
  const [clockoptionValue, setclockoptionValue] = useState("");
  const [strongroomoptionLabel, setstrongroomoptionLabel] = useState();
  const [parkingoptionLabel, setparkingoptionLabel] = useState();
  const [washdrinkoptionLabel, setwashdrinkoptionLabel] = useState();
  const [genestoptionLabel, setgenestoptionLabel] = useState();
  const [clockoptionLabel, setclockoptionLabel] = useState();
  const [searchFilters22, setSearchFilters22] = useState({ testCenter: "", strongRoom: "", parking: "", drinkingWater: "", genset: "", clockRoom: "" });

  const handleClose22 = () => setShow22(false);
  //added by kajal for PCs and Test center
  const [minCap2, setMinCap2] = useState("");

  const NumValid33 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMinCap2(e.target.value);
    }
  };

  function handlestrongroomOption(event) {
    setstrongroomoptionValue(event.value);
    setstrongroomoptionLabel(event.label);
  }
  function handleparkingOption(event) {
    setparkingoptionValue(event.value);
    setparkingoptionLabel(event.label);
  }

  function handlewashdrinkOption(event) {
    setwashdrinkoptionValue(event.value);
    setwashdrinkoptionLabel(event.label);
  }
  function handlegenestOption(event) {
    setgenestoptionValue(event.value);
    setgenestoptionLabel(event.label);
  }
  function handleclockOption(event) {
    setclockoptionValue(event.value);
    setclockoptionLabel(event.label);
  }

  const PDF = `<html>

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
            rel="stylesheet" />
        <link rel="icon" href="~/Content/image/logo-f.png" type="image/x-icon">
        <title></title>
    
        <style type="text/css">
            body {
                font-family: 'Roboto', sans-serif;
            }
    
            .border_right {
                border-right: 1px solid #333;
            }
    
            .border_left {
                border-left: 1px solid #333;
            }
    
            .door-sec td {
                border-bottom: 1px solid #999a9a;
                padding: 3px 10px;
            }
    
            .door-sec th {
                text-align: center;
                padding: 10px;
                background: #dfdfdf;
            }
    
            .door-sec td {
                font-size: 14px;
            }
    
            table {
                border-spacing: 0;
                width: 100%
            }
        </style>
    
    </head>
    
    <body>
    
        <table style="width: 100%;">
            <thead>
    
                <tr>
                    <td style="  padding: 65px 10px 0px; width: 100%;">
                        <p
                            style="margin: 0px; font-size: 20; font-weight: bold;text-align: center; text-transform: uppercase; color: black">
                            Effective from 01st April’2022</p>
                        <p
                            style="margin: 15px 0 5px 0; font-size: 15px; font-weight: 300; text-align: center; text-transform: uppercase; color: #000;">
                            Exam Centre Checklist & Booking Form (To be filled)</p>
                    </td>
                    <td style="padding-top: 10px;"><img style="margin-bottom: 5px; max-width: 75px;     padding-left: 35px;"
                            alt="" title="" src="logo.png"></td>
    
                </tr>
            </thead>
        </table>
    
        <tbody>
            <tr>
                <td style="padding-top: 15px;" colspan="9">
                    <table style="width: 100%; margin-top: 35px;" className="">
                        <tbody>
                            <tr>
                                <td>Exam Centre -</td>
                                <td></td>
                                <td>City -</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Audited by </td>
                                <td></td>
                                <td>Contact No.-</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Date of Audit:</td>
                                <td></td>
                                <td>Exam Date:</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
    
    
    
            <tr>
                <td style="padding-top: 15px;" colspan="9">
                    <table style="width: 100%; margin-top: 35px;" className="door-sec">
                        <thead>
    
                            <tr>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Venue Checklist Parameters</th>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Status</th>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Venue Checklist Parameters</th>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Status</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr>
                                <td className=" border_left border-right">Distance from Bus/Railway Station (in KM) <br>बस/रेलवे
                                    स्टेशन से दरूी (कि.मी में)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Door with Laches in Every Classroom (Yes/No)<br>हर कक्षा में कुण्डी
                                    वाला दरवाजा (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Conveyance Facility to Centre (Yes/No)<br>केंद्र के
                                    लिए वाहन सुविधा (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">No. of Invigilators (1 Invigilator Per 12 Students)<br>१२ अभ्यर्थी
                                    पर नियुक्त १ निरीक्षक (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Approach road in good condition (Yes/No)<br>एप्रोच रोड
                                    अच्छी स्थिति में है (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">No. of Security Guards (Yes/No)<br>सुरक्षा गार्डों की संख्या
                                    (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Strong Room with Locking facility (Yes/No)<br>लॉकिंग
                                    सुविधा के साथ स्ट्रांग रूम (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Clock Room Facility to keep bags and mobile (Yes/No)<br>छात्रों को
                                    अपना सामान रखने की क्लॉक रूम की सुविधा(हाां/नहीां</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Doors/Windows on Class (Yes/No)<br>कक्षा में
                                    दरवाजे/खिड़कियां (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">First Aid Facility (Yes/No)<br>प्राथमिक चिकित्सा सुविधा (हां/नहीं)
                                </td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Boundary Wall Present (Yes/No)<br>सीमा की दीवार मौजूद
                                    है (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Fire Extinguisher (Yes/No)<br>अग्निशामक (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Parking Facility (Yes/No)<br>पार्किंग सुविधा
                                    (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Genset Available (Yes/No)
                                    KVA?<br>जनरेटर (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">How many lockable entries and exit points
                                    does the venue have<br>केंद्र में अन्दर तथा बाहर जाने के लिये लॉक करने लायक कितने दरवाजे
                                    है</td>
                                <td className="border_right border_left"></td>
                                <td rowspan="2" className="border_right">Actual Photos from the Site required as below<br>निचे
                                    दिए गये सभी स्थानों का फोटो सलग्न करे <br><br>Classroom (5 Pics), Entrance Gate, and
                                    Main
                                    Building, Toilets, Lobby, Main Exam Control Room</td>
                                <td rowspan="2" className="border_right border_left"></td>
                            </tr>
                            <tr>
                                <td className=" border_left border-right">How many lockable entries and exit points
                                    does the venue have<br>केंद्र में अन्दर तथा बाहर जाने के लिये लॉक करने लायक कितने दरवाजे
                                    है</td>
                                <td className="border_right border_left"></td>
                                
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </body>
    
    </html>`;

  const dispatch = useDispatch({});

  let getAdminCompletedAuditListRes = useSelector((state) => state.globalReducer.getAdminCompletedAuditListRes);
  let total = getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.count : "";
  let listCentersByProjectIdRes = useSelector((state) => state.globalReducer.listCentersByProjectIdRes);
  let [filterProject, setFilterProject] = useState("");

  useEffect(() => {
    if (listCentersByProjectIdRes) {
      const filteredArray = listCentersByProjectIdRes?.data?.filter((item) => item !== null);
      setFilterProject(filteredArray);
    } else {
      setFilterProject([]);
    }
  }, [listCentersByProjectIdRes]);

  let token = localStorage.getItem("token");

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    dispatch(getProjectTitleAPI(formData));
    dispatch(getCenterListDDLURLAPI(formData));
  }, []);

  const [startDate, setStartdate] = useState(null);
  const [endDate, setEndDate] = useState();
  const [page, setpage] = useState(1);
  const [centerName22, setCenterName22] = useState([]);
  const [centerName33, setCenterName33] = useState([]);
  const [view, setView] = useState(false);
  const [state, setstate] = useState("");
  const [city, setciti] = useState("");
  const [start, setStart] = useState(0);
  const [citylist, setcitylist] = useState([]);
  const [allData22, setAllData22] = useState();
  const [project, setproject] = useState("");
  const [project22, setproject22] = useState();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);



  const handleReason = (e) => {
    setReason(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }
  };

  const Reject = () => {
    if (reason == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }

    if (token && reason) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("isApproved", 0);
      formData.append("reject_reason", reason);
      dispatch(dispatchLoadingStatus(true));
      dispatch(auditApproveRejectAPI(formData));
      setShow22(false);
      Apicall((page - 1) * 20, page);
    }
  };

  function Apicall(start, pages) {
    let formData = new FormData();
    let formData22 = new FormData();
    formData.append("token", token);
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("project_id", project);
    formData.append("center_id", centerName22);
    formData.append("start_date", startDate ? moment(startDate).format("DD/MM/yyyy") : "");
    formData.append("end_date", endDate ? moment(endDate).format("DD/MM/yyyy") : "");
    formData.append("exam_type", "PBT");
    //add all fiters parameter here added by kajal
    formData.append("center_city", city);
    formData.append("strong_room", strongroomoptionValue);
    formData.append("clock_room", clockoptionValue);
    formData.append("parking", parkingoptionValue);
    formData.append("genset", genestoptionValue);
    formData.append("washroom_drinking_water", washdrinkoptionValue);
    formData.append("total_capacity_min", minCap2);

    if ( state || centerName22 || centerName22 == "" || project || project == "" || startDate || endDate || city || strongroomoptionValue || clockoptionValue || parkingoptionValue || genestoptionValue || washdrinkoptionValue || minCap2) {
      if (localStorage.getItem("getAdminCompletedAuditList") != true) {
        localStorage.setItem("getAdminCompletedAuditList", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getAdminCompletedAuditListAPI(formData));
      }
    } else {
      if (localStorage.getItem("getAdminCompletedAuditList") != true) {
        localStorage.setItem("getAdminCompletedAuditList", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getAdminCompletedAuditListAPI(formData));
      }
    }

    formData22.append("token", token);
    formData22.append("project_id", project);

    if (project) {
      dispatch(listCentersByProjectIdAPI(formData22));
    }
  }

  function clearValue() {
    setSearchFilters22({
      testCenter: "",
      strongRoom: "Select value",
      parking: "Select value",
      drinkingWater: "Select value",
      genset: "Select value",
      clockRoom: "Select value",
    });

    setclockoptionValue("");
    setgenestoptionValue("");
    setwashdrinkoptionValue("");
    setparkingoptionValue("");
    setstrongroomoptionValue("");
    setMinCap2("");

    if (showFilters1 == true) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("start", 0);
      formData.append("limit", 20);
      formData.append("project_id", project ? project : "");
      formData.append("center_id", centerName22 ? centerName22 : "");
      formData.append("start_date", startDate ? moment(startDate).format("DD/MM/yyyy") : "");
      formData.append("end_date", endDate ? moment(endDate).format("DD/MM/yyyy") : "");
      formData.append("exam_type", "PBT");
      formData.append("center_city", city ? city : "");
      formData.append("total_pc_min", "");
      formData.append("is_lift", "");
      formData.append("is_waiting_area", "");
      formData.append("is_netwok_cabling", "");
      formData.append("is_diesel_genset", "");
      formData.append("is_labs_air_conditioning", "");
      formData.append("is_drinking_water", "");
      formData.append("is_labs_cctv", "");
      formData.append("is_ph_wheel_chair", "");
      formData.append("strong_room", "");
      formData.append("clock_room", "");
      formData.append("parking", "");
      formData.append("genset", "");
      formData.append("washroom_drinking_water", "");
      formData.append("total_capacity_min", "");
      if (localStorage.getItem("getAdminCompletedAuditList") != true) {
        localStorage.setItem("getAdminCompletedAuditList", true);
        dispatch(getAdminCompletedAuditListAPI(formData));
      }
    }
  }

  let getProjectTitleRes = useSelector((state) => state.globalReducer.getProjectTitleRes);
  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);

  const [state33, setstate33] = useState("");

  function setcity(e) {
    if (getstatecityReg) {
      var filter = getstatecityReg.data.filter((i) => i._id == e.value);
      setstate(e.value);
      setstate33(filter.length > 0 ? filter[0].state : []);

      setciti("");
      if (filter && e.value) {
        setcitylist(filter[0].districts);
        setStart(0);
        setciti([]);
      } else {
        setcitylist([]);
      }
    }
  }

  useEffect(() => {
    Apicall(start);
  }, [start]);



  useEffect(() => {
    debugger;
    if (project || project == "") {
      setpage(1);

      Apicall(0);
    }
  }, [project]);

  useEffect(() => {
    debugger;
    if (centerName22) {
      if (centerName22.length > 0) {
        debugger;
        setpage(1);

        Apicall(0);
      }
    }
  }, [centerName22]);
  useEffect(() => {
    if (startDate == null) {
      setpage(1);

      Apicall(0);
    } else {
      setpage(1);

      Apicall(0);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate == null) {
      setpage(1);

      Apicall(0);
    } else {
      setpage(1);

      Apicall(0);
    }
  }, [endDate]);

  //added by kajal

  useEffect(() => {
    if (minCap2 == "") {
      setpage(1);
    }
  }, [minCap2]);

  useEffect(() => {
    if (strongroomoptionValue == "") {
      setstrongroomoptionLabel("Select value");
      setpage(1);
    }
  }, [strongroomoptionValue]);

  useEffect(() => {
    if (washdrinkoptionValue == "") {
      setwashdrinkoptionLabel("Select value");
      setpage(1);
    }
  }, [washdrinkoptionValue]);

  useEffect(() => {
    if (genestoptionValue == "") {
      setgenestoptionLabel("Select value");
      setpage(1);
    }
  }, [genestoptionValue]);

  useEffect(() => {
    if (clockoptionValue == "") {
      setclockoptionLabel("Select value");
      setpage(1);
    }
  }, [clockoptionValue]);

  useEffect(() => {
    if (parkingoptionValue == "") {
      setparkingoptionLabel("Select value");
      setpage(1);
    }
  }, [parkingoptionValue]);
  useEffect(() => {
    if (city || city == "") {
      setpage(1);
      Apicall(0);
    }
  }, [city]);

  function handleSearchButton() {
    if (minCap2 || strongroomoptionValue || washdrinkoptionValue || genestoptionValue || clockoptionValue || parkingoptionValue) {
      setSearchFilters22({
        testCenter: minCap2 ? minCap2 : "",
        strongRoom: strongroomoptionLabel ? strongroomoptionLabel : "",
        parking: parkingoptionLabel ? parkingoptionLabel : "",
        drinkingWater: washdrinkoptionLabel ? washdrinkoptionLabel : "",
        genset: genestoptionLabel ? genestoptionLabel : "",
        clockRoom: clockoptionLabel ? clockoptionLabel : "",
      });
      setSearchFilters(true);
      setpage(1);

      Apicall(0);
    } else {
      setSearchFilters22({
        testCenter: minCap2 ? minCap2 : "",
        strongRoom: "Select value",
        parking: parkingoptionLabel ? parkingoptionLabel : "Select value",
        drinkingWater: washdrinkoptionLabel ? washdrinkoptionLabel : "Select value",
        genset: genestoptionLabel ? genestoptionLabel : "Select value",
        clockRoom: clockoptionLabel ? clockoptionLabel : "Select value",
      });
      setpage(1);
      Apicall(0);
    }
  }

  useEffect(() => {
    if (getAdminCompletedAuditListRes) {
      if (getAdminCompletedAuditListRes.errorcode == 1) {
        setAllData22([]);
      } else {
        setAllData22(getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.data : getAdminCompletedAuditListRes.data);
      }
    }

    if (getAdminCompletedAuditListRes) {
      if (getAdminCompletedAuditListRes.errorcode == 0) {
        setAllData22(getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.data : getAdminCompletedAuditListRes.data);
      } else {
        setAllData22([]);
      }
    }
  }, [getAdminCompletedAuditListRes]);

  const [order, setOrder] = useState("ASC");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      if (!window.location.href.includes("AuditedPBT")) {
        window.location.href = window.location.origin + "/AuditedPBT";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  function handleCenterName(e) {
    setCenterName22(e.value);

    setCenterName33(e.label);
  }

  function handleProject(e) {
    if (e.value) {
      setCenterName22("");
      setproject(e.value);
      setproject22(e.label);
    } else {
      setFilterProject([]);

      setCenterName22("");
      setproject("");
      setproject22("Project title");
    }
  }

  return (
    <>
      <Loader />
      <MainDashbord />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">PBT List</h4>
                </div>
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-body" style={{ padding: "2.25rem 1.25rem" }}>
                    {view ? <button type="button" style={{ float: "right", marginTop: "-25px" }} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setView(false)}></button> : ""}

                    {view ? (
                      parse(PDF)
                    ) : (
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">

                          <div className="col-md-12">
                            <div className="row align-items-center ">
                              <div className="col-md-3 col-6 mb-4 ">
                                <Select className="cursor-pointer" options={getProjectTitleRes ? [{ label: "Project title", value: "" }].concat(getProjectTitleRes.map((e) => ({ label: e.projectTitle, value: e._id }))) : []} isClearable={false} placeholder={"Project title"} value={project == "" ? [{ label: "Project title", value: "" }] : project ? [{ label: project22, value: project }] : [{ label: "Project title", value: "" }]} onChange={(e) => handleProject(e)} styles={customStyles} />
                              </div>

                              <div className="col-md-3 col-6 mb-4">
                                <Select
                                  className="selectCenter"
                                  id="selectCenter"
                                  options={filterProject ? [{ label: "Center name", value: "" }].concat(filterProject.map((e) => ({ label: e.center_name, value: e._id }))) : [{ label: "Center name", value: "" }]}
                                  isClearable={false}
                                  placeholder={"Center name"}
                                  value={centerName22 == "" ? [{ label: "Center name", value: "" }] : centerName22 ? [{ label: centerName33, value: centerName22 }] : [{ label: "Center name", value: "" }]}
                                  styles={customStyles}
                                  onChange={(e) => {
                                    handleCenterName(e);
                                  }}
                                />
                              </div>

                              <div className="col-md-3 col-6 mb-4 ">

                                <DatePicker
                                  name="Startdate"
                                  className="form-control cursor-pointer"
                                  placeholderText={"Start date"}
                                  dateFormat="MM/dd/yyyy"
                                  showMonthDropdown
                                  showYearDropdown
                                  scrollableYearDropdown
                                  minDate={moment("01/01/1947").toDate()}

                                  onChange={(value) => {
                                    if (value === null) {
                                      setStartdate(null);
                                    } else {
                                      // The user selected a date
                                      setStartdate(value);
                                    }
                                  }}
                                  selected={startDate}
                                  maxDate={endDate ? new Date(endDate) : new Date()}
                                  autoComplete="off"
                                  isClearable={true}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="col-md-3 col-6 mb-4">
                                <DatePicker
                                  className="form-control cursor-pointer"

                                  dateFormat="dd/MM/yyyy"
                                  onChange={(value) => {
                                    setEndDate(value)
                                  }}
                                  selected={endDate ? endDate : null}
                                  placeholderText={"End date"}
                                  showMonthDropdown
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={moment().year() - 1900}
                                  minDate={startDate}
                                  maxDate={moment().toDate()}
                                  autoComplete="off"
                                  isClearable={true}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>

                              <div className="col-md-3 col-6 mb-4">
                                <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} value={state == "" ? [{ label: "State", value: "" }] : state ? [{ label: state33, value: state }] : [{ label: "State", value: "" }]} onChange={(e) => setcity(e)} styles={customStyles} />
                              </div>
                              <div className="col-md-3 col-6 mb-4">
                                <Select
                                  options={citylist ? [{ label: "City", value: "" }].concat(citylist.map((e) => ({ label: e, value: e }))) : []}
                                  
                                  placeholder={"City"}
                                  value={city == "" ? [{ label: "City", value: "" }] : city ? [{ label: city, value: city }] : [{ label: "City", value: "" }]}
                                  onChange={(e) => setciti(e.value)}
                                  styles={customStyles}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <section className="filter-main-div mt-3 mb-3" style={{ cursor: "pointer" }}>
                            <div
                              onClick={() => {
                                setShowFilters1(!showFilters1);
                                clearValue();
                              }}
                              className="d-flex main-cbt-div"
                            >
                              <h4 className="cbt-heading w-100 m-0 ps-2">PBT Filters</h4>
                              <p className="pe-2 m-0"> {showFilters1 ? <i className="bx bx-minus cursor-pointer"></i> : <i className="bx bx-plus cursor-pointer"></i>}</p>
                            </div>
                            {showFilters1 ? (
                              <div className="form-main-div-cbt">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <label htmlFor="validationCustom05" className="form-label">
                                        Capacity of Test Center
                                      </label>
                                      <input className="form-control" maxlength="6" placeholder="Min" value={minCap2} onChange={NumValid33}></input>

                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <label htmlFor="validationCustom05" className="form-label">
                                        Availability of Strong Room
                                      </label>
                                      <Select
                                        options={options ? [{ label: "Select value", value: "" }].concat(options.map((option) => ({ label: option.label, value: option.value }))) : []}
                                        isClearable={false}
                                        placeholder={"Select value"}
                                        value={strongroomoptionValue == "" ? [{ label: "Select value", value: "" }] : strongroomoptionValue ? [{ label: strongroomoptionLabel, value: strongroomoptionValue }] : [{ label: "Select title", value: "" }]}
                                        onChange={(event) => handlestrongroomOption(event)}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <label htmlFor="validationCustom05" className="form-label">
                                        Availability of Parking{" "}
                                      </label>
                                      <Select
                                        options={options ? [{ label: "Select value", value: "" }].concat(options.map((option) => ({ label: option.label, value: option.value }))) : []}
                                        isClearable={false}
                                        placeholder={"Select value"}
                                        value={parkingoptionValue == "" ? [{ label: "Select value", value: "" }] : parkingoptionValue ? [{ label: parkingoptionLabel, value: parkingoptionValue }] : [{ label: "Select title", value: "" }]}
                                        onChange={(event) => handleparkingOption(event)}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <label htmlFor="validationCustom05" className="form-label">
                                        Availability of Washroom, drinking water
                                      </label>
                                      <Select
                                        options={options ? [{ label: "Select value", value: "" }].concat(options.map((option) => ({ label: option.label, value: option.value }))) : []}
                                        isClearable={false}
                                        placeholder={"Select value"}
                                        value={washdrinkoptionValue == "" ? [{ label: "Select value", value: "" }] : washdrinkoptionValue ? [{ label: washdrinkoptionLabel, value: washdrinkoptionValue }] : [{ label: "Select title", value: "" }]}
                                        onChange={(event) => handlewashdrinkOption(event)}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <label htmlFor="validationCustom05" className="form-label">
                                        Availability of Genset
                                      </label>
                                      <Select
                                        options={options ? [{ label: "Select value", value: "" }].concat(options.map((option) => ({ label: option.label, value: option.value }))) : []}
                                        isClearable={false}
                                        placeholder={"Select value"}
                                        value={genestoptionValue == "" ? [{ label: "Select value", value: "" }] : genestoptionValue ? [{ label: genestoptionLabel, value: genestoptionValue }] : [{ label: "Select title", value: "" }]}
                                        onChange={(event) => handlegenestOption(event)}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <label htmlFor="validationCustom05" className="form-label">
                                        Availability of Clock room
                                      </label>
                                      <Select
                                        options={options ? [{ label: "Select value", value: "" }].concat(options.map((option) => ({ label: option.label, value: option.value }))) : []}
                                        isClearable={false}
                                        placeholder={"Select value"}
                                        value={clockoptionValue == "" ? [{ label: "Select value", value: "" }] : clockoptionValue ? [{ label: clockoptionLabel, value: clockoptionValue }] : [{ label: "Select title", value: "" }]}
                                        onChange={(event) => handleclockOption(event)}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="text-center d-block m-auto">
                                      <button type="button" className="btn btn-primary btn-md    waves-effect waves-light" onClick={() => handleSearchButton()}>
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </section>
                        </div>
                        Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.count : ""} entries
                        {searchFilters == true ? (
                          <div className="row mt-3">
                            {searchFilters22.testCenter ? (
                              <div className="col-lg-3">
                                <p> Capacity of Test Center:- {searchFilters22.testCenter ? searchFilters22.testCenter : ""} </p>{" "}
                              </div>
                            ) : (
                              ""
                            )}
                            {searchFilters22.clockRoom == "Select value" ? (
                              ""
                            ) : (
                              <div className="col-lg-3">
                                {" "}
                                <p> Availability of Clock room:- {searchFilters22.clockRoom ? searchFilters22.clockRoom : ""} </p>{" "}
                              </div>
                            )}
                            {searchFilters22.genset == "Select value" ? (
                              ""
                            ) : (
                              <div className="col-lg-3">
                                <p>Availability of Genset:-{searchFilters22.genset ? searchFilters22.genset : ""} </p>{" "}
                              </div>
                            )}
                            {searchFilters22.drinkingWater == "Select value" ? (
                              ""
                            ) : (
                              <div className="col-lg-3">
                                {" "}
                                <p>Availability of Washroom, drinking water:- {searchFilters22.drinkingWater ? searchFilters22.drinkingWater : ""} </p>{" "}
                              </div>
                            )}
                            {searchFilters22.parking == "Select value" ? (
                              ""
                            ) : (
                              <div className="col-lg-3">
                                {" "}
                                <p> Availability of Parking:- {searchFilters22.parking ? searchFilters22.parking : ""}</p>{" "}
                              </div>
                            )}
                            {searchFilters22.strongRoom == "Select value" ? (
                              ""
                            ) : (
                              <div className="col-lg-3">
                                {" "}
                                <p> Availability of Strong Room:- {searchFilters22.strongRoom ? searchFilters22.strongRoom : ""}</p>{" "}
                              </div>
                            )}
                          </div>
                        ) : null}
                        <div className="table-responsive">
                          <table id="example" className="table table-striped dt-responsive nowrap w-100">
                            <thead>
                              <tr>
                                <th> Sr No. </th>
                                <th>
                                  Center Name <i onClick={() => Sortt("center_name")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>

                                <th>
                                  State <i onClick={() => Sortt("center_state")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th>
                                  City <i onClick={() => Sortt("center_city")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>

                                <th>
                                  Center Phone <i onClick={() => Sortt("cs_mobile")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th>
                                  Seating Capacity<i onClick={() => Sortt("total_capacity")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>

                                <th data-priority="3" height="15px" width="15px" style={{ marginLeft: 5 }}>
                                  Audited Report
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allData22 &&
                                allData22.map((i, index) => (
                                  <tr>
                                    <th> {page * 20 + index + 1 - 20}.</th>

                                    <td>{i.center_name}</td>
                                    <td>{i.center_state}</td>
                                    <td>{i.center_city}</td>
                                    <td>{i.cs_mobile}</td>
                                    <td>{i.total_capacity}</td>

                                    <td>
                                      {i.unsigned_pbt_audit_pdf_name || i.unsigned_cbt_audit_pdf_name ? (
                                        <a href={i.exam_type == "PBT" ? (i.unsigned_pbt_audit_pdf_name ? i.unsigned_pbt_audit_pdf_name : "") : i.unsigned_cbt_audit_pdf_name ? i.unsigned_cbt_audit_pdf_name : ""} target="_black">
                                          <button className="me-2 btn btn-dark waves-effect waves-light">
                                            <i className="me-2 mdi mdi-file-pdf-box-outline"></i>PDF
                                          </button>{" "}
                                        </a>
                                      ) : (
                                        <center>-</center>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {allData22 ? (
                            allData22.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}
                        </div>
                        <Modal show={show22} onHide={handleClose22} backdrop="static" keyboard={false}>
                          <Modal.Header closeButton>
                            <Modal.Title>Reason for Rejection </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <form>
                              <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason}></textarea>
                            </form>
                            {reasonError}
                          </Modal.Body>
                          <Modal.Footer>
                            <button type="button" className="btn btn-light" onClick={handleClose22}>
                              Close
                            </button>
                            {reason == "" ? (
                              <button type="button" className="btn btn-primary" onClick={Reject}>
                                Submit
                              </button>
                            ) : (
                              <button type="button" id="close" className="btn btn-primary" data-bs-dismiss="modal" onClick={Reject}>
                                Submit
                              </button>
                            )}
                          </Modal.Footer>
                        </Modal>
                        <div className=" btns-table align-items-center justify-content-end">
                          {getAdminCompletedAuditListRes ? (
                            getAdminCompletedAuditListRes.count > 0 ? (
                              <div className="row align-items-center wrapper-pg-main">
                                <div className="col-6">
                                  Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.count : ""} entries
                                </div>
                                <div className="col-6">
                                  <div className="wrapper-pgn">
                                    <div className="pgn pgn-default">
                                      {getAdminCompletedAuditListRes.count >= 20 ? (
                                        <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                          <i className="bx bx-chevrons-left cursor-pointer"></i>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="pages" id="content">
                                        {(() => {
                                          const pages = [];

                                          for (let i = 1; i < getAdminCompletedAuditListRes.count / 20 + 1; i++) {
                                            getAdminCompletedAuditListRes && getAdminCompletedAuditListRes.count && getAdminCompletedAuditListRes.count / 20 <= 1
                                              ? pages.push(
                                                  <div className={page == i ? "page page-btn active" : "page page-btn"} style={{ cursor: "default" }}>
                                                    {i}
                                                  </div>
                                                )
                                              : pages.push(
                                                  <div
                                                    className={page == i ? "page page-btn active" : "page page-btn"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                      Apicall((i - 1) * 20);
                                                      setpage(i);
                                                    }}
                                                  >
                                                    {i}
                                                  </div>
                                                );
                                          }

                                          return pages;
                                        })()}
                                      </div>
                                      {getAdminCompletedAuditListRes.count >= 20 ? (
                                        <div
                                          onClick={() => {
                                            document.getElementById("content").scrollBy(50, 0);
                                          }}
                                          className=" next "
                                        >
                                          <i className="bx bx-chevrons-right"></i>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                        <div className="rightbar-overlay"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Powered by{" "}
                <a href="https://www.nichetechsolutions.com/" target="_blank">
                  Nichetech.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default PBTFilter;
