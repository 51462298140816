import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainDashbord from "../Sidebar/MainDashbord";
import { getAdminUsersListAPI, dispatchLoadingStatus, changeAdminUserIsActiveAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default function RoleManagement() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  const [order, setOrder] = useState("ASC");
  const [searchval, setsearch] = useState("");
  const [datasrc, setdatasrc] = useState();
  const [page, setpage] = useState(1);

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.removeItem("admin_user_id");
    localStorage.setItem("getAdminUsersListAPI", false);
  }, []);

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  function Apicall(start, pageno) {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("name", searchval);
    if (searchval) {
      if (localStorage.getItem("getAdminUsersListAPI") != "true") {
        localStorage.setItem("getAdminUsersListAPI", true);
        dispatch(getAdminUsersListAPI(formData));
        setpage(pageno ? pageno : 1);
      }
    } else {
      if (localStorage.getItem("getAdminUsersListAPI") != "true") {
        localStorage.setItem("getAdminUsersListAPI", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getAdminUsersListAPI(formData));
        setpage(pageno ? pageno : 1);
      }
    }
  }

  function searchchange(value) {
    setsearch(value.trim() == "" ? "" : value);
  }
  useEffect(() => {
    if (searchval == "" || searchval.length > 0) {
      setpage(1);
      Apicall(0);
    }
  }, [searchval]);

  let getAdminUsersListRes = useSelector((state) => state.globalReducer.getAdminUsersListRes);
  let total = getAdminUsersListRes ? getAdminUsersListRes.count : "";
  let changeSRes = useSelector((state) => state.globalReducer.changeAdminUserIsActiveRes);

  useEffect(() => {
    if (getAdminUsersListRes) {
      if (getAdminUsersListRes.errorcode == 1) {
        setdatasrc([]);
      } else {
        setdatasrc(getAdminUsersListRes ? (getAdminUsersListRes.data ? getAdminUsersListRes.data : []) : []);
      }
    }
  }, [getAdminUsersListRes]);

  useEffect(() => {
    if (changeSRes) {
      Apicall((page - 1) * 20, page);
    }
  }, [changeSRes]);

  function OtherPage(i) {
    history("/EditUser", { state: i });
  }

  function toggleSwitch(e, i, idx) {
    var vall = i.is_active ? "inactive" : "active";
    swal({
      text: "Are you sure you want to " + vall + " this sub admin?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("admin_user_id", i._id);
        dispatch(dispatchLoadingStatus(true));
        dispatch(changeAdminUserIsActiveAPI(formData, i._id, i.is_active));
      }
    });
  }
  useEffect(() => {
  }, [datasrc]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];

    if (tokenRolee.find((t) => t === "Role Management")) {
      if (token) {
        if (!window.location.href.includes("AdminUserManagement")) {
          window.location.href = window.location.origin + "/AdminUserManagement";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  return (
    <>
      {" "}
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Admin User Management</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                          Dashboard
                        </a>
                        <a href="/AdminUserManagement" className="breadcrumb-item active">
                          Admin User Management{" "}
                        </a>
                      </ol>
                      <div className="">
                        <a onClick={() => history("/AssignUser")} className="mt-4 btn btn-primary float-lg-end waves-effect waves-light">
                          {" "}
                          Create New User
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-4   col-12  ">
                            <div className="mt-4 mb-3 mt-sm-0 d-sm-flex align-items-center">
                              <div className="search-box me-2">
                                <div className="position-relative">
                                  <input type="text" className="form-control " onChange={(e) => searchchange(e.target.value)} value={searchval} placeholder="Search..." />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive ">
                            Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminUsersListRes ? getAdminUsersListRes.count : ""} entries
                            <table id="example" className="table table-striped dt-responsive">
                              <thead>
                                <tr>
                                  <th> Sr No. </th>
                                  <th data-priority="1">
                                    {" "}
                                    Name
                                    <i onClick={() => Sortt("name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>

                                  <th data-priority="2">
                                    Email
                                    <i onClick={() => Sortt("adminemail")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>

                                  <th data-priority="2">
                                    Mobile
                                    <i onClick={() => Sortt("mobile")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>
                                  <th data-priority="2">Role </th>

                                  <th data-priority="5">
                                    Created At
                                    <i onClick={() => Sortt("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                  </th>
                                  <th data-priority="5">Active/Inactive</th>

                                  <th data-priority="5">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {datasrc &&
                                  datasrc.map((i, index) => (
                                    <tr key={"proj-" + index}>
                                      <th> {page * 20 + index + 1 - 20}.</th>
                                      <td>{i.name}</td>
                                      <td>{i.adminemail}</td>
                                      <td>{i.mobile}</td>
                                      <td>{i.roles ? i.roles.join(",\n\n\n\n\n\n") : ""}</td>

                                      <td>{i.created_at}</td>

                                      <td>
                                        <label class="switch">
                                          <input type="checkbox" key={i._id} onClick={(e) => toggleSwitch(e, i, index)} id="toggleSwitch" checked={i.is_active} defaultChecked={i.is_active} />
                                          <span class="slider round"></span>
                                        </label>
                                      </td>

                                      <td>
                                        <i className="cursor-pointer bx bx-edit font-size-18" onClick={() => OtherPage(i)} key={i._id}></i>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>

                          {datasrc ? (
                            datasrc.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}

                          <div className=" btns-table align-items-center justify-content-end">
                            {getAdminUsersListRes ? (
                              getAdminUsersListRes.count > 0 ? (
                                <div className="row align-items-center wrapper-pg-main">
                                  <div className="col-6">
                                    Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminUsersListRes ? getAdminUsersListRes.count : ""} entries
                                  </div>
                                  <div className="col-6">
                                    <div className="wrapper-pgn">
                                      <div className="pgn pgn-default">
                                        <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                          <i className="bx bx-chevrons-left"></i>
                                        </div>
                                        <div className="pages" id="content">
                                          {(() => {
                                            const pages = [];

                                            for (let i = 1; i < getAdminUsersListRes.count / 20 + 1; i++) {
                                              pages.push(
                                                <div
                                                  className={page == i ? "page page-btn active" : "page page-btn"}
                                                  onClick={() => {
                                                    Apicall((i - 1) * 20);
                                                    setpage(i);
                                                  }}
                                                >
                                                  {i}
                                                </div>
                                              );
                                            }

                                            return pages;
                                          })()}
                                        </div>
                                        <div onClick={() => document.getElementById("content").scrollBy(50, 0)} className=" next ">
                                          <i className="bx bx-chevrons-right"></i>
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
