
import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { freelancerDataAPI, deleteFreelancerAPI, downloadFreelancerListAPI, getRolesAPI, addReviewAPI, getProjectTitleAPI, getfreelancersAPI, dispatchLoadingStatus, getstatecityAPI, getQulificationAPI, ActiveInactiveFreelancerAPI, importFreelancersAPI } from '../../API/Action/globalAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import MainDashbord from '../Sidebar/MainDashbord';
import Loader from '../Loader/Loader';
import Select from 'react-select';
import swal from 'sweetalert';
import { FaStar } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import blankImg from '../../assets/images/blank.jpg'


toast.configure();
const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
};
function Dashbord2() {

    let wrapper1 = document.getElementById('wrapper1');
    let wrapper2 = document.getElementById('wrapper2');

    const [show22, setShow22] = useState(false);
    const handleClose22 = () => setShow22(false);
    const handleShow22 = () => setShow22(true)
    const [currentValue, setCurrentValue] = useState(0);
    const [firstName, setFirstname] = useState("")
    const [message, setMessage] = useState("");
    const [freelancerRole, setFreelancerRole] = useState("");
    const [freelancerRoleName, setFreelancerRoleName] = useState("");
    const getRolesReg = useSelector(state => state.globalReducer.getRolesReg);
    const ActiveInactiveFreelancerRes = useSelector(state => state.globalReducer.ActiveInactiveFreelancerRes);
    const deleteFreelancerReg = useSelector(state => state.globalReducer.deleteFreelancerReg);

    useEffect(() => {
        if (ActiveInactiveFreelancerRes) {
            let formData = new FormData();
            Apicall((page - 1) * 20, page);
            formData.append("token", localStorage.getItem('token'));
            dispatch(freelancerDataAPI(formData));
        }
    }, [ActiveInactiveFreelancerRes])

    function toggleSwitch(e, i) {
        var vall = i.is_active ? "inactive" : "active"
        swal({
            text: "Are you sure you want to " + vall + " this freelancer?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let formData = new FormData();
                    formData.append("token", localStorage.getItem('token'));
                    formData.append("freelancer_id", i._id);
                    dispatch(dispatchLoadingStatus(true));
                    dispatch(ActiveInactiveFreelancerAPI(formData, i._id, i.is_active));
                }
            });
    }
    //updated by kajal 04-08-2023
        function deleteFreelancer(i) {
        swal({
            text: "Are you sure you want to delete freelancer?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let formData = new FormData();
                    formData.append("token", localStorage.getItem('token'));
                    formData.append("freelancer_id", i._id);
                    dispatch(dispatchLoadingStatus(true));
                    dispatch(deleteFreelancerAPI(formData));
                }
            });
    }

    useEffect(() => {
        if (deleteFreelancerReg) {
            Apicall((page - 1) * 20, page);
        }
    }, [deleteFreelancerReg])

    const [hoverValue, setHoverValue] = useState(undefined);
    const stars = Array(5).fill(0)

    const handleClick = value => {
        setCurrentValue(value)
    }

    const handleMouseOver = newHoverValue => {
        setHoverValue(newHoverValue)
    };

    const handleMouseLeave = () => {
        setHoverValue(undefined)
    }

    const dispatch = useDispatch({
    });

    let history = useNavigate();
    const [start, setStart] = useState(0);
    const [project, setproject] = useState('');
    const [project22, setproject22] = useState({ label: "Project", value: "" });
    const [num, setNum] = useState(0);
    const [reload, setreload] = useState("false");

    useEffect(() => {
        localStorage.removeItem("AddTeamToken");
        if (window.performance) {
            if (performance.navigation.type == 1) {
                localStorage.setItem("projectTitle33", "")
                localStorage.setItem("projectTitle44", "Project");
                setreload("true")
                setproject('')
                setproject22({ label: "Project", value: "" });
            }
            else {
                if (localStorage.getItem("projectTitle33")) {
                    setproject(localStorage.getItem("projectTitle33"))
                    setproject22({ label: localStorage.getItem("projectTitle44"), value: localStorage.getItem("projectTitle33") });
                } else {
                    setreload("true")
                }
            }
        }
    }, [])

    useEffect(() => {
        let formData = new FormData();
        formData.append("token", token);
        formData.append("start", start);
        formData.append("limit", 20);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("pincode", pincode);
        formData.append("firstname", frname);
        localStorage.setItem('FRlist', true)
        dispatch(dispatchLoadingStatus(true))
        dispatch(getfreelancersAPI(formData));
        localStorage.removeItem("acceptDone")
        dispatch(dispatchLoadingStatus(true))
        dispatch(getQulificationAPI())
    }, [])

    const [state, setstate] = useState('');
    const [city, setciti] = useState('');
    const [pincode, setpincode] = useState('');
    const [frname, setFRname] = useState('');
    const [page, setpage] = useState(1);


    const NumValid = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setpincode(e.target.value)
        }
    }

    let getfreelancersReg = useSelector(state => state.globalReducer.getfreelancersReg);
    let freelancerDataReg = useSelector(state => state.globalReducer.freelancerDataReg);
    let total = getfreelancersReg ? getfreelancersReg.count : "";
    let getstatecityReg = useSelector(state => state.globalReducer.getstatecityReg);
    let token = localStorage.getItem('token');
    const [allData22, setAllData22] = useState();
    const [freelancerData, setFreelancerData] = useState();
    const [indexCh, setIndexCh] = useState(0);

    const [order, setOrder] = useState("ASC");
    const Sortt = (col) => {
        if (order === "ASC") {
            const sorted = [...allData22].sort((a, b) =>
                (a[col]?.toLowerCase() || '') > (b[col]?.toLowerCase() || '') ? 1 : -1
            );
            setAllData22(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...allData22].sort((a, b) =>
                (a[col]?.toLowerCase() || '') < (b[col]?.toLowerCase() || '') ? 1 : -1
            );
            setAllData22(sorted);
            setOrder("ASC");
        }
    }

    const [citylist, setcitylist] = useState([]);

    function Apicall(start) {
        let formData = new FormData();
        formData.append("token", token);
        formData.append("start", start);
        formData.append("limit", 20);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("pincode", pincode);
        formData.append("firstname", frname);
        formData.append("project_id", project);
        formData.append("roleid", freelancerRole);

        if (frname || state || state == "" || city || pincode || project || freelancerRole) {
            if (num == 1 || reload == "true") {
                setreload("false")
                dispatch(getfreelancersAPI(formData));
                localStorage.removeItem("acceptDone")
            }
        }
        else {
            if (num == 1 || reload == "true") {
                setreload("false")
                dispatch(dispatchLoadingStatus(true))
                dispatch(getfreelancersAPI(formData));
                localStorage.removeItem("acceptDone")
            }
        }
    }

    useEffect(() => {
        localStorage.setItem("projectTitle", "")
        localStorage.setItem("projectTitle22", "Project");
        dispatch(dispatchLoadingStatus(true))
        dispatch(getstatecityAPI());
        let formData = new FormData();
        formData.append("token", localStorage.getItem('token'));
        dispatch(getProjectTitleAPI(formData))
        dispatch(getRolesAPI(formData))
        dispatch(freelancerDataAPI(formData))
    }, [])


    let getProjectTitleRes = useSelector(state => state.globalReducer.getProjectTitleRes);
    useEffect(() => {
        if (getfreelancersReg) {
            if (getfreelancersReg.errorcode == 1) {
                setAllData22([])
            } else {
                setAllData22(getfreelancersReg ? getfreelancersReg.data : getfreelancersReg.data);
            }
        }
    }, [getfreelancersReg])

    useEffect(() => {
        if (freelancerDataReg) {
            if (freelancerDataReg.errorcode == 1) {
                setFreelancerData([])
            } else {
                setFreelancerData(freelancerDataReg ? freelancerDataReg.data : freelancerDataReg.data);
                window.scrollTo(0, 0)
            }
        }
    }, [freelancerDataReg])

    const [state33, setstate33] = useState('');
    function setcity(e) {
        if (getstatecityReg) {
            var filter = getstatecityReg.data.filter(i => i._id == e.value)
            setstate(e.value)
            setstate33(filter.length > 0 ? filter[0].state : [])
            setciti('')
            if (filter && e.value) {
                setcitylist(filter[0].districts);
                setStart(0);
                setciti('');
            }
            else {
                setcitylist([])
                setciti('')
            }
        }
    }


    useEffect(() => {
        Apicall(start)
    }, [start])

    useEffect(() => {
        setpage(1)
        Apicall(0)
    }, [state])

    useEffect(() => {
        setpage(1)
        Apicall(0)
    }, [project])

    useEffect(() => {
        setpage(1)
        Apicall(0)
    }, [city])

    useEffect(() => {
        if (pincode.length == 6 || pincode.length == 0) {
            setpage(1)
            Apicall(0)
        }
    }, [pincode])

    function ChangesFRname(value) {
        setFRname(value.trim() == "" ? "" : value)
    }

    useEffect(() => {
        if (frname == '' || frname.length > 0) {
            setpage(1)
            Apicall(0)
        }
    }, [frname])

    useEffect(() => {
        setNum(1);
        setpage(1)
        Apicall(0)
    }, [project])

    useEffect(() => {
        setpage(1)
        Apicall(0)
    }, [freelancerRole])

    useEffect(() => {
        if (getfreelancersReg) {
            if (getfreelancersReg.errorcode == 1) {
                setAllData22([])
            } else {
                setAllData22(getfreelancersReg ? getfreelancersReg.data : getfreelancersReg.data);
            }
        }
    }, [getfreelancersReg])

    useEffect(() => {
        const token = localStorage.getItem('token');
        let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];

        if (tokenRolee.find(t => t === "Freelancers")) {
            if (token) {
                if (!window.location.href.includes('Freelancers')) {
                    window.location.href = window.location.origin + '/Freelancers'
                }
            }
            else {
                if (!window.location.href.includes('/Login')) {
                    window.location.href = window.location.origin + '/Login'
                }
            }
        }
        else if (tokenRolee.find(t => t === "Dashboard")) {
            if (!window.location.href.includes('Dashboard')) {
                window.location.href = window.location.origin + '/Dashboard'
            }
        }
        else if (tokenRolee.find(t => t === "Projects")) {
            if (!window.location.href.includes('Projects')) {
                window.location.href = window.location.origin + '/Projects'
            }
        }
        else if (tokenRolee.find(t => t === "Centers")) {
            if (!window.location.href.includes('Centers')) {
                window.location.href = window.location.origin + '/Centers'
            }
        }

        else if (tokenRolee.find(t => t === "Assign")) {
            if (!window.location.href.includes('Addpeople')) {
                window.location.href = window.location.origin + '/Addpeople'
            }
        }

        else if (tokenRolee.find(t => t === "Attendance")) {
            if (!window.location.href.includes('Attendance')) {
                window.location.href = window.location.origin + '/Attendance'
            }
        }
        else if (tokenRolee.find(t => t === "Message")) {
            if (!window.location.href.includes('Message')) {
                window.location.href = window.location.origin + '/Message'
            }
        }
        else if (tokenRolee.find(t => t === "Audits")) {
            if (!window.location.href.includes('Audits')) {
                window.location.href = window.location.origin + '/Audits'
            }
        }
        else if (tokenRolee.find(t => t === "Expenses")) {
            if (!window.location.href.includes('Expenses')) {
                window.location.href = window.location.origin + '/Expenses'
            }
        }
        else if (tokenRolee.find(t => t === "Role Management")) {
            if (!window.location.href.includes('AdminUserManagement')) {
                window.location.href = window.location.origin + '/AdminUserManagement'
            }
        }
        else if (tokenRolee.find(t => t === "Report")) {
            if (!window.location.href.includes('Report')) {
                window.location.href = window.location.origin + '/Reports'
            }
        }
    }, [])

    const [modelState, setModelState] = useState({ "label": "State", "value": "" });
    const [FreelancerState, setFreelancerState] = useState("");
    const [modelStateErr, setModelStateErr] = useState(false);

    function handleModelState(e) {

        if (e.value == "") {
            setModelState({ "label": "State", "value": "" });
            setFreelancerState("");
            setModelStateErr(true);
        }
        else {
            setModelState({ "label": e.label, "value": e.value });
            setFreelancerState(e.value);
            setModelStateErr(false);
        }
    }

    const [show, setShow] = useState(false);
    const [file, setFile] = useState("");
    const [fileErr, setFileErr] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function handleFile(event) {
        if (
            event.target.files[0].name.toLowerCase().includes("xls") ||
            event.target.files[0].name.toLowerCase().includes("csv") ||
            event.target.files[0].name.toLowerCase().includes("xlsx")) {
            setFile(event.target.files[0]);
            setFileErr("")
        } else {
            setFile("")
            setFileErr("The file must be of type XLS, XLSX, or CSV")
        }
    }

    const allDeatils = () => {
        if (file) {
            if (
                file.name.toLowerCase().includes("xls") ||
                file.name.toLowerCase().includes("csv") ||
                file.name.toLowerCase().includes("xlsx")) {
                setFileErr("")
            } else {
                setFileErr("The file must be of type XLS, XLSX, or CSV")
            }
        } else {
            setFileErr("Please upload freelancers ")
        }
        if (FreelancerState == "") {
            setModelStateErr(true)
        } else {
            setModelStateErr(false)
        }
        if (file && FreelancerState) {
            let formData = new FormData();
            formData.append("token", localStorage.getItem('token'));
            formData.append("state_id", FreelancerState);
            formData.append("file_freelancers", file);
            dispatch(dispatchLoadingStatus(true));
            dispatch(importFreelancersAPI(formData));
            setShow(false);
            Apicall((page - 1) * 20, page);
        }
    }

    const [messageError, setMessageError] = useState("");




    const handleMessage = (e) => {
        const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/; // /^[-a-z0-9,/()&:. ]*[a-z][-a-z0-9,/()&:. ]*$/;
        // all number and charcetr allowed //space not  // special symbol not // middle space are allowed

        if (e.target.value) {
            if (re.test(e.target.value)) {
                setMessage(e.target.value);
            }
            else {
                setMessage("");
            }
        }
        else {
            setMessage("");
        }
    }

    const [reviewOne, setReviewOne] = useState(false);

    function handleRating(i) {
        if (currentValue > 0) {
            let formData = new FormData();
            formData.append("token", localStorage.getItem('token'));
            formData.append("freelancer_id", firstName._id);
            formData.append("star", currentValue);
            formData.append("review", message ? message : "");
            dispatch(dispatchLoadingStatus(true));
            dispatch(addReviewAPI(formData));
            setReviewOne(false);
            setShow22(false);
        }
        else {
            setReviewOne(true)
        }
    }

    let addReviewRes = useSelector(state => state.globalReducer.addReviewRes);

    useEffect(() => {
        if (addReviewRes) {
            Apicall((page - 1) * 20, page);
        }
    }, [addReviewRes])

    function downloadcsv() {
        if (allData22.length > 0) {
            let formData = new FormData();
            formData.append("token", token);
            formData.append("project_id", project ? project : "");
            formData.append("state", state ? state : "");
            formData.append("city", city ? city : "");
            formData.append("pincode", pincode ? pincode : "");
            formData.append("roleid", freelancerRole ? freelancerRole : "");
            dispatch(dispatchLoadingStatus(true))
            dispatch(downloadFreelancerListAPI(formData))
        } else {
            swal("", "No data found", "error");
        }
    }

    return (
        <>   <Loader />
            <div id="layout-wrapper">
                <MainDashbord />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">Freelancers</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <a href='/Dashboard' className="breadcrumb-item active">Dashboard </a> <a href='/Freelancers' className="breadcrumb-item active">Freelancers </a>
                                            </ol>
                                            <div className="">
                                                <a href="https://cms-api.smartexams.in/routes/sample/Freelancer_Sample_Data.xlsx" className="me-2  mt-4 btn me-2 btn-primary waves-effect waves-light" >Sample Freelancers File</a>
                                                <button className=" mt-4 btn btn-primary me-2 waves-effect waves-light" onClick={() => { handleShow(); setFile(""); }} data-bs-target="#staticBackdrop" htmlFor='import'>Import Freelancers</button>
                                                <button className=" mt-4 btn btn-primary me-2 waves-effect waves-light" onClick={() => downloadcsv()}><i className="bx bx-download font-size-17"></i> Download</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Active Freelancers</p>
                                              <h4 className="mb-0">{freelancerData ?freelancerData.totalActiveFreelancers : 0} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Approved Freelancers</p>
                                              <h4 className="mb-0">{freelancerData ?freelancerData.total_approved_freelancers_count : 0} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Total Freelancers</p>
                                              <h4 className="mb-0">{freelancerData ?freelancerData.total_freelancers_count : 0} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Pending Freelancers</p>
                                              <h4 className="mb-0">{freelancerData ?freelancerData.total_pending_freelancers_count : 0} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Rejected Freelancers</p>
                                              <h4 className="mb-0">{freelancerData ?freelancerData.total_rejected_freelancers_count : 0} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <Modal
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Import Freelancers </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form >
                                        <Select
                                            options={getstatecityReg ? [{ label: 'State', value: '' }].concat(getstatecityReg.data.map(e => ({ label: e.state, value: e._id }))) : []}
                                            placeholder={"State"}
                                            isClearable={false}
                                            value={modelState ? modelState : [{ label: 'State', value: '' }]}
                                            onChange={(e) => handleModelState(e)}
                                        />
                                        {modelStateErr && <span style={{ "color": "#BE312E" }}> Please select state</span>}
                                        <br /> <br />
                                        <div className='wrap'>
                                            <form>
                                                <div className="file">
                                                    <div className="file__input" id="file__input">
                                                        <input className="file__input--file"
                                                            type="file" multiple="multiple"
                                                            onChange={(event) => handleFile(event)}
                                                            name="files[]" />
                                                        <label className="file__input--label" for="customFile" data-text-btn="Choose Files"> {file ? file.name : "Add file:"}</label>
                                                    </div>
                                                </div>
                                            </form>
                                            <span style={{ "color": "#BE312E" }}>{fileErr}</span>
                                        </div>
                                    </form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-light" id="close" onClick={handleClose} >Close</button>
                                    <button type="button" id="close" className="btn btn-primary" onClick={() => { allDeatils(); }}>Submit</button>
                                </Modal.Footer>
                            </Modal>
                            <div className="row">
                                <div className='col-lg-12'>
                                    <div className='card'>
                                        <div className='card-body' >
                                            <table className="table">
                                                <thead >
                                                    <tr>
                                                        <th>Project</th>
                                                        <th>State</th>
                                                        <th>City</th>
                                                        <th>Pincode</th>
                                                        <th>Role</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> {project22 ? project22.label == "Project" ? "All" : project22.label : "All"}</td>
                                                        <td>{state33 ? state33 == "" ? "All" : state33 : "All"}</td>
                                                        <td> {city ? city : "All"}</td>
                                                        <td>{pincode ? pincode.length == 6 ? pincode : "All" : "All"}</td>
                                                        <td>{freelancerRoleName ? freelancerRoleName == "Role" ? "All" : freelancerRoleName : "All"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-rep-plugin">
                                                <div className="row mb-3 no-gutters">
                                                    <div className="col-md-4    ">
                                                        <form className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                                                            <div className="search-box me-2 mb-4">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control " onChange={(e) => ChangesFRname(e.target.value)} value={frname} placeholder="Search..." />
                                                                    <i className="bx bx-search-alt search-icon"></i>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className='row align-items-center'>
                                                            <div className='col-md-4 col-6 mb-4'>
                                                                <Select
                                                                    options={getProjectTitleRes ? [{ label: 'Project', value: '' }].concat(getProjectTitleRes.map(e => ({ label: e.projectTitle, value: e._id }))) : []}
                                                                    isClearable={false}
                                                                    placeholder={"Project"}
                                                                    value={project22 ? project22.value == "" ? [{ label: 'Project', value: '' }] : project22 : ""}
                                                                    onChange={(e) => { setproject(e.value); setproject22({ label: e.label, value: e.value }) }} />
                                                            </div>
                                                            <div className='col-md-4 col-6 mb-4'>
                                                                <Select
                                                                    options={getstatecityReg ? [{ label: 'State', value: '' }].concat(getstatecityReg.data.map(e => ({ label: e.state, value: e._id }))) : []}
                                                                    placeholder={"State"}
                                                                    value={state == "" ? [{ label: 'State', value: '' }] : state ? [{ label: state33, value: state }] : [{ label: 'State', value: '' }]}
                                                                    onChange={(e) => setcity(e)}
                                                                />
                                                            </div>
                                                            <div className='col-md-4 col-6 mb-4'>
                                                                <Select
                                                                    options={citylist ? [{ label: 'City', value: '' }].concat(citylist.map(e => ({ label: e, value: e }))) : []}
                                                                    value={city == "" ? [{ label: 'City', value: '' }] : city ? [{ label: city, value: city }] : [{ label: 'City', value: '' }]}
                                                                    placeholder={"City"}
                                                                    onChange={(e) => setciti(e.value)} />
                                                            </div>
                                                            <div className='col-md-4 col-6 mb-4'>
                                                                <input value={pincode} className="form-control" maxLength={6} placeholder={"Pincode"} onChange={NumValid} />
                                                            </div>
                                                            <div className='col-md-4 col-6 mb-4'>
                                                                <Select
                                                                    options={getRolesReg ? [{ label: 'Role', value: '' }].concat(getRolesReg.map(e => ({ label: e.role, value: e.roleid }))) : []}
                                                                    placeholder={"Role"}
                                                                    isClearable={false}
                                                                    onChange={(e) => { setFreelancerRole(e.value); setFreelancerRoleName(e.label) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total}  of {getfreelancersReg ? getfreelancersReg.count : ""} entries
                                                <div id="wrapper1" onScroll={() => {
                                                    if (wrapper2) {
                                                        wrapper2.scrollLeft = wrapper1.scrollLeft;
                                                    }
                                                }}>
                                                    <div id="div1">
                                                    </div>
                                                </div>

                                                <div className="table-responsive"
                                                    id="wrapper2" onScroll={() => {
                                                        if (wrapper1) {
                                                            wrapper1.scrollLeft = wrapper2.scrollLeft;
                                                        }
                                                    }}
                                                >
                                                    <div id="div2" className="scrollbar">
                                                        <table id="example" className="table table-striped no-wrap dt-responsive force-overflow">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr No.</th>
                                                                    <th data-priority="2">Name
                                                                        <i onClick={() => Sortt("firstname")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                                                    </th>

                                                                    <th data-priority="1" >Number
                                                                        <i onClick={() => Sortt("phone")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                                                    </th>
                                                                    <th data-priority="5" >Location
                                                                        <i onClick={() => Sortt("address")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                                                    </th>
                                                                    <th data-priority="2" >Role
                                                                        <i onClick={() => Sortt("role_names")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                                                    </th>
                                                                    <th data-priority="5" >Status
                                                                        <i onClick={() => Sortt("status")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}>  </i>
                                                                    </th>
                                                                    <th data-priority="5">Rating
                                                                        <i onClick={() => Sortt("star")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}>  </i>
                                                                    </th>
                                                                    <th data-priority="5">Active/Inactive</th>
                                                                    <th data-priority="5" > Actions </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {allData22 && allData22.map((i, index) =>
                                                                    <tr>
                                                                        <th> {((page * 20) + index + 1) - 20}.</th>
                                                                        <th>
                                                                            <div className="d-flex " >
                                                                                <div className="flex-shrink-0 me-3">
                                                                                    <img className="d-flex-object rounded-circle avatar-xs" alt="" src={i.profiepic ? i.profiepic : blankImg} />
                                                                                </div>
                                                                                <div className="flex-grow-1">
                                                                                    <h5 className="font-weight-bold mb-1">{i.firstname} {i.lastname}</h5>
                                                                                    <p className="text-muted mb-1">
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <td>+91{i.phone}</td>
                                                                        <td>  {i.city}  {!i.city && !i.state ? "-" : ","}  {getstatecityReg ? getstatecityReg.data.filter(j => j._id == i.state)[0] ? getstatecityReg.data.filter(j => j._id == i.state)[0].state : '' : ""}</td>
                                                                        <td>{i.role_names ? i.role_names : '-'}</td>
                                                                        <td>
                                                                            {i.status ? i.status == 1 ? <div style={{ "color": "green" }}>Accepted</div> : i.status == 2 ? <div style={{ "color": "red" }}>Rejected</div> : i.status == 3 ? <div style={{ "color": "grey" }}>Resubmitted</div> : "" : <div style={{ "color": "grey" }}>None</div>}
                                                                        </td>
                                                                        <td>
                                                                            <div className="stars d-flex">
                                                                                {stars.map((_, index) => {
                                                                                    return (
                                                                                        <FaStar
                                                                                            key={index}
                                                                                            size={24}
                                                                                            color={(hoverValue || i ? i.star : "0") > index ? colors.orange : colors.grey}
                                                                                            style={{
                                                                                                marginRight: 3,
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <label className="switch">
                                                                                <input type="checkbox" key={i._id} onClick={(e) => toggleSwitch(e, i)} id="toggleSwitch" checked={i.is_active} defaultChecked={i.is_active} />
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <div className='d-flex align-items-center'>
                                                                                <button className="me-2 btn btn-primary waves-effect waves-light" onClick={() => { handleShow22(); setCurrentValue(i ? i.star : "0"); setFirstname(i); setMessage(i ? i.review : ""); setReviewOne(false) }} key={i._id}>Add Review</button>
                                                                                {/* added by kajal 04-08-2023 */}
                                                                                <button className="me-2 btn btn-primary waves-effect waves-light" 
                                                                                onClick={() => deleteFreelancer(i)}>Delete</button>

                                                                                <i className='cursor-pointer far fa-eye ms-3 font-size-18' onClick={() => {
                                                                                    history('/ProfileFreelancer',
                                                                                        {
                                                                                            state: {
                                                                                                fname: i.firstname, vendor_email: i.vendor_email, dob: i.dob, altrphone: i.altrphone, bankname: i.bankname, roles: i.roles, jobtitle: i.jobtitle, organization: i.organization, stardate: i.stardate, enddate: i.enddate, responsibilities: i.responsibilities, workexperience: i.workexperience, qualification: i.qualification, lname: i.lastname, phone: i.phone, email: i.email, address: i.address, aadhar: i.aadharno, pan: i.panno, bankAC: i.bankacno, ifsc: i.ifsccode,
                                                                                                aadharImage: i.aadhar, panImage: i.pan, bankAcImage: i.cheque, profile: i.profiepic, status: i.status, _id: i._id, rejectReason: i.reason, exam_experiences: i.exam_experiences,
                                                                                                stateid: i.state, city: i.city, pincode: i.pincode, statename: getstatecityReg ? getstatecityReg.data.filter(j => j._id == i.state)[0] ? getstatecityReg.data.filter(j => j._id == i.state)[0].state : '' : ""
                                                                                            }
                                                                                        }

                                                                                    ); localStorage.setItem("projectTitle33", "")
                                                                                    localStorage.setItem("projectTitle44", "Project")
                                                                                }}></i>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <Modal
                                                    show={show22}
                                                    onHide={handleClose22}
                                                    backdrop="static"
                                                    keyboard={false}
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Add Review </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="cursor-pointer rating-div">
                                                            <h2>{firstName.firstname} {firstName.lastname}'s Ratings </h2>
                                                            <div className="stars">
                                                                {stars.map((_, index) => {
                                                                    return (
                                                                        <FaStar
                                                                            key={index}
                                                                            size={24}
                                                                            onClick={() => handleClick(index + 1)}
                                                                            onMouseOver={() => handleMouseOver(index + 1)}
                                                                            onMouseLeave={handleMouseLeave}
                                                                            color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                                                                            style={{
                                                                                marginRight: 10,
                                                                            }}
                                                                        />
                                                                    )
                                                                })}
                                                            </div>
                                                            <div>
                                                                <textarea
                                                                    className="textarea form-control"
                                                                    name="message"
                                                                    value={message}
                                                                    onChange={handleMessage}
                                                                    maxLength={500}
                                                                    placeholder="What's your experience?"
                                                                />
                                                            </div>
                                                            {reviewOne && <span className='mb-3' style={{ "color": "#BE312E", "display": "table", marginTop: "-20px" }}>Please select rating </span>}
                                                            <button className="button" onClick={() => { handleRating(); }}> Submit </button>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                                {allData22 ? allData22.length > 0 ? null : <div style={{ textAlign: 'center' }} ><p>No data found</p> </div> : null}
                                                <div className=' btns-table align-items-center justify-content-end'>
                                                    {getfreelancersReg ? getfreelancersReg.count > 0 ? <div className="row align-items-center wrapper-pg-main">
                                                        <div className='col-6'>
                                                            Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total}  of {getfreelancersReg ? getfreelancersReg.count : ""} entries
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className="wrapper-pgn">
                                                                <div className="pgn pgn-default">
                                                                    <div className="prev " onClick={() => document.getElementById('content').scrollBy(-50, 0)}><i className="bx bx-chevrons-left"></i></div>
                                                                    <div className="pages" id="content">
                                                                        {(() => {
                                                                            const pages = [];
                                                                            for (let i = 1; i < (getfreelancersReg.count / 20) + 1; i++) {
                                                                                pages.push(<div className={page == i ? "page page-btn active" : "page page-btn"} onClick={() => { Apicall((i - 1) * 20); setpage(i); setIndexCh(indexCh + 1) }}>{i}</div>);
                                                                            }
                                                                            return pages;
                                                                        })()}
                                                                    </div>
                                                                    <div onClick={() => document.getElementById('content').scrollBy(50, 0)} className=" next "><i className="bx bx-chevrons-right"></i></div>
                                                                </div> </div>
                                                        </div>
                                                    </div> : null : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    © Edu-Test All Rights Reserved {(new Date().getFullYear())}.
                                </div>
                                <div className="col-sm-6">
                                    <div className="text-sm-end d-none d-sm-block">
                                        Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

                <div className="right-bar">
                    <div data-simplebar className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2">Settings</h5>
                            <a className="right-bar-toggle ms-auto">
                                <i className="mdi mdi-close noti-icon"></i>
                            </a>
                        </div>
                        <hr className="mt-0" />
                        <h6 className="text-center mb-0">Choose Layouts</h6>
                        <div className="p-4">
                            <div className="mb-2">
                                <img src="images/layouts/layout-1.jpg" className="img-thumbnail" alt="layout images" />
                            </div>
                            <div className="form-check form-switch mb-3">
                                <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" />
                                <label className="form-check-label" htmlFor="light-mode-switch">Light Mode</label>
                            </div>
                            <div className="mb-2">
                                <img src="images/layouts/layout-2.jpg" className="img-thumbnail" alt="layout images" />
                            </div>
                            <div className="form-check form-switch mb-3">
                                <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" />
                                <label className="form-check-label" htmlFor="dark-mode-switch">Dark Mode</label>
                            </div>
                            <div className="mb-2">
                                <img src="images/layouts/layout-3.jpg" className="img-thumbnail" alt="layout images" />
                            </div>
                            <div className="form-check form-switch mb-3">
                                <input className="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch" />
                                <label className="form-check-label" htmlFor="rtl-mode-switch">RTL Mode</label>
                            </div>
                            <div className="mb-2">
                                <img src="images/layouts/layout-4.jpg" className="img-thumbnail" alt="layout images" />
                            </div>
                            <div className="form-check form-switch mb-5">
                                <input className="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch" />
                                <label className="form-check-label" htmlFor="dark-rtl-mode-switch">Dark RTL Mode</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightbar-overlay"></div>
            </div>
        </>
    )
}

export default Dashbord2;