import MainDashbord from "../Sidebar/MainDashbord";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { sendMessageAPI, getCentersByProjectIdAPI, dispatchLoadingStatus, getstatecityAPI, getProjectTitleAPI, getRolesAPI } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProjectTitleRes, centersbyfreelanceprojectIdRes } from "../../Constant";
import Loader from "../Loader/Loader";
import swal from "sweetalert";

function Sendmessage() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  function handleMessage22(e) {
    if (window.location.href.includes("Sendmessage")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          history(e);
        } 
      });
    }
  }

  useEffect(() => {
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");

    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");

    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getProjectTitleAPI(formData));

    dispatch(getRolesAPI(formData));
  }, []);

  useEffect(() => {
    dispatch({ type: getProjectTitleRes, payload: null });
    dispatch({ type: centersbyfreelanceprojectIdRes, payload: null });
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
  }, []);

  const [Message, setMessage] = useState("");
  const [MessageError, setMessageError] = useState(false);
  const [MessageError22, setMessageError22] = useState(false);

  const [examType, setExamType] = useState();
  const [projectIdError, setProjectIdError] = useState(false);
  const [centerId, setCenterId] = useState("");

  const [centerIdError, setCenterIdError] = useState(false);
  const [freelancerRole, setFreelancerRole] = useState("");

  const [freelancerRoleDDL, setFreelancerRoleDDL] = useState("");
  const [freelancerRoleError, setFreelancerRoleError] = useState(false);
  const [projectId, setProjectId] = useState("");

  const handleFreelancerRole = (e) => {
    setFreelancerRoleDDL(e);
    if (e.value) {
      setFreelancerRole(e.value);
      setFreelancerRoleError(false);
    } else {
      setFreelancerRole("");
      setFreelancerRoleError(true);
    }
  };

  const handleMessage = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setMessage(e.target.value);
        setMessageError22(false);
        setMessageError(false);
      } else {
        setMessage("");
        setMessageError(false);
        setMessageError22(true);
      }
    } else {
      setMessage("");
      setMessageError(true);
      setMessageError22(false);
    }
  };

  const handleProjectId = (e) => {
    if (e) {
      setProjectId(e.value);

      var filter22 = getRolesReg.filter((i) => i.examType == e.examType);
      if (filter22.length > 0) {
        setExamType(filter22);
        setFreelancerRole("");
        setFreelancerRoleDDL("");
      }

      if (e.value) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("project_id", e.value);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getCentersByProjectIdAPI(formData));
        setProjectIdError(false);
        setCenterId("");
      }
      setProjectIdError(false);
    } else {
      setProjectId("");
      setProjectIdError(true);
    }
  };

  const handleCenterId = (e) => {
    if (e.length > 0) {
      var isall = e[e.length - 1].value == "all" ? true : false;
      var filter = e.filter((i) => i.value == "all");
      if (isall && filter.length > 0) {
        setCenterId(filter);
        setCenterIdError(false);
      } else {
        if (filter.length > 0) {
          let index = e.indexOf(filter[0]);
          e.splice(index, 1);
          setCenterId(e);
          setCenterIdError(false);
        } else {
          setCenterId(e);
          setCenterIdError(false);
        }
      }
    } else {
      setCenterId(e);
      setCenterIdError(true);
    }
  };

  const projectddlres = useSelector((state) => state.globalReducer.getProjectTitleRes);
  const getRolesReg = useSelector((state) => state.globalReducer.getRolesReg);
  const getCentersByProjectIdRes = useSelector((state) => state.globalReducer.getCentersByProjectIdRes);

  const allDeatils = () => {
    if (!projectId) {
      setProjectIdError(true);
    }

    if (!centerId) {
      setCenterIdError(true);
    }

    if (!freelancerRole) {
      setFreelancerRoleError(true);
    }

    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;

    if (Message) {
      if (re.test(Message)) {
        setMessageError22(false);
        setMessageError(false);
      } else {
        setMessageError(false);
        setMessageError22(true);
      }
    } else {
      setMessageError(true);
      setMessageError22(false);
    }

    if (projectId && centerId && freelancerRole && Message) {
      let formData = new FormData();

      let centerIds = centerId.map((i) => i.value);
      if (centerIds.toString() == "all") {
        centerIds = getCentersByProjectIdRes ? getCentersByProjectIdRes.data.map((i) => i._id) : "";
      }

      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", projectId);
      formData.append("center_ids", centerIds.toString());
      formData.append("freelancer_role_id", freelancerRole);
      formData.append("message", Message);
      dispatch(dispatchLoadingStatus(true));
      dispatch(sendMessageAPI(formData));
    }
  };

  return (
    <>
      <MainDashbord />
      <Loader />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Add Message</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => handleMessage22("/Message")} className="breadcrumb-item active">
                          Messages list
                        </a>
                        <a href="/Sendmessage" className="breadcrumb-item active">
                          Add message
                        </a>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-rep-plugin">
                      <div className="row mt-5">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label">Select Project *</label>
                            <Select options={projectddlres ? [{ label: "Project title", value: "" }].concat(projectddlres.map((e) => ({ label: e.projectTitle, value: e._id, examType: e.examType }))) : []} isClearable={false} placeholder={"Project title"} onChange={(e) => handleProjectId(e)} />
                            {projectIdError && <span style={{ color: "#BE312E" }}>Please select project</span>}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label">Center Name *</label>
                            <Select options={getCentersByProjectIdRes ? [{ label: "All", value: "all" }].concat(getCentersByProjectIdRes.data.map((e) => ({ label: e.center_name, value: e._id, _id: e._id }))) : []} isClearable={true} placeholder={"Select"} isMulti onChange={(e) => handleCenterId(e)} value={centerId} />
                            {centerIdError && <span style={{ color: "#BE312E" }}>Please select center *</span>}
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="mb-4">
                            <label className="form-label">Select Role *</label>
                            <Select
                              options={examType ? [{ label: "Select role", value: "" }].concat(examType.map((e) => ({ label: e.role, value: e.roleid }))) : []}
                              value={freelancerRoleDDL}
                              placeholder={"Select"}
                              isClearable={false}
                              onChange={(e) => handleFreelancerRole(e)}
                            />
                            {freelancerRoleError && <span style={{ color: "#BE312E" }}>Please select role</span>}
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="mb-4">
                            <label className="form-label">Write Message *</label>
                            <textarea className="form-control" name="Message" value={Message} rows={2} onChange={(e) => handleMessage(e)} placeholder="Write Message" maxLength={500} />
                            {MessageError && <span style={{ color: "#BE312E" }}>Please write message</span>}
                            {MessageError22 && <span style={{ color: "#BE312E" }}>Please write valid message</span>}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="text-center d-block m-auto">
                            <button
                              type="button"
                              className="btn btn-primary btn-md    waves-effect waves-light"
                              data-bs-toggle="modal"
                              data-bs-target=".transaction-detailModal"
                              onClick={() => {
                                allDeatils();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sendmessage;
