
import MainDashbord from "../Sidebar/MainDashbord";
import React, { useState, useEffect, } from 'react';
import { addEditAdminUserAPI, dispatchLoadingStatus, getstatecityAPI, } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getProjectTitleRes, centersbyfreelanceprojectIdRes } from '../../Constant';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckedImg from '../../assets/images/checked.svg'
import UnCheckedImg from '../../assets/images/checkbox-empty.svg'
import Loader from '../Loader/Loader';
import swal from 'sweetalert';



function AssignRole() {

  const dispatch = useDispatch({
  });
  let history = useNavigate();

  function handleMessage(e) {
    if (window.location.href.includes("AssignUser") || window.location.href.includes("EditUser")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            history(e)
          } 
        });
    } else {
    }

  }

  useEffect(() => {
    localStorage.setItem("projectTitle", "")
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "")
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem('token'));
  }, [])

  useEffect(() => {
    dispatch({ type: getProjectTitleRes, payload: null });
    dispatch({ type: centersbyfreelanceprojectIdRes, payload: null });
    dispatch(dispatchLoadingStatus(true))
    dispatch(getstatecityAPI());
  }, [])


  const [Password, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState(false);
  const [ConformPassword, setConformPassword] = useState("");
  const [ConformPasswordError, setConformPasswordError] = useState(false);
  const [BothPasswordError, setBothPasswordError] = useState(false);
  const [eyes, setEyes] = useState(false);
  const [eyes22, setEyes22] = useState(false);
  const [PasswordError22, setPasswordError22] = useState(false);
  const [ConformPasswordError22, setConformPasswordError22] = useState(false);


  const toggleBtn = () => {
    setEyes(preState => !preState);
  }

  const toggleBtn22 = () => {
    setEyes22(preState => !preState);
  }

  function handlePassword(e) {
    if (e) {
      setPassword(e.target.value.trim() == "" ? "" : e.target.value);
    }
    else {
      setPassword("");
    }
  }

  function handleConformPassword(e) {
    if (e) {
      setConformPassword(e.target.value.trim() == "" ? "" : e.target.value);
    }
    else {
      setConformPassword("");
    }
  }

  const [rolearr, setrole] = useState([]);
  const [roleError, setRoleError] = useState("");

  function checkboxClick(id) {
    if (rolearr) {
      if (rolearr.includes(id)) {
        let xx = [...rolearr]
        const index = xx.indexOf(id);
        if (index > -1) { // only splice array when item is found
          xx.splice(index, 1) // 2nd parameter means remove one item only
        }
        setrole(xx)
      } else {
        setrole([...rolearr, id])
      }
    }
  }

  
const [All, setAll] = useState(false)


  const roleee = [
    {
      label: "Dashboard",
      value: "Dashboard"
    },
    {
      label: "Freelancers",
      value: "Freelancers"
    },
    {
      label: "Projects",
      value: "Projects"
    },
    {
      label: "Centers",
      value: "Centers"
    },
    {
      label: "Assign",
      value: "Assign"
    },
    {
      label: "Attendance",
      value: "Attendance"
    },
    {
      label: "Message",
      value: "Message"
    },
    {
      label: "Audits",
      value: "Audits"
    },
    {
      label: "Expenses",
      value: "Expenses"
    },
    {
      label: "Admin User Management",
      value: "Role Management"
    },
    {
      label: "Reports",
      value: "Report"
    },
  ]


  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false)
  const [Name, setName] = useState("");
  const [NameError, setNameError] = useState(false)
  const [NameError22, setNameError22] = useState(false)
  const [NameError33, setNameError33] = useState(false)
  const [Mobile, setMobile] = useState("");
  const [MobileError, setMobileError] = useState(false)
  const [MobileError22, setMobileError22] = useState(false)


  const handleEmail = (e) => {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);
    setEmailError("");
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function handleName(e) {
    if (e) {
      setName(e.target.value.trim() == "" ? "" : e.target.value);
    }
    else {
      setName("");
    }
  }

  function handleMobile(e) {
    if (e) {
      setMobile(e.target.value.trim() == "" ? "" : e.target.value);
    }
    else {
      setMobile("");
    }
  }

  const allDeatils = () => {
    if (email) {
      if (!isValidEmail(email)) {
        setEmailError(<span style={{ "color": "#BE312E" }}>Please enter valid email</span>);
      }
    }
    else {
      setEmailError(<span style={{ "color": "#BE312E" }}>Please enter email</span>);
    }
    let nameRe = /^[a-zA-Z\s]*$/;

    if (Name) {
      if (Name.length >= 3) {
        setNameError22(false)
        setNameError(false)
        setNameError33(false)

      if (!nameRe.test(Name)) {
        setNameError22(true)
        setNameError(false)
        setNameError33(false)
      }
      else {
        setNameError22(false)
        setNameError(false)
        setNameError33(false)
      }
    }
      else {
        setNameError22(false)
        setNameError(false)
        setNameError33(true)
      }
    }
    else {
      setNameError(true)
      setNameError22(false)
      setNameError33(false)
    }


    let MobileRe = /^[0-9\b]+$/;

    if (Mobile) {
      if (!MobileRe.test(Mobile)) {
        setMobileError22(true)
        setMobileError(false)
      }
      else {
        setMobileError22(false)
        setMobileError(false)
      }
    }
    else {
      setMobileError(true)
      setMobileError22(false)
    }

    if (Password) {
      if(Password.length >= 6 && Password.length <= 16 ){
        setPasswordError22(false);
        setPasswordError(false)
      }
      else{
        setPasswordError22(true);
       setPasswordError(false)
      }
    }
    else {
      setPasswordError(true)
      setPasswordError22(false);
    }

    if (ConformPassword) {
      if(ConformPassword.length >= 6  && ConformPassword.length <= 16){
        setConformPasswordError22(false);
        setConformPasswordError(false)
      }
      else{
        setConformPasswordError22(true);
        setConformPasswordError(false)
      }
    }
    else {
      setConformPasswordError(true)
      setConformPasswordError22(false);
    }

    if (rolearr.length > 0) {
      setRoleError(false);
    }
    else {
      setRoleError(true);
    }

    if (isValidEmail(email) && nameRe.test(Name) && Name.length >= 3 && MobileRe.test(Mobile)  && Password.length >= 6 && Password.length <= 16 && ConformPassword.length >= 6  && ConformPassword.length <= 16 && rolearr.length > 0) {
      if (Password == ConformPassword) {
        setBothPasswordError(false)
        let formData = new FormData();
        formData.append('token', localStorage.getItem('token'));
        formData.append('email', email);
        formData.append('mobile', Mobile);
        formData.append('name', Name);
        formData.append('roles', rolearr.toString());
        formData.append('password', Password);
        formData.append('isAllDataAccess', All);
        formData.append('confirm_password', ConformPassword);
        dispatch(dispatchLoadingStatus(true))
        dispatch(addEditAdminUserAPI(formData));
      }
      else {
        setBothPasswordError(true)
      }
    }
  }

  const addEditAdminUserRes = useSelector(state => state.globalReducer.addEditAdminUserRes);

  useEffect(() => {
    setRoleError("");
  }, [rolearr])

  useEffect(() => {
    if(addEditAdminUserRes){
      history('/AdminUserManagement')
    }
  }, [addEditAdminUserRes])


  return (
    <>
      <MainDashbord />
      <Loader />
      <div className="main-content" >
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Assign User</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={()=> handleMessage("/AdminUserManagement")} className="breadcrumb-item active">Admin User Management</a><a href='/AssignUser' className="breadcrumb-item active">Assign User</a>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-rep-plugin">
                      <div className="row mt-5">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label">Email *</label>
                            <input type="text" className="form-control"  autoComplete="off" placeholder="Enter Email" value={email} onChange={(e) => handleEmail(e)} maxLength={320} />
                            {emailError}
                          </div>
                          <div className="mb-4">
                            <label className="form-label">Name *</label>
                            <input type="text" className="form-control" placeholder="Enter Name" value={Name} onChange={(e) => handleName(e)} maxLength={30}/>
                            {NameError && <span style={{ "color": "#BE312E" }}>Please enter name</span>}
                            {NameError22 == true ? NameError22 && <span style={{ "color": "#BE312E" }}>Please enter valid name</span>
                             :  NameError33 && <span style={{ "color": "#BE312E" }}>Please enter minimum 3 character name</span>}

                          </div>
                          <div className="mb-4">
                            <label className="form-label">Mobile *</label>
                            <input type="text" className="form-control" name="Mobile" autoComplete="false"  placeholder="Enter Mobile"  value={Mobile} maxLength='10' onChange={(e) => handleMobile(e)} />
                            {MobileError && <span style={{ "color": "#BE312E" }}>Please enter mobile </span>}
                            {MobileError22 && <span style={{ "color": "#BE312E" }}>Please enter valid mobile</span>}


                          </div>
                        </div>
                       
                        <div className="col-md-6 mb-3">
                          <label htmlFor="username" className="form-label">Preferred Roles *</label>
                          <div className='row'>

                          {roleee && roleee.map((i) => {
                            return (
                                <div className='col-md-6'>
                                 <div className="form-check mb-2 ps-0" onClick={() => checkboxClick(i.value)}>
                                    {
                                      rolearr && rolearr.includes(i.value) ?
                                        <img src={CheckedImg} width={16} height={16} /> :
                                        <img src={UnCheckedImg} width={16} height={16} />
                                    }

                                    <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor={i.label}>
                                      {i.label}
                                    </label>
                                  </div>
                                </div>
                              
                            )
                          })}
                          {roleError && <span style={{ "color": "#BE312E" }}>Please select Role</span>}
                          </div>

                          <label htmlFor="username" className="form-label mt-3">Allowed Data </label>
                          <div className="form-check mb-2 ps-0" >


                          <div className='col-md-6'>
                                 <div className="form-check mb-2 ps-0" onClick={() => setAll(!All)}>
                                 {
                                      All && All == true ?
                                        <img src={CheckedImg} width={16} height={16} /> :
                                        <img src={UnCheckedImg} width={16} height={16} />
                                }
                                
                                <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="All">
                                      All
                                    </label>
                                </div>
    </div>
                                  </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-6">
                        <div className="mb-4">
                            <label className="form-label">Password *</label>
                            <div class="input-group auth-pass-inputgroup"><input type={eyes ? "text" : "password"} autoComplete="new-password" id="Passwordd" className="form-control" placeholder="Enter Password" value={Password} onChange={(e) => handlePassword(e)} maxLength={16}/>
                              <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn}>
                                {
                                  eyes ? <Visibility style={{ width: "18", height: "18" }} /> : <VisibilityOff style={{ width: "18", height: "18" }} />
                                }
                              </button>
                            </div>
                            {PasswordError && <span style={{ "color": "#BE312E" }}>Please enter password</span>}
                            {PasswordError22 && <span style={{ "color": "#BE312E" }}>Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters</span>}

                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="username" className="form-label">Confirm Password *</label>
                            <div class="input-group auth-pass-inputgroup">
                              <input type={eyes22 ? "text" : "password"} className="form-control" placeholder="Enter Confirm Password" value={ConformPassword} onChange={(e) => handleConformPassword(e)} maxLength={16} />
                              <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn22}>
                                {
                                  eyes22 ? <Visibility style={{ width: "18", height: "18" }} /> : <VisibilityOff style={{ width: "18", height: "18" }} />
                                }
                              </button>
                            </div>
                            {ConformPasswordError && <span style={{ "color": "#BE312E" }}>Please enter confirm password</span>}
                            {ConformPasswordError22 == true ? ConformPasswordError22 && <span style={{ "color": "#BE312E" }}>Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters</span> :
                            BothPasswordError && <span style={{ "color": "#BE312E" }}>Password and Confirm Password does not match</span> }
                            
                          </div>
                          </div>
                        </div>


                        <div className="col-md-6 mb-3">
                          <div className='row'>
                                <div className='col-md-6'>
                                
                                </div>
                          
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="text-center d-block m-auto">
                            <button type="button" className="btn btn-primary btn-md    waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".transaction-detailModal" onClick={() => { allDeatils(); }}>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default AssignRole;