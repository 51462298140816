// All Validation is Done This Page

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStatesCitiesByProjectIdAPI, getAssignProjectAPI, pendingCentersByFreelancerProjectIdAPI, getProjectTitleAPI, getstatecityAPI, getRolesAPI, dispatchLoadingStatus } from "../../API/Action/globalAction";
import moment from "moment";
import Select from "react-select";
import Loader from "../Loader/Loader";
import MainDashbord from "../Sidebar/MainDashbord";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

function AssignAction() {
  const dispatch = useDispatch({});

  let history = useNavigate();

  const { state } = useLocation();
  const { freelancerId, firstname, statee, city } = state;

  const [startDate, setStartdate] = useState(new Date());

  function handleMessage(e) {
    if (window.location.href.includes("Assignaction")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          history(e);
        } else {
        }
      });
    } else {
    }
  }

  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartdateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [projectIdError, setProjectIdError] = useState(false);
  const [centerId, setCenterId] = useState("");
  const [centerIdError, setCenterIdError] = useState(false);
  const [freelancerRole, setFreelancerRole] = useState("");
  const [freelancerRoleDDL, setFreelancerRoleDDL] = useState("");
  const [freelancerRoleError, setFreelancerRoleError] = useState(false);
  const [examType, setExamType] = useState();
  const [PendingCenters, setPendingCenters] = useState();
  const [state22, setstate22] = useState("");
  const [state33, setstate33] = useState("");
  const [cityNew, setciti] = useState("");
  const [citylist22, setcitylist22] = useState([]);

  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);

  function handleCity(e) {
    if (e.value == "") {
      setciti("");
      if (projectId && freelancerRole && freelancerId) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("freelancerId", freelancerId);
        formData.append("freelancer_role_id", freelancerRole);
        formData.append("project_id", projectId);
        formData.append("state_id", state22 ? state22 : "");
        formData.append("city", e.value);
        dispatch(pendingCentersByFreelancerProjectIdAPI(formData));
      }
      setCenterId("");
    } else {
      setciti(e.value);

      if (projectId && freelancerRole && freelancerId) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("freelancerId", freelancerId);
        formData.append("freelancer_role_id", freelancerRole);
        formData.append("project_id", projectId);
        formData.append("state_id", state22 ? state22 : "");
        formData.append("city", e.value);

        dispatch(pendingCentersByFreelancerProjectIdAPI(formData));
      }
      setCenterId("");
    }
  }

  function setStateCenter(e) {
    if (e.value == "") {
      setstate22("");
      setcitylist22("");
      setciti("");

      if (projectId && freelancerRole && freelancerId) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("freelancerId", freelancerId);
        formData.append("freelancer_role_id", freelancerRole);
        formData.append("project_id", projectId);
        formData.append("state_id", e.value);
        formData.append("city", cityNew ? cityNew : "");
        dispatch(pendingCentersByFreelancerProjectIdAPI(formData));
      }
    } else {
      if (getstatecityReg) {
        var filter = getstatecityReg.data.filter((i) => i._id == e.value);
        setstate22(e.value);
        setstate33(filter.length > 0 ? filter[0].state : []);
        if (filter && e.value) {
          setcitylist22(filter[0].districts);
          setciti("");
          if (projectId && freelancerRole && freelancerId) {
            let formData = new FormData();
            formData.append("token", localStorage.getItem("token"));
            formData.append("freelancerId", freelancerId);
            formData.append("freelancer_role_id", freelancerRole);
            formData.append("project_id", projectId);
            formData.append("state_id", e.value);
            formData.append("city", cityNew ? cityNew : "");
            dispatch(pendingCentersByFreelancerProjectIdAPI(formData));
          }
          setCenterId("");
        } else {
          setcitylist22([]);
        }
      }
    }
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("freelancerId", freelancerId);
    dispatch(dispatchLoadingStatus(true));
    dispatch(getProjectTitleAPI(formData));
    dispatch(getRolesAPI(formData));
  }, []);

  let getRolesReg = useSelector((state) => state.globalReducer.getRolesReg);
  const projectddlres = useSelector((state) => state.globalReducer.getProjectTitleRes);
  const pendingCentersByFreelancerProjectIdRes = useSelector((state) => state.globalReducer.pendingCentersByFreelancerProjectIdRes);
  let getStatesCitiesByProjectIdRes = useSelector((state) => state.globalReducer.getStatesCitiesByProjectIdRes);

  useEffect(() => {
    if (pendingCentersByFreelancerProjectIdRes) {
      if (pendingCentersByFreelancerProjectIdRes.errorcode == 1) {
        setPendingCenters([]);
      } else {
        setPendingCenters(pendingCentersByFreelancerProjectIdRes ? pendingCentersByFreelancerProjectIdRes.data : []);
      }
    }
  }, [pendingCentersByFreelancerProjectIdRes]);

  useEffect(() => {
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
  }, []);

  const handleProjectId = (e) => {
    if (e) {
      if (e.value) {
        setProjectId(e.value);
        setPendingCenters("");
        setCenterId("");
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("project_id", e.value);
        dispatch(getStatesCitiesByProjectIdAPI(formData));
        setstate22("");
        setciti("");
        setcitylist22([]);
      }

      setEndDate("");
      var filter22 = getRolesReg.filter((i) => i.examType == e.examType);
      if (filter22.length > 0) {
        setExamType(filter22.filter((i) => i.is_active == true));
        setFreelancerRole("");
        setFreelancerRoleDDL("");
      }
      setProjectIdError(false);
    } else {
      setProjectId("");
      setProjectIdError(true);
    }
  };

  const handleCenterId = (e) => {
    if (e.length > 0) {
      var isall = e[e.length - 1].value == "0" ? true : false;
      var filter = e.filter((i) => i.value == "0");
      if (isall && filter.length > 0) {
        setCenterId(filter);
        setCenterIdError(false);
      } else {
        if (filter.length > 0) {
          let index = e.indexOf(filter[0]);
          e.splice(index, 1);
          setCenterId(e);
          setCenterIdError(false);
        } else {
          setCenterId(e);
          setCenterIdError(false);
        }
      }
    } else {
      setCenterId(e);
      setCenterIdError(true);
    }
  };

  const handleFreelancerRole = (e) => {
    setFreelancerRoleDDL(e);
    if (e.value) {
      setFreelancerRole(e.value);
      setFreelancerRoleError(false);
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("freelancerId", freelancerId);
      formData.append("freelancer_role_id", e.value);
      formData.append("project_id", projectId);
      formData.append("state_id", state22 ? state22 : "");
      formData.append("city", cityNew ? cityNew : "");
      dispatch(dispatchLoadingStatus(true));
      dispatch(pendingCentersByFreelancerProjectIdAPI(formData));
      setCenterId("");
    } else {
      setFreelancerRole("");
      setFreelancerRoleError(true);
    }
  };

  const allDeatils = () => {
    if (!startDate) {
      setStartdateError(true);
    }

    if (!endDate) {
      setEndDateError(true);
    }

    if (!projectId) {
      setProjectIdError(true);
    }

    if (!centerId) {
      setCenterIdError(true);
    }

    if (!freelancerRole) {
      setFreelancerRoleError(true);
    }

    if (projectId && centerId.length > 0 && startDate && endDate && freelancerRole) {
      let formData = new FormData();
      let centerIds = centerId.map((i) => i.value);
      if (centerIds.toString() == "0") {
        centerIds = pendingCentersByFreelancerProjectIdRes ? pendingCentersByFreelancerProjectIdRes.data.map((i) => i._id) : "";
      }
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", projectId);
      formData.append("freelancer_id", freelancerId);
      formData.append("freelancer_role_id", freelancerRole);
      formData.append("freelancer_name", firstname.replace(/  +/g, " "));
      formData.append("center_id", centerIds.toString());
      formData.append("assign_date", moment(startDate).format("DD/MM/yyyy"));
      formData.append("due_date", moment(endDate).format("DD/MM/yyyy"));
      dispatch(dispatchLoadingStatus(true));
      dispatch(getAssignProjectAPI(formData));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      if (!window.location.href.includes("Assignaction")) {
        window.location.href = window.location.origin + "/Assignaction";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);
  return (
    <>
      <div id="layout-wrapper">
        <Loader />
        <MainDashbord />
        <ToastContainer />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Assign Action</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => handleMessage("/Addpeople")} className="breadcrumb-item active">
                          Assign
                        </a>
                        <a href="/Assignaction" className="breadcrumb-item active">
                          Assign Action
                        </a>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-4"></div>
                        </div>

                        <div className="row no-gutters">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Freelancer Name *
                              </label>
                              <input type="text" className="form-control" value={firstname.replace(/  +/g, " ")} placeholder="Freelancer Name" disabled />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                State *
                              </label>
                              <input type="text" className="form-control" value={statee ? statee : ""} placeholder="State Name" disabled />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                City *
                              </label>
                              <input type="text" className="form-control" value={city ? city : ""} placeholder="City Name" disabled />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Project Title *</label>

                              <Select options={projectddlres ? projectddlres.map((e) => ({ label: e.projectTitle, value: e._id, examType: e.examType })) : []} isClearable={false} placeholder={"Select"} onChange={(e) => handleProjectId(e)} />
                              {projectIdError && <span style={{ color: "#BE312E" }}>Please select project</span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Freelancers Role *</label>
                              <Select options={examType && examType.map((e) => ({ label: e.role, value: e.roleid }))} value={freelancerRoleDDL} placeholder={"Select"} isClearable={false} onChange={(e) => handleFreelancerRole(e)} />
                              {freelancerRoleError && <span style={{ color: "#BE312E" }}>Please select role</span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Select State </label>

                              <Select options={getStatesCitiesByProjectIdRes ? [{ label: "State", value: "" }].concat(getStatesCitiesByProjectIdRes.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} value={state22 == "" ? [{ label: "State", value: "" }] : state22 ? [{ label: state33, value: state22 }] : [{ label: "State", value: "" }]} onChange={(e) => setStateCenter(e)} />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Select City </label>

                              <Select options={citylist22 ? [{ label: "City", value: "" }].concat(citylist22.map((e) => ({ label: e, value: e }))) : []} placeholder={"City"} value={cityNew == "" ? [{ label: "City", value: "" }] : cityNew ? [{ label: cityNew, value: cityNew }] : [{ label: "City", value: "" }]} onChange={(e) => handleCity(e)} />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Center Name *</label>

                              <Select options={PendingCenters ? (PendingCenters.length > 0 ? [{ label: "All", value: "0" }].concat(PendingCenters.map((e) => ({ label: e.center_name, value: e._id, _id: e._id }))) : []) : []} isClearable={true} placeholder={"Select"} isMulti closeMenuOnSelect={false} hideSelectedOptions={true} onChange={(e) => handleCenterId(e)} value={centerId} />
                              {centerIdError && <span style={{ color: "#BE312E" }}>Please select center </span>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-4">
                              <label>Assign Date *</label>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setStartdate(value);
                                  setStartdateError(false);
                                }}
                                selected={startDate ? startDate : null}
                                value={startDate}
                                placeholderText={"dd/mm/yyyy"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                maxDate={endDate}
                                minDate={moment().toDate()}
                              />
                              {startDateError && <span style={{ color: "#BE312E" }}>Please select assign date</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label>Due Date *</label>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setEndDate(value);
                                  setEndDateError(false);
                                }}
                                selected={endDate ? endDate : null}
                                placeholderText={"dd/mm/yyyy"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                minDate={startDate}
                              />

                              {endDateError && <span style={{ color: "#BE312E" }}>Please select due date</span>}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="text-center d-block m-auto">
                              <button type="button" className="btn btn-outline-primary btn-md  waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".transaction-detailModal" onClick={() => history("/Addpeople")}>
                                Back
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary btn-md   ms-2   waves-effect waves-light"
                                data-bs-toggle="modal"
                                data-bs-target=".transaction-detailModal"
                                onClick={() => {
                                  allDeatils();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default AssignAction;
