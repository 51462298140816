
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { LoginAPI , dispatchLoadingStatus  } from '../../API/Action/globalAction';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loader from '../Loader/Loader';
import { toast  } from 'react-toastify';
import CheckedImg from '../../assets/images/checked.svg'
import UnCheckedImg from '../../assets/images/checkbox-empty.svg'
import logo from '../../assets/images/logo.png'
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

function AdminLogin() {
    let userRem = localStorage.getItem('user');
    let passwordRem = localStorage.getItem('password'); 


    const [email, setEmail] = useState(userRem);
    const [password, setPassword] = useState(passwordRem);
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [eyes, setEyes] = useState(false);
    const [remember, setRemember] = useState(false);

    const toggleBtn = () => {
        setEyes(preState => !preState);
    }

    const dispatch = useDispatch({
    });

    let history = useNavigate();

    let AdminLoginReg = useSelector(state => state.globalReducer.AdminLoginReg);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value) {
            if(!isValidEmail(e.target.value)){
                setEmailError(<p className='error'>Email not valid</p>)
            }
            else{
                setEmailError("")
            }
        }
        else{
            setEmailError(<p className='error'>Email is required</p>);
        }
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
        if (!e.target.value ) {
            setPasswordError(<p className='error'>Password is required</p>);
        }
        else {
            setPasswordError("");
        }
    }


    const Login = (e) => {
        e.preventDefault();
        if (email) {
            if(!isValidEmail(email)){
                setEmailError(<p className='error'>Email not valid</p>)
            }
            else{
                setEmailError("")
            }
        }
        else{
            setEmailError(<p className='error'>Email is required</p>);
        }
        if (!password ) {
            setPasswordError(<p className='error'>Password is required</p>);
        }
        else {
            setPasswordError("");
        }

        if (email && password) {
            localStorage.setItem('user', remember ? email : '');
            localStorage.setItem('password', remember ? password : '');
            localStorage.setItem('isremember', remember);
            let formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            dispatch(dispatchLoadingStatus(true))
            dispatch(LoginAPI(formData,email))
        }
    }

  
    useEffect(() => {
        if (AdminLoginReg) {
            history('/Dashboard');
        }
    }, [AdminLoginReg])

    useEffect(() => {
            localStorage.removeItem("resendValue");
            localStorage.removeItem('sendEmail');
            localStorage.removeItem('adminemail');
            localStorage.removeItem('otp_id');
            localStorage.removeItem('admin_id');   
            localStorage.removeItem('token');  
        const token = localStorage.getItem('token');

        if (token) {
            if (!window.location.href.includes('Freelancers')) {
                window.location.href = window.location.origin + '/Freelancers'
            }
        } else {
            if (!window.location.href.includes('/Login')) {
                window.location.href = window.location.origin + '/Login'
            }
            var isremember = localStorage.getItem('isremember')
            if(isremember){
                setEmail(localStorage.getItem('user'))
                setPassword(localStorage.getItem('password'))
            }else{
                setEmail('')
                setPassword('')
            }
            setRemember(localStorage.getItem('isremember')=="true"?true:false)
        }
    }, [])
    return (




        <div className="account-pages my-5 pt-sm-5">
            <Loader/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="card-body ">
                                <div className="auth-logo">
                                    <a href="" className="auth-logo-dark">
                                        <div className=" profile-user-wid mb-4">
                                            <span className="logo ">
                                                <img src={logo} alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" >
                                        <div className="mb-3">
                                            <label htmlFor="username" className="form-label">Email</label>
                                            <input type="text" className="form-control" id="username" placeholder="Enter Email" value={email} onChange={handleEmail} />
                                            {emailError}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input type={eyes ? "text" : "password"} className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" value={password} onChange={handlePassword} />
                                                <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn}>
                                                    {
                                                        eyes ? <Visibility style={{width : "18" ,height : "18"}}/> : <VisibilityOff style={{width : "18" ,height : "18"}}/>
                                                    }
                                                </button>
                                            </div>
                                            {passwordError}
                                        </div>
                                        <div className="form-check">
                                        {
                                                    remember ?
                                                    <img onClick={() => setRemember(!remember)} className="form-check-input" src={CheckedImg} style={{height:15,width:15}} /> :
                                                    <img onClick={() => setRemember(!remember)} className="form-check-input" src={UnCheckedImg} style={{height:15,width:15}} />
                                                }
                                            <label onClick={() => setRemember(!remember)} className="form-check-label" htmlFor="remember-check">
                                                Remember me
                                            </label>
                                        </div>

                                        <div className="mt-3 d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={Login}>Log In</button>
                                        </div>

                                        <div className="mt-4 text-center">
                                            <a href='/ForgetPassword' className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  
    )
}

export default AdminLogin;