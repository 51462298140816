import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainDashbord from "../Sidebar/MainDashbord";
import { centerDataAPI, updateCentersLatLongAPI, CenterAddReviewAPI, importCenterAPI, changeCenterIsActiveAPI, dispatchLoadingStatus, getstatecityAPI, getProjectTitleAPI, getCenterListAPI, downloadCenterDataAPI, deleteCenterAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import Select from "react-select";
import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaStar } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";

toast.configure();

export default function Centers() {
  let wrapper1 = document.getElementById("wrapper1");
  let wrapper2 = document.getElementById("wrapper2");
  const [firstName, setFirstname] = useState("");
  const [reviewOne, setReviewOne] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch({});
  let history = useNavigate();
  const [message, setMessage] = useState("");
  const [currentValue, setCurrentValue] = useState(0);
  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);
  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  function deleteCenter(i) {
    swal({
      text: "Are you sure you want to delete Center?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("center_id", i._id);
        dispatch(dispatchLoadingStatus(true));
        dispatch(deleteCenterAPI(formData));
      }
    });
  }

  function handleRating(i) {
    if (currentValue > 0) {
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("center_id", firstName._id);
      formData.append("star", currentValue);
      formData.append("review", message ? message : "");
      dispatch(dispatchLoadingStatus(true));
      dispatch(CenterAddReviewAPI(formData));
      setReviewOne(false);
      setShow22(false);
    } else {
      setReviewOne(true);
    }
  }

  let CenterAddReviewRes = useSelector((state) => state.globalReducer.CenterAddReviewRes);

  useEffect(() => {
    if (CenterAddReviewRes) {
      Apicall((page - 1) * 20, page);
    }
  }, [CenterAddReviewRes]);

  const handleMessage = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/; // /^[-a-z0-9,/()&:. ]*[a-z][-a-z0-9,/()&:. ]*$/;
    // all number and charcetr allowed //space not  // special symbol not // middle space are allowed

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setMessage(e.target.value);
      } else {
        setMessage("");
      }
    } else {
      setMessage("");
    }
  };

  const [order, setOrder] = useState("ASC");
  const [state22, setstate] = useState("");
  const [centerState, setCenterState] = useState({ label: "State", value: "" });
  const [centerState22, setCenterState22] = useState("");
  const [centerStateError, setCenterStateError] = useState(false);
  const [state33, setstate33] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [project, setproject] = useState("");
  const [num, setNum] = useState(0);
  const [reload, setreload] = useState("false");
  const [searchval, setsearch] = useState("");
  const [citylist, setcitylist] = useState([]);
  const [datasrc, setdatasrc] = useState();
  const [page, setpage] = useState(1);
  const [start, setStart] = useState(0);
  const [minCap, setMinCap] = useState("");
  const [maxCap, setMaxCap] = useState("");

  const NumValid = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setpincode(e.target.value);
    }
  };
  const NumValid22 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMinCap(e.target.value);
    }
  };

  const NumValid33 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMaxCap(e.target.value);
    }
  };

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    localStorage.setItem("center_id", "");
    localStorage.removeItem("flage");
    dispatch(dispatchLoadingStatus(true));
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(getstatecityAPI());
    dispatch(getProjectTitleAPI(formData));
    dispatch(centerDataAPI(formData));
  }, []);

  const [centerData, setCenterData] = useState();

  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);
  let centerDataReg = useSelector((state) => state.globalReducer.centerDataReg);

  useEffect(() => {
    if (centerDataReg) {
      if (centerDataReg.errorcode == 1) {
        setCenterData([]);
      } else {
        setCenterData(centerDataReg ? centerDataReg.data : centerDataReg.data);
        window.scrollTo(0, 0);
      }
    }
  }, [centerDataReg]);

  let getCenterListRes = useSelector((state) => state.globalReducer.getCenterListRes);
  let total = getCenterListRes ? getCenterListRes.count : "";

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  const Sortt22 = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  function Apicall(start) {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("center_state", state22);
    formData.append("center_city", city);
    formData.append("center_pincode", pincode);
    formData.append("projectTitle", project);
    formData.append("center_name", searchval);
    formData.append("center_no_of_candidate_from", minCap);
    formData.append("center_no_of_candidate_to", maxCap);

    if (searchval || state22 || state22 == "" || city || project || pincode || minCap || maxCap) {
      if (num == 1 || reload == "true") {
        setreload("false");
        dispatch(getCenterListAPI(formData));
      }
    } else {
      if (num == 1 || reload == "true") {
        setreload("false");
        dispatch(dispatchLoadingStatus(true));
        dispatch(getCenterListAPI(formData));
      }
    }
  }

  function setstatecitilist(e) {
    if (getstatecityReg) {
      var filter = getstatecityReg.data.filter((i) => i._id == e.value);
      setstate(e.value);
      setstate33(filter.length > 0 ? filter[0].state : []);
      setcity("");
      if (filter && e.value) {
        setcitylist(filter.length > 0 ? filter[0].districts : []);
        setStart(0);
      } else {
        setcitylist([]);
      }
    }
  }

  function setstatecitilist22(e) {
    if (e.value == "") {
      setCenterState({ label: "State", value: "" });
      setCenterState22("");
      setCenterStateError(true);
    } else {
      setCenterState({ label: e.label, value: e.value });
      setCenterState22(e.value);
      setCenterStateError(false);
    }
  }

  useEffect(() => {
    Apicall(start);
  }, [start]);

  useEffect(() => {
    setNum(1);
    setpage(1);
    Apicall(0);
  }, [state22]);

  useEffect(() => {
    setNum(1);
    setpage(1);
    Apicall(0);
  }, [city]);

  useEffect(() => {
    if (pincode.length == 6 || pincode.length == 0) {
      setNum(1);
      setpage(1);
      Apicall(0);
    }
  }, [pincode]);

  useEffect(() => {
    setNum(1);
    setpage(1);
    Apicall(0);
  }, [project]);

  function searchchange(value) {
    setsearch(value.trim() == "" ? "" : value);
  }
  useEffect(() => {
    if (searchval == "" || searchval.length > 0) {
      setNum(1);
      setpage(1);
      Apicall(0);
    }
  }, [searchval]);

  useEffect(() => {
    if (minCap == "" || minCap.length > 0) {
      setNum(1);
      setpage(1);
      Apicall(0);
    }
  }, [minCap]);

  useEffect(() => {
    if (maxCap == "" || maxCap.length > 0) {
      setNum(1);
      setpage(1);
      Apicall(0);
    }
  }, [maxCap]);

  useEffect(() => {
    if (getCenterListRes) {
      if (getCenterListRes.errorcode == 1) {
        setdatasrc([]);
      } else {
        setdatasrc(getCenterListRes ? (getCenterListRes.data ? getCenterListRes.data : []) : []);
      }
    }
  }, [getCenterListRes]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [reload]);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        localStorage.setItem("projectTitle", "");
        localStorage.setItem("projectTitle22", "Project");
        setreload("true");
        setproject("");
      } else {
        if (localStorage.getItem("projectTitle")) {
          setproject(localStorage.getItem("projectTitle"));
        } else {
          setreload("true");
        }
      }
    }
  }, []);

  function OtherPage(i) {
    history("/Editcenter", { state: i });
  }

  const [errorsprofile, seterrorsprofile] = useState(false); //The photo must be a file of type: jpg, png, jpeg
  const [base64CodeError, setBase64CodeError] = useState(false); // Please add your profile
  const [base64Code, setBase64Code] = useState("");
  const [projectModel, setProjectModel] = useState({ label: "Project", value: "" });

  function modelClose() {
    if (!projectModel.value == "" && base64Code) {
      document.getElementById("close").click();
    }
  }

  function changeprofile(event) {
    if (event.target.files[0].name.toLowerCase().includes("xls") || event.target.files[0].name.toLowerCase().includes("csv") || event.target.files[0].name.toLowerCase().includes("xlsx")) {
      setBase64Code(event.target.files[0]);
      setBase64CodeError(false);
      seterrorsprofile(false);
    } else {
      setBase64Code("");
      seterrorsprofile(true);
      setBase64CodeError(false);
    }
  }

  const allDeatils = () => {
    if (!base64Code) {
      setBase64CodeError(true);
    }

    if (centerState22 == "") {
      setCenterStateError(true);
    } else {
      setCenterStateError(false);
    }

    if (base64Code && centerState22) {
      let formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", "");
      formData.append("center_state_id", centerState22);
      formData.append("file_centers", base64Code);
      dispatch(dispatchLoadingStatus(true));
      dispatch(importCenterAPI(formData));
      setShow(false);
      Apicall((page - 1) * 20, page);
    }
  };

  let changeSRes = useSelector((state) => state.globalReducer.changeCenterIsActiveRes);
  useEffect(() => {
    if (changeSRes) {
      let formData = new FormData();
      Apicall((page - 1) * 20, page);
      formData.append("token", localStorage.getItem("token"));
      dispatch(centerDataAPI(formData));
    }
  }, [changeSRes]);
  function toggleSwitch(e, i) {
    var vall = i.is_active ? "inactive" : "active";
    swal({
      text: "Are you sure you want to " + vall + " this center?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("center_id", i._id);
        dispatch(dispatchLoadingStatus(true));
        dispatch(changeCenterIsActiveAPI(formData, i._id, i.is_active));
      }
    });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");
    if (tokenRolee.find((t) => t === "Centers")) {
      if (token) {
        if (!window.location.href.includes("Centers")) {
          window.location.href = window.location.origin + "/Centers";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  const token = localStorage.getItem("token");

  function downloadcsv() {
    if (datasrc.length > 0) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("center_no_of_candidate_from", minCap ? minCap : "");
      formData.append("center_no_of_candidate_to", maxCap ? maxCap : "");
      formData.append("center_state", state22 ? state22 : "");
      formData.append("center_name", searchval ? searchval : "");
      formData.append("center_city", city ? city : "");
      formData.append("center_pincode", pincode ? pincode : "");
      dispatch(dispatchLoadingStatus(true));
      dispatch(downloadCenterDataAPI(formData));
    } else {
      swal("", "No data found", "error");
    }
  }

  function handleLanlong() {
    dispatch(dispatchLoadingStatus(true));
    dispatch(updateCentersLatLongAPI());
  }
  return (
    <>
      {" "}
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Center List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                          Dashboard
                        </a>
                        <a href="/Centers" className="breadcrumb-item active">
                          Centers{" "}
                        </a>
                      </ol>
                      <div className="">
                        <a
                          className=" mt-4 btn btn-primary me-2 waves-effect waves-light"
                          onClick={() => {
                            history("/Addcenter");
                            localStorage.setItem("flage", false);
                            localStorage.setItem("projectTitle", "");
                            localStorage.setItem("projectTitle22", "Project");
                          }}
                        >
                          Add Center
                        </a>
                        <a
                          className=" mt-4 btn btn-primary me-2 waves-effect waves-light"
                          onClick={() => {
                            handleShow();
                            seterrorsprofile(false);
                            setBase64CodeError(false);
                            setProjectModel("");
                            setBase64Code("");
                            setCenterState("");
                            setCenterStateError(false);
                          }}
                          data-bs-target="#staticBackdrop"
                          htmlFor="import"
                        >
                          Import Centers
                        </a>
                        <a href="https://cms-api.smartexams.in/routes/sample/Venue_Sample_Data_&_Project_Code.xlsx" className=" mt-4 btn btn-primary me-2 waves-effect waves-light">
                          Sample Centers File
                        </a>
                        <button className=" mt-4 btn btn-primary me-2 waves-effect waves-light" onClick={() => downloadcsv()}>
                          <i className="bx bx-download font-size-17"></i> Download
                        </button>
                        <button className=" mt-4 btn btn-primary me-2 waves-effect waves-light" title="update" onClick={() => handleLanlong()}>
                          {" "}
                          Update Center
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Centers Count</p>
                          <h4 className="mb-0">{centerData ? centerData.total_centers_count : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Assigned Centers</p>
                          <h4 className="mb-0">{centerData ? centerData.totalAssignedCenters : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Active Centers</p>
                          <h4 className="mb-0">{centerData ? centerData.totalActiveCenters : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Audited Centers</p>
                          <h4 className="mb-0">{centerData ? centerData.totalAuditedCenters : 0} </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Import Centers </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} isClearable={false} value={centerState ? centerState : [{ label: "State", value: "" }]} onChange={(e) => setstatecitilist22(e)} />
                    {centerStateError && <span style={{ color: "#BE312E" }}> Please select state</span>}
                    <br /> <br />
                    <div className="wrap">
                      <form>
                        <div class="file">
                          <div class="file__input" id="file__input">
                            <input class="file__input--file" type="file" multiple="multiple" onChange={(event) => changeprofile(event)} name="files[]" />
                            <label class="file__input--label" for="customFile" data-text-btn="Choose Files">
                              {" "}
                              {base64Code ? base64Code.name : "Add file:"}
                            </label>
                          </div>
                        </div>
                      </form>
                      {errorsprofile == true ? errorsprofile && <span style={{ color: "#BE312E" }}>The file must be of type XLS, XLSX, or CSV</span> : base64CodeError == true ? base64CodeError && <span style={{ color: "#BE312E" }}>Please upload centers</span> : ""}
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn btn-light" id="close" onClick={handleClose}>
                    Close
                  </button>

                  <button
                    type="button"
                    id="close"
                    className="btn btn-primary"
                    onClick={() => {
                      allDeatils();
                      modelClose();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-2   col-12  ">
                            <div className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                              <div className="search-box me-2">
                                <div className="position-relative">
                                  <input type="text" className="form-control " onChange={(e) => searchchange(e.target.value)} value={searchval} placeholder="Search..." />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 col-12 mtsm-4">
                            <div className="row align-items-center justify-content-end">
                              <div className="col-md-2 col-6 mb-4 text end">Seating Capacity</div>

                              <div className="col-md-2 col-6 mb-4">
                                <input value={minCap} className="form-control" maxLength={6} placeholder={"Min"} onChange={NumValid22} />
                              </div>
                              <div className="col-md-2 col-6 mb-4">
                                <input value={maxCap} className="form-control" maxLength={6} placeholder={"Max"} onChange={NumValid33} />
                              </div>
                              <div className="col-md-2 col-6 mb-4">
                                <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} isClearable={false} value={state22 == "" ? [{ label: "State", value: "" }] : state22 ? [{ label: state33, value: state22 }] : [{ label: "State", value: "" }]} onChange={(e) => setstatecitilist(e)} />
                              </div>
                              <div className="col-md-2 col-6 mb-4">
                                <Select
                                  options={citylist ? [{ label: "City", value: "" }].concat(citylist.map((e) => ({ label: e, value: e }))) : []}
                                  isClearable={false}
                                  placeholder={"City"}
                                  value={city == "" ? [{ label: "City", value: "" }] : city ? [{ label: city, value: city }] : [{ label: "City", value: "" }]}
                                  id="selectTag"
                                  onChange={(e) => setcity(e.value)}
                                />
                              </div>
                              <div className="col-md-2 col-6 mb-4">
                                <input value={pincode} className="form-control" maxLength={6} placeholder={"Pincode"} onChange={NumValid} />
                              </div>
                            </div>
                          </div>
                          Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getCenterListRes ? getCenterListRes.count : ""} entries
                          <div
                            id="wrapper1"
                            onScroll={() => {
                              if (wrapper2) {
                                wrapper2.scrollLeft = wrapper1.scrollLeft;
                              }
                            }}
                          >
                            <div id="div1"></div>
                          </div>
                          <div
                            className="table-responsive "
                            id="wrapper2"
                            onScroll={() => {
                              if (wrapper1) {
                                wrapper1.scrollLeft = wrapper2.scrollLeft;
                              }
                            }}
                          >
                            <div id="div2" className="scrollbar">
                              <table id="example" className="table table-striped no-wrap dt-responsive force-overflow">
                                <thead>
                                  <tr>
                                    <th>Sr No.</th>
                                    <th data-priority="1">
                                      Center Name
                                      <i onClick={() => Sortt("center_name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>

                                    <th data-priority="2">
                                      State
                                      <i onClick={() => Sortt("center_state")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>
                                    <th data-priority="2">
                                      City
                                      <i onClick={() => Sortt("center_city")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>

                                    <th data-priority="2">
                                      Center Phone
                                      <i onClick={() => Sortt22("center_contact")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>
                                    <th>
                                      Seating Capacity
                                      <i onClick={() => Sortt22("center_no_of_candidate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                    </th>
                                   
                                    <th data-priority="5">
                                      Rating
                                      <i onClick={() => Sortt("star")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                    </th>

                                    <th data-priority="5">Active/Inactive</th>

                                    <th data-priority="5">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {datasrc &&
                                    datasrc.map((i, index) => (
                                      <tr key={"proj-" + index}>
                                        <th> {page * 20 + index + 1 - 20}.</th>
                                        <td>{i.center_name}</td>
                                        <td>{i.center_state}</td>
                                        <td>{i.center_city}</td>
                                        <td>{i.center_contact}</td>
                                        <td>{i.center_no_of_candidate}</td>

                                        <td>
                                          <div className="stars d-flex">
                                            {stars.map((_, index) => {
                                              return (
                                                <FaStar
                                                  key={index}
                                                  size={24}
                                                  color={(hoverValue || i ? i.star : "0") > index ? colors.orange : colors.grey}
                                                  style={{
                                                    marginRight: 3,
                                                  }}
                                                />
                                              );
                                            })}
                                          </div>
                                        </td>

                                        <td>
                                          <label class="switch">
                                            <input type="checkbox" key={i._id} onClick={(e) => toggleSwitch(e, i)} id="toggleSwitch" checked={i.is_active} defaultChecked={i.is_active} />
                                            <span class="slider round"></span>
                                          </label>
                                        </td>

                                        <td>
                                          <div className="d-flex align-items-center">
                                            <button
                                              className="me-2 btn btn-primary waves-effect waves-light"
                                              onClick={() => {
                                                handleShow22();
                                                setCurrentValue(i ? i.star : "0");
                                                setFirstname(i);
                                                setMessage(i ? i.review : "");
                                                setReviewOne(false);
                                              }}
                                              key={i._id}
                                            >
                                              Add Review
                                            </button>

                                            {/* added by kajal 04-08-2023 */}
                                            <button className="me-2 btn btn-primary waves-effect waves-light" onClick={() => deleteCenter(i)}>
                                              Delete
                                            </button>

                                            <i
                                              className="cursor-pointer bx bx-edit font-size-18"
                                              onClick={() => {
                                                OtherPage(i);
                                                localStorage.setItem("flage", true);
                                                localStorage.setItem("center_id", i._id);
                                                localStorage.setItem("projectTitle", "");
                                                localStorage.setItem("projectTitle22", "Project");
                                              }}
                                            ></i>
                                            <i
                                              className="cursor-pointer far fa-eye ms-3 font-size-18"
                                              onClick={() => {
                                                history("/Centerdetails", { state: i });
                                                localStorage.setItem("projectTitle", "");
                                                localStorage.setItem("projectTitle22", "Project");
                                              }}
                                            ></i>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <Modal show={show22} onHide={handleClose22} backdrop="static" keyboard={false}>
                            <Modal.Header closeButton>
                              <Modal.Title>Add Review </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="cursor-pointer rating-div">
                                <h2>{firstName.center_name}'s Ratings </h2>
                                <div className="stars">
                                  {stars.map((_, index) => {
                                    return (
                                      <FaStar
                                        key={index}
                                        size={24}
                                        onClick={() => handleClick(index + 1)}
                                        onMouseOver={() => handleMouseOver(index + 1)}
                                        onMouseLeave={handleMouseLeave}
                                        color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                                        style={{
                                          marginRight: 10,
                                        }}
                                      />
                                    );
                                  })}
                                </div>

                                <div>
                                  <textarea className="textarea form-control" name="message" value={message} onChange={handleMessage} maxLength={500} placeholder="What's your experience?" />
                                </div>

                                {reviewOne && (
                                  <span className="mb-3" style={{ color: "#BE312E", display: "table", marginTop: "-20px" }}>
                                    Please select rating{" "}
                                  </span>
                                )}

                                <button
                                  className="button"
                                  onClick={() => {
                                    handleRating();
                                  }}
                                >
                                  {" "}
                                  Submit{" "}
                                </button>
                              </div>
                            </Modal.Body>
                          </Modal>
                          {datasrc ? (
                            datasrc.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}
                          <div className=" btns-table align-items-center justify-content-end">
                            {getCenterListRes ? (
                              getCenterListRes.count > 0 ? (
                                <div className="row align-items-center wrapper-pg-main">
                                  <div className="col-6">
                                    Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getCenterListRes ? getCenterListRes.count : ""} entries
                                  </div>
                                  <div className="col-6">
                                    <div className="wrapper-pgn">
                                      <div className="pgn pgn-default">
                                        <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                          <i className="bx bx-chevrons-left"></i>
                                        </div>
                                        <div className="pages" id="content">
                                          {(() => {
                                            const pages = [];

                                            for (let i = 1; i < getCenterListRes.count / 20 + 1; i++) {
                                              pages.push(
                                                <div
                                                  className={page == i ? "page page-btn active" : "page page-btn"}
                                                  onClick={() => {
                                                    Apicall((i - 1) * 20);
                                                    setpage(i);
                                                  }}
                                                >
                                                  {i}
                                                </div>
                                              );
                                            }

                                            return pages;
                                          })()}
                                        </div>
                                        <div
                                          onClick={() => {
                                            document.getElementById("content").scrollBy(50, 0);
                                          }}
                                          className=" next "
                                        >
                                          <i className="bx bx-chevrons-right"></i>
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
