import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attendenceDataAPI, getAttendanceHistoryListAPI, listCentersByProjectIdAPI, dispatchLoadingStatus, getstatecityAPI, getProjectTitleAPI, getRolesAPI, getadminattendancelistAPI, attendanceapproverejectAPI, getCenterListDDLURLAPI, AttendanceCSVAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import MainDashbord from "../Sidebar/MainDashbord";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import blankImg from '../../assets/images/blank.jpg'

function Attendance() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const dispatch = useDispatch({});
  let history = useNavigate();

  let getstatecityReg = useSelector((state) => state.globalReducer.getstatecityReg);
  const getRolesReg = useSelector((state) => state.globalReducer.getRolesReg);
  const getadminattendancelistRes = useSelector((state) => state.globalReducer.getadminattendancelistRes);
  let total = getadminattendancelistRes ? getadminattendancelistRes.count : "";
  let token = localStorage.getItem("token");

  const [page, setpage] = useState(1);

  const [startDate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [freelancerRole, setFreelancerRole] = useState("");
  const [state, setstate] = useState("");
  const [city, setciti] = useState("");
  const [pincode, setpincode] = useState("");
  const [start, setStart] = useState(0);
  const [citylist, setcitylist] = useState([]);
  const [frname, setFRname] = useState("");
  const [centerName, setCenterName] = useState("");
  const [centerName22, setCenterName22] = useState([]);
  const [centerName33, setCenterName33] = useState([]);
  const [allData22, setAllData22] = useState();
  const [project, setproject] = useState("");
  const [project22, setproject22] = useState();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [rej, setRej] = useState("");

  function handleProject(e) {
    if (e.value) {
      setproject(e.value);
      setproject22(e.label);
      setCenterName22("");
    } else {
      setCenterName22("");
      setproject("");
      setproject22("Project title");
    }
  }

  function handleAttendance(i) {
    let formData22 = new FormData();
    formData22.append("attendance_id", i._id);
    dispatch(dispatchLoadingStatus(true));
    dispatch(getAttendanceHistoryListAPI(formData22));
  }

  let getAttendanceHistoryListRes = useSelector((state) => state.globalReducer.getAttendanceHistoryListRes);
  const [historyList, setHistoryList] = useState();

  useEffect(() => {
    if (getAttendanceHistoryListRes) {
      if (getAttendanceHistoryListRes.errorcode === 1) {
        setHistoryList([]);
      } else {
        setHistoryList(getAttendanceHistoryListRes ? getAttendanceHistoryListRes.data : getAttendanceHistoryListRes.data);
      }
    }
  }, [getAttendanceHistoryListRes]);

  const handleReason = (e) => {
    setReason(e.target.value.trim() == "" ? "" : e.target.value);
    if (e.target.value == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }
  };
  const Accept = (i) => {
    if (token && i._id) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("attendanceId", i._id);
      formData.append("isApproved", 1);
      dispatch(dispatchLoadingStatus(true));
      dispatch(attendanceapproverejectAPI(formData));
      Apicall((page - 1) * 20, page);
    } else {
      toast.error("Something Wrong");
    }
  };

  const Reject = (i) => {
    if (reason == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }

    if (token && rej && reason) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("attendanceId", rej);
      formData.append("isApproved", 0);
      formData.append("reject_reason", reason);
      dispatch(dispatchLoadingStatus(true));
      dispatch(attendanceapproverejectAPI(formData));
      Apicall((page - 1) * 20, page);
      setShow22(false);
    }
  };

  function Apicall(start) {
    let formData = new FormData();
    let formData22 = new FormData();
    formData.append("token", token);
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("center_state", state);
    formData.append("freelancerCity", city);
    formData.append("center_name", centerName22);
    formData.append("pincode", pincode);
    formData.append("freelancerName", frname);
    formData.append("projectTitle", project);
    formData.append("startdate", startDate ? moment(startDate).format("yyyy/MM/DD") : "");
    formData.append("enddate", endDate ? moment(endDate).format("yyyy/MM/DD") : "");
    formData.append("freelancer_role_id", freelancerRole);

    if (frname || state || state == "" || city || centerName || project || startDate || endDate || freelancerRole) {
      if (localStorage.getItem("getadminattendancelistAPI") != "true") {
        localStorage.setItem("getadminattendancelistAPI", true);
        dispatch(getadminattendancelistAPI(formData));
      }
    } else {
      if (localStorage.getItem("getadminattendancelistAPI") != "true") {
        localStorage.setItem("getadminattendancelistAPI", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getadminattendancelistAPI(formData));
      }
    }

    formData22.append("token", token);
    formData22.append("project_id", project);

    if (project) {
      dispatch(listCentersByProjectIdAPI(formData22));
      setCenterName("");
    }
  }

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    dispatch(getRolesAPI(formData));
    dispatch(getProjectTitleAPI(formData));
    dispatch(getCenterListDDLURLAPI(formData));
    dispatch(attendenceDataAPI(formData));
  }, []);
  let attendanceapproverejectRes = useSelector((state) => state.globalReducer.attendanceapproverejectRes);

  useEffect(() => {
    if (attendanceapproverejectRes) {
      if (attendanceapproverejectRes.errorcode == 0) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        dispatch(attendenceDataAPI(formData));
        Apicall((page - 1) * 20, page);
        window.scrollTo(0, 0);
      }
    }
  }, [attendanceapproverejectRes]);

  const [attendenceData, setattendenceData] = useState();
  let attendenceDataReg = useSelector((state) => state.globalReducer.attendenceDataReg);

  useEffect(() => {
    if (attendenceDataReg) {
      if (attendenceDataReg.errorcode == 1) {
        setattendenceData([]);
      } else {
        setattendenceData(attendenceDataReg ? attendenceDataReg.data : attendenceDataReg.data);
      }
    }
  }, [attendenceDataReg]);

  let getProjectTitleRes = useSelector((state) => state.globalReducer.getProjectTitleRes);
  let listCentersByProjectIdRes = useSelector((state) => state.globalReducer.listCentersByProjectIdRes);

  const [state33, setstate33] = useState("");

  function setcity(e) {
    if (getstatecityReg) {
      var filter = getstatecityReg.data.filter((i) => i._id == e.value);
      setstate(e.value);
      setstate33(filter.length > 0 ? filter[0].state : []);
      setciti("");
      if (filter && e.value) {
        setcitylist(filter[0].districts);
        setStart(0);
        setciti([]);
      } else {
        setcitylist([]);
      }
    }
  }

  useEffect(() => {
    Apicall(start);
  }, [start]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [state]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [city]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [freelancerRole]);

  useEffect(() => {
    if (pincode.length == 6 || pincode.length == 0) {
      setpage(1);
      Apicall(0);
    }
  }, [pincode]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [startDate]);
  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [endDate]);

  function ChangesFRname(value) {
    setFRname(value.trim() == "" ? "" : value);
  }

  useEffect(() => {
    if (frname == "" || frname.length > 0) {
      setpage(1);
      Apicall(0);
    }
  }, [frname]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [project]);

  useEffect(() => {
    setpage(1);
    Apicall(0);
  }, [centerName]);
  useEffect(() => {
    if (getadminattendancelistRes) {
      if (getadminattendancelistRes.errorcode === 1) {
        setAllData22([]);
      } else {
        setAllData22(getadminattendancelistRes ? getadminattendancelistRes.data : getadminattendancelistRes.data);
      }
    }
  }, [getadminattendancelistRes]);

  const handleFreelancerRole = (e) => {
    if (e.value) {
      setFreelancerRole(e.value);
    } else {
      setFreelancerRole("");
    }
  };

  function downloadcsv() {
    if (allData22.length > 0) {
      var data = `?is_download=1&token=${token}&projectTitle=${project}&center_state=${state}&center_name=${centerName}&startdate=${startDate ? moment(startDate).format("yyyy/MM/DD") : ""}&enddate=${endDate ? moment(endDate).format("yyyy/MM/DD") : ""}&freelancer_role_id=${freelancerRole}&freelancerName=${frname}&freelancerCity=${city}`;
      dispatch(dispatchLoadingStatus(true));
      dispatch(AttendanceCSVAPI(data));
    } else {
      swal("", "No data found", "error");
    }
  }
  const [order, setOrder] = useState("ASC");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };


  useEffect(() => {
    const token = localStorage.getItem("token");

    let tokenRolee = localStorage.getItem("newRoleVissible").split(",");

    if (tokenRolee.find((t) => t === "Attendance")) {
      if (token) {
        if (!window.location.href.includes("Attendance")) {
          window.location.href = window.location.origin + "/Attendance";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  function handleCenterName(e) {
    setCenterName([{ label: e.label, value: e.value }]);
    setCenterName22(e.value);

    setCenterName33(e.label);
  }
  return (
    <>
      {" "}
      <Loader />
      <MainDashbord />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Attendance</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                        Dashboard
                      </a>
                      <a href="/Attendance" className="breadcrumb-item active">
                        Attendance
                      </a>
                    </ol>
                    <div className="">
                      <button className=" mt-4 btn btn-primary float-lg-end waves-effect waves-light" onClick={() => downloadcsv()}>
                        <i className="bx bx-download font-size-17"></i> Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Active Projects Count</p>
                        <h4 className="mb-0">{attendenceData ? attendenceData.activeProjectsCount : 0} </h4>
                      </div>
                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="bx bx-layout font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Total Active Freelancers</p>
                        <h4 className="mb-0">{attendenceData ? attendenceData.totalActiveFreelancers : 0} </h4>
                      </div>
                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="bx bx-user font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Today Attendence Count</p>
                        <h4 className="mb-0">{attendenceData ? attendenceData.todayAttendenceCount : 0} </h4>
                      </div>
                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="bx bx-user font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Weekly Attendance Count</p>
                        <h4 className="mb-0">{attendenceData ? attendenceData.weeklyAttendanceCount : 0} </h4>
                      </div>
                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="bx bx-user font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-rep-plugin">
                    <div className="row mb-3 no-gutters">
                      <div className="col-md-4">
                        <form className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                          <div className="search-box me-2 mb-4">
                            <div className="position-relative">
                              <input type="text" className="form-control " onChange={(e) => ChangesFRname(e.target.value)} value={frname} placeholder="Search..." />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="col-md-8">
                        <div className="row align-items-center">
                          <div className="col-md-3 col-6 mb-4">
                            <Select options={getstatecityReg ? [{ label: "State", value: "" }].concat(getstatecityReg.data.map((e) => ({ label: e.state, value: e._id }))) : []} placeholder={"State"} value={state == "" ? [{ label: "State", value: "" }] : state ? [{ label: state33, value: state }] : [{ label: "State", value: "" }]} onChange={(e) => setcity(e)} />
                          </div>
                          <div className="col-md-3 col-6 mb-4">
                            <Select
                              options={citylist ? [{ label: "City", value: "" }].concat(citylist.map((e) => ({ label: e, value: e }))) : []}
                            
                              placeholder={"City"}
                              value={city == "" ? [{ label: "City", value: "" }] : city ? [{ label: city, value: city }] : [{ label: "City", value: "" }]}
                              onChange={(e) => setciti(e.value)}
                            />
                          </div>

                          <div className="col-md-3 col-6 mb-4">
                            <Select options={getProjectTitleRes ? [{ label: "Project title", value: "" }].concat(getProjectTitleRes.map((e) => ({ label: e.projectTitle, value: e._id }))) : []} isClearable={false} placeholder={"Project title"} value={project == "" ? [{ label: "Project title", value: "" }] : project ? [{ label: project22, value: project }] : [{ label: "Project title", value: "" }]} onChange={(e) => handleProject(e)} />
                          </div>

                          <div className="col-md-3 col-6 mb-4">
                            <Select options={listCentersByProjectIdRes ? [{ label: "Center name", value: "" }].concat(listCentersByProjectIdRes.data.map((e) => ({ label: e.center_name, value: e._id }))) : []} isClearable={false} placeholder={"Center name"} value={centerName22 == "" ? [{ label: "Center name", value: "" }] : centerName22 ? [{ label: centerName33, value: centerName22 }] : [{ label: "Center name", value: "" }]} onChange={(e) => handleCenterName(e)} />
                          </div>

                          <div className="col-md-3 col-6 mb-4">
                            <DatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              onChange={(value) => {
                                setStartdate(value);
                              }}
                              selected={startDate ? startDate : null}
                              placeholderText={"Start date"}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={moment().year() - 1900}
                              maxDate={endDate ? endDate : moment().toDate()}
                            />
                          </div>
                          <div className="col-md-3 col-6 mb-4">
                            <DatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              onChange={(value) => {
                                setEndDate(value);
                              }}
                              selected={endDate ? endDate : null}
                              placeholderText={"End date"}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={moment().year() - 1900}
                              minDate={startDate}
                              maxDate={moment().toDate()}
                            />
                          </div>
                          <div className="col-md-3 col-6 mb-4">
                            <Select options={getRolesReg ? [{ label: "Role", value: "" }].concat(getRolesReg.map((e) => ({ label: e.role, value: e.roleid }))) : []} placeholder={"Role"} isClearable={false} onChange={(e) => handleFreelancerRole(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getadminattendancelistRes ? getadminattendancelistRes.count : ""} entries
                    <div className="table-responsive">
                      <table id="example" className="table table-striped dt-responsive nowrap w-100">
                        <thead>
                          <tr>
                            <th> Sr No. </th>
                            <th>
                              Date <i onClick={() => Sortt("inDate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>
                            <th>
                              Freelancer <i onClick={() => Sortt("Name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>
                            <th>
                              Project <i onClick={() => Sortt("projectTitle")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>
                            <th>
                              Center <i onClick={() => Sortt("center_name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                            </th>
                            <th>
                              Phone <i onClick={() => Sortt("phone")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th data-priority="1">Status</th>
                            <th data-priority="1">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allData22 &&
                            allData22.map((i, index) => (
                              <tr>
                                <th> {page * 20 + index + 1 - 20}.</th>
                                <td>{i.inDate ? i.inDate.split("-").join("/") : null}</td>
                                <td>
                                  <div className="d-flex ">
                                    <div className="flex-shrink-0 me-3">
                                      <Zoom>
                                        {" "}
                                        <img className="d-flex-object rounded-circle avatar-xs" alt="" src={i.profiepic ? i.profiepic : blankImg} />
                                      </Zoom>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h5 className="font-weight-bold mb-1">{i.freelancerName}</h5>
                                    </div>
                                  </div>{" "}
                                </td>
                                <td>{i.projectTitle} </td>
                                <td>{i.center_name}</td>
                                <td>{i.phone}</td>

                                {i.status == "4" ? (
                                  <td>
                                    <div style={{ color: "red" }}>Rejected</div>
                                  </td>
                                ) : i.status == "5" ? (
                                  <td>
                                    <div style={{ color: "green" }}>Approved</div>
                                  </td>
                                ) : i.status == "2" ? (
                                  <td>
                                    <div className="d-flex">
                                      <button type="button" className="btn btn-success me-2  waves-effect waves-light" id="sa-success-grt" onClick={() => Accept(i)}>
                                        <i className="mdi mdi-check"></i> Accept
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary  waves-effect waves-light"
                                        onClick={() => {
                                          handleShow22();
                                          setReason("");
                                          setRej(i._id);
                                          setReasonError("");
                                        }}
                                      >
                                        <i className="mdi mdi-close"></i>Reject
                                      </button>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>
                                  {" "}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleShow();
                                      handleAttendance(i);
                                    }}
                                    className="btn btn-primary  waves-effect waves-light"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop22"
                                  >
                                    Attendance History
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {allData22 ? (
                        allData22.length > 0 ? null : (
                          <div style={{ textAlign: "center" }}>
                            <p>No data found</p>{" "}
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className=" btns-table align-items-center justify-content-end">
                      {getadminattendancelistRes ? (
                        getadminattendancelistRes.count > 0 ? (
                          <div className="row align-items-center wrapper-pg-main">
                            <div className="col-6">
                              Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getadminattendancelistRes ? getadminattendancelistRes.count : ""} entries
                            </div>
                            <div className="col-6">
                              <div className="wrapper-pgn">
                                <div className="pgn pgn-default">
                                  <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                    <i className="bx bx-chevrons-left"></i>
                                  </div>
                                  <div className="pages" id="content">
                                    {(() => {
                                      const pages = [];
                                      for (let i = 1; i < getadminattendancelistRes.count / 20 + 1; i++) {
                                        pages.push(
                                          <div
                                            className={page == i ? "page page-btn active" : "page page-btn"}
                                            onClick={() => {
                                              Apicall((i - 1) * 20);
                                              setpage(i);
                                            }}
                                          >
                                            {i}
                                          </div>
                                        );
                                      }
                                      return pages;
                                    })()}
                                  </div>
                                  <div onClick={() => document.getElementById("content").scrollBy(50, 0)} className=" next ">
                                    <i className="bx bx-chevrons-right"></i>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Attendance History </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body custom-scroll-body">
            <div className="table-responsive">
              <table className="table mb-0S">
                <thead className="table-light">
                  <tr>
                    <th>Sr No.</th>
                    <th>In Date</th>
                    <th>Out Date</th>
                    <th>In Time</th>
                    <th>OutTime</th>
                    <th>In Address</th>
                    <th>Out Address</th>
                    <th>In Photo</th>
                    <th>Out Photo</th>
                  </tr>
                </thead>
                <tbody>
                  {historyList && historyList ? (
                    historyList.map((i, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{i.inDate}</td>
                          <td>{i.outDate ? i.outDate : "-"}</td>
                          <td>{i.inTime ? i.inTime : "-"}</td>
                          <td>{i.outTime ? i.outTime : "-"}</td>
                          <td>{i.inaddress ? i.inaddress : "-"}</td>
                          <td>{i.outaddress ? i.outaddress : "-"}</td>
                          <td>
                            <Zoom>
                              {" "}
                              <img style={{ width: "60px" }} src={i.inphoto}></img>
                            </Zoom>{" "}
                          </td>
                          <td>
                            <Zoom>
                              {" "}
                              <img style={{ width: "60px" }} src={i.outphoto}></img>
                            </Zoom>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={show22} onHide={handleClose22} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Reason for Rejection </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason}></textarea>
          </form>
          {reasonError}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={handleClose22}>
            Close
          </button>
          {reason == "" ? (
            <button type="button" className="btn btn-primary" onClick={Reject}>
              Submit
            </button>
          ) : (
            <button type="button" id="close" className="btn btn-primary" data-bs-dismiss="modal" onClick={Reject}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <script src="%PUBLIC_URL%/libs/magnific-popup/jquery.magnific-popup.min.js"></script>
      <script src="%PUBLIC_URL%/js/pages/lightbox.init.js"></script>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Powered by{" "}
                <a href="https://www.nichetechsolutions.com/" target="_blank">
                  Nichetech.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Attendance;
