import React from 'react';
import AddCenter  from './AddCenter';


function EditCenter (){

    return(
        <>
            <AddCenter/>
        </>
    )
}

export default EditCenter;