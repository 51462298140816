// All Validation is Done This Page
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getstatecityAPI, getProjectTypeAPI, dispatchLoadingStatus, AddProjectAPI, EditProjectAPI } from "../../API/Action/globalAction";
import moment from "moment";
import Select from "react-select";
import Loader from "../Loader/Loader";
import MainDashbord from "../Sidebar/MainDashbord";
import { ReactComponent as CloseSvg } from "../../assets/images/icons8-no.svg";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";

function Project() {
  const { state } = useLocation();
  const flageee = localStorage.getItem("flageee");

  const dispatch = useDispatch({});

  let history = useNavigate();
  var mdate = new Date();
  mdate.setFullYear(mdate.getFullYear() + 100);

  const [startDate, setStartdate] = useState(state ? new Date(state.startdate) : "");
  const [endDate, setEndDate] = useState(state ? new Date(state.enddate) : "");
  const [startDateError, setStartdateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  function handleMessage22(e) {
    if (window.location.href.includes("Addproject")) {
      swal({
        text: "Are you sure you want to leave this page?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          history(e);
        }
      });
    }
  }

  const [projectTitle, setProjectTitle] = useState(state ? state.projectTitle : "");
  const [projectTitleError, setProjectTitleError] = useState(false);
  const [projectTitleError22, setProjectTitleError22] = useState(false);
  const [examCode, setExamCode] = useState(state ? state.examCode : "");
  const [examCodeError, setExamCodeError] = useState(false);
  const [examCodeError22, setExamCodeError22] = useState(false);
  const [examType, setExamType] = useState(state ? state.examType : "");
  const [examTypeError, setExamTypeError] = useState(false);
  const [message, setMessage] = useState(state ? state.notes : "");
  const [messageError, setMessageError] = useState("");
  const [projectStateError, setProjectStateError] = useState(false);
  const [projectState, setProjectState] = useState();

  let statelist = useSelector((state) => state.globalReducer.getstatecityReg);

  const handleProjectTitle = (e, index) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z0-9][-a-zA-Z0-9_,/()&:. ]*$/;
    // space not
    // only characetr and number
    // middle space allowed
    //special character not // starting number not

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setProjectTitle(e.target.value);
        setProjectTitleError(false);
        setProjectTitleError22(false);
      } else {
        setProjectTitle("");
        setProjectTitleError22(true);
        setProjectTitleError(false);
      }
    } else {
      setProjectTitle("");
      setProjectTitleError(true);
      setProjectTitleError22(false);
    }
  };

  const handleMessage = (e) => {
    const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/; // /^[-a-z0-9,/()&:. ]*[a-z][-a-z0-9,/()&:. ]*$/;
    // all number and charcetr allowed //space not  // special symbol not // middle space are allowed

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setMessage(e.target.value);
        setMessageError("");
      } else {
        setMessage("");
        setMessageError("Please enter valid message");
      }
    } else {
      setMessage("");
      setMessageError("");
    }
  };

  useEffect(() => {
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    const token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    dispatch(getProjectTypeAPI(formData));
  }, []);


  const [allData22, setAllData22] = useState();

  useEffect(() => {
    if (statelist) {
      if (statelist.errorcode == 0) {
        setAllData22(statelist ? statelist.data : []);
      } else {
        setAllData22([]);
      }
    }
  }, [statelist]);

  useEffect(() => {
    let statedata = state ? state.projectStates : "";
    if (statedata) {
      var data2222 = statelist ? (statelist.data ? statelist.data.filter((item) => statedata.find((d) => d === item._id)) : "") : "";
    }
    const mapppp = data2222 ? data2222.map((e) => ({ label: e.state, value: e.state, _id: e._id })) : [];
    setProjectState(mapppp);
  }, [statelist]);

  const handleProjectState = (e) => {
    if (e) {
      setProjectState(e);
      setProjectStateError(false);
    } else {
      setProjectState("");
      setProjectStateError(true);
    }
    if (e.length == 0) {
      setProjectStateError(true);
    }
  };

  const handleExamType = (e) => {
    if (e.target.value) {
      setExamType(e.target.value);
      setExamTypeError(false);
    } else {
      setExamType("");
      setExamTypeError(true);
    }
  };

  const handleExamCode = (e) => {
    const re = /^[a-zA-Z]*$/;
    // only chacater // 3 charcater is required // space not //special charcter not

    if (e.target.value) {
      if (re.test(e.target.value)) {
        setExamCode(e.target.value);
        setExamCodeError(false);
        setExamCodeError22(false);
      } else {
        setExamCode("");
        setExamCodeError22(true);
        setExamCodeError(false);
      }
    } else {
      setExamCode("");
      setExamCodeError(true);
      setExamCodeError22(false);
    }
  };

  const allDeatils = () => {
    const re = /^[a-zA-Z ]+$/;

    const MessageRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/; // /^[-a-zA-Z0-9,/()&:. ]*[a-z][-a-zA-Z0-9,/()&:. ]*$/;

    if (message) {
      if (MessageRe.test(message)) {
        setMessageError("");
      } else {
        setMessageError("Please enter valid message");
      }
    } else {
      setMessage("");
      setMessageError("");
    }

    const projectTitleRe = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z0-9][-a-zA-Z0-9,/()&:. ]*$/; // /^[-a-zA-Z0-9,/()&:. ]*[a-z][-a-zA-Z0-9,/()&:. ]*$/;

    if (projectTitle) {
      if (projectTitleRe.test(projectTitle)) {
        setProjectTitleError(false);
        setProjectTitleError22(false);
      } else {
        setProjectTitleError22(true);
        setProjectTitleError(false);
      }
    } else {
      setProjectTitleError(true);
      setProjectTitleError22(false);
    }
    if (!startDate) {
      setStartdateError(true);
    }
    if (!endDate) {
      setEndDateError(true);
    }

    const examCodeRe = /^[a-zA-Z]*$/;
    if (examCode) {
      if (examCodeRe.test(examCode)) {
        setExamCodeError(false);
        setExamCodeError22(false);
      } else {
        setExamCodeError22(true);
        setExamCodeError(false);
      }
    } else {
      setExamCodeError(true);
      setExamCodeError22(false);
    }
    if (!examType) {
      setExamTypeError(true);
    }
    if (projectState.length == 0) {
      setProjectStateError(true);
    }

    if (state) {
      if (projectTitle && startDate && endDate && examType && examCode && projectState.length >= 1) {
        const token = localStorage.getItem("token");
        let formData = new FormData();
        formData.append("token", token);
        formData.append("project_id", state ? state._id : "");
        formData.append("projectTitle", projectTitle);
        formData.append("startdate", moment(startDate).format("DD/MM/yyyy"));
        formData.append("enddate", moment(endDate).format("DD/MM/yyyy"));
        formData.append("projectType", "Project_PM");
        formData.append("examType", examType);
        formData.append("examCode", examCode);
        let result = projectState ? projectState.filter((person) => person).map((filteredPerson) => filteredPerson._id) : null;
        formData.append("projectStates", result);
        formData.append("projectcenter", "");
        uploadedFiles &&
          uploadedFiles.map((i) => {
            formData.append("UploadAnyotherdocs", i ? i : []);
          });
        formData.append("notes", message);
        dispatch(dispatchLoadingStatus(true));
        dispatch(EditProjectAPI(formData));
      }
    } else {
      if (projectTitle && startDate && endDate && examType && examCode && projectState.length >= 1) {
        const token = localStorage.getItem("token");
        let formData = new FormData();
        formData.append("token", token);
        formData.append("projectTitle", projectTitle);
        formData.append("startdate", moment(startDate).format("DD/MM/yyyy"));
        formData.append("enddate", moment(endDate).format("DD/MM/yyyy"));
        formData.append("projectType", "Project_PM");
        formData.append("examType", examType);
        formData.append("examCode", examCode);
        let result = projectState ? projectState.filter((person) => person).map((filteredPerson) => filteredPerson._id) : null;
        formData.append("projectStates", result);
        formData.append("projectcenter", "");
        uploadedFiles &&
          uploadedFiles.map((i) => {
            formData.append("UploadAnyotherdocs", i ? i : []);
          });
        formData.append("notes", message);

        dispatch(dispatchLoadingStatus(true));
        dispatch(AddProjectAPI(formData));
      }
    }
  };

  const MAX_COUNT = 5;

  const [uploadedFiles, setUploadedFiles] = useState(state ? state.UploadAnyotherdocs : []);
  const [fileLimit, setFileLimit] = useState(false);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleRemove = (index, file) => {
    const list = [...uploadedFiles];
    if (index > -1) {
      list.splice(index, 1);
      setUploadedFiles(list);
    }
    if (index == "") {
      document.getElementById("selectImage").value = "";
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      if (!window.location.href.includes("Addproject")) {
        window.location.href = window.location.origin + "/Addproject";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  return (
    <>
      <div id="layout-wrapper">
        <Loader />
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18"> {flageee == "0" ? "Edit Project" : "Add Project"} </h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a onClick={() => handleMessage22("/Projects")} className="breadcrumb-item active">
                          Projects
                        </a>
                        <a href="/Addproject" className="breadcrumb-item active">
                          Add Project
                        </a>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-4"></div>
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Project Title *
                              </label>
                              <input type="text" name="projecttitle" className="form-control" value={projectTitle} onChange={handleProjectTitle} placeholder="Enter project title" maxLength={30}></input>
                              {projectTitleError && <span style={{ color: "#BE312E" }}>Please enter project title</span>}
                              {projectTitleError22 && <span style={{ color: "#BE312E" }}>Please enter valid title</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label>Project Start Date *</label>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setStartdate(value);
                                  setStartdateError(false);
                                }}
                                selected={startDate ? startDate : null}
                                placeholderText={"dd/mm/yyyy"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                minDate={moment().toDate()}
                                maxDate={endDate}
                              />
                              {startDateError && <span style={{ color: "#BE312E" }}>Please select start date</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label>Project End Date *</label>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setEndDate(value);
                                  setEndDateError(false);
                                }}
                                selected={endDate ? endDate : null}
                                placeholderText={"dd/mm/yyyy"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                minDate={startDate ? startDate : moment().toDate()}
                              />
                              {endDateError && <span style={{ color: "#BE312E" }}>Please select end date</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="d-block mb-3">Exam Type *</label>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value="PBT" name="examptype" checked={examType == "PBT" ? true : false} onChange={handleExamType} id="pbt" /> <label htmlFor="pbt">PBT</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value="CBT" name="examptype" checked={examType == "CBT" ? true : false} onChange={handleExamType} id="cbt" /> <label htmlFor="cbt">CBT</label>
                              </div>{" "}
                              <br />
                              {examTypeError && <span style={{ color: "#BE312E" }}>Please select a type of exam</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Exam Code *</label>
                              <input type="text" name="examCode" value={examCode} className="form-control" onChange={handleExamCode} placeholder="Enter exam code for unique identification" maxLength="4"></input>
                              {examCodeError && <span style={{ color: "#BE312E" }}>Please enter exam code</span>}
                              {examCodeError22 && <span style={{ color: "#BE312E" }}>Please use only alphabets</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label">Project States *</label>
                              <Select options={allData22 ? allData22.map((e) => ({ label: e.state, value: e.state, _id: e._id })) : []} isMulti isClearable={true} onChange={handleProjectState} value={projectState} />

                              {projectStateError && <span style={{ color: "#BE312E" }}>Please select project states</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div class="wrap">
                              <label class="form-label">Upload any other docs</label>
                              <form action="#" name="form" method="get">
                                <div class="file">
                                  <div class="file__input" id="file__input">
                                    <input class="file__input--file" type="file" multiple="multiple" onChange={handleFileEvent} disabled={fileLimit} name="files[]" />
                                    <label class="file__input--label" for="customFile" data-text-btn="Choose Files">
                                      Add file:
                                    </label>
                                  </div>
                                </div>
                              </form>
                              {uploadedFiles.map((file, index) => (
                                <div className="choosed-files">
                                  {file.name ? file.name : file.split("_").at(-1)}

                                  {uploadedFiles.length > 0 && <CloseSvg width={18} height={18} onClick={() => handleRemove(index, file)} />}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="message">Message </label>
                              <textarea id="message" name="message" value={message} onChange={handleMessage} className="form-control" placeholder="Enter Message" maxLength={500}></textarea>
                              {<span style={{ color: "#BE312E" }}> {messageError}</span>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="text-center d-block m-auto">
                              <button
                                type="button"
                                className="btn btn-primary btn-md    waves-effect waves-light"
                                data-bs-toggle="modal"
                                data-bs-target=".transaction-detailModal"
                                onClick={() => {
                                  allDeatils();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Project;
