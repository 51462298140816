
//Staging URL 
export const baseURL = "https://cms-api.smartexams.in/psapi/";


//LIVE URL
// export const baseURL = "https://psapi.edu-test.in/psapi/";


export const REACT_APP_GOOGLE_CLIENT_ID = "100376781692-mkuaem1fssj18tu10lef0eie5s2l2tla.apps.googleusercontent.com"

export const REACT_APP_TRACKING_ID = "G-QTZ126D0ML"

export const REACT_APP_clientId = "com.brightswipe.web"


export const REACT_APP_IV="trlSHOLFlKuG_DWG"
export const REACT_APP_AES_KEY= "8oam-tx2aa+yd6FQ_a<nTw4C9qqb^r*5"


export const Login_URL = "login";
export const LoginReg = "loginReg";

export const paginationReg = "paginationReg";

export const sendOtp_URL = "sendotp";
export const sendOtpReg = "otpReg";

export const verifyOtp_URL = "verifyotp";
export const verifyOtpReg = "verifyOtpReg";

export const AdminLogin_URL = "admin/login";
export const AdminLoginReg = "AdminLoginReg";

export const getfreelancers_URL = "admin/getfreelancers";
export const getfreelancersReg = "getfreelancersReg";

export const freelancerstatus_URL = "admin/freelancerstatus";
export const freelancerstatusReg = "freelancerstatusReg";
export const getstate_URL = "getstatecity"
export const getstatecityReg = "getstatecityReg";


export const getRolesURL = "admin/getroles";
export const getRolesReg = "getRolesReg";

export const getProfileURL = "getprofile";
export const getProfile = "getProfile";
export const getqulification_URL = "getqulifications";
export const getQulificationsReg = "getQulificationsReg";
export const getprojects_URL = "/admin/getProjectList";
export const getProjectsRes = "getProjectsRes";

export const getprojectddl_URL = "/admin/getprojecttypes";
export const getProjectddlRes = "getProjectddlRes";

export const getStatecityURL = "getstatecity";
export const getStatecity = "getStatecity";

export const getProjectTypeURL = "admin/getprojecttypes";
export const getProjectTypeReg = "getProjectTypeReg";

export const AddProjectURL = "/admin/Addproject";
export const AddProjectReg = "AddProjectReg";

export const IS_LOADING = "IS_LOADING";

export const getprojecttitle_URL = "/admin/centerprojectlist";
export const getProjectTitleRes = "getProjectTitleRes";

export const centersbyfreelanceprojectId_URL = "/admin/centersbyfreelanceprojectId";
export const centersbyfreelanceprojectIdRes = "centersbyfreelanceprojectIdRes";

export const getCenterList_URL = "/admin/getCenterList";
export const getCenterListRes = "getCenterListRes";

export const AssignProjectURL = "/admin/AddAssignProject";
export const AssignProjectRes = "AssignProjectRes";

export const freelancersAssignedCentersURL = "/admin/freelancersAssignedCenters";
export const freelancersAssignedCentersRes = "freelancersAssignedCentersRes";

export const getadminattendancelistURL = "/admin/getadminattendancelist";
export const getadminattendancelistRes = "getadminattendancelistRes";


export const getCenterListDDLURL = "/admin/getCenterListDDL";
export const getCenterNameRes = "getCenterNameRes";

export const AttendanceCSVURL = "/admin/getadminattendanceListDownload";
export const AttendanceCSVRes = "AttendanceCSVRes";

export const attendanceapproverejectURL = "/admin/attendanceapprovereject";
export const attendanceapproverejectRes = "attendanceapproverejectRes";

export const getAdminCompletedAuditListURL = "/admin/getAdminCompletedAuditList";
export const getAdminCompletedAuditListRes = "getAdminCompletedAuditListRes";

export const auditApproveRejectURL = "/admin/auditApproveReject";
export const auditApproveRejectRes = "auditApproveRejectRes";

export const getCentersByProjectIdURL = "/admin/getCentersByProjectId";
export const getCentersByProjectIdRes = "getCentersByProjectIdRes";

export const listCentersByProjectIdURL = "/admin/listCentersByProjectId";
export const listCentersByProjectIdRes = "listCentersByProjectIdRes";

export const changeCenterIsActiveURL = "/admin/changeCenterIsActive";
export const changeCenterIsActiveRes = "changeCenterIsActiveRes";

export const importCenterURL = "/admin/importCentersDataFileByProject";
export const importCenterRes = "changeCenterIsActiveRes";

export const TypeSchoolURL = "/admin/getActiveCenterTypes";
export const TypeSchoolRes = "TypeSchoolRes";

export const CategoryURL = "/admin/getActiveCenterCategories";
export const CategoryRes = "CategoryRes";

export const addEditCenterDataURL = "/admin/addEditCenterData";
export const addEditCenterDataRes = "addEditCenterDataRes";

export const getStatesCitiesByProjectIdURL = "/admin/getStatesCitiesByProjectId";
export const getStatesCitiesByProjectIdRes = "getStatesCitiesByProjectIdRes";

export const changeProjectIsActiveURL = "/admin/changeProjectIsActive";
export const changeProjectIsActiveRes = "changeProjectIsActiveRes";

export const getExpenseTypesDDLURL = "/getExpenseTypesDDL";
export const getExpenseTypesDDLRes = "getExpenseTypesDDLRes";

export const ExpenseURL = "/admin/getAdminExpenseList";
export const ExpenseRes = "ExpenseRes";

export const expenseApproveRejectURL = "/admin/expenseApproveReject";
export const expenseApproveRejectRes = "expenseApproveRejectRes";

export const dashboardDataURL = "/admin/dashboardData";
export const dashboardDataRes = "dashboardDataRes";

export const getDashboardProjectListURL = "/admin/getDashboardProjectList";
export const getDashboardProjectListRes = "getDashboardProjectListRes";

export const sendOtpInEmailURL = "/admin/sendOtpInEmail";
export const sendOtpInEmailRes = "sendOtpInEmailRes";

export const OtpVerifyForEmailURL = "/admin/OtpVerifyForEmail";
export const OtpVerifyForEmailRes = "OtpVerifyForEmailRes";

export const resetPasswordURL = "/admin/resetPassword";
export const resetPasswordRes = "resetPasswordRes";

export const sendMessageURL = "/admin/sendMessage";
export const sendMessageRes = "sendMessageRes";

export const getAdminMessagesListURL = "/admin/getAdminMessagesList";
export const getAdminMessagesListRes = "getAdminMessagesListRes";

export const addEditAdminUserURL = "/admin/addEditAdminUser";
export const addEditAdminUserRes = "addEditAdminUserRes";

export const changeAdminUserIsActiveURL = "/admin/changeAdminUserIsActive";
export const changeAdminUserIsActiveRes = "changeAdminUserIsActiveRes";

export const getAdminUsersListURL = "/admin/getAdminUsersList";
export const getAdminUsersListRes = "getAdminUsersListRes";

export const getFreelancerRoleListURL = "/admin/getFreelancerRoleList";
export const getFreelancerRoleListRes = "getFreelancerRoleListRes";

export const addEditFreelancerRoleURL = "/admin/addEditFreelancerRole";
export const addEditFreelancerRoleRes = "addEditFreelancerRoleRes";

export const deleteFreelancerRoleDataURL = "/admin/deleteFreelancerRoleData";
export const deleteFreelancerRoleDataRes = "deleteFreelancerRoleDataRes";

export const changeFreelancerRoleIsActiveURL = "/admin/changeFreelancerRoleIsActive";
export const changeFreelancerRoleIsActiveRes = "changeFreelancerRoleIsActiveRes";

export const addReviewURL = "/admin/add-review";
export const addReviewRes = "addReviewRes";

export const CenterAddReviewURL = "/admin/add-center-review ";
export const CenterAddReviewRes = "CenterAddReviewRes";


export const associateCentersWithProjectURL = "/admin/associateCentersWithProject";
export const associateCentersWithProjectRes = "associateCentersWithProjectRes";

export const getAttendanceHistoryListURL = "getAttendanceHistoryList";
export const getAttendanceHistoryListRes = "getAttendanceHistoryListRes";


export const associatedCentersByProjectIdURL = "/admin/associatedCentersByProjectId";
export const associatedCentersByProjectIdRes = "associatedCentersByProjectIdRes";


export const freelancersDDLURL = "/admin/freelancersDDL";
export const freelancersDDLRes = "freelancersDDLRes";

export const downloadAdminExpenseListURL = "/admin/downloadAdminExpenseList";
export const downloadAdminExpenseListRes = "downloadAdminExpenseListRes";


export const assignTeamToProjectCenterURL = "/admin/assignTeamToProjectCenter";
export const assignTeamToProjectCenterRes = "assignTeamToProjectCenterRes";

export const pendingCentersByFreelancerProjectId_URL = "/admin/pendingCentersByFreelancerProjectId";
export const pendingCentersByFreelancerProjectIdRes = "pendingCentersByFreelancerProjectIdRes";

export const downloadFreelancerListURL = "/admin/downloadFreelancerReport";

export const getFreelancerDataPdfURL = "/admin/getFreelancerDataPdf";
export const getFreelancerDataPdfRes = "getFreelancerDataPdfRes";


export const assignCentersToFreelancerRolesURL = "/admin/assignCentersToFreelancerRoles";
export const assignCentersToFreelancerRolesRes = "assignCentersToFreelancerRolesRes";

export const getExamsURL = "/getExams";
export const getExamsRes = "getExamsRes";

export const getCenterAuditedImagesURL = "/admin/getCenterAuditedImages";
export const getCenterAuditedImagesRes = "getCenterAuditedImagesRes";

export const getCenterProjectsURL = "/admin/getCenterProjects";
export const getCenterProjectsRes = "getCenterProjectsRes";

export const getAdminProfileByIdURL = "/admin/getAdminProfileById ";
export const getAdminProfileByIdRes = "getAdminProfileByIdRes";

export const updateAdminProfileByIdURL = "/admin/updateAdminProfileById ";
export const updateAdminProfileByIdRes = "updateAdminProfileByIdRes";

export const ActiveInactiveFreelancerURL = "/admin/changeFreelancerStatus";
export const ActiveInactiveFreelancerRes = "ActiveInactiveFreelancerRes";

export const EditProjectURL = "/admin/editProject";
export const EditProjectReg = "EditProjectReg";

export const downloadCenterDataURL = "/admin/downloadCenterData";
export const downloadCenterDataReg = "downloadCenterDataReg";


export const getActiveFreelancersURL = "/admin/getActiveFreelancers";
export const getActiveFreelancersReg = "getActiveFreelancersReg";


export const importFreelancersDataURL = "/admin/importFreelancersData";
export const importFreelancersDataReg = "importFreelancersDataReg";


export const deleteFreelancerURL = "/admin/deleteFreelancer";
export const deleteFreelancerReg = "deleteFreelancerReg";

//added by kajal 11-07-2023
export const projectVsFreelancerChartData = "admin/projectVsFreelancerChartData"
export const projectVsCenterChartData = "admin/projectVsCenterChartData"


export const updateCentersLatLong_URL = "/admin/updateCentersLatLong"
export const updateCentersLatLongReg = "updateCentersLatLongReg";

export const freelancerData_URL = "/admin/freelancerData"
export const freelancerDataReg = "freelancerDataReg";

export const centerData_URL = "/admin/centerData"
export const centerDataReg = "centerDataReg";

export const projectData_URL = "/admin/projectData"
export const projectDataReg = "projectDataReg";


export const attendenceData_URL = "/admin/attendenceData"
export const attendenceDataReg = "attendenceDataReg";

//added by kajal 09-08-2023
export const deleteCenter_URL ="/admin/deleteCenter"






















































