import MainDashbord from "../Sidebar/MainDashbord";
import React, { useState, useEffect,} from 'react';
import { updateAdminProfileByIdAPI, dispatchLoadingStatus, getAdminProfileByIdAPI } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loader from '../Loader/Loader';
import swal from 'sweetalert';

function MyProfile() {

    const dispatch = useDispatch({
    });
    let history = useNavigate();

    function handleMessage(e) {
        if (window.location.href.includes("MyProfile") || window.location.href.includes("Dashboard")) {
            swal({
                text: "Are you sure you want to leave this page?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        history(e)
                    } 
                });
        }
    }

    const [Password, setPassword] = useState("");
    const [ConformPassword, setConformPassword] = useState("");
    const [BothPasswordError, setBothPasswordError] = useState(false);
    const [eyes, setEyes] = useState(false);
    const [eyes22, setEyes22] = useState(false);
    const [PasswordError22, setPasswordError22] = useState(false);
    const [ConformPasswordError22, setConformPasswordError22] = useState(false);

    const toggleBtn = () => {
        setEyes(preState => !preState);
    }

    const toggleBtn22 = () => {
        setEyes22(preState => !preState);
    }

    function handlePassword(e) {
        if (e) {
            setPassword(e.target.value.trim() == "" ? "" : e.target.value);
        }
        else {
            setPassword("");
        }
    }

    function handleConformPassword(e) {
        if (e) {
            setConformPassword(e.target.value.trim() == "" ? "" : e.target.value);
        }
        else {
            setConformPassword("");
        }
    }

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false)
    const [emailError22, setEmailError22] = useState(false)

    const [Name, setName] = useState("");
    const [NameError, setNameError] = useState(false)
    const [NameError22, setNameError22] = useState(false)
    const [NameError33, setNameError33] = useState(false)

    const [Mobile, setMobile] = useState("");
    const [MobileError, setMobileError] = useState(false)
    const [MobileError22, setMobileError22] = useState(false);
    const [MobileError33, setMobileError33] = useState(false);

    const handleEmail = (e) => {
        setEmail(e.target.value.trim() == "" ? "" : e.target.value);
        setEmailError("");
    }


    function handleName(e) {
        if (e) {
            setName(e.target.value.trim() == "" ? "" : e.target.value);
        }
        else {
            setName("");
        }
    }

    function handleMobile(e) {
        if (e) {
            setMobile(e.target.value.trim() == "" ? "" : e.target.value);
        }
        else {
            setMobile("");
        }
    }

    const allDeatils = () => {
        let emailreg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        if (email) {
            setEmailError(false);
            if (!emailreg.test(email)) {
                setEmailError22(true);
                setEmailError(false);
            }
            else {
                setEmailError22(false);
                setEmailError(false);
            }
        }
        else {
            setEmailError(true);
            setEmailError22(false);
        }

        let MobileRe = /^[0-9\b]+$/;
        if (Mobile) {
            if (Mobile.length == 10) {
                setMobileError33(false);
                setMobileError22(false)
                setMobileError(false)
                if (!MobileRe.test(Mobile)) {
                    setMobileError22(true)
                    setMobileError(false)
                    setMobileError33(false);
                }
                else {
                    setMobileError22(false)
                    setMobileError(false)
                    setMobileError33(false);
                }
            }
            else {
                setMobileError33(true);
                setMobileError22(false)
                setMobileError(false)
            }
        }
        else {
            setMobileError(true)
            setMobileError22(false)
            setMobileError33(false);
        }

        let nameRe = /^[a-zA-Z\s]*$/;
        if (Name) {
            if (Name.length >= 3) {
                setNameError22(false)
                setNameError(false)
                setNameError33(false)
                if (!nameRe.test(Name)) {
                    setNameError22(true)
                    setNameError(false)
                    setNameError33(false)
                }
                else {
                    setNameError22(false)
                    setNameError(false)
                    setNameError33(false)
                }
            }
            else {
                setNameError22(false)
                setNameError(false)
                setNameError33(true)
            }
        }
        else {
            setNameError(true)
            setNameError22(false)
            setNameError33(false)
        }


        if (email && emailreg.test(email) && Name && nameRe.test(Name) && Name.length >= 3 && Mobile && MobileRe.test(Mobile) && Mobile.length == 10) {
            if (Password || ConformPassword) {
                if (Password.length >= 6 && Password.length <= 16) {
                    setPasswordError22(false);
                }
                else {
                    setPasswordError22(true);
                }

                if (ConformPassword.length >= 6 && ConformPassword.length <= 16) {
                    setConformPasswordError22(false);
                }
                else {
                    setConformPasswordError22(true);
                }

                if (Password.length >= 6 && Password.length <= 16 && ConformPassword.length >= 6 && ConformPassword.length <= 16) {
                    if (Password == ConformPassword) {
                        setBothPasswordError(false);
                        let formData = new FormData();
                        formData.append('token', localStorage.getItem('token'));
                        formData.append('email', email ? email : "");
                        formData.append('name', Name ? Name : "");
                        formData.append('mobile', Mobile ? Mobile : "");
                        formData.append('password', Password ? Password : "" );
                        formData.append('confirm_password', ConformPassword ? ConformPassword : "");
                        dispatch(dispatchLoadingStatus(true))
                        dispatch(updateAdminProfileByIdAPI(formData));
                        setPasswordError22(false);
                        setConformPasswordError22(false);
                    }
                    else {
                        setBothPasswordError(true);
                    }
                }
            }

            else {
                let formData = new FormData();
                formData.append('token', localStorage.getItem('token'));
                formData.append('email', email ? email : "");
                formData.append('name', Name ? Name : "");
                formData.append('mobile', Mobile ? Mobile : "");
                formData.append('password', Password ? Password : "" );
                formData.append('confirm_password', ConformPassword ? ConformPassword : "");
                dispatch(dispatchLoadingStatus(true))
                dispatch(updateAdminProfileByIdAPI(formData));
            }
        }
    }

    const getAdminProfileByIdRes = useSelector(state => state.globalReducer.getAdminProfileByIdRes);
    const updateAdminProfileByIdRes = useSelector(state => state.globalReducer.updateAdminProfileByIdRes);

   
    useEffect(() => {
        let formData = new FormData();
        formData.append('token', localStorage.getItem('token'));
        dispatch(dispatchLoadingStatus(true))
        dispatch(getAdminProfileByIdAPI(formData));
    }, [])


    const [allData22, setAllData22] = useState();

    useEffect(() => {
        if (getAdminProfileByIdRes) {
            if (getAdminProfileByIdRes.errorcode == 1) {
                setAllData22([])
            } else {
                setAllData22(getAdminProfileByIdRes ? getAdminProfileByIdRes.data : "");
            }

        }


    }, [getAdminProfileByIdRes])

    useEffect(() => {
        if (updateAdminProfileByIdRes) {
            if (updateAdminProfileByIdRes.errorcode == 1) {
            } else {
                localStorage.setItem('nameAdmin', Name) 
            }
        }
    }, [updateAdminProfileByIdRes])

    useEffect(() => {
        if (allData22) {
            setEmail(allData22 ? allData22.adminemail : "")
            setName(allData22 ? allData22.name : "")
            setMobile(allData22 ? allData22.mobile : "")
        }


    }, [allData22])


    return (
        <>
            <MainDashbord />
            <Loader />
            <div className="main-content" >
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">My Profile</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                            <a  onClick={() =>handleMessage("/Dashboard")} className="breadcrumb-item active">Dashboard</a> <a href="/MyProfile" className="breadcrumb-item active"> My Profile</a> 
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-rep-plugin">
                                            <div className="row mt-1">
                                                <div className="col-lg-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Email *</label>
                                                        <input type="text" className="form-control" autoComplete="off" placeholder="Enter Email" value={email} onChange={(e) => handleEmail(e)} maxLength={320} disabled />
                                                        {emailError == true ? emailError && <span style={{ "color": "#BE312E" }}>Please enter email</span>
                                                            : emailError22 && <span style={{ "color": "#BE312E" }}>Please enter valid name</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Name *</label>
                                                        <input type="text" className="form-control" placeholder="Enter Name" value={Name} onChange={(e) => handleName(e)} maxLength={30} />
                                                        {NameError && <span style={{ "color": "#BE312E" }}>Please enter name</span>}
                                                        {NameError22 == true ? NameError22 && <span style={{ "color": "#BE312E" }}>Please enter valid name</span>
                                                            : NameError33 && <span style={{ "color": "#BE312E" }}>Please enter minimum 3 character name</span>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 ">
                                                    <div className="mb-4">
                                                        <label className="form-label">Mobile *</label>
                                                        <input type="text" className="form-control" placeholder="Enter Mobile" value={Mobile} maxLength='10' onChange={(e) => handleMobile(e)} />
                                                      
                                                        {MobileError && <span style={{ "color": "#BE312E" }}>Please enter mobile number</span>}
                                                        {MobileError22 == true ? MobileError22 && <span style={{ "color": "#BE312E" }}>Please enter valid mobile number</span>
                                                            : MobileError33 && <span style={{ "color": "#BE312E" }}>Please enter valid mobile number</span>}

                     


                                                    </div>
                                                </div>

                                                <div className="col-lg-12 ">
                                                    <div className="mb-4">
                                                        <label className="form-label">Password </label>
                                                        <div class="input-group auth-pass-inputgroup"><input type={eyes ? "text" : "password"} autoComplete="new-password" id="Passwordd" className="form-control" placeholder="Enter Password" value={Password} onChange={(e) => handlePassword(e)} maxLength={16} />
                                                            <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn}>
                                                                {
                                                                    eyes ? <Visibility style={{ width: "18", height: "18" }} /> : <VisibilityOff style={{ width: "18", height: "18" }} />
                                                                }
                                                            </button>
                                                        </div>
                                                        {PasswordError22 && <span style={{ "color": "#BE312E" }}>Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters</span>}




                                                    </div>
                                                </div>

                                                <div className="col-lg-12 ">
                                                    <div className="mb-4">
                                                        <label htmlFor="username" className="form-label">Confirm Password </label>
                                                        <div class="input-group auth-pass-inputgroup">
                                                            <input type={eyes22 ? "text" : "password"} className="form-control" placeholder="Enter Confirm Password" value={ConformPassword} onChange={(e) => handleConformPassword(e)} maxLength={16} />
                                                            <button className="btn btn-light " type="button" id="password-addon" onClick={toggleBtn22}>
                                                                {
                                                                    eyes22 ? <Visibility style={{ width: "18", height: "18" }} /> : <VisibilityOff style={{ width: "18", height: "18" }} />
                                                                }
                                                            </button>

                                                        </div>

                                                        {ConformPasswordError22 == true ? ConformPasswordError22 && <span style={{ "color": "#BE312E" }}>Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters</span> :
                                                            BothPasswordError && <span style={{ "color": "#BE312E" }}>Password and Confirm Password does not match</span>}
                                                
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="text-center d-block m-auto">
                                                        <button type="button" className="btn btn-primary btn-md    waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".transaction-detailModal" onClick={() => { allDeatils(); }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    © Edu-Test All Rights Reserved {(new Date().getFullYear())}.
                                </div>
                                <div className="col-sm-6">
                                    <div className="text-sm-end d-none d-sm-block">
                                        Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
            </div>
        </>
    );
}


export default MyProfile;