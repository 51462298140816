import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCentersByProjectIdAPI, dispatchLoadingStatus, auditApproveRejectAPI, getAdminCompletedAuditListAPI, getstatecityAPI, getProjectTitleAPI, getCenterListDDLURLAPI } from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import "react-medium-image-zoom/dist/styles.css";
import MainDashbord from "../Sidebar/MainDashbord";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import parse from "html-react-parser";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import blankImg from "../../assets/images/blank.jpg";

function CompletedAudits() {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer", // set the cursor to pointer for the control (main input)
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer", // set the cursor to pointer for each option
    }),
  };
  //added by kajal 15-09-2023

  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const PDF = `<html>

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
            rel="stylesheet" />
        <link rel="icon" href="~/Content/image/logo-f.png" type="image/x-icon">
        <title></title>
    
        <style type="text/css">
            body {
                font-family: 'Roboto', sans-serif;
            }
    
            .border_right {
                border-right: 1px solid #333;
            }
    
            .border_left {
                border-left: 1px solid #333;
            }
    
            .door-sec td {
                border-bottom: 1px solid #999a9a;
                padding: 3px 10px;
            }
    
            .door-sec th {
                text-align: center;
                padding: 10px;
                background: #dfdfdf;
            }
    
            .door-sec td {
                font-size: 14px;
            }
    
            table {
                border-spacing: 0;
                width: 100%
            }
        </style>
    
    </head>
    
    <body>
    
        <table style="width: 100%;">
            <thead>
    
                <tr>
                    <td style="  padding: 65px 10px 0px; width: 100%;">
                        <p
                            style="margin: 0px; font-size: 20; font-weight: bold;text-align: center; text-transform: uppercase; color: black">
                            Effective from 01st April’2022</p>
                        <p
                            style="margin: 15px 0 5px 0; font-size: 15px; font-weight: 300; text-align: center; text-transform: uppercase; color: #000;">
                            Exam Centre Checklist & Booking Form (To be filled)</p>
                    </td>
                    <td style="padding-top: 10px;"><img style="margin-bottom: 5px; max-width: 75px;     padding-left: 35px;"
                            alt="" title="" src="logo.png"></td>
    
                </tr>
            </thead>
        </table>
    
        <tbody>
            <tr>
                <td style="padding-top: 15px;" colspan="9">
                    <table style="width: 100%; margin-top: 35px;" className="">
                        <tbody>
                            <tr>
                                <td>Exam Centre -</td>
                                <td></td>
                                <td>City -</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Audited by </td>
                                <td></td>
                                <td>Contact No.-</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Date of Audit:</td>
                                <td></td>
                                <td>Exam Date:</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
    
    
    
            <tr>
                <td style="padding-top: 15px;" colspan="9">
                    <table style="width: 100%; margin-top: 35px;" className="door-sec">
                        <thead>
    
                            <tr>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Venue Checklist Parameters</th>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Status</th>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Venue Checklist Parameters</th>
                                <th
                                    style="border-top: 1px solid#333;border-bottom: 1px solid#333; border-right: 1px solid #333; border-left: 1px solid #333;">
                                    Status</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr>
                                <td className=" border_left border-right">Distance from Bus/Railway Station (in KM) <br>बस/रेलवे
                                    स्टेशन से दरूी (कि.मी में)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Door with Laches in Every Classroom (Yes/No)<br>हर कक्षा में कुण्डी
                                    वाला दरवाजा (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Conveyance Facility to Centre (Yes/No)<br>केंद्र के
                                    लिए वाहन सुविधा (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">No. of Invigilators (1 Invigilator Per 12 Students)<br>१२ अभ्यर्थी
                                    पर नियुक्त १ निरीक्षक (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Approach road in good condition (Yes/No)<br>एप्रोच रोड
                                    अच्छी स्थिति में है (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">No. of Security Guards (Yes/No)<br>सुरक्षा गार्डों की संख्या
                                    (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Strong Room with Locking facility (Yes/No)<br>लॉकिंग
                                    सुविधा के साथ स्ट्रांग रूम (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Clock Room Facility to keep bags and mobile (Yes/No)<br>छात्रों को
                                    अपना सामान रखने की क्लॉक रूम की सुविधा(हाां/नहीां</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Doors/Windows on Class (Yes/No)<br>कक्षा में
                                    दरवाजे/खिड़कियां (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">First Aid Facility (Yes/No)<br>प्राथमिक चिकित्सा सुविधा (हां/नहीं)
                                </td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Boundary Wall Present (Yes/No)<br>सीमा की दीवार मौजूद
                                    है (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Fire Extinguisher (Yes/No)<br>अग्निशामक (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">Parking Facility (Yes/No)<br>पार्किंग सुविधा
                                    (हां/नहीं)</td>
                                <td className="border_right border_left"></td>
                                <td className="border_right">Genset Available (Yes/No)
                                    KVA?<br>जनरेटर (हाां/नहीां)</td>
                                <td className="border_right border_left"></td>
                            </tr>
    
                            <tr>
                                <td className=" border_left border-right">How many lockable entries and exit points
                                    does the venue have<br>केंद्र में अन्दर तथा बाहर जाने के लिये लॉक करने लायक कितने दरवाजे
                                    है</td>
                                <td className="border_right border_left"></td>
                                <td rowspan="2" className="border_right">Actual Photos from the Site required as below<br>निचे
                                    दिए गये सभी स्थानों का फोटो सलग्न करे <br><br>Classroom (5 Pics), Entrance Gate, and
                                    Main
                                    Building, Toilets, Lobby, Main Exam Control Room</td>
                                <td rowspan="2" className="border_right border_left"></td>
                            </tr>
                            <tr>
                                <td className=" border_left border-right">How many lockable entries and exit points
                                    does the venue have<br>केंद्र में अन्दर तथा बाहर जाने के लिये लॉक करने लायक कितने दरवाजे
                                    है</td>
                                <td className="border_right border_left"></td>
                                
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </body>
    
    </html>`;

  const dispatch = useDispatch({});
  let history = useNavigate();

  let getAdminCompletedAuditListRes = useSelector((state) => state.globalReducer.getAdminCompletedAuditListRes);
  let total = getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.count : "";
  let listCentersByProjectIdRes = useSelector((state) => state.globalReducer.listCentersByProjectIdRes);

  let [filterProject, setFilterProject] = useState("");

  useEffect(() => {
    if (listCentersByProjectIdRes) {
      const filteredArray = listCentersByProjectIdRes?.data?.filter((item) => item !== null);
      setFilterProject(filteredArray);
    }
  }, [listCentersByProjectIdRes]);

  let token = localStorage.getItem("token");

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");
    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(dispatchLoadingStatus(true));
    dispatch(getstatecityAPI());
    dispatch(getProjectTitleAPI(formData));
    dispatch(getCenterListDDLURLAPI(formData));
  }, []);

  const [startDate, setStartdate] = useState(null);

  const [endDate, setEndDate] = useState();
  const [page, setpage] = useState(1);
  const [centerName22, setCenterName22] = useState([]);
  const [centerName33, setCenterName33] = useState([]);
  const [view, setView] = useState(false);
  const [rej, setRej] = useState("");
  const [frname, setFRname] = useState("");
  const [allData22, setAllData22] = useState();
  const [project, setproject] = useState("");
  const [project22, setproject22] = useState();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);


  const handleReason = (e) => {
    setReason(e.target.value.trim() == "" ? "" : e.target.value);
    if (e.target.value == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }
  };
  const Accept = (i) => {
    if (token && i._id) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("audit_id", i._id);
      formData.append("isApproved", 1);
      dispatch(dispatchLoadingStatus(true));
      dispatch(auditApproveRejectAPI(formData));
      Apicall((page - 1) * 20, page);
    } else {
      swal("", "Something went wrong, please try again", "error");
    }
  };

  const Reject = () => {
    if (reason == "") {
      setReasonError(<p className="error">Reason is required</p>);
    } else {
      setReasonError("");
    }

    if (token && rej && reason) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("audit_id", rej);
      formData.append("isApproved", 0);
      formData.append("reject_reason", reason);
      dispatch(dispatchLoadingStatus(true));
      dispatch(auditApproveRejectAPI(formData));
      setShow22(false);
      Apicall((page - 1) * 20, page);
    }
  };

  function Apicall(start) {
    let formData = new FormData();
    let formData22 = new FormData();
    formData.append("token", token);
    formData.append("start", start);
    formData.append("limit", 20);
    formData.append("audited_by", frname);
    formData.append("project_id", project);
    formData.append("center_id", centerName22);
    formData.append("start_date", startDate ? moment(startDate).format("DD/MM/yyyy") : "");
    formData.append("end_date", endDate ? moment(endDate).format("DD/MM/yyyy") : "");

    if (frname || centerName22 || centerName22 == "" || project || project == "" || startDate || endDate) {
      if (localStorage.getItem("getAdminCompletedAuditList") != true) {
        localStorage.setItem("getAdminCompletedAuditList", true);
        dispatch(getAdminCompletedAuditListAPI(formData));
      }
    } else {
      if (localStorage.getItem("getAdminCompletedAuditList") != true) {
        localStorage.setItem("getAdminCompletedAuditList", true);
        dispatch(dispatchLoadingStatus(true));
        dispatch(getAdminCompletedAuditListAPI(formData));
      }
    }

    formData22.append("token", token);
    formData22.append("project_id", project);

    if (project) {
      dispatch(listCentersByProjectIdAPI(formData22));
    }
  }

  let getProjectTitleRes = useSelector((state) => state.globalReducer.getProjectTitleRes);



  function ChangesFRname(value) {
    setFRname(value.trim() == "" ? "" : value);
  }

  useEffect(() => {
    if (frname) {
      setpage(1);

      Apicall(0);
    }
  }, [frname]);

  useEffect(() => {
    debugger;
    if (project || project == "") {
      setpage(1);

      Apicall(0);
    }
  }, [project]);

  useEffect(() => {
    debugger;
    if (centerName22) {
      if (centerName22.length > 0) {
        debugger;
        setpage(1);

        Apicall(0);
      }
    }
  }, [centerName22]);
  useEffect(() => {
    if (startDate == null) {
      setpage(1);

      Apicall(0);
    } else {
      setpage(1);

      Apicall(0);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate == null) {
      setpage(1);

      Apicall(0);
    } else {
      setpage(1);

      Apicall(0);
    }
  }, [endDate]);

  useEffect(() => {
    if (getAdminCompletedAuditListRes) {
      if (getAdminCompletedAuditListRes.errorcode == 1) {
        setAllData22([]);
      } else {
        setAllData22(getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.data : getAdminCompletedAuditListRes.data);
      }
    }

    if (getAdminCompletedAuditListRes) {
      if (getAdminCompletedAuditListRes.errorcode == 0) {
        setAllData22(getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.data : getAdminCompletedAuditListRes.data);
      } else {
        setAllData22([]);
      }
    }
  }, [getAdminCompletedAuditListRes]);

  const [order, setOrder] = useState("ASC");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];

    if (tokenRolee.find((t) => t === "Audits")) {
      if (token) {
        if (!window.location.href.includes("Audits")) {
          window.location.href = window.location.origin + "/Audits";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Dashboard")) {
      if (!window.location.href.includes("Dashboard")) {
        window.location.href = window.location.origin + "/Dashboard";
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  function handleCenterName(e) {
    setCenterName22(e.value);
    setCenterName33(e.label);
  }

  function handleProject(e) {
    if (e.value) {
      setCenterName22("");
      setproject(e.value);
      setproject22(e.label);
    } else {
      setFilterProject([]);
      setCenterName22("");
      setproject("");
      setproject22("Project title");
    }
  }

  return (
    <>
      <Loader />
      <MainDashbord />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Audit List</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <a onClick={() => history("/Dashboard")} className="breadcrumb-item active">
                        Dashboard/
                      </a>{" "}
                      Completed Audit
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body" style={{ padding: "2.25rem 1.25rem" }}>
                    {view ? <button type="button" style={{ float: "right", marginTop: "-25px" }} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setView(false)}></button> : ""}

                    {view ? (
                      parse(PDF)
                    ) : (
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="col-md-3">
                            <form className="mt-4 mt-sm-0 d-sm-flex align-items-center">
                              <div className="search-box me-2 mb-4 w-100">
                                <div className="position-relative">
                                  <input type="text" onChange={(e) => ChangesFRname(e.target.value)} value={frname} className="form-control " placeholder="Search..." style={{ cursor: "pointer" }} />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="col-md-9">
                            <div className="row align-items-center ">
                              <div className="col-md-3 col-6 mb-4 ">
                                <Select className="cursor-pointer" options={getProjectTitleRes ? [{ label: "Project title", value: "" }].concat(getProjectTitleRes.map((e) => ({ label: e.projectTitle, value: e._id }))) : []} isClearable={false} placeholder={"Project title"} value={project == "" ? [{ label: "Project title", value: "" }] : project ? [{ label: project22, value: project }] : [{ label: "Project title", value: "" }]} onChange={(e) => handleProject(e)} styles={customStyles} />
                              </div>

                              <div className="col-md-3 col-6 mb-4">
                                <Select
                                  className="selectCenter"
                                  id="selectCenter"
                                  options={filterProject ? [{ label: "Center name", value: "" }].concat(filterProject.map((e) => ({ label: e.center_name, value: e._id }))) : [{ label: "Center name", value: "" }]}
                                  isClearable={false}
                                  placeholder={"Center name"}
                                  value={centerName22 == "" ? [{ label: "Center name", value: "" }] : centerName22 ? [{ label: centerName33, value: centerName22 }] : [{ label: "Center name", value: "" }]}
                                  styles={customStyles}
                                  onChange={(e) => {
                                    handleCenterName(e);
                                  }}
                                />
                              </div>

                              <div className="col-md-3 col-6 mb-4 ">
                                <DatePicker
                                  name="Startdate"
                                  className="form-control cursor-pointer"
                                  placeholderText={"Start date"}
                                  dateFormat="MM/dd/yyyy"
                                  showMonthDropdown
                                  showYearDropdown
                                  scrollableYearDropdown
                                  minDate={moment("01/01/1947").toDate()}
                                  onChange={(value) => {
                                    if (value === null) {
                                      setStartdate(null);
                                    } else {
                                      // The user selected a date
                                      setStartdate(value);
                                    }
                                  }}
                                  selected={startDate}
                                  maxDate={endDate ? new Date(endDate) : new Date()}
                                  autoComplete="off"
                                  isClearable={true}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="col-md-3 col-6 mb-4">
                                <DatePicker
                                  className="form-control cursor-pointer"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(value) => {
                                    setEndDate(value);
                                  }}
                                  selected={endDate ? endDate : null}
                                  placeholderText={"End date"}
                                  showMonthDropdown
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={moment().year() - 1900}
                                  minDate={startDate}
                                  maxDate={moment().toDate()}
                                  autoComplete="off"
                                  isClearable={true}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                        Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.count : ""} entries
                        <div className="table-responsive">
                          <table id="example" className="table table-striped dt-responsive nowrap w-100">
                            <thead>
                              <tr>
                                <th> Sr No. </th>
                                <th>
                                  Freelancer <i className="cursor-pointer fa fa-sort" onClick={() => Sortt("audited_by")} height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>

                                <th>
                                  Project Name <i onClick={() => Sortt("projectTitle")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th>
                                  Center Name <i onClick={() => Sortt("center_name")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="1">
                                  Audit Date <i onClick={() => Sortt("audited_date")} className="cursor-pointer fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </th>
                                <th data-priority="3" height="15px" width="15px" style={{ marginLeft: 5 }}>
                                  Signed PDF
                                </th>
                                <th data-priority="3" height="15px" width="15px" style={{ marginLeft: 5 }}>
                                  Unsigned PDF
                                </th>

                                <th data-priority="1">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allData22 &&
                                allData22.map((i, index) => (
                                  <tr>
                                    <th> {page * 20 + index + 1 - 20}.</th>

                                    <td>
                                      <div className="d-flex ">
                                        <div className="flex-shrink-0 me-3">
                                          <img className="d-flex-object rounded-circle avatar-xs" alt="" src={i.profiepic ? i.profiepic : blankImg} />
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-weight-bold mb-1">{i.audited_by}</h5>
                                          <p className="text-muted mb-1"></p>
                                        </div>
                                      </div>{" "}
                                    </td>
                                    <td>{i.projectTitle}</td>
                                    <td>{i.center_name}</td>
                                    <td>{moment(i.audited_date).format("DD/MM/yyyy")}</td>

                                    <td>
                                      {i.signed_pbt_audit_pdf_name || i.signed_cbt_audit_pdf_name ? (
                                        <a href={i.exam_type == "PBT" ? (i.signed_pbt_audit_pdf_name ? i.signed_pbt_audit_pdf_name : "") : i.signed_cbt_audit_pdf_name ? i.signed_cbt_audit_pdf_name : ""} target="_black">
                                          <button className="me-2 btn btn-dark waves-effect waves-light">
                                            <i className="me-2 mdi mdi-file-pdf-box-outline"></i>PDF
                                          </button>{" "}
                                        </a>
                                      ) : (
                                        <center>-</center>
                                      )}{" "}
                                    </td>
                                    <td>
                                      {i.unsigned_pbt_audit_pdf_name || i.unsigned_cbt_audit_pdf_name ? (
                                        <a href={i.exam_type == "PBT" ? (i.unsigned_pbt_audit_pdf_name ? i.unsigned_pbt_audit_pdf_name : "") : i.unsigned_cbt_audit_pdf_name ? i.unsigned_cbt_audit_pdf_name : ""} target="_black">
                                          <button className="me-2 btn btn-dark waves-effect waves-light">
                                            <i className="me-2 mdi mdi-file-pdf-box-outline"></i>PDF
                                          </button>{" "}
                                        </a>
                                      ) : (
                                        <center>-</center>
                                      )}
                                    </td>

                                    {i.status == "4" ? (
                                      <td>
                                        {" "}
                                        <center>
                                          {" "}
                                          <div style={{ color: "red" }}>Rejected</div>{" "}
                                          <div style={{ color: "grey" }}>
                                            <tr>{i.reject_reason}</tr>
                                          </div>{" "}
                                        </center>{" "}
                                      </td>
                                    ) : i.status == "5" ? (
                                      <td>
                                        <center>
                                          {" "}
                                          <div style={{ color: "green" }}>Approved</div>{" "}
                                        </center>
                                      </td>
                                    ) : i.status == "1" ? (
                                      <td>
                                        {i.is_audit_complete == true ? (
                                          <div className="d-flex">
                                            <button type="button" className="btn btn-success me-2  waves-effect waves-light" id="sa-success-grt" onClick={() => Accept(i)}>
                                              <i className="mdi mdi-check"></i> Accept
                                            </button>

                                            <button
                                              type="button"
                                              className="btn btn-primary  waves-effect waves-light"
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              onClick={() => {
                                                handleShow22();
                                                setReason("");
                                                setRej(i._id);
                                                setReasonError("");
                                              }}
                                            >
                                              <i className="mdi mdi-close"></i>Reject
                                            </button>
                                          </div>
                                        ) : (
                                          <center>-</center>
                                        )}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {allData22 ? (
                            allData22.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}
                        </div>
                        <Modal show={show22} onHide={handleClose22} backdrop="static" keyboard={false}>
                          <Modal.Header closeButton>
                            <Modal.Title>Reason for Rejection </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <form>
                              <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason}></textarea>
                            </form>
                            {reasonError}
                          </Modal.Body>
                          <Modal.Footer>
                            <button type="button" className="btn btn-light" onClick={handleClose22}>
                              Close
                            </button>
                            {reason == "" ? (
                              <button type="button" className="btn btn-primary" onClick={Reject}>
                                Submit
                              </button>
                            ) : (
                              <button type="button" id="close" className="btn btn-primary" data-bs-dismiss="modal" onClick={Reject}>
                                Submit
                              </button>
                            )}
                          </Modal.Footer>
                        </Modal>
                        <div className=" btns-table align-items-center justify-content-end">
                          {getAdminCompletedAuditListRes ? (
                            getAdminCompletedAuditListRes.count > 0 ? (
                              <div className="row align-items-center wrapper-pg-main">
                                <div className="col-6">
                                  Showing {page <= 1 ? 1 : (page - 1) * 20 + 1} to {page * 20 <= total ? page * 20 : total} of {getAdminCompletedAuditListRes ? getAdminCompletedAuditListRes.count : ""} entries
                                </div>
                                <div className="col-6">
                                  <div className="wrapper-pgn">
                                    <div className="pgn pgn-default">
                                      {getAdminCompletedAuditListRes.count >= 20 ? (
                                        <div className="prev " onClick={() => document.getElementById("content").scrollBy(-50, 0)}>
                                          <i className="bx bx-chevrons-left cursor-pointer"></i>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="pages" id="content">
                                        {(() => {
                                          const pages = [];

                                          for (let i = 1; i < getAdminCompletedAuditListRes.count / 20 + 1; i++) {
                                            pages.push(
                                              <div
                                                className={page == i ? "page page-btn active" : "page page-btn"}
                                                onClick={() => {
                                                  Apicall((i - 1) * 20);
                                                  setpage(i);
                                                }}
                                              >
                                                {i}
                                              </div>
                                            );
                                          }
                                          return pages;
                                        })()}
                                      </div>
                                      {getAdminCompletedAuditListRes.count >= 20 ? (
                                        <div
                                          onClick={() => {
                                            document.getElementById("content").scrollBy(50, 0);
                                          }}
                                          className=" next "
                                        >
                                          <i className="bx bx-chevrons-right"></i>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                        <div className="rightbar-overlay"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Powered by{" "}
                <a href="https://www.nichetechsolutions.com/" target="_blank">
                  Nichetech.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default CompletedAudits;
