import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainDashbord from "../Sidebar/MainDashbord";
import { dashboardDataAPI, getDashboardProjectListAPI, dispatchLoadingStatus, projectFreelancerChartAPI, FreelancerCentersChartAPI } from "../../API/Action/globalAction";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Loader from "../Loader/Loader";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function Dashboard() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  const [allData, setAllData] = useState();
  const [allData22, setAllData22] = useState();

  const [order, setOrder] = useState("ASC");

  useEffect(() => {
    localStorage.removeItem("AddTeamToken");
    localStorage.setItem("projectTitle", "");
    localStorage.setItem("projectTitle22", "Project");

    localStorage.setItem("projectTitle33", "");
    localStorage.setItem("projectTitle44", "Project");
    dispatch(dispatchLoadingStatus(true));
    let formData22 = new FormData();
    formData22.append("token", localStorage.getItem("token"));

    dispatch(dashboardDataAPI(formData22));
    dispatch(getDashboardProjectListAPI(formData22));
  }, []);

  let dashboardDataRes = useSelector((state) => state.globalReducer.dashboardDataRes);
  let getDashboardProjectListRes = useSelector((state) => state.globalReducer.getDashboardProjectListRes);
  let projectVsFreelancerChartData = useSelector((state) => state.globalReducer.projectVsFreelancerChartData);
  let projectVsCenterChartData = useSelector((state) => state.globalReducer.projectVsCenterChartData);

  useEffect(() => {
    if (dashboardDataRes) {
      if (dashboardDataRes.errorcode == 1) {
        setAllData([]);
      } else {
        setAllData(dashboardDataRes ? dashboardDataRes.data : dashboardDataRes.data);
      }
    }
  }, [dashboardDataRes]);

  useEffect(() => {
    if (getDashboardProjectListRes) {
      if (getDashboardProjectListRes.errorcode == 1) {
        setAllData22([]);
      } else {
        setAllData22(getDashboardProjectListRes ? getDashboardProjectListRes.data : getDashboardProjectListRes.data);
      }
    }
  }, [getDashboardProjectListRes]);

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    let tokenRolee = localStorage.getItem("newRoleVissible") ? localStorage.getItem("newRoleVissible").split(",") : [];

    if (tokenRolee.find((t) => t === "Dashboard")) {
      if (token) {
        if (!window.location.href.includes("Dashboard")) {
          window.location.href = window.location.origin + "/Dashboard";
        }
      } else {
        if (!window.location.href.includes("/Login")) {
          window.location.href = window.location.origin + "/Login";
        }
      }
    } else if (tokenRolee.find((t) => t === "Freelancers")) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else if (tokenRolee.find((t) => t === "Projects")) {
      if (!window.location.href.includes("Projects")) {
        window.location.href = window.location.origin + "/Projects";
      }
    } else if (tokenRolee.find((t) => t === "Centers")) {
      if (!window.location.href.includes("Centers")) {
        window.location.href = window.location.origin + "/Centers";
      }
    } else if (tokenRolee.find((t) => t === "Assign")) {
      if (!window.location.href.includes("Addpeople")) {
        window.location.href = window.location.origin + "/Addpeople";
      }
    } else if (tokenRolee.find((t) => t === "Attendance")) {
      if (!window.location.href.includes("Attendance")) {
        window.location.href = window.location.origin + "/Attendance";
      }
    } else if (tokenRolee.find((t) => t === "Message")) {
      if (!window.location.href.includes("Message")) {
        window.location.href = window.location.origin + "/Message";
      }
    } else if (tokenRolee.find((t) => t === "Audits")) {
      if (!window.location.href.includes("Audits")) {
        window.location.href = window.location.origin + "/Audits";
      }
    } else if (tokenRolee.find((t) => t === "Expenses")) {
      if (!window.location.href.includes("Expenses")) {
        window.location.href = window.location.origin + "/Expenses";
      }
    } else if (tokenRolee.find((t) => t === "Role Management")) {
      if (!window.location.href.includes("AdminUserManagement")) {
        window.location.href = window.location.origin + "/AdminUserManagement";
      }
    } else if (tokenRolee.find((t) => t === "Report")) {
      if (!window.location.href.includes("Report")) {
        window.location.href = window.location.origin + "/Reports";
      }
    }
  }, []);

  //added by kajal 11-07-2023
  //declare stat to show the count and title data on graph
  const [projectTitle, setprojectTitle] = useState([]);
  const [freelancerCount, setfreelancerCount] = useState([]);
  const [cprojectTitle, setcprojectTitle] = useState([]);
  const [centerCount, setcenterCount] = useState([]);

  //declare variables to stored the for looping time of data and stored them in variable
  let PTitle = [];
  let FCount = [];
  let CPTitle = [];
  let CCount = [];

  useEffect(() => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(projectFreelancerChartAPI(formData));
  }, []);

  useEffect(() => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    dispatch(FreelancerCentersChartAPI(formData));
  }, []);

  //this useeffect take response check the condition it have data or not and then i iterate the data in for loop
  //check the multiple condition role id wise count the value how much roles have on PBT and CBT type based

  useEffect(() => {
    if (projectVsCenterChartData) {
      for (let index = 0; index < projectVsCenterChartData.length; index++) {
        const title = projectVsCenterChartData[index].projectTitle;
        const Count = projectVsCenterChartData[index].centerCount;
        PTitle.push(title);
        FCount.push(Count);
      }
      setprojectTitle(PTitle);
      setfreelancerCount(FCount);
    }
  }, [projectVsCenterChartData]);
  useEffect(() => {
    if (projectVsFreelancerChartData) {
      for (let index = 0; index < projectVsFreelancerChartData.length; index++) {
        const title = projectVsFreelancerChartData[index].projectTitle;
        const Count = projectVsFreelancerChartData[index].freelancerCount;
        CPTitle.push(title);
        CCount.push(Count);
      }
      setcprojectTitle(CPTitle);
      setcenterCount(CCount);
    }
  }, [projectVsFreelancerChartData]);

  //here create style for bar chart
  const float_container = {
    padding: "5px",
    border: "5px",
    solid: "#ffff",
  };
  const float_child = {
    width: "50%",
    float: "left",
    padding: "20px",
    border: "2px",
    solid: "red",
  };
  const float_child_yellow = {
    backgroundColor: "#FFFFFF",
  };
  const barStyle = {
    height: "350px",
    width: "100%",
  };
  //here create option values provide indexaxis :- horizontal : y / Vertical : x
  // in elements provide type which chart type you want declare in it . im create bar chart
  // provide responsive attribute as a true to fixed the position
  // provide plugins in it provide legend it's position :left or right title provide title attribute display true or false
  // text for title value
  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          display: false,
        },
        font: {
          size: 25,
        },
      },
      y: {
        grid: {
          display: false,
        },
        font: {
          size: 25,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -27,
        align: "start",
      },
      legend: {
        position: "top",
        font: {
          size: 20,
        },
      },
      title: {
        display: true,
        text: "Top 5 Project VS Centers",
        font: {
          size: 15,
        },
      },
    },
  };

  //create label for y axis
  //this label display on y axis
  const label = projectTitle;
  //provide data that i set in usestate
  const data = {
    labels: label,
    datasets: [
      {
        //this label is show on out side area with provided backgroundColor data is showing the count
        label: "Centers Count",
        data: freelancerCount,

        backgroundColor: "rgba(0, 0, 255, 0.8)", //'rgba(143, 199, 119, 0.6)',
        borderColor: "rgba(0, 0, 255)",
        barThickness: 40,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options1 = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          display: false,
        },
        font: {
          size: 25,
        },
      },
      y: {
        grid: {
          display: false,
        },
        font: {
          size: 25,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -27,
        align: "start",
      },
      legend: {
        position: "top",
        font: {
          size: 20,
        },
      },
      title: {
        display: true,
        text: "Top 5 Project VS Freelancer",
        font: {
          size: 15,
        },
      },
    },
  };

  //create label for y axis
  //this label display on y axis
  const label1 = cprojectTitle;
  //provide data that i set in usestate
  const data1 = {
    labels: label1,
    datasets: [
      {
        //this label is show on out side area with provided backgroundColor data is showing the count
        label: "Freelancer Count",
        data: centerCount,
        backgroundColor: "rgba(0, 0, 255, 0.8)", //'rgba(143, 199, 119, 0.6)',
        borderColor: "rgba(0, 0, 255)",
        barThickness: 40,
        categoryPercentage: 0.5,
      },
    ],
  };
  //finish by kajal 11-07-2023
  return (
    <>
      {" "}
      <Loader />
      <div id="layout-wrapper">
        <MainDashbord />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <a href="/Dashboard" onClick={() => history()} className="breadcrumb-item active">
                          Dashboard
                        </a>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Freelancers</p>
                          <h4 className="mb-0">{allData ? allData.total_freelancers_count : ""}</h4>
                        </div>

                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-user font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Projects</p>
                          <h4 className="mb-0">{allData ? allData.total_projects_count : ""}</h4>
                        </div>

                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Active Projects</p>
                          <h4 className="mb-0">{allData ? allData.total_active_projects_count : ""}</h4>
                        </div>

                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-layout font-size-24"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* added by kajal 11-07-2023 */}
              <div style={float_container} className="float-container">
                <div style={float_child} className="float-child">
                  <div style={float_child_yellow} className="blue">
                    <Bar style={barStyle} data={data} options={options} />
                  </div>
                </div>
                <div style={float_child} className="float-child">
                  <div style={float_child_yellow} className="blue">
                    <Bar style={barStyle} data={data1} options={options1} />
                  </div>
                </div>
              </div>
              {/* finish by kajal 11-07-2023 */}
              <div className="row mt-3">
                <div className="col-12 ">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h5 className="mb-sm-0 ">Active Projects</h5>
                    <div className="page-title-right">
                      <a onClick={() => history("/Projects")} className="  btn btn-primary float-lg-end waves-effect waves-light">
                        View all
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-rep-plugin">
                        <div className="row mb-3 no-gutters">
                          <div className="table-responsive ">
                            <table id="example" className="table table-striped dt-responsive">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th data-priority="1">
                                    Project Title
                                    <i onClick={() => Sortt("projectTitle")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                  </th>
                                  <th data-priority="2">
                                    Type
                                    <i onClick={() => Sortt("examType")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>
                                  <th data-priority="2">
                                    States
                                    <i onClick={() => Sortt("projectStates")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>
                                  <th data-priority="2">
                                    Start Date
                                    <i onClick={() => Sortt("startdate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>
                                  <th data-priority="2">
                                    End Date
                                    <i onClick={() => Sortt("enddate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                                  </th>
                                  <th data-priority="5">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allData22 &&
                                  allData22.map((i, index) => (
                                    <tr key={"proj-" + index}>
                                      <td>{index + 1}.</td>
                                      <td>{i.projectTitle}</td>
                                      <td>{i.examType}</td>
                                      <td>{i.projectStates}</td>
                                      <td>{moment(i.startdate).format("DD/MM/YYYY")}</td>
                                      <td>{moment(i.enddate).format("DD/MM/YYYY")}</td>
                                      <td>
                                        <button className="me-2 btn btn-primary waves-effect waves-light" onClick={() => history("/Projectdetails", { state: i })} key={i._id}>
                                          Track
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {getDashboardProjectListRes && getDashboardProjectListRes.data ? (
                            getDashboardProjectListRes.data.length > 0 ? null : (
                              <div style={{ textAlign: "center" }}>
                                <p>No data found</p>{" "}
                              </div>
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">© Edu-Test All Rights Reserved {new Date().getFullYear()}.</div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Powered by{" "}
                    <a href="https://www.nichetechsolutions.com/" target="_blank">
                      Nichetech.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
